/* eslint-disable jsx-a11y/anchor-is-valid */
import { ReactElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  ContactUsContainer,
  ContactColWrapper,
  ContactRowWrapper,
  Header,
  Content,
  Border,
  FooterText,
  FooterMenu,
  FooterLogo,
} from "./subcomponent";
import Aos from "aos";
import playStore from "../../asserts/google-play.png";
import appStore from "../../asserts/iphone.png";
import { useHistory } from "react-router-dom";
import ROUTES from "../../const/routes";
import FlexWrapper from "../FlexWrapper";
import DOHR from "../../asserts/dohrWhite.png";
import { updatefaq } from "../../redux/Common/action";

const Footers = ({ marginTop }: { marginTop?: string }): ReactElement => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { foo, head } = useSelector((state: RootState) => ({
    foo: state.Language.language.footer,
    head: state.Language.language.header.main,
  }));
  const {
    Our_solutions,
    other,
    Recruitment,
    Personal_Development,
    saam,
    gswith,
    Customers,
    Substitutes,
    Jobseekers,
    more,
  } = foo;

  useEffect(() => {
    Aos.init({
      duration: 900,
      once: false,
    });
  }, []);

  return (
    <ContactUsContainer marginTop={marginTop}>
      <ContactRowWrapper xs={1} sm={5}>
        <ContactColWrapper paddingleft="10px" data-aos="fade-right">
          <Header>{Our_solutions}</Header>
          <Content>
            <FooterMenu
              onClick={() => {
                history.push(ROUTES.STAFF_ABSENCE);
              }}
            >
              {saam}
            </FooterMenu>
            <FooterMenu
              onClick={() => {
                history.push(ROUTES.RECRUITMENT);
              }}
            >
              {Recruitment}
            </FooterMenu>
            <FooterMenu
              onClick={() => {
                history.push(ROUTES.DEVELOPMENT);
              }}
            >
              {Personal_Development}
            </FooterMenu>
          </Content>
        </ContactColWrapper>
        <ContactColWrapper paddingleft="1px" data-aos="fade-right">
          <Header>{gswith}</Header>
          <Content>
            <FooterMenu
              onClick={() => {
                history.push(ROUTES.OUR_SOLUTION);
              }}
            >
              {Customers}
            </FooterMenu>
            <FooterMenu
              onClick={() => {
                history.push(ROUTES.SUBSTITUTE);
              }}
            >
              {Substitutes}
            </FooterMenu>
            <FooterMenu
              onClick={() => {
                history.push(ROUTES.JOB_SEEKER);
              }}
            >
              {Jobseekers}
            </FooterMenu>
          </Content>
        </ContactColWrapper>
        <ContactColWrapper paddingleft="55px" data-aos="fade-right">
          <Header>{more}</Header>
          <Content>
            <FooterMenu
              onClick={() => {
                history.push(ROUTES.ABOUT);
              }}
            >
              {head.aboutUs}
            </FooterMenu>
            <FooterMenu
              onClick={() => {
                history.push(ROUTES.CONTACT_FORM);
              }}
            >
              {head.contactUs}
            </FooterMenu>
            <FooterMenu
              onClick={() => {
                history.push(ROUTES.NEWS);
              }}
            >
              {head.news}
            </FooterMenu>

            <FooterMenu
              onClick={() => {
                dispatch(updatefaq("second"));
                history.push(ROUTES.STATIC_FAQ);
              }}
            >
              {head.faq}
            </FooterMenu>
            <FooterMenu
              onClick={() => {
                history.push(ROUTES.PRIVACY_POLICY);
              }}
            >
              {head.integrity}
            </FooterMenu>
          </Content>
        </ContactColWrapper>
        <ContactColWrapper data-aos="fade-left" paddingleft="50px">
          <Header>{other.Download}</Header>
          <a href="/#">
            <img className="apple" src={appStore} alt="Get it On PlayStore" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.dohr.ab">
            <img src={playStore} alt="Get it On PlayStore" />
          </a>
        </ContactColWrapper>
        <FlexWrapper justifyContent="center" noMargin noPadding>
          <ContactColWrapper data-aos="fade-left" paddingleft="70px">
            <Header>{other.follow_us}</Header>
            <span
              onClick={() => {
                window.open(
                  "https://www.facebook.com/profile.php?id=61556034750446"
                );
              }} 
            >
              <i id="link" className="bx bxl-facebook"></i>
            </span>
            <span
              onClick={() => { 
                window.open("https://www.instagram.com/dohr.io/");
              }}
            >
              {" "}
              <i id="insta" className="bx bxl-instagram"></i>
            </span>
          </ContactColWrapper>
        </FlexWrapper>
      </ContactRowWrapper>
      <Border />

      <FlexWrapper justifyContent="space-between" noMargin>
        <FooterText> 072-157 20 71 | support@dohr.io</FooterText>

        <FooterText noPadding style={{ textAlign: "end" }}>
          {other.rights} <FooterLogo src={DOHR} alt="logo" />
        </FooterText>
      </FlexWrapper>
    </ContactUsContainer>
  );
};

export default Footers;
