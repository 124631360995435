import { ReactElement, useEffect, useState } from "react";
import { Form, Modal, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  ActionButton,
  FlexWrapper,
  Loader,
  PageWrapper,
  SectionTitle,
} from "../../../../components";
import apiEndpoints from "../../../../const/apiEndpoints";
import ROUTES from "../../../../const/routes";
import { CommonDelete } from "../../../../redux/Common/api";
import { getMyInstitute } from "../../../../redux/Hq_Management/LeaveManagement/api";
import { getCreatedCustomer } from "../../../../redux/login/api";
import { RootState } from "../../../../redux/store";
import { Label } from "../../../../typography";
import { CardWraper } from "../../../HqManagement/AbsenceManagement/unCovered/subcomponent";
import { AdminAction, CancleButton, DeleteButton } from "./subcomponents";
import { EditCustomerStatus } from "../../../../redux/Institute/api";

const CreatedCustomer = (): ReactElement => {
  const { userInfo, getCustomer, lang, isLoading, reload } = useSelector(
    (state: RootState) => ({
      userInfo: state.login.userInfo,
      getCustomer: state.login.getCustomerdetaisl,
      isLoading: state.login.isLoading,
      lang: state.Language.language.Tables.management,
      reload: state.common.reload,
    })
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const [deleteuser, setdeleteuser] = useState("");

  useEffect(() => {
    dispatch(
      getMyInstitute({
        id: userInfo?.data?.id,
      })
    );
    dispatch(
      getCreatedCustomer({
        ins_id: userInfo?.data.id,
      })
    ); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reload) {
      dispatch(
        getCreatedCustomer({
          ins_id: userInfo?.data.id,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <PageWrapper>
      <CardWraper>
        <FlexWrapper
          backgroundColor="#eff3fa"
          noMargin
          justifyContent="space-between"
        >
          <SectionTitle title={lang.sectionTitle} noMargin />
          <ActionButton
            onClick={() => {
              history.push(ROUTES.INSTITUTE.CREATE_CUSTOMER);
            }}
          >
            {lang.button}
          </ActionButton>
        </FlexWrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <FlexWrapper justifyContent="center">
            <Table striped borderless hover>
              <thead
                style={{
                  backgroundColor: "#2097f5",
                  color: "white",
                  fontFamily: "Montserrat",
                }}
              >
                <tr style={{ textAlign: "center" }}>
                  {lang.tableHeader.map((data, index) => (
                    <th key={`Header Unique id -${index}`}>{data}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {getCustomer.map((data, index) => (
                  <tr key={index} style={{ textAlign: "center" }}>
                    <td>{++index}</td>
                    <td>{data?.first_name}</td>
                    <td>{data?.last_name}</td>
                    <td>{data?.email_id}</td>

                    <td>{data?.title}</td>
                    <td>
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={data.is_inactive === 1 ? false : true}
                        onClick={(e: any) => {
                          const { checked } = e.target;
                          if (checked) {
                            dispatch(
                              EditCustomerStatus({ id: data.id, value: 0 })
                            );
                          } else {
                            dispatch(
                              EditCustomerStatus({ id: data.id, value: 1 })
                            );
                          }
                        }}
                      />
                    </td>
                    <td style={{ width: "150px" }}>
                      <AdminAction
                        handleDelete={() => {
                          setdeleteuser(data?.id);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </FlexWrapper>
        )}
      </CardWraper>

      <Modal
        show={deleteuser ? true : false}
        size={"sm"}
        centered={true}
        backdrop="static"
        keyboard={false}
      >
        <FlexWrapper justifyContent="center" width="100%">
          <FlexWrapper justifyContent="center">
            {/* <Emoji src={emoji} alt="emoji" /> */}
            <Label>{lang.modal}</Label>
          </FlexWrapper>
          <FlexWrapper noPadding>
            <CancleButton onClick={() => setdeleteuser("")}>No</CancleButton>
            <DeleteButton
              onClick={() => {
                setdeleteuser("");
                dispatch(
                  CommonDelete({
                    id: deleteuser,
                    endPoint: apiEndpoints.deleteAdministators,
                  })
                );
              }}
            >
              Yes
            </DeleteButton>
          </FlexWrapper>
        </FlexWrapper>
      </Modal>
    </PageWrapper>
  );
};

export default CreatedCustomer;
