import { ReactElement, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Form,
  FormControl,
  Modal,
  ModalBody,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import {
  DropdownWrapper,
  FlexWrapper,
  Input,
  ActionButton,
  ToastMessage,
  Loader,
  Modals,
} from "../../../../components";
import { updateRegister } from "../../../../redux/login/action";
import { handleRegister, EmailValidation } from "../../../../redux/login/api";
import { RootState } from "../../../../redux/store";
import { initialValuesBoolan } from "./const";
import "react-datepicker/dist/react-datepicker.css";
import {
  CheckBoxWrapper,
  PageContainer,
  TitleCard,
  DropdownWrapperfor,
  PlusButton,
  LangContainer,
} from "./subcomponent";
import { RegisterField } from "./types";
import validateofRegisterForm from "./helper";
import { Title } from "../../../../components/EditableDropdown/subcomponents";
import Input2 from "../../../../components/Input2";
import { colors } from "../../../../const/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Rating from "react-rating";
import { expDetailsProps } from "../../../../redux/login/types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { validateForm } from "./FormValidation/validation";
import { PrivacyPolicy } from "../../../../pages";

const RegisterForm = (): ReactElement => {
  const {
    login: { registerPayload, hasRegister, isLoading, emailvalidation },
    register,
  } = useSelector((state: RootState) => ({
    login: state.login,
    register: state.Language.language.register,
    hasRegister: state.login.hasRegister,
    emailvalidation: state.login.emailvalidation,
  }));

  const dispatch = useDispatch();
  //inputFields
  const [values, setValues] = useState(registerPayload);
  const [otherLang, setOtherLang] = useState({
    other: "",
    ratin: 0,
    proficiency: "",
  });

  const [checkBox, setcheckBox] = useState(initialValuesBoolan);
  const [errors, setErrors] = useState({} as Record<string, string>);
  const [isStudent, setisStudent] = useState("");
  const [fileValidat, setFileValidat] = useState(false);
  const [fileSize, setFileSize] = useState(false);
  const [rating, setRating] = useState({ swedish: 0, english: 0, other: 0 });
  const [worIn, setWorkIn] = useState({} as expDetailsProps);
  const [worOut, setWorkOut] = useState({} as expDetailsProps);
  const [otherOccu, setotherOccu] = useState({} as expDetailsProps);
  const [fileSelected, setFileSelected] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const validateForms = (field: RegisterField): void => {
    setErrors(
      validateofRegisterForm({
        values,
        errors,
        field,
      })
    );
  };

  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [preferredWorkInstitutions, setPreferredWorkInstitutions] = useState<
    string[]
  >([]);

  const isLanguageSkillsValid = () => {
    // Check if at least one checkbox is selected or the "other" language section is filled out
    if (values?.language_skills.swedish || values?.language_skills.english) {
      return true; // Validation success
    }
    return false; // Validation failed
  };

  const handleCheckboxChange = (name: string) => {
    const isChecked = preferredWorkInstitutions.includes(name);
    const updatedPreferredWorkInstitutions = isChecked
      ? preferredWorkInstitutions.filter((item) => item !== name)
      : [...preferredWorkInstitutions, name];

    setPreferredWorkInstitutions(updatedPreferredWorkInstitutions);

    // Update the form values
    setValues({
      ...values,
      preferred_work_institution: updatedPreferredWorkInstitutions,
    });
  };

  const isCheckboxChecked = (name: string) =>
    preferredWorkInstitutions.includes(name);

  const isAtLeastOneCheckboxSelected = () =>
    preferredWorkInstitutions.length > 0;

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const selectedDate = values.date_of_brith
    ? typeof values.date_of_brith === "string"
      ? new Date(values.date_of_brith)
      : values.date_of_brith
    : null;

  const selectedSpecifyDate = values.specify_date
    ? typeof values.specify_date === "string"
      ? new Date(values.specify_date)
      : values.specify_date
    : null;

  const emailMessage = emailvalidation.message;

  const handleSubmit = async (e: any) => {
    e.preventDefault(); // Prevent the form from actually submitting

    // Set the form as submitted
    setSubmitted(true);

    const validationErrors = validateForm(values);
    setErrors(validationErrors);

    // Check if there are validation errors
    if (Object.values(validationErrors).some((error) => !!error)) {
      // If there are validation errors, log them and show the modal
      setModalMessage("Please fill in all required fields");
      setShowModal(true);
      return; // Stop further processing
    }

    // Check if the terms and conditions checkbox is not checked
    if (!checkBox.terms || !checkBox?.accurate_info || !checkBox?.marketing) {
      // Show a separate alert message or modal
      setModalMessage(errormsgcheck);
      setShowModal(true);
      return; // Stop further processing
    }
    if (!isLanguageSkillsValid()) {
      // Show a separate alert message or modal for language skills validation
      setModalMessage("Please select atleast one language");
      setShowModal(true);
      return; // Stop further processing
    }

    // If no validation errors and terms are accepted, proceed with registration
    await dispatch(
      handleRegister({
        ...values,
        terms_and_condition: checkBox?.terms,
        accurate_information: checkBox?.accurate_info,
        marketing_purpose: checkBox?.marketing,
        info_work_exp_in_school: [...values.info_work_exp_in_school, worIn],
        info_work_exp_out_school: [...values.info_work_exp_out_school, worOut],
        info_other_work_experience: [
          ...values.info_other_work_experience,
          otherOccu,
        ],
        iam_student: false,
      })
    );

    setValues(registerPayload);
    setRating({ swedish: 0, english: 0, other: 0 });
    setPreferredWorkInstitutions([]);
    setcheckBox(initialValuesBoolan);
    setisStudent("");
    setSubmitted(false);
  };

  const addFormFields = () => {
    if (
      worIn.duration ||
      worIn.key_res ||
      worIn.name_of_emp ||
      worIn.title_of_role
    ) {
      setValues({
        ...values,
        info_work_exp_in_school: [
          ...values.info_work_exp_in_school,
          { ...worIn },
        ],
      });
    }

    setWorkIn({
      name_of_emp: "",
      duration: "",
      title_of_role: "",
      key_res: "",
    });
  };

  const addFormFieldsOut = () => {
    if (
      worOut.duration ||
      worOut.key_res ||
      worOut.name_of_emp ||
      worOut.title_of_role
    ) {
      setValues({
        ...values,
        info_work_exp_out_school: [
          ...values.info_work_exp_out_school,
          { ...worOut },
        ],
      });
    }
    setWorkOut({
      name_of_emp: "",
      duration: "",
      title_of_role: "",
      key_res: "",
    });
  };

  const addFormFieldsOther = () => {
    if (
      otherOccu.duration ||
      otherOccu.key_res ||
      otherOccu.name_of_emp ||
      otherOccu.title_of_role
    ) {
      setValues({
        ...values,
        info_other_work_experience: [
          ...values.info_other_work_experience,
          { ...otherOccu },
        ],
      });
    }

    setotherOccu({
      name_of_emp: "",
      duration: "",
      title_of_role: "",
      key_res: "",
    });
  };

  const removeFormFields = (i: any) => {
    let newFormValues = [...values.info_work_exp_in_school];
    newFormValues.splice(i, 1);
    setValues({ ...values, info_work_exp_in_school: newFormValues });
  };

  const removeFormFieldsOut = (i: any) => {
    let newFormValues = [...values.info_work_exp_out_school];
    newFormValues.splice(i, 1);
    setValues({ ...values, info_work_exp_out_school: newFormValues });
  };

  const removeFormFieldsOther = (i: any) => {
    let newFormValues = [...values.info_other_work_experience];
    newFormValues.splice(i, 1);
    setValues({ ...values, info_other_work_experience: newFormValues });
  };

  const {
    sectionTitle,
    firstName,
    lastName,
    address,
    email,
    dob,
    phoneNumber,
    swedishBankAccount,
    selfEmploye,
    freelancer,
    primary_occupation,
    selectDp,
    student,
    education_type,
    prefferedWorkInsti,
    description,
    workExperienceinSchool,
    workExperienceoutSchool,
    otherOccupation,
    nameOfEmploye,
    workWeekend,
    durations,
    studyTime,
    titleofRole,
    keyRes,
    Post_code,
    nameofIns,
    workStatrt,
    swedish,
    english,
    otherLange,
    policeCLearenceReport,
    drivingLicence,
    howMayHours,
    city,
    area_name,
    sectionprocedure,
    personal,
    work,
    lang,
    other,
    availablity,
    checkbox_1,
    checkbox_2,
    checkbox_3,
    declaration_1,
    declaration_2,
    declaration_3,
    submit,
    YESORNO,
    CareInstitution,
    Gymnasium,
    Preschool,
    school,
    doing,
    Time,
    Uploadproof,
    Uploadproofi,
    Specify,
    Assoonaspossible,
    Icanstart,
    Specifydate,
    HOURSELECTION,
    Beginner,
    Fluent,
    Intermediate,
    Native,
    Proficient,
    Otherlanguage,
    swedishlaw1,
    swedishlaw2,
    permitlaw2,
    permitlaw,
    hear,
    workSweden,
    howKnowAboutUs,
    DOB,
    OtherSpecify,
    message,
    mail,
    errormsgcheck,
  } = register;

  const [privacy, setPrivacy] = useState(false);

  var query450 = window.matchMedia("(max-width: 550px)");

  return (
    <PageContainer>
      <Card style={{ margin: "1rem auto" }}>
        <FlexWrapper
          backgroundColor="#2097F5"
          height={55}
          noMargin
          justifyContent="center"
        >
          <p
            style={{
              fontWeight: "600",
              fontSize: "20px",
              paddingBottom: "0px",
              color: colors.darkBlue,
            }}
          >
            {sectionTitle}
          </p>
        </FlexWrapper>
        <FlexWrapper
          backgroundColor="#2097F5"
          noMargin
          noPadding
          justifyContent="center"
        >
          <p style={{ paddingBottom: "0px", color: colors.darkBlue }}>
            {sectionprocedure}
          </p>
        </FlexWrapper>
        <Accordion defaultActiveKey="">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <Title>{personal}</Title>
            </Accordion.Header>
            <Accordion.Body>
              <FlexWrapper noMargin noPadding>
                <DropdownWrapper>
                  <Input2
                    value={values?.first_name}
                    label={firstName}
                    onBlur={() => validateForms("first_name")}
                    error={errors.first_name}
                    onChange={(value: string) => {
                      setValues({
                        ...values,
                        first_name: value,
                      });
                      validateForms("first_name");
                    }}
                  />
                </DropdownWrapper>
                <DropdownWrapper>
                  <Input2
                    value={values?.last_name}
                    label={lastName}
                    onBlur={() => validateForms("last_name")}
                    error={errors.last_name}
                    onChange={(value: string) => {
                      setValues({
                        ...values,
                        last_name: value,
                      });
                      validateForms("last_name");
                    }}
                  />
                </DropdownWrapper>
              </FlexWrapper>
              <FlexWrapper noMargin noPadding>
                <DropdownWrapper>
                  <Input2
                    value={values?.address}
                    label={address}
                    onBlur={() => validateForms("address")}
                    error={errors.address}
                    onChange={(value: string) => {
                      setValues({
                        ...values,
                        address: value,
                      });
                      validateForms("address");
                    }}
                  />
                </DropdownWrapper>
                <DropdownWrapper>
                  <Input2
                    value={values?.postalcode}
                    label={Post_code}
                    inputType="number"
                    onBlur={() => validateForms("postalcode")}
                    error={errors.postalcode}
                    onChange={(value: string) => {
                      setValues({
                        ...values,
                        postalcode: value,
                      });
                      validateForms("postalcode");
                    }}
                  />
                </DropdownWrapper>
                <DropdownWrapper>
                  <Input2
                    value={values?.area_name}
                    onBlur={() => validateForms("area_name")}
                    error={errors.area_name}
                    label={area_name}
                    onChange={(value: string) => {
                      setValues({
                        ...values,
                        area_name: value,
                      });
                      validateForms("area_name");
                    }}
                  />
                </DropdownWrapper>
                <DropdownWrapper>
                  <Input2
                    value={values?.city}
                    onBlur={() => validateForms("city")}
                    error={errors.city}
                    label={city}
                    onChange={(value: string) => {
                      setValues({
                        ...values,
                        city: value,
                      });
                      validateForms("city");
                    }}
                  />
                </DropdownWrapper>
              </FlexWrapper>
              <FlexWrapper noMargin noPadding>
                <DropdownWrapper>
                  <Input2
                    value={values?.phone_number}
                    inputType="number"
                    onBlur={() => validateForms("phone_number")}
                    error={errors.phone_number}
                    label={phoneNumber}
                    onChange={(value: string) => {
                      setValues({
                        ...values,
                        phone_number: value,
                      });
                      validateForms("phone_number");
                    }}
                  />
                </DropdownWrapper>
                <DropdownWrapper>
                  <Input2
                    value={values?.email_id}
                    label={email}
                    onBlur={() => validateForms("email_id")}
                    error={errors.email_id}
                    onChange={(value: string) => {
                      setValues({
                        ...values,
                        email_id: value,
                      });
                      dispatch(EmailValidation({ email_id: value }));
                      validateForms("email_id");
                    }}
                  />
                </DropdownWrapper>

                <DropdownWrapper style={{ marginTop: "-5px" }}>
                  <DatePicker
                    selected={selectedDate}
                    dateFormat="yyyy/MM/dd"
                    showMonthDropdown
                    showYearDropdown
                    yearDropdownItemNumber={50}
                    scrollableYearDropdown
                    onBlur={() => validateForms("date_of_brith")}
                    placeholderText={DOB}
                    onChange={(value) => {
                      setValues({
                        ...values,
                        date_of_brith: value,
                      });
                      validateForms("date_of_brith");
                    }}
                    customInput={
                      <Input
                        value={selectedDate}
                        label={dob}
                        placeholder="Select date"
                        error={errors.date_of_brith}
                      />
                    }
                  />
                </DropdownWrapper>
              </FlexWrapper>
              <FlexWrapper
                noMargin
                noPadding
                style={{ flexDirection: "column" }}
              >
                <TitleCard
                  style={{
                    color: colors.darkBlue,
                    marginLeft: "2%",
                  }}
                >
                  {swedishBankAccount}
                </TitleCard>
                <DropdownWrapper>
                  <Input2
                    value={
                      values?.reg_i_have_swedish
                        ? YESORNO[0].name
                        : values?.reg_i_have_swedish === false
                        ? YESORNO[1].name
                        : ""
                    }
                    label={selectDp}
                    onBlur={() => validateForms("reg_i_have_swedish")}
                    error={errors.reg_i_have_swedish}
                    dropdownList={YESORNO}
                    onChange={(value) => {
                      setValues({
                        ...values,
                        reg_i_have_swedish:
                          value === YESORNO[0].name ? true : false,
                      });
                      validateForms("reg_i_have_swedish");
                    }}
                  />
                </DropdownWrapper>
              </FlexWrapper>
              <FlexWrapper noMargin noPadding>
                <TitleCard style={{ marginLeft: "2%" }}>
                  {prefferedWorkInsti}
                </TitleCard>
                <DropdownWrapperfor
                  width="100"
                  className={
                    submitted && !isAtLeastOneCheckboxSelected() ? "errors" : ""
                  }
                >
                  <Form.Check
                    name="preschool"
                    label={Preschool}
                    checked={isCheckboxChecked("preschool")}
                    onClick={() => handleCheckboxChange("preschool")}
                    style={{
                      paddingRight: "2%",
                      marginTop: "8px",
                      color: colors.darkBlue,
                      fontSize: "15px",
                    }}
                  />
                  <Form.Check
                    name="school"
                    label={school}
                    checked={isCheckboxChecked("school")}
                    onClick={() => handleCheckboxChange("school")}
                    style={{
                      paddingRight: "2%",
                      marginTop: "8px",
                      color: colors.darkBlue,
                      fontSize: "15px",
                    }}
                  />{" "}
                  <Form.Check
                    name="Gymnasium"
                    label={Gymnasium}
                    checked={isCheckboxChecked("Gymnasium")}
                    onClick={() => handleCheckboxChange("Gymnasium")}
                    style={{
                      paddingRight: "2%",
                      marginTop: "8px",
                      color: colors.darkBlue,
                      fontSize: "15px",
                    }}
                  />
                  <Form.Check
                    name="CareInstitution"
                    label={CareInstitution}
                    checked={isCheckboxChecked("CareInstitution")}
                    onClick={() => handleCheckboxChange("CareInstitution")}
                    style={{
                      paddingRight: "2%",
                      marginTop: "8px",
                      color: colors.darkBlue,
                      fontSize: "15px",
                    }}
                  />
                </DropdownWrapperfor>
              </FlexWrapper>
              <FlexWrapper noPadding style={{ flexDirection: "column" }}>
                <TitleCard style={{ marginLeft: "2%", color: colors.darkBlue }}>
                  {description}
                </TitleCard>
                <DropdownWrapperfor width="40">
                  <Input
                    height="auto"
                    value={values?.reg_short_description}
                    onBlur={() => validateForms("reg_short_description")}
                    inputType="textarea"
                    error={errors.reg_short_description}
                    onChange={(value: string) => {
                      setValues({
                        ...values,
                        reg_short_description: value,
                      });
                      validateForms("reg_short_description");
                    }}
                  />
                </DropdownWrapperfor>
              </FlexWrapper>

              <FlexWrapper noMargin noPadding style={{ marginTop: "2%" }}>
                <TitleCard
                  style={{
                    color: colors.darkBlue,
                    width: "100%",
                    marginLeft: "2%",
                  }}
                >
                  {doing}
                </TitleCard>
                <DropdownWrapper>
                  <Input2
                    value={isStudent}
                    label={student}
                    onBlur={() => validateForms("reg_iam_teacher_student")}
                    error={errors.reg_iam_teacher_student}
                    dropdownList={YESORNO}
                    onChange={(value) => {
                      setValues({
                        ...values,
                        reg_iam_teacher_student:
                          value === YESORNO[0].name ? true : false,
                      });
                      setisStudent(value);
                      validateForms("reg_iam_teacher_student");
                    }}
                  />
                </DropdownWrapper>
                {values?.reg_iam_teacher_student === true && (
                  <>
                    <DropdownWrapper>
                      <Input2
                        value={values?.type_of_study}
                        label={education_type}
                        onBlur={() => validateForms("type_of_study")}
                        error={errors.type_of_study}
                        dropdownList={Time}
                        onChange={(value) => {
                          setValues({
                            ...values,
                            type_of_study: value,
                          });
                          validateForms("type_of_study");
                        }}
                      />
                    </DropdownWrapper>
                    {values.type_of_study === Time[1].name ? (
                      <DropdownWrapper>
                        <Input2
                          value={values.state_hours_weeks_student}
                          onBlur={() =>
                            validateForms("state_hours_weeks_student")
                          }
                          error={errors.state_hours_weeks_student}
                          label={studyTime}
                          onChange={(value: string) => {
                            setValues({
                              ...values,
                              state_hours_weeks_student: value,
                            });
                            validateForms("state_hours_weeks_student");
                          }}
                        />
                        <span style={{ color: "red" }}>
                          {errors.state_hours_weeks_student}
                        </span>
                      </DropdownWrapper>
                    ) : null}

                    <DropdownWrapper>
                      <Input2
                        value={values?.name_of_institutition}
                        label={nameofIns}
                        onBlur={() => validateForms("name_of_institutition")}
                        error={errors.name_of_institutition}
                        onChange={(value: string) => {
                          setValues({
                            ...values,
                            name_of_institutition: value,
                          });
                          validateForms("name_of_institutition");
                        }}
                      />
                    </DropdownWrapper>

                    <DropdownWrapper>
                      <TitleCard
                        style={{
                          color: colors.darkBlue,
                          marginTop: query450?.matches ? "-5%" : "-12%",
                          marginLeft: "2%",
                          marginBottom: "12px",
                        }}
                      >
                        {Uploadproof}{" "}
                        <Tooltip title={Uploadproofi} placement="top-start">
                          <span>
                            <FontAwesomeIcon
                              icon={["fas", "info-circle"]}
                              size="sm"
                            />
                          </span>
                        </Tooltip>
                      </TitleCard>
                      <FormControl
                        type="file"
                        isValid={fileSelected} // Apply isValid class when a valid file is selected
                        isInvalid={!fileSelected} // Apply isInvalid class when no file is selected or there's an error
                        onChange={(e: any) => {
                          const file = e.target.files[0]; // Get the selected file

                          if (!file) {
                            // No file selected
                            setFileSelected(false); // Set fileSelected to false for validation
                            return; // Don't proceed further
                          }

                          if (
                            file.type !== "application/pdf" &&
                            file.type !== "image/jpeg" &&
                            file.type !== "image/jpg" &&
                            file.type !== "image/png" &&
                            file.type !== "image/gif"
                          ) {
                            setFileValidat(true);
                            setFileSize(false);
                            setFileSelected(false); // Set fileSelected to false for validation
                            window.scrollTo({ top: 0 });
                          } else if (file.size > 1000000) {
                            setFileSize(true);
                            setFileValidat(false);
                            setFileSelected(false); // Set fileSelected to false for validation
                            window.scrollTo({ top: 0 });
                          } else {
                            if (values.reg_iam_teacher_student === false) {
                              setFileSelected(false);
                            }
                            // Valid file selected
                            else setFileSelected(true); // Set fileSelected to true
                            // Read the file as a Data URL
                            let reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onload = () => {
                              setValues({
                                ...values,
                                proof_of_studies: reader.result,
                              });
                            };
                          }
                        }}
                      />
                    </DropdownWrapper>
                  </>
                )}
                {values.reg_iam_teacher_student === false && (
                  <>
                    <DropdownWrapper>
                      <Input2
                        value={
                          values.self_employed
                            ? YESORNO[0].name
                            : values?.self_employed === false
                            ? YESORNO[1].name
                            : ""
                        }
                        label={selfEmploye}
                        onBlur={() => validateForms("self_employed")}
                        error={errors.self_employed}
                        dropdownList={YESORNO}
                        onChange={(value) => {
                          setValues({
                            ...values,
                            self_employed:
                              value === YESORNO[0].name ? true : false,
                          });
                          validateForms("self_employed");
                        }}
                      />
                    </DropdownWrapper>

                    <DropdownWrapper>
                      <Input2
                        value={
                          values.iam_retired_pensioner
                            ? YESORNO[0].name
                            : values?.iam_retired_pensioner === false
                            ? YESORNO[1].name
                            : ""
                        }
                        label={freelancer}
                        onBlur={() => validateForms("iam_retired_pensioner")}
                        error={errors.iam_retired_pensioner}
                        dropdownList={YESORNO}
                        onChange={(value) => {
                          setValues({
                            ...values,
                            iam_retired_pensioner:
                              value === YESORNO[0].name ? true : false,
                          });
                          validateForms("iam_retired_pensioner");
                        }}
                      />
                    </DropdownWrapper>
                    <DropdownWrapper width="30">
                      <Input2
                        value={
                          values.other_occupations
                            ? YESORNO[0].name
                            : values?.other_occupations === false
                            ? YESORNO[1].name
                            : ""
                        }
                        label={primary_occupation}
                        onBlur={() => validateForms("other_occupations")}
                        error={errors.other_occupations}
                        dropdownList={YESORNO}
                        onChange={(value) => {
                          setValues({
                            ...values,
                            other_occupations:
                              value === YESORNO[0].name ? true : false,
                          });
                          validateForms("other_occupations");
                        }}
                      />
                    </DropdownWrapper>
                    {values.other_occupations === true ? (
                      <DropdownWrapper>
                        <Input2
                          value={values.info_other_occupations}
                          label={Specify}
                          inputType="text"
                          onChange={(value: string) => {
                            setValues({
                              ...values,
                              info_other_occupations: value,
                            });
                          }}
                        />
                      </DropdownWrapper>
                    ) : (
                      <DropdownWrapper>
                        <Input2
                          value={values?.info_other_occupations}
                          label={OtherSpecify}
                          onChange={(value: string) => {
                            setValues({
                              ...values,
                              info_other_occupations: value,
                            });
                          }}
                        />
                      </DropdownWrapper>
                    )}
                  </>
                )}
              </FlexWrapper>
              <FlexWrapper noMargin noPadding></FlexWrapper>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <Title>{work}</Title>
            </Accordion.Header>
            <Accordion.Body>
              <FlexWrapper noPadding noMargin>
                <TitleCard style={{ marginLeft: "2%", marginBottom: "1%" }}>
                  {workExperienceinSchool}
                </TitleCard>
                <DropdownWrapperfor width="25">
                  <Input2
                    value={
                      values.work_exp_in_school
                        ? YESORNO[0].name
                        : values?.work_exp_in_school === false
                        ? YESORNO[1].name
                        : ""
                    }
                    label={selectDp}
                    onBlur={() => validateForms("work_exp_in_school")}
                    error={errors.work_exp_in_school}
                    width="100%"
                    dropdownList={YESORNO}
                    onChange={(value) => {
                      setValues({
                        ...values,
                        work_exp_in_school:
                          value === YESORNO[0].name ? true : false,
                      });
                      validateForms("work_exp_in_school");
                    }}
                  />
                  {values?.work_exp_in_school === true ? (
                    values.info_work_exp_in_school.length + 1 <= 2 ? (
                      <div
                        style={{
                          marginLeft: "2%",
                        }}
                      >
                        <Button onClick={addFormFields}>+</Button>
                      </div>
                    ) : (
                      <div style={{ marginLeft: "2%" }}>
                        <Button disabled>+</Button>
                      </div>
                    )
                  ) : null}
                </DropdownWrapperfor>

                {values?.work_exp_in_school === true && (
                  <>
                    <DropdownWrapper>
                      <Input2
                        value={worIn?.name_of_emp}
                        label={nameOfEmploye}
                        onBlur={() => validateForms("info_work_exp_in_school")}
                        error={errors.info_work_exp_in_school}
                        onChange={(value: string) => {
                          setWorkIn({ ...worIn, name_of_emp: value });
                        }}
                      />
                    </DropdownWrapper>
                    <DropdownWrapper>
                      <Input2
                        value={worIn?.duration}
                        label={durations}
                        onBlur={() => validateForms("info_work_exp_in_school")}
                        error={errors.info_work_exp_in_school}
                        onChange={(value: string) => {
                          setWorkIn({ ...worIn, duration: value });
                        }}
                      />
                    </DropdownWrapper>
                    <DropdownWrapper>
                      <Input2
                        value={worIn?.title_of_role}
                        onBlur={() => validateForms("info_work_exp_in_school")}
                        onChange={(value: string) => {
                          setWorkIn({ ...worIn, title_of_role: value });
                        }}
                        label={titleofRole}
                      />
                    </DropdownWrapper>
                    <FlexWrapper
                      noMargin
                      noPadding
                      style={{ marginTop: "-2%" }}
                      width="100%"
                    >
                      <DropdownWrapper>
                        <Input
                          height="auto"
                          inputType="textarea"
                          value={worIn?.key_res}
                          onBlur={() =>
                            validateForms("info_work_exp_in_school")
                          }
                          error={errors.info_work_exp_in_school}
                          onChange={(value: string) => {
                            setWorkIn({ ...worIn, key_res: value });
                          }}
                          label={keyRes}
                        />
                      </DropdownWrapper>
                    </FlexWrapper>
                  </>
                )}
                {values.info_work_exp_in_school.map((item, index) => (
                  <>
                    <DropdownWrapper width="21">
                      <Input2
                        onChange={() => {}}
                        onBlur={() => validateForms("info_work_exp_in_school")}
                        error={errors.info_work_exp_in_school}
                        value={item.name_of_emp}
                        label={nameOfEmploye}
                      />
                    </DropdownWrapper>

                    <DropdownWrapper width="21">
                      <Input2
                        value={item.duration}
                        label={durations}
                        onChange={() => {}}
                      />
                    </DropdownWrapper>
                    <DropdownWrapper width="21">
                      <Input2
                        value={item.title_of_role}
                        onChange={() => {}}
                        label={titleofRole}
                      />
                    </DropdownWrapper>
                    <DropdownWrapper width="21">
                      <Input2
                        value={item.key_res}
                        onChange={() => {}}
                        label={keyRes}
                      />
                    </DropdownWrapper>
                    <div style={{ marginLeft: "2%", marginTop: "12px" }}>
                      <Button
                        onClick={() => {
                          removeFormFields(index);
                        }}
                      >
                        -
                      </Button>
                    </div>
                  </>
                ))}
              </FlexWrapper>
              <FlexWrapper noPadding>
                <TitleCard style={{ marginLeft: "2%", marginBottom: "1%" }}>
                  {workExperienceoutSchool}
                </TitleCard>
                <DropdownWrapperfor width="25">
                  <Input2
                    value={
                      values.work_exp_out_school
                        ? YESORNO[0].name
                        : values?.work_exp_out_school === false
                        ? YESORNO[1].name
                        : ""
                    }
                    label={selectDp}
                    onBlur={() => validateForms("work_exp_out_school")}
                    error={errors.work_exp_out_school}
                    width="100%"
                    dropdownList={YESORNO}
                    onChange={(value) => {
                      setValues({
                        ...values,
                        work_exp_out_school:
                          value === YESORNO[0].name ? true : false,
                      });
                      validateForms("work_exp_out_school");
                    }}
                  />
                  {values?.work_exp_out_school === true ? (
                    values.info_work_exp_out_school.length + 1 <= 2 ? (
                      <div style={{ marginLeft: "2%" }}>
                        <Button onClick={addFormFieldsOut}>+</Button>
                      </div>
                    ) : (
                      <div style={{ marginLeft: "2%" }}>
                        <Button disabled>+</Button>
                      </div>
                    )
                  ) : null}
                </DropdownWrapperfor>
                {values?.work_exp_out_school === true && (
                  <>
                    <DropdownWrapper>
                      <Input2
                        value={worOut.name_of_emp}
                        label={nameOfEmploye}
                        onChange={(value: string) => {
                          setWorkOut({ ...worOut, name_of_emp: value });
                        }}
                      />
                    </DropdownWrapper>
                    <DropdownWrapper>
                      <Input2
                        value={worOut.duration}
                        label={durations}
                        onChange={(value: string) => {
                          setWorkOut({ ...worOut, duration: value });
                        }}
                      />
                    </DropdownWrapper>
                    <DropdownWrapper>
                      <Input2
                        value={worOut.title_of_role}
                        onChange={(value: string) => {
                          setWorkOut({ ...worOut, title_of_role: value });
                        }}
                        label={titleofRole}
                      />
                    </DropdownWrapper>
                    <FlexWrapper
                      noMargin
                      noPadding
                      style={{ marginTop: "-2%" }}
                      width="100%"
                    >
                      <DropdownWrapper>
                        <Input
                          height="auto"
                          inputType="textarea"
                          value={worOut.key_res}
                          onChange={(value: string) => {
                            setWorkOut({ ...worOut, key_res: value });
                          }}
                          label={keyRes}
                        />
                      </DropdownWrapper>
                    </FlexWrapper>
                  </>
                )}
                {values.info_work_exp_out_school.map((item, index) => (
                  <>
                    <DropdownWrapper width="21">
                      <Input2
                        onChange={() => {}}
                        value={item.name_of_emp}
                        label={nameOfEmploye}
                      />
                    </DropdownWrapper>

                    <DropdownWrapper width="21">
                      <Input2
                        value={item.duration}
                        label={durations}
                        onChange={() => {}}
                      />
                    </DropdownWrapper>
                    <DropdownWrapper width="21">
                      <Input2
                        value={item.title_of_role}
                        onChange={() => {}}
                        label={titleofRole}
                      />
                    </DropdownWrapper>
                    <DropdownWrapper width="21">
                      <Input2
                        value={item.key_res}
                        onChange={() => {}}
                        label={keyRes}
                      />
                    </DropdownWrapper>
                    <div style={{ marginLeft: "2%", marginTop: "12px" }}>
                      <Button
                        onClick={() => {
                          removeFormFieldsOut(index);
                        }}
                      >
                        -
                      </Button>
                    </div>
                  </>
                ))}
              </FlexWrapper>
              <FlexWrapper noPadding>
                <TitleCard style={{ marginLeft: "2%", marginBottom: "1%" }}>
                  {otherOccupation}
                </TitleCard>
                <DropdownWrapperfor width="25">
                  <Input2
                    value={
                      values?.other_work_experience
                        ? YESORNO[0].name
                        : values?.other_work_experience === false
                        ? YESORNO[1].name
                        : ""
                    }
                    label={selectDp}
                    width="100%"
                    onBlur={() => validateForms("other_work_experience")}
                    error={errors.other_work_experience}
                    dropdownList={YESORNO}
                    onChange={(value) => {
                      setValues({
                        ...values,
                        other_work_experience:
                          value === YESORNO[0].name ? true : false,
                      });
                      validateForms("other_work_experience");
                    }}
                  />
                  {values?.other_work_experience === true ? (
                    values.info_other_work_experience.length + 1 <= 2 ? (
                      <div style={{ marginLeft: "2%" }}>
                        <Button onClick={addFormFieldsOther}>+</Button>
                      </div>
                    ) : (
                      <div style={{ marginLeft: "2%" }}>
                        <Button disabled>+</Button>
                      </div>
                    )
                  ) : null}
                </DropdownWrapperfor>
                {values?.other_work_experience === true && (
                  <>
                    <DropdownWrapper>
                      <Input2
                        value={otherOccu.name_of_emp}
                        label={nameOfEmploye}
                        onChange={(value: string) => {
                          setotherOccu({ ...otherOccu, name_of_emp: value });
                        }}
                      />
                    </DropdownWrapper>
                    <DropdownWrapper>
                      <Input2
                        value={otherOccu.duration}
                        label={durations}
                        onChange={(value: string) => {
                          setotherOccu({ ...otherOccu, duration: value });
                        }}
                      />
                    </DropdownWrapper>
                    <DropdownWrapper>
                      <Input2
                        value={otherOccu.title_of_role}
                        onChange={(value: string) => {
                          setotherOccu({ ...otherOccu, title_of_role: value });
                        }}
                        label={titleofRole}
                      />
                    </DropdownWrapper>
                    <FlexWrapper
                      noMargin
                      noPadding
                      style={{ marginTop: "-2%" }}
                      width="100%"
                    >
                      <DropdownWrapper>
                        <Input
                          height="auto"
                          inputType="textarea"
                          value={otherOccu.key_res}
                          onChange={(value: string) => {
                            setotherOccu({ ...otherOccu, key_res: value });
                          }}
                          label={keyRes}
                        />
                      </DropdownWrapper>
                    </FlexWrapper>
                  </>
                )}
                {values.info_other_work_experience.map((item, index) => (
                  <>
                    <DropdownWrapper width="21">
                      <Input2
                        onChange={() => {}}
                        value={item.name_of_emp}
                        label={nameOfEmploye}
                      />
                    </DropdownWrapper>

                    <DropdownWrapper width="21">
                      <Input2
                        value={item.duration}
                        label={durations}
                        onChange={() => {}}
                      />
                    </DropdownWrapper>
                    <DropdownWrapper width="21">
                      <Input2
                        value={item.title_of_role}
                        onChange={() => {}}
                        label={titleofRole}
                      />
                    </DropdownWrapper>
                    <DropdownWrapper width="21">
                      <Input2
                        value={item.key_res}
                        onChange={() => {}}
                        label={keyRes}
                      />
                    </DropdownWrapper>
                    <div style={{ marginLeft: "2%", marginTop: "12px" }}>
                      <Button
                        onClick={() => {
                          removeFormFieldsOther(index);
                        }}
                      >
                        -
                      </Button>
                    </div>
                  </>
                ))}
              </FlexWrapper>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <Title>{availablity}</Title>
            </Accordion.Header>
            <Accordion.Body>
              <FlexWrapper noPadding>
                <TitleCard
                  style={{
                    marginLeft: "2%",
                    marginBottom: "4px",
                    width: "100%",
                  }}
                >
                  {workStatrt}
                </TitleCard>
                <DropdownWrapperfor width="25">
                  <Input2
                    value={
                      values?.as_soon_as_possible
                        ? Assoonaspossible
                        : values?.as_soon_as_possible === false
                        ? Specifydate
                        : ""
                    }
                    label={Icanstart}
                    error={errors.as_soon_as_possible}
                    onBlur={() => validateForms("as_soon_as_possible")}
                    width="100%"
                    dropdownList={[
                      {
                        id: "01",
                        name: Assoonaspossible,
                      },
                      {
                        id: "02",
                        name: Specifydate,
                      },
                    ]}
                    onChange={(value) => {
                      setValues({
                        ...values,
                        as_soon_as_possible:
                          value === Assoonaspossible ? true : false,
                      });
                      validateForms("as_soon_as_possible");
                    }}
                  />
                </DropdownWrapperfor>
                {values?.as_soon_as_possible === false && (
                  <DropdownWrapper style={{ marginTop: "-5px" }}>
                    <DatePicker
                      selected={selectedSpecifyDate}
                      showMonthDropdown
                      showYearDropdown
                      yearDropdownItemNumber={50}
                      scrollableYearDropdown
                      placeholderText={selectDp}
                      dateFormat="yyyy/MM/dd"
                      onChange={(value) => {
                        setValues({
                          ...values,
                          specify_date: value,
                        });
                      }}
                      customInput={
                        <Input
                          value={selectedDate}
                          placeholder="Select date"
                          error={errors.specify_date}
                        />
                      }
                    />
                  </DropdownWrapper>
                )}
              </FlexWrapper>
              <FlexWrapper noMargin noPadding width="100%">
                <DropdownWrapper width="35">
                  <Input2
                    value={values?.working_hours_or_weeks}
                    label={howMayHours}
                    error={errors.working_hours_or_weeks}
                    onBlur={() => validateForms("working_hours_or_weeks")}
                    width="100%"
                    dropdownList={HOURSELECTION}
                    onChange={(value) => {
                      setValues({
                        ...values,
                        working_hours_or_weeks: value,
                      });
                      validateForms("working_hours_or_weeks");
                    }}
                  />
                </DropdownWrapper>
                <DropdownWrapper width="35">
                  <Input2
                    value={
                      values?.working_evening_or_weekends
                        ? YESORNO[0].name
                        : values?.working_evening_or_weekends === false
                        ? YESORNO[1].name
                        : ""
                    }
                    label={workWeekend}
                    error={errors.working_evening_or_weekends}
                    onBlur={() => validateForms("working_evening_or_weekends")}
                    width="100%"
                    dropdownList={YESORNO}
                    onChange={(value) => {
                      setValues({
                        ...values,
                        working_evening_or_weekends:
                          value === YESORNO[0].name ? true : false,
                      });
                      validateForms("working_evening_or_weekends");
                    }}
                  />
                </DropdownWrapper>
              </FlexWrapper>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              <Title>{lang}</Title>
            </Accordion.Header>
            <Accordion.Body>
              {/* checkBoc */}
              <FlexWrapper noMargin noPadding>
                <DropdownWrapperfor
                  width="35"
                  className={
                    submitted && !isLanguageSkillsValid() ? "error" : ""
                  }
                >
                  <LangContainer>
                    <TitleCard
                      className={
                        submitted && !isLanguageSkillsValid() ? "error" : ""
                      }
                    >
                      {swedish}:
                    </TitleCard>
                    <span style={{ color: colors.darkBlue, fontSize: "14px" }}>
                      {values?.language_skills?.swedish}
                    </span>
                  </LangContainer>

                  <CheckBoxWrapper>
                    <Rating
                      initialRating={rating.swedish}
                      onChange={(rate: any) => {
                        if (rate === 1) {
                          setValues({
                            ...values,
                            language_skills: {
                              ...values?.language_skills,
                              swedish: Beginner,
                            },
                          });
                          setRating({ ...rating, swedish: rate });
                        } else if (rate === 2) {
                          setValues({
                            ...values,
                            language_skills: {
                              ...values?.language_skills,
                              swedish: Intermediate,
                            },
                          });
                          setRating({ ...rating, swedish: rate });
                        } else if (rate === 3) {
                          setValues({
                            ...values,
                            language_skills: {
                              ...values?.language_skills,
                              swedish: Proficient,
                            },
                          });
                          setRating({ ...rating, swedish: rate });
                        } else if (rate === 4) {
                          setValues({
                            ...values,
                            language_skills: {
                              ...values?.language_skills,
                              swedish: Fluent,
                            },
                          });
                          setRating({ ...rating, swedish: rate });
                        } else if (rate === 5) {
                          setValues({
                            ...values,
                            language_skills: {
                              ...values?.language_skills,
                              swedish: Native,
                            },
                          });
                          setRating({ ...rating, swedish: rate });
                        }
                      }}
                    />
                  </CheckBoxWrapper>
                  <span style={{ color: "red" }}>{errors.language_skills}</span>
                </DropdownWrapperfor>
              </FlexWrapper>
              <FlexWrapper noMargin noPadding>
                <DropdownWrapperfor
                  width="35"
                  className={
                    submitted && !isLanguageSkillsValid() ? "error" : ""
                  }
                >
                  <LangContainer>
                    <TitleCard
                      className={
                        submitted && !isLanguageSkillsValid() ? "error" : ""
                      }
                    >
                      {english}:
                    </TitleCard>
                    <span style={{ color: colors.darkBlue, fontSize: "14px" }}>
                      {values?.language_skills?.english}
                    </span>
                  </LangContainer>

                  <CheckBoxWrapper>
                    <Rating
                      initialRating={rating.english}
                      onChange={(rate: any) => {
                        if (rate === 1) {
                          setValues({
                            ...values,
                            language_skills: {
                              ...values?.language_skills,
                              english: Beginner,
                            },
                          });
                          setRating({ ...rating, english: rate });
                        } else if (rate === 2) {
                          setValues({
                            ...values,
                            language_skills: {
                              ...values?.language_skills,
                              english: Intermediate,
                            },
                          });
                          setRating({ ...rating, english: rate });
                        } else if (rate === 3) {
                          setValues({
                            ...values,
                            language_skills: {
                              ...values?.language_skills,
                              english: Proficient,
                            },
                          });
                          setRating({ ...rating, english: rate });
                        } else if (rate === 4) {
                          setValues({
                            ...values,
                            language_skills: {
                              ...values?.language_skills,
                              english: Fluent,
                            },
                          });
                          setRating({ ...rating, english: rate });
                        } else if (rate === 5) {
                          setValues({
                            ...values,
                            language_skills: {
                              ...values?.language_skills,
                              english: Native,
                            },
                          });
                          setRating({ ...rating, english: rate });
                        }
                      }}
                    />
                  </CheckBoxWrapper>
                </DropdownWrapperfor>
              </FlexWrapper>

              {values.language_skills.other?.map((item: any, i) => (
                <FlexWrapper noMargin noPadding>
                  <DropdownWrapperfor width="60">
                    <div style={{ width: "20%" }}>
                      <TitleCard>{item.lang}:</TitleCard>
                      <span
                        style={{ color: colors.darkBlue, fontSize: "14px" }}
                      >
                        {item.proficiency}
                      </span>
                    </div>

                    <CheckBoxWrapper>
                      <Rating initialRating={item.ratin} onChange={() => {}} />
                    </CheckBoxWrapper>
                    <div>
                      <Button
                        style={{ marginTop: "2px", marginLeft: "32px" }}
                        onClick={() => {
                          let newFormValues = [...values.language_skills.other];
                          newFormValues.splice(i, 1);
                          setValues({
                            ...values,
                            language_skills: {
                              ...values.language_skills,
                              other: newFormValues,
                            },
                          });
                        }}
                      >
                        <FontAwesomeIcon icon={["fas", "minus"]} />
                      </Button>
                    </div>
                  </DropdownWrapperfor>
                </FlexWrapper>
              ))}
              {values.language_skills.other.length <= 2 ? (
                <FlexWrapper noPadding noMargin width="100%">
                  <DropdownWrapperfor width="40">
                    <div>
                      <TitleCard>{otherLange}</TitleCard>
                    </div>

                    <PlusButton
                      onClick={() => {
                        setcheckBox({
                          ...checkBox,
                          other: !checkBox?.other,
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={["fas", "plus"]} />
                    </PlusButton>

                    <CheckBoxWrapper>
                      {/* <Form.Check
                      name=""
                      checked={checkBox?.other}
                      onClick={(e: any) => {
                        setcheckBox({
                          ...checkBox,
                          other: !checkBox?.other,
                        });
                        if (!checkBox?.other) {
                          setValues({
                            ...values,
                            language_skills: {
                              ...values?.language_skills,
                              otherLang: e.target.name,
                            },
                          });
                        } else if (checkBox?.other) {
                          setValues({
                            ...values,
                            language_skills: {
                              ...values?.language_skills,
                              otherLang: "",
                            },
                          });
                          setRating({ ...rating, other: 0 });
                        }
                      }}
                      style={{
                        marginTop: "2px",
                        color: colors.darkBlue,
                      }}
                    /> */}
                    </CheckBoxWrapper>
                  </DropdownWrapperfor>
                </FlexWrapper>
              ) : null}

              <Modals
                show={checkBox?.other}
                handleCancel={() => {
                  setcheckBox({
                    ...checkBox,
                    other: false,
                  });
                }}
                center={true}
                handleSubmit={() => {
                  setValues({
                    ...values,
                    language_skills: {
                      ...values.language_skills,
                      other: [
                        ...values.language_skills.other,
                        {
                          proficiency: otherLang.proficiency,
                          lang: otherLang.other,
                          ratin: otherLang.ratin,
                        },
                      ],
                    },
                  });
                  setOtherLang({ other: "", ratin: 0, proficiency: "" });
                  setcheckBox({
                    ...checkBox,
                    other: false,
                  });
                }}
                sizeModal="md"
                title={otherLang.proficiency}
              >
                <FlexWrapper
                  width="100%"
                  justifyContent="center"
                  noMargin
                  noPadding
                >
                  <DropdownWrapperfor width="50" style={{ marginTop: "12px" }}>
                    <Input2
                      value={otherLang?.other}
                      label={Otherlanguage}
                      width="100%"
                      onChange={(value: string) => {
                        setOtherLang({
                          ...otherLang,
                          other: value,
                        });
                      }}
                    />
                  </DropdownWrapperfor>
                  <CheckBoxWrapper style={{ marginTop: "16px" }}>
                    <Rating
                      initialRating={otherLang.ratin}
                      onChange={(rate: any) => {
                        if (rate === 1) {
                          setOtherLang({
                            ...otherLang,
                            proficiency: Beginner,
                            ratin: rate,
                          });
                        } else if (rate === 2) {
                          setOtherLang({
                            ...otherLang,
                            proficiency: Intermediate,
                            ratin: rate,
                          });
                        } else if (rate === 3) {
                          setOtherLang({
                            ...otherLang,
                            proficiency: Proficient,
                            ratin: rate,
                          });
                        } else if (rate === 4) {
                          setOtherLang({
                            ...otherLang,
                            proficiency: Fluent,
                            ratin: rate,
                          });
                        } else if (rate === 5) {
                          setOtherLang({
                            ...otherLang,
                            proficiency: Native,
                            ratin: rate,
                          });
                        }
                      }}
                    />
                  </CheckBoxWrapper>
                </FlexWrapper>
              </Modals>

              <FlexWrapper
                noMargin
                width="100%"
                style={{ marginTop: "-2%" }}
              ></FlexWrapper>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              <Title>{other}</Title>
            </Accordion.Header>
            <Accordion.Body>
              <FlexWrapper>
                <DropdownWrapper width="29" style={{ marginTop: "-1%" }}>
                  <TitleCard style={{ marginBottom: "4%" }}>
                    {policeCLearenceReport}
                  </TitleCard>
                  <Input2
                    value={
                      values?.police_clearance_report
                        ? YESORNO[0].name
                        : values?.police_clearance_report === false
                        ? YESORNO[1].name
                        : ""
                    }
                    label={selectDp}
                    width="100%"
                    error={errors.police_clearance_report}
                    onBlur={() => validateForms("police_clearance_report")}
                    dropdownList={YESORNO}
                    onChange={(value) => {
                      setValues({
                        ...values,
                        police_clearance_report:
                          value === YESORNO[0].name ? true : false,
                      });
                      validateForms("police_clearance_report");
                    }}
                  />
                </DropdownWrapper>
                {/* {values?.police_clearance_report === true && (
                  <DropdownWrapper width="28" style={{ marginTop: "-1%" }}>
                    <TitleCard style={{ marginBottom: "4%" }}>
                      {upfile}
                    </TitleCard>

                    <FormControl
                      type="file"
                      isValid={fileSelected1} // Apply isValid class when a valid file is selected
                      isInvalid={fileSelected1 === false} // Apply isInvalid class when no file is selected or there's an error
                      onChange={(e: any) => {
                        const file = e.target.files[0]; // Get the selected file

                        if (!file) {
                          // No file selected
                          setFileSelected1(false); // Set fileSelected to false for validation
                          return; // Don't proceed further
                        }

                        if (
                          file.type !== "application/pdf" &&
                          file.type !== "image/jpeg" &&
                          file.type !== "image/jpg" &&
                          file.type !== "image/png" &&
                          file.type !== "image/gif"
                        ) {
                          setFileValidat(true);
                          setFileSize(false);
                          setFileSelected1(false); // Set fileSelected to false for validation
                          window.scrollTo({ top: 0 });
                        } else if (file.size > 1000000) {
                          setFileSize(true);
                          setFileValidat(false);
                          setFileSelected1(false); // Set fileSelected to false for validation
                          window.scrollTo({ top: 0 });
                        } else {
                          // Valid file selected
                          setFileSelected1(true); // Set fileSelected to true
                          // Read the file as a Data URL
                          let reader = new FileReader();
                          reader.readAsDataURL(file);
                          reader.onload = () => {
                            setValues({
                              ...values,
                              police_report: reader.result,
                            });
                          };
                        }
                      }}
                    />

                    <span style={{ color: "red" }}>{errors.police_report}</span>
                  </DropdownWrapper>
                )} */}
                {values?.police_clearance_report === false && (
                  <DropdownWrapper
                    width="60"
                    style={{ marginLeft: "4%", marginTop: "-1%" }}
                  >
                    <TitleCard style={{ marginBottom: "0px" }}>
                      {swedishlaw1}
                      <a
                        href=" https://polisen.se/tjanster-tillstand/belastningsregistret/skola-eller-forskola/"
                        target="_blank"
                      >
                        {" "}
                        https://polisen.se/tjanster-tillstand/belastningsregistret/skola-eller-forskola/{" "}
                      </a>
                    </TitleCard>
                    <TitleCard style={{ marginTop: "2%" }}>
                      {" "}
                      {swedishlaw2}
                    </TitleCard>
                  </DropdownWrapper>
                )}
              </FlexWrapper>
              <FlexWrapper noMargin style={{ marginTop: "-2%" }}>
                <DropdownWrapper width="29" style={{ marginTop: "-6px" }}>
                  <TitleCard style={{ marginBottom: "4%" }}>
                    {workSweden}
                  </TitleCard>
                  <Input2
                    value={
                      values?.right_to_work_in_Sweden
                        ? YESORNO[0].name
                        : values?.right_to_work_in_Sweden === false
                        ? YESORNO[1].name
                        : ""
                    }
                    label={selectDp}
                    error={errors.right_to_work_in_Sweden}
                    onBlur={() => validateForms("right_to_work_in_Sweden")}
                    width="100%"
                    dropdownList={YESORNO}
                    onChange={(value) => {
                      setValues({
                        ...values,
                        right_to_work_in_Sweden:
                          value === YESORNO[0].name ? true : false,
                      });
                      validateForms("right_to_work_in_Sweden");
                    }}
                  />
                </DropdownWrapper>
                {/* {values?.right_to_work_in_Sweden === true && (
                  <FlexWrapper style={{ marginTop: "2.5%" }}>
                    <CheckBoxWrapper>
                      <Form.Check
                        name="EU_Citizen"
                        checked={checkBox?.euCitizen}
                        label={EUcitizen}
                        onClick={(e: any) => {
                          setcheckBox({
                            ...checkBox,
                            euCitizen: !checkBox?.euCitizen,
                            Iam_non_EU_citizen: false,
                          });
                          if (!checkBox?.euCitizen) {
                            setValues({
                              ...values,
                              Iam_EU_citizen: true,
                            });
                          } else if (checkBox?.euCitizen) {
                            setValues({
                              ...values,
                              Iam_EU_citizen: null,
                            });
                          }
                        }}
                        style={{
                          paddingRight: "5px",
                          fontSize: "15px",
                        }}
                      />
                    </CheckBoxWrapper>
                    <CheckBoxWrapper>
                      <Form.Check
                        name="non_EU_Citizen"
                        checked={checkBox?.Iam_non_EU_citizen}
                        label={nonEUcitizen}
                        onClick={(e: any) => {
                          setcheckBox({
                            ...checkBox,
                            Iam_non_EU_citizen: !checkBox?.Iam_non_EU_citizen,
                            euCitizen: false,
                          });
                          if (!checkBox?.Iam_non_EU_citizen) {
                            setValues({
                              ...values,
                              Iam_EU_citizen: false,
                              Iam_non_EU_citizen: true,
                            });
                          } else if (checkBox?.Iam_non_EU_citizen) {
                            setValues({
                              ...values,
                              Iam_EU_citizen: null,
                              Iam_non_EU_citizen: null,
                            });
                          }
                        }}
                        style={{
                          paddingRight: "5px",
                          fontSize: "15px",
                        }}
                      />
                    </CheckBoxWrapper>{" "}
                  </FlexWrapper>
                )} */}

                {values?.right_to_work_in_Sweden === false && (
                  <TitleCard style={{ marginLeft: "2%", marginTop: "2%" }}>
                    {permitlaw} <br /> {permitlaw2}{" "}
                    <a
                      href="https://www.migrationsverket.se"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      https://www.migrationsverket.se
                    </a>
                  </TitleCard>
                )}
              </FlexWrapper>

              {/* {values?.Iam_EU_citizen === true &&
                values?.right_to_work_in_Sweden === true && (
                  <FlexWrapper noMargin noPadding>
                    <DropdownWrapper
                      width="28"
                      style={{ marginTop: "-1%", marginLeft: "4%" }}
                    >
                      <TitleCard style={{ marginBottom: "4%" }}>
                        {Passport}
                      </TitleCard>

                      <FormControl
                        type="file"
                        isValid={fileSelected2} // Apply isValid class when a valid file is selected
                        isInvalid={fileSelected2 === false} // Apply isInvalid class when no file is selected or there's an error
                        onChange={(e: any) => {
                          const file = e.target.files[0]; // Get the selected file

                          if (!file) {
                            // No file selected
                            setFileSelected2(false); // Set fileSelected to false for validation
                            return; // Don't proceed further
                          }

                          if (
                            file.type !== "application/pdf" &&
                            file.type !== "image/jpeg" &&
                            file.type !== "image/jpg" &&
                            file.type !== "image/png" &&
                            file.type !== "image/gif"
                          ) {
                            setFileValidat(true);
                            setFileSize(false);
                            setFileSelected2(false); // Set fileSelected to false for validation
                            window.scrollTo({ top: 0 });
                          } else if (file.size > 1000000) {
                            setFileSize(true);
                            setFileValidat(false);
                            setFileSelected2(false); // Set fileSelected to false for validation
                            window.scrollTo({ top: 0 });
                          } else {
                            // Valid file selected
                            setFileSelected2(true); // Set fileSelected to true
                            // Read the file as a Data URL
                            let reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onload = () => {
                              setValues({
                                ...values,
                                right_to_work_in_Sweden_attachment:
                                  reader.result,
                              });
                            };
                          }
                        }}
                      />
                    </DropdownWrapper>
                  </FlexWrapper>
                )}

              {values?.Iam_EU_citizen === false &&
                values?.right_to_work_in_Sweden === true && (
                  <FlexWrapper noMargin noPadding>
                    <DropdownWrapper
                      width="28"
                      style={{ marginTop: "-1%", marginLeft: "4%" }}
                    >
                      <TitleCard style={{ marginBottom: "4%" }}>
                        {PermitCard}
                      </TitleCard>

                      <FormControl
                        type="file"
                        isValid={fileSelected2} // Apply isValid class when a valid file is selected
                        isInvalid={fileSelected2 === false} // Apply isInvalid class when no file is selected or there's an error
                        onChange={(e: any) => {
                          const file = e.target.files[0]; // Get the selected file

                          if (!file) {
                            // No file selected
                            setFileSelected2(false); // Set fileSelected to false for validation
                            return; // Don't proceed further
                          }

                          if (
                            file.type !== "application/pdf" &&
                            file.type !== "image/jpeg" &&
                            file.type !== "image/jpg" &&
                            file.type !== "image/png" &&
                            file.type !== "image/gif"
                          ) {
                            setFileValidat(true);
                            setFileSize(false);
                            setFileSelected2(false); // Set fileSelected to false for validation
                            window.scrollTo({ top: 0 });
                          } else if (file.size > 1000000) {
                            setFileSize(true);
                            setFileValidat(false);
                            setFileSelected2(false); // Set fileSelected to false for validation
                            window.scrollTo({ top: 0 });
                          } else {
                            // Valid file selected
                            setFileSelected2(true); // Set fileSelected to true
                            // Read the file as a Data URL
                            let reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onload = () => {
                              setValues({
                                ...values,
                                right_to_work_in_Sweden_attachment:
                                  reader.result,
                              });
                            };
                          }
                        }}
                      />
                    </DropdownWrapper>
                  </FlexWrapper>
                )} */}

              <FlexWrapper noMargin style={{ marginTop: "-1%" }} width="100%">
                <DropdownWrapper width="29" style={{ marginLeft: "2%" }}>
                  <TitleCard style={{ marginBottom: "4%" }}>
                    {drivingLicence}
                  </TitleCard>
                  <Input2
                    value={
                      values?.Driving_license
                        ? YESORNO[0].name
                        : values?.Driving_license === false
                        ? YESORNO[1].name
                        : ""
                    }
                    label={selectDp}
                    onBlur={() => validateForms("Driving_license")}
                    error={errors.Driving_license}
                    width="100%"
                    dropdownList={YESORNO}
                    onChange={(value) => {
                      setValues({
                        ...values,
                        Driving_license:
                          value === YESORNO[0].name ? true : false,
                      });
                      validateForms("Driving_license");
                    }}
                  />
                </DropdownWrapper>
              </FlexWrapper>
              <FlexWrapper noMargin style={{ marginTop: "-1%" }} width="100%">
                <DropdownWrapper width="29" style={{ marginLeft: "2%" }}>
                  <TitleCard style={{ marginBottom: "4%" }}>{hear}</TitleCard>
                  <Input2
                    value={values?.How_did_you_hear_about_us}
                    label={selectDp}
                    error={errors.How_did_you_hear_about_us}
                    onBlur={() => validateForms("How_did_you_hear_about_us")}
                    width="100%"
                    dropdownList={howKnowAboutUs}
                    onChange={(value) => {
                      setValues({
                        ...values,
                        How_did_you_hear_about_us: value,
                      });
                      validateForms("How_did_you_hear_about_us");
                    }}
                  />
                </DropdownWrapper>
              </FlexWrapper>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <FlexWrapper noMargin>
          <DropdownWrapper width="80" style={{ display: "flex" }}>
            <div
              style={{
                paddingLeft: "10px",
                marginBottom: "4px",
                color: colors.darkBlue,
                fontSize: "15px",
              }}
            >
              {declaration_1}{" "}
              <span
                style={{
                  cursor: "pointer",
                  color: "#2097F5",
                  textDecoration: "underline",
                }}
                onClick={() => {
                  setPrivacy(!privacy);
                }}
              >
                {declaration_2}
              </span>
              {declaration_3}
            </div>
          </DropdownWrapper>
          <DropdownWrapper
            width="100"
            style={{ display: "flex", marginLeft: "3%" }}
          >
            <Form.Check
              name={checkbox_1}
              onClick={(e: any) => {
                setcheckBox({
                  ...checkBox,
                  terms: !checkBox?.terms,
                });
                if (!checkBox?.terms) {
                  setValues({
                    ...values,
                    terms_and_condition: !checkBox?.terms,
                  });
                }
              }}
              checked={checkBox?.terms}
            />
            <div
              style={{
                paddingLeft: "10px",
                marginBottom: "4px",
                color: colors.darkBlue,
                fontSize: "15px",
              }}
            >
              {checkbox_1}
            </div>
          </DropdownWrapper>
          <DropdownWrapper
            width="80"
            style={{ display: "flex", marginLeft: "3%" }}
          >
            <Form.Check
              name={checkbox_2}
              onClick={(e: any) => {
                setcheckBox({
                  ...checkBox,
                  accurate_info: !checkBox?.accurate_info,
                });
                if (!checkBox?.accurate_info) {
                  setValues({
                    ...values,
                    accurate_information: !checkBox?.accurate_info,
                  });
                }
              }}
              checked={checkBox?.accurate_info}
            />
            <div
              style={{
                paddingLeft: "10px",
                marginBottom: "4px",
                color: colors.darkBlue,
                fontSize: "15px",
              }}
            >
              {checkbox_2}
            </div>
          </DropdownWrapper>
          <DropdownWrapper
            width="80"
            style={{ display: "flex", marginLeft: "3%" }}
          >
            <Form.Check
              name={checkbox_3}
              onClick={(e: any) => {
                setcheckBox({
                  ...checkBox,
                  marketing: !checkBox?.marketing,
                });
                if (!checkBox?.marketing) {
                  setValues({
                    ...values,
                    marketing_purpose: !checkBox?.marketing,
                  });
                }
              }}
              checked={checkBox?.marketing}
            />
            <div
              style={{
                paddingLeft: "10px",
                marginBottom: "4px",
                color: colors.darkBlue,
                fontSize: "15px",
              }}
            >
              {checkbox_3}
            </div>
          </DropdownWrapper>

          {/* <DropdownWrapper width="100" style={{ display: "flex" }}>
            <Form.Check
              onClick={() => {
                setcheckBox({
                  ...checkBox,
                  accept: !checkBox?.accept,
                });
              }} 
              checked={checkBox?.accept}
            />
            <div style={{ paddingLeft: "10px" }}>Ja, jag accepterar </div>
          </DropdownWrapper> */}
        </FlexWrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <FlexWrapper justifyContent="center">
            <ActionButton onClick={handleSubmit}>{submit}</ActionButton>
          </FlexWrapper>
        )}
      </Card>

      {/* {hasError && (
        <ToastMessage
          show={!!hasError}
          top="4%"
          right="20%"
          bg="danger"
          onCloseHandler={() => {
            dispatch(updateHasError(false));
          }}
          message={mail}
        ></ToastMessage>
      )} */}
      {fileValidat && (
        <ToastMessage
          show={!!fileValidat}
          bg="danger"
          top="4%"
          right="40%"
          onCloseHandler={() => {
            setFileValidat(false);
          }}
          message="File format should be PDF, JPEG, JPG, PNG, or GIF. "
        ></ToastMessage>
      )}
      {fileSize && (
        <ToastMessage
          show={!!fileSize}
          bg="danger"
          top="4%"
          right="40%"
          onCloseHandler={() => {
            setFileSize(false);
          }}
          message="File is too large"
        ></ToastMessage>
      )}
      {hasRegister && (
        <ToastMessage
          show={!!hasRegister}
          bg="success"
          top="4%"
          right="40%"
          onCloseHandler={() => {
            dispatch(updateRegister(false));
          }}
          message={message}
        ></ToastMessage>
      )}
      {emailMessage === false && (
        <ToastMessage
          show={emailMessage === false}
          bg="danger"
          top="4%"
          right="10%"
          message={mail}
        ></ToastMessage>
      )}
      <Modal show={showModal} onHide={handleCloseModal} style={{ top: "12%" }}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={privacy}
        onHide={() => {
          setPrivacy(false);
        }}
        size="xl"
        style={{ marginTop: "100px" }}
      >
        <Modal.Header closeButton></Modal.Header>
        <ModalBody>
          <PrivacyPolicy />
        </ModalBody>
      </Modal>
    </PageContainer>
  );
};

export default RegisterForm;
