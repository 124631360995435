export const initialValues: any = {
  start_date: "",
  end_date: "",
  start_time: "",
  end_Time: "",
  additional_comment: "",
  appro_due_date: "",
};

export const messageValue =
  "No schedule is found on the selected dates and substitute_required is enabled";
