import ROUTES from "./routes";

const Teacher = [
  {
    title: "instrumentbräda",
    to: ROUTES.TEACHER.TEC_DASHBOARD,
  },
  {
    title: "Frånvaro Förvaltning",
    childsItem: [
      {
        title: "Omslagshantering",
        childsItem: [
          {
            title: "Mitt schema",
            to: ROUTES.TEACHER.TEC_MY_SCHEDULE,
          },
          {
            title: "Mina täckta lektioner",
            to: ROUTES.TEACHER.TEC_MY_COVERED_LESSON,
          },
          {
            title: "Jag täckte lektioner",
            to: ROUTES.TEACHER.TEC_ICOVERED_LESSON,
          },
          {
            title: "Mitt tilldelade omslag",
            to: ROUTES.TEACHER.TEC_MY_ASSIGNED_COVER,
          },
        ],
      },
      {
        title: "Registrera frånvaro",
        to: ROUTES.TEACHER.TEC_REGISTER_LEAVE,
      },
      {
        title: "Min frånvaro",
        to: ROUTES.TEACHER.TEC_MY_ABSENCE,
      },
    ],
  },
];

const Institute = [
  {
    title: "instrumentbräda",
    to: ROUTES.DASHBOARD,
  },
  {
    title: "Administrering",
    to: ROUTES.INSTITUTE.CREATED_CUSTOMER,
  },
  {
    title: "Frånvarohantering",
    childsItem: [
      {
        title: "Frånvarande personal",
        to: ROUTES.HQ_MANAGEMENT.HQ_ABCENT_STAFF,
      },
      {
        title: "Cover management",
        childsItem: [
          {
            title: "Omslagshantering",
            to: ROUTES.HQ_MANAGEMENT.HQ_UNCOVERED_TABEL,
          },
          {
            title: "Täckt",
            to: ROUTES.HQ_MANAGEMENT.HQ_COVERED,
          },
          {
            title: "Mitt utkast",
            to: ROUTES.HQ_MANAGEMENT.HQ_MY_DRAFT,
          },
        ],
      },
      {
        title: "Lediga platser",
        childsItem: [
          {
            title: "Publicerad",
            to: ROUTES.HQ_MANAGEMENT.HQ_PUBLISHED_VACANCIES,
          },
          {
            title: "Avslutad",
            to: ROUTES.HQ_MANAGEMENT.HQ_COMPLETED_VACANCIES,
          },
        ],
      },
      {
        title: "Lämna förfrågningar",
        childsItem: [
          {
            title: "Godkänd förfrågan",
            to: ROUTES.HQ_MANAGEMENT.HQ_APPROVED_LEAVE_REQUEST,
          },
          {
            title: "Väntande förfrågan",
            to: ROUTES.HQ_MANAGEMENT.HQ_PENDING_LEAVE,
          },
        ],
      },
      {
        title: "Registrera ledighet",
        to: "",
      },
    ],
  },
  {
    title: "Statistik",
    to: ROUTES.INSTITUTE.STATICSTIS,
  },
  {
    title: "Mer",
    childsItem: [
      {
        title: "Mina konsulter",
        to: ROUTES.INSTITUTE.MY_CONSULTANTS,
      },
      {
        title: "Q&A",
        to: "",
      },
      {
        title: "Om DoHR",
        to: "",
      },
      {
        title: "Kontakta DoHR",
        to: "",
      },
    ],
  },
];

const Scl_Management = [
  {
    title: "instrumentbräda",
    to: ROUTES.DASHBOARD,
  },
  {
    title: "Frånvarohantering",
    childsItem: [
      {
        title: "Frånvarande personal",
        to: ROUTES.HQ_MANAGEMENT.HQ_ABCENT_STAFF,
      },
      {
        title: "Cover management",
        childsItem: [
          {
            title: "Omslagshantering",
            to: ROUTES.HQ_MANAGEMENT.HQ_UNCOVERED_TABEL,
          },
          {
            title: "Täckt",
            to: ROUTES.HQ_MANAGEMENT.HQ_COVERED,
          },
          {
            title: "Mitt utkast",
            to: ROUTES.HQ_MANAGEMENT.HQ_MY_DRAFT,
          },
        ],
      },
      {
        title: "Lediga platser",
        childsItem: [
          {
            title: "Publicerad",
            to: ROUTES.HQ_MANAGEMENT.HQ_PUBLISHED_VACANCIES,
          },
          {
            title: "Avslutad",
            to: ROUTES.HQ_MANAGEMENT.HQ_COMPLETED_VACANCIES,
          },
        ],
      },
      {
        title: "Lämna förfrågningar",
        childsItem: [
          {
            title: "Godkänd förfrågan",
            to: ROUTES.HQ_MANAGEMENT.HQ_APPROVED_LEAVE_REQUEST,
          },
          {
            title: "Väntande förfrågan",
            to: ROUTES.HQ_MANAGEMENT.HQ_PENDING_LEAVE,
          },
        ],
      },
      {
        title: "Registrera ledighet",
        to: "",
      },
    ],
  },
  {
    title: "Statistik",
    to: "",
  },
  {
    title: "Chatt",
    to: "",
  },
  {
    title: "Mer",
    childsItem: [
      {
        title: "Mina konsulter",
        to: ROUTES.INSTITUTE.MY_CONSULTANTS,
      },
      {
        title: "Q&A",
        to: "",
      },
      {
        title: "Om DoHR",
        to: "",
      },
      {
        title: "Kontakta DoHR",
        to: "",
      },
    ],
  },
];

const Hq_Management = [
  {
    title: "instrumentbräda",
    to: ROUTES.DASHBOARD,
  },
  {
    title: "Frånvarohantering",
    childsItem: [
      {
        title: "Frånvarande personal",
        to: ROUTES.HQ_MANAGEMENT.HQ_ABCENT_STAFF,
      },
      {
        title: "Cover management",
        childsItem: [
          {
            title: "Omslagshantering",
            to: ROUTES.HQ_MANAGEMENT.HQ_UNCOVERED_TABEL,
          },
          {
            title: "Täckt",
            to: ROUTES.HQ_MANAGEMENT.HQ_COVERED,
          },
          {
            title: "Mitt utkast",
            to: ROUTES.HQ_MANAGEMENT.HQ_MY_DRAFT,
          },
        ],
      },
      {
        title: "Lediga platser",
        childsItem: [
          {
            title: "Publicerad",
            to: ROUTES.HQ_MANAGEMENT.HQ_PUBLISHED_VACANCIES,
          },
          {
            title: "Avslutad",
            to: ROUTES.HQ_MANAGEMENT.HQ_COMPLETED_VACANCIES,
          },
        ],
      },
      {
        title: "Lämna förfrågningar",
        childsItem: [
          {
            title: "Godkänd förfrågan",
            to: ROUTES.HQ_MANAGEMENT.HQ_APPROVED_LEAVE_REQUEST,
          },
          {
            title: "Väntande förfrågan",
            to: ROUTES.HQ_MANAGEMENT.HQ_PENDING_LEAVE,
          },
        ],
      },
      {
        title: "Registrera ledighet",
        to: "",
      },
    ],
  },
  {
    title: "Statistik",
    to: "",
  },
  {
    title: "Chatt",
    to: "",
  },
  {
    title: "Mer",
    childsItem: [
      {
        title: "Mina konsulter",
        to: ROUTES.INSTITUTE.MY_CONSULTANTS,
      },
      {
        title: "Q&A",
        to: "",
      },
      {
        title: "Om DoHR",
        to: "",
      },
      {
        title: "Kontakta DoHR",
        to: "",
      },
    ],
  },
];

const menus = {
  teacher: Teacher,
  hq_management: Hq_Management,
  school: Scl_Management,
  Institute: Institute,
};

export default menus;
