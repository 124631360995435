import { Button, Card, Modal } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "../../../../const/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ContentContainer = styled.div`
  background-color: ${({ color }) => (color ? color : "white")};
  padding: 2% 0px 3% 0px;
`;

export const ContentHeader = styled.h1`
  margin: 12vw 0px 1vw 0px;
  text-align: center;
  font-weight: 600;
  color: ${({ color }) => (color ? color : "whitesmoke")};
  line-height: 1.6;
`;

export const Container = styled.div`
  background: linear-gradient(to right, #002c99, #2097f5);
  height: auto;
  padding-bottom: 4%;
  @media (max-width: 800px) {
  }
  @media (max-width: 600px) {
  }
  @media (max-width: 450px) {
  }
`;

export const RecruitContainer = styled.div`
  background: linear-gradient(to right, #002c99, #2097f5);
  height: auto;
  padding: 5vw 0px 2vw 0px;
`;

export const RecruitHeader = styled.p`
  padding-bottom: 0%;
  font-size: 4vw;
  margin-top: 4vw;
  font-weight: 700;
  color: white;
  text-align: center;
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 4s linear infinite;
  @keyframes textclip {
    to {
      background-position: -200% center;
    }
  }
`;
export const Demo = styled.p`
  padding-bottom: 0%;
  font-size: 1.4vw;
  display: inline;
  font-weight: 500;
  color: white;
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-image: linear-gradient(
    225deg,
    #ff4d00 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: demo 4s linear infinite;
  @keyframes demo {
    to {
      background-position: -200% center;
    }
  }
`;

export const StartButton = styled(Button)`
  color: ${({ color }) => (color ? color : `${colors.dohrBlue}`)};
  background: ${({ background }) => (background ? background : "white")};
  font-size: 1.1vw;
  font-weight: 600;
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : "0px")};
  border: 2px solid white;
  margin-bottom: 4%;
  border-radius: 4vw;
  margin-top: ${({ margintop }) => (margintop ? margintop : "0px")};
  padding: 0.4vw 1vw;
  &:hover {
    background-color: #002c99;
    color: white;
    border: 2px solid white;
    box-shadow: 0px 2px 5px black;
  }
  @media (max-width: 550px) {
    font-size: 10px;
  }
`;

export const TableStartButton = styled(Button)`
  color: white;
  background: ${colors.darkBlue};
  font-size: 1.1vw;
  font-weight: 600;
  border: 2px solid white;
  margin-bottom: ${({ marginbottom }) => (marginbottom ? marginbottom : "0px")};
  border-radius: 4vw;
  padding: 5px 20px;
  margin-left: ${({ marginleft }) => (marginleft ? marginleft : "4%")};
  &:hover {
    color: ${colors.dohrBlue};
    background: white;
    border: 1px solid white;
    box-shadow: 0px 2px 5px black;
  }
  @media (max-width: 550px) {
    font-size: 10px;
    width: 80px;
    padding: 5px 5px;
  }
`;

interface Props {
  fontSize?: string;
}

export const InfoHeader = styled.p<Props>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "2.5vw")};
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  max-width: 90%;
  color: #002c99;
  line-height: 1.6;
  @media (max-width: 650px) {
    font-size: 20px;
  }
  @media (max-width: 415px) {
    font-size: 13px;
  }
`;

export const RecruitInfoHeader = styled.p`
  font-size: 28px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  text-align: left;
  color: whitesmoke;
  margin-left: 4%;
  margin-right: 4%;
  text-transform: capitalize;
  line-height: 1.6;
  animation: curtain 5s infinite;

  @keyframes curtain {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0.25;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  @media (max-width: 650px) {
    font-size: 20px;
  }
  @media (max-width: 450px) {
    font-size: 16px;
  }
`;
export const CardTitle = styled(Card.Title)`
  font-size: 16px;
  font-weight: 600;
  color: ${colors.darkBlue};
  @media (max-width: 650px) {
    font-size: 14px;
  }
  @media (max-width: 450px) {
    font-size: 10px;
  }
`;
interface CardContainerProps {
  paddingTop?: string;
}
export const CardContainer = styled.div<CardContainerProps>`
  display: flex;
  justify-content: space-around;
  padding-top: ${({ paddingTop }) => (paddingTop ? paddingTop : "4%")};
`;

export const CardImage = styled(Card.Img)`
  width: ${({ width }) => (width ? width : "70px")};
  object-fit: contain;
  align-self: center;
`;

export const GetStartButton = styled(Button)`
  color: white;
  background: ${colors.darkBlue};
  font-size: 16px;
  font-weight: 600;
  border: 2px solid white;
  border-radius: 20px;
  width: auto;
  &:hover {
    color: ${colors.dohrBlue};
    background: white;
    border: 2px solid white;
    box-shadow: 0px 2px 5px grey;
  }
  @media (max-width: 1000px) {
    font-size: 14px;
    margin-top: 20px;
  }
  @media (max-width: 850px) {
    font-size: 14px;
    margin-top: 20px;
  }
  @media (max-width: 650px) {
    margin-top: 20px;
  }
  @media (max-width: 500px) {
    font-size: 14px;
    margin-top: 16px;
  }
  @media (max-width: 415px) {
    font-size: 12px;
    margin-top: 12px;
  }
`;

export const Imagine = styled.p`
  font-size: 40px;
  display: flex;
  flex-wrap: nowrap;
  margin-top: 4%;
  text-align: center;
  font-weight: 700;
  justify-content: center;
  color: white;
  @media (max-width: 900px) {
    font-size: 45px;
  }
  @media (max-width: 650px) {
    font-size: 40px;
  }
  @media (max-width: 500px) {
    font-size: 36px;
  }
  @media (max-width: 415px) {
    font-size: 30px;
  }
`;

export const CaptionsParaSub = styled.p`
  margin-top: 5%;
  font-size: 22px;
  font-weight: 600;
  margin-left: 3.5rem;
  color: white;
  p {
    margin: 5px 0;
  }
  @media (max-width: 460px) {
    font-size: 10px;
  }
`;

export const ParaSub = styled.p`
  margin-top: 2%;
  font-size: 1.4vw;
  font-weight: 500;
  text-align: center;
  color: white;
  @media (max-width: 550px) {
    font-size: 15px;
    margin-top:5px;
  }
`;

export const InfoHeader2 = styled.p`
  font-size: 22px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  margin-bottom: 0px;
  color: ${({ color }) => (color ? color : colors.darkBlue)};
  @media (max-width: 800px) {
    font-size: 16px;
  }
  @media (max-width: 600px) {
    font-size: 14px;
  }
  /* @media (max-width: 450px) {
    font-size: 14px;
  } */
`;

export const SaamHeader = styled.p`
  padding-bottom: 0%;
  font-size: 4vw;
  font-weight: 700;
  color: white;
  text-align: center;
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 4s linear infinite;
  @keyframes textclip {
    to {
      background-position: -200% center;
    }
  }
`;

export const GetStartButton2 = styled(Button)`
  color: ${colors.dohrBlue};
  background: white;
  font-size: 1.1vw;
  margin-bottom: 4%;
  font-weight: 600;
  border: 2px solid white;
  border-radius: 4vw;
  &:hover {
    color: white;
    background: ${colors.darkBlue};
    border: 1px solid white;
    box-shadow: 0px 2px 5px black;
  }
  @media (max-width: 550px) {
    font-size: 10px;
  }
`;

export const ModalBody = styled(Modal.Body)`
  color: ${colors.darkBlue};
  text-align: center;
  font-size: 20px;
  font-weight: 600;
`;

export const StyledIcons = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: ${({ color }) => (color ? color : "green")};
`;

export const Recruit3Header = styled.h2`
  padding-bottom: 0px;
  text-align: left;
  font-size: 2.5vw;
  font-weight: 700;
  color: #002c99;
`;

export const ThirdWrapper = styled.div`
  margin: 2vw 0px 1vw 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const StyledCard = styled(Card)`
  box-shadow: none !important;
  border: none !important;
  
`;

interface Props {
  center?: boolean;
  width?: string;
  size?: string;
  left?: string;
  bold?: string;
}

export const StyledText = styled.h1<Props>`
  color: ${({ color }) => (color ? color : "white")};
  text-align: ${({ center }) => (center ? "center" : "left")};
  font-size: ${({ size }) => (size ? size : "1.5vw")};
  width: ${({ width }) => (width ? width : "75%")};
  font-weight: ${({ bold }) => (bold ? bold : "700")};
  margin-top: 2%;
  margin-left: ${({ left }) => (left ? left : "0px")};
  transform: scale(0.94);
  animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);

  @keyframes scale {
    100% {
      transform: scale(1);
    }
  }

  span {
    display: inline-block;
    opacity: 0;
    filter: blur(4px);
  }

  span:nth-child(1) {
    animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }

  span:nth-child(2) {
    animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }

  span:nth-child(3) {
    animation: fade-in 0.8s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }

  span:nth-child(4) {
    animation: fade-in 0.8s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }

  span:nth-child(5) {
    animation: fade-in 0.8s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }

  span:nth-child(6) {
    animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }

  span:nth-child(7) {
    animation: fade-in 0.8s 0.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }

  span:nth-child(8) {
    animation: fade-in 0.8s 0.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }

  span:nth-child(9) {
    animation: fade-in 0.8s 0.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }

  span:nth-child(10) {
    animation: fade-in 0.8s 1s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }

  @keyframes fade-in {
    100% {
      opacity: 1;
      filter: blur(0);
    }
  }
`;

export const StyledTexts = styled.h1<Props>`
  color: ${({ color }) => (color ? color : "white")};
  text-align: ${({ center }) => (center ? "center" : "left")};
  font-size: ${({ size }) => (size ? size : "1.5vw")};
  width: ${({ width }) => (width ? width : "75%")};
  font-weight: ${({ bold }) => (bold ? bold : "700")};
  margin-top: 2%;
  margin-left: ${({ left }) => (left ? left : "0px")};
  transform: scale(0.94);
  animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
  animation-delay: 3s;

  @keyframes scale {
    100% {
      transform: scale(1);
    }
  }

  span {
    display: inline-block;
    opacity: 0;
    filter: blur(4px);
  }

  span:nth-child(1) {
    animation: fade-in 0.8s 1.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }

  span:nth-child(2) {
    animation: fade-in 0.8s 1.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }

  span:nth-child(3) {
    animation: fade-in 0.8s 1.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }

  span:nth-child(4) {
    animation: fade-in 0.8s 1.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }

  span:nth-child(5) {
    animation: fade-in 0.8s 1.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }

  span:nth-child(6) {
    animation: fade-in 0.8s 1.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }

  span:nth-child(7) {
    animation: fade-in 0.8s 1.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }

  span:nth-child(8) {
    animation: fade-in 0.8s 1.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }

  span:nth-child(9) {
    animation: fade-in 0.8s 1.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }

  span:nth-child(10) {
    animation: fade-in 0.8s 2s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }

  @keyframes fade-in {
    100% {
      opacity: 1;
      filter: blur(0);
    }
  }
`;

export const StyledTextss = styled.h1<Props>`
  color: ${({ color }) => (color ? color : "white")};
  text-align: ${({ center }) => (center ? "center" : "left")};
  font-size: ${({ size }) => (size ? size : "1.5vw")};
  width: ${({ width }) => (width ? width : "75%")};
  font-weight: ${({ bold }) => (bold ? bold : "700")};
  margin-top: 2%;
  margin-left: ${({ left }) => (left ? left : "0px")};
  transform: scale(0.94);
  animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
  animation-delay: 6s;

  @keyframes scale {
    100% {
      transform: scale(1);
    }
  }

  span {
    display: inline-block;
    opacity: 0;
    filter: blur(4px);
  }

  span:nth-child(1) {
    animation: fade-in 0.8s 2.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }

  span:nth-child(2) {
    animation: fade-in 0.8s 2.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }

  @keyframes fade-in {
    100% {
      opacity: 1;
      filter: blur(0);
    }
  }
`;
