import { ReactElement } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DropdownWrapper, FlexWrapper, Input } from "../../components";
import Input2 from "../../components/Input2"; 
import { addApplicationUser } from "../../redux/Common/action";
import { ApplicationProps } from "../../redux/Common/types";
import { RootState } from "../../redux/store"; 
import { Accordion } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Title } from "../../components/EditableDropdown/subcomponents"; 
import { TitleCard } from "../../container/Login/ChooseSignIn/register/subcomponent"; 
import { EmailValidation } from "../../redux/login/api";

const PersonalDetails = ({
  errorss,
  validateForms,
}: Partial<ApplicationProps>): ReactElement => {
  const { 
    values,
    register,
  } = useSelector(
    (state: RootState) => ({
      string: state.Language.language,
      common: state.common,
      register: state.Language.language.register,
      values: state.common.userApplicationProsp as ApplicationProps,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const { lang } = useSelector((state: RootState) => ({
    lang: state.Language.language,
  }));

  const { selectDp, YESORNO, preferedWorkLoc } = register;
 
  const {
    firstName,
    lastName,
    Post_code,
    address,
    area_name,
    city,
    dob,
    email,
    swedishBankAccount, 
    personaldetails,
    DOB, 
    phoneNumber,
    statelocation,
  } = lang.applicationForm;

   
   

  const selectedDate = values.date_of_birth
    ? typeof values.date_of_birth === "string"
      ? new Date(values.date_of_birth)
      : values.date_of_birth
    : null;
 
  return (
    <>
      <Accordion defaultActiveKey="">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <Title>{personaldetails}</Title>
          </Accordion.Header>
          <Accordion.Body>
            {" "}
            <FlexWrapper
              noMargin
              noPadding
              style={{ marginTop: "2%", marginLeft: "2%" }}
              width="100%"
            >
              <DropdownWrapper>
                <Input2
                  value={values.first_name}
                  label={firstName}
                  onBlur={() => {
                    validateForms("first_name");
                  }}
                  onChange={(value) => {
                    dispatch(addApplicationUser({ first_name: value }));
                    validateForms("first_name");
                  }}
                  error={errorss.first_name}
                />
              </DropdownWrapper>
              <DropdownWrapper>
                <Input2
                  value={values.last_name}
                  label={lastName}
                  onBlur={() => {
                    validateForms("last_name");
                  }}
                  onChange={(value) => {
                    dispatch(addApplicationUser({ last_name: value }));
                    validateForms("last_name");
                  }}
                  error={errorss.last_name}
                />
              </DropdownWrapper>
            </FlexWrapper>
            <FlexWrapper noMargin noPadding style={{ marginLeft: "2%" }}>
              <DropdownWrapper>
                <Input2
                  value={values.address}
                  label={address}
                  onBlur={() => {
                    validateForms("address");
                  }}
                  onChange={(value) => {
                    dispatch(addApplicationUser({ address: value }));
                    validateForms("address");
                  }}
                  error={errorss.address}
                />
              </DropdownWrapper>
              <DropdownWrapper width="20">
                <Input2
                  value={values.postalcode}
                  inputType="number"
                  onBlur={() => {
                    validateForms("postalcode");
                  }}
                  label={Post_code}
                  onChange={(value) => {
                    dispatch(addApplicationUser({ postalcode: value }));
                    validateForms("postalcode");
                  }}
                  error={errorss.postalcode}
                />
              </DropdownWrapper>
              <DropdownWrapper>
                <Input2
                  value={values.area_name}
                  label={area_name}
                  onBlur={() => {
                    validateForms("area_name");
                  }}
                  onChange={(value) => {
                    dispatch(addApplicationUser({ area_name: value }));
                    validateForms("area_name");
                  }}
                  error={errorss.area_name}
                />
              </DropdownWrapper>
              <DropdownWrapper>
                <Input2
                  value={values.city}
                  label={city}
                  onBlur={() => {
                    validateForms("city");
                  }}
                  onChange={(value) => {
                    dispatch(addApplicationUser({ city: value }));
                    validateForms("city");
                  }}
                  error={errorss.city}
                />
              </DropdownWrapper>
            </FlexWrapper>
            <FlexWrapper noMargin noPadding style={{ marginLeft: "2%" }}>
              {/* <DropdownWrapper>
                <Input2 value={""} label={phoneNumber} onChange={() => {}} />
              </DropdownWrapper> */}
              <DropdownWrapper>
                <Input2
                  value={values.phone_number}
                  inputType="number"
                  onBlur={() => validateForms("phone_number")}
                  error={errorss.phone_number}
                  label={phoneNumber}
                  onChange={(value) => {
                    dispatch(addApplicationUser({ phone_number: value }));
                    validateForms("phone_number");
                  }}
                />
              </DropdownWrapper>
              <DropdownWrapper>
                <Input2
                  value={values.email_id}
                  label={email}
                  onBlur={() => {
                    validateForms("email_id");
                  }}
                  onChange={(value) => {
                    dispatch(addApplicationUser({ email_id: value }));
                    dispatch(EmailValidation({ email_id: value }));
                    validateForms("email_id");
                  }}
                  error={errorss.email_id}
                />
              </DropdownWrapper>

              <DropdownWrapper marginTop="-7px">
                <DatePicker
                  selected={selectedDate}
                  dateFormat="yyyy/MM/dd"
                  showYearDropdown
                  yearDropdownItemNumber={50}
                  showMonthDropdown
                  scrollableYearDropdown
                  placeholderText={DOB}
                  onChange={(value) => {
                    dispatch(addApplicationUser({ date_of_birth: value }));
                    validateForms("date_of_birth");
                  }}
                  customInput={
                    <Input
                      value={selectedDate}
                      placeholder="Select date"
                      label={dob}
                      error={errorss.date_of_birth}
                      onBlur={() => {
                        validateForms("date_of_birth");
                      }}
                    />
                  }
                />
              </DropdownWrapper>
            </FlexWrapper>
            <FlexWrapper noMargin noPadding style={{ marginLeft: "2%" }}>
              <DropdownWrapper marginTop="-16px">
                <TitleCard style={{ marginBottom: "2%" }}>
                  {swedishBankAccount}
                </TitleCard>
                <Input2
                  value={
                    values?.swedish_bank_account
                      ? YESORNO[0].name
                      : values?.swedish_bank_account === false
                      ? YESORNO[1].name
                      : ""
                  }
                  label={selectDp}
                  dropdownList={YESORNO}
                  onBlur={() => {
                    validateForms("swedish_bank_account");
                  }}
                  onChange={(value) => {
                    dispatch(
                      addApplicationUser({
                        swedish_bank_account:
                          value === YESORNO[0].name ? true : false,
                      })
                    );
                    validateForms("swedish_bank_account");
                  }}
                  error={errorss.swedish_bank_account}
                />
              </DropdownWrapper>
              <DropdownWrapper marginTop="-16px" width="35">
                <TitleCard style={{ marginBottom: "1.5%" }}>
                  {preferedWorkLoc}
                </TitleCard>
                <Input2
                  value={values?.preferred_location}
                  label={statelocation}
                  error={errorss.preferred_location}
                  onBlur={() => {
                    validateForms("preferred_location");
                  }}
                  onChange={(value) => {
                    dispatch(addApplicationUser({ preferred_location: value }));
                    validateForms("preferred_location");
                  }}
                />
              </DropdownWrapper>
            </FlexWrapper>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default PersonalDetails;
