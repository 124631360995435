import React, { useEffect, useState } from "react";
import {
  ActionButton,
  FlexWrapper,
  Loader,
  PageWrapper,
  SectionTitle,
} from "../../../../../components";
import { CardWraper } from "../../Vacancies/PublishedVacancies/subcomponents";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { GetAllLeaveApi } from "../../../../../redux/Hq_Management/LeaveManagement/api";
import { TYPE } from "../../../../../const/getType";
import { Modal, ModalHeader, Table } from "react-bootstrap";
import moment from "moment";
import { Title } from "../../../../../components/EditableDropdown/subcomponents";

const DenyleaveList = () => {
  const dispatch = useDispatch();
  const { getAllLeaveReq, userInfo, isLoading, lang } = useSelector(
    (state: RootState) => ({
      getAllLeaveReq: state.LeaveMaster.getAllLeaveReq,
      isLoading: state.LeaveMaster.isLoading,
      userInfo: state.login.userInfo,
      lang: state.Language.language.Tables.denied,
    })
  );
  const { tableHeader } = lang;

  useEffect(() => {
    if (userInfo?.type === TYPE.INSTITUTE) {
      dispatch(
        GetAllLeaveApi({
          ins_id: userInfo?.data.id,
          status: "0",
          is_denied: "1",
        })
      );
    } else {
      dispatch(
        GetAllLeaveApi({
          ins_id: userInfo?.data.ins_id,
          status: "0",
          is_denied: "1",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log(getAllLeaveReq, "getAllLeaveReq");
  const [showDetail, setShowDetail] = useState(false);
  const [leave, setLeave] = useState({
    Name: "",
    LeaveType: "",
    SubstituteRequired: "",
    FromDate: "",
    ToDate: "",
    Reason: "",
    StartTime: "",
    EndTime: "",
    Comment: "",
    Createdate: "",
    Denyreason: "",
    Parentapercentage: "",
    ChildName: "",
    BirthDate: "",
    AdComment: "",
    DueDate: "",
    DeniedDate: "",
  });
  const duration1 = moment.duration(leave.StartTime);
  const duration2 = moment.duration(leave.EndTime);
  const difference = duration2.subtract(duration1);
  const hours = Math.floor(difference.asHours());
  const day1 = moment(leave.FromDate);
  const day2 = moment(leave.ToDate);

  const totalDays = day2.diff(day1, "days") + 1;
  return (
    <PageWrapper>
      <Modal
        show={showDetail}
        onHide={() => {
          setShowDetail(false);
        }}
        // size="sm"
      >
        <ModalHeader closeButton style={{ textAlign: "center" }}>
          Leave Details
        </ModalHeader>
        <Modal.Body>
          <FlexWrapper justifyContent="center" noPadding>
            <Title style={{ textAlign: "start" }}>
              <b>Name: </b> {leave.Name}
              <br />
              <br />
              <b>Leave type: </b> {leave.LeaveType}
              <br />
              <br />
              <b>Substitute required: </b>
              {leave.SubstituteRequired}
              <br />
              <br />
              <b>From date: </b>
              {leave.FromDate}
              <br />
              <br />
              <b>To date: </b>
              {leave.ToDate}
              <br />
              <br />
              <b>Start time: </b>
              {leave.StartTime}
              <br />
              <br />
              <b>End time: </b>
              {leave.EndTime}
              <br />
              <br />
              <b>Duration: </b>
              {leave.FromDate == leave.ToDate
                ? `${hours} hours`
                : `${totalDays} Days`}
              <br />
              <br />
              <b>Submitted on: </b>
              {leave.Createdate}
              <br />
              <br />
              <b>Denied on: </b>
              {leave.DeniedDate}
              <br />
              <br />
              <b>Reason: </b>
              {leave.Reason}
              <br />
              <br />
              <b>Comment: </b>
              {leave.Comment}
              <br />
              <br />
              <b>Reason for deny: </b>
              {leave.Denyreason}
              <br />
              <br />
              {leave.LeaveType === "Parental leave" ? (
                <>
                  <b>Percentage of your parental leave: </b>
                  {leave.Parentapercentage}
                  <br />
                  <br />
                  <b>First initial of the childs name: </b>
                  {leave.ChildName}
                  <br />
                  <br />
                  <b>Year and month of the birth date: </b>
                  {leave.BirthDate}
                  <br />
                  <br />

                  <b>Child not born yet</b>

                  <br />
                  <br />
                  <b>Additional comments: </b>
                  {leave.AdComment}
                  <br />
                  <br />

                  <b>Approximate due date: </b>
                  {leave.DueDate}
                  <br />
                  <br />
                </>
              ) : (
                ""
              )}
            </Title>
          </FlexWrapper>
          <FlexWrapper justifyContent="center" noPadding noMargin>
            <ActionButton
              onClick={() => {
                setShowDetail(false);
              }}
            >
              Close
            </ActionButton>
          </FlexWrapper>
        </Modal.Body>
      </Modal>
      <CardWraper>
        {" "}
        <FlexWrapper
          backgroundColor="#eff3fa"
          noMargin
          justifyContent="space-between"
        >
          <SectionTitle title={"Denied Request "} noMargin />
        </FlexWrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <FlexWrapper justifyContent="center">
            <Table striped borderless hover>
              <thead
                style={{
                  backgroundColor: "#2097f5",
                  color: "white",
                  fontFamily: "Montserrat",
                }}
              >
                <tr style={{ textAlign: "center" }}>
                  {tableHeader.map((data, index) => (
                    <th key={`Header Unique id -${index}`}>{data}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {getAllLeaveReq.map((item: any, index) => {
                  const fromTime = moment(item.from_time, "hh:ss:mm");
                  const endTime = moment(item.to_time, "hh:ss:mm");
                  const duration = moment.duration(endTime.diff(fromTime));
                  const getHours = duration.hours();
                  const minutes = duration.minutes();
                  let hh = getHours <= 10 ? `${getHours}` : getHours;
                  let mm = minutes <= 10 ? `0${minutes}` : minutes;

                  const fromdateFormate = moment(item.from_date).format(
                    "M/DD/YYYY"
                  );
                  const todateFormate = moment(item.to_date).format(
                    "M/DD/YYYY"
                  );
                  var fDt = moment(fromdateFormate, "M/DD/YYYY");
                  var tDt = moment(todateFormate, "M/DD/YYYY");
                  var dayDiff = tDt.diff(fDt, "days");
                  return (
                    <tr key={index} style={{ textAlign: "center" }}>
                      {item?.is_approved === 0 ? (
                        <>
                          <td>{index + 1}</td>
                          <td>{`${item.first_name}${item.last_name}`}</td>
                          <td>{item.leave_type}</td>
                          <td>
                            {" "}
                            {item.substitute_required === 0 ? "No" : "Yes"}{" "}
                          </td>
                          <td>{moment(item.from_date).format("DD-MM-YYYY")}</td>
                          <td>{moment(item.to_date).format("DD-MM-YYYY")}</td>
                          <td>
                            {item.is_denied === 1 ? (
                              <span style={{ color: "red" }}>Denied</span>
                            ) : item.is_approved === 0 ? (
                              <span style={{ color: "red" }}>Pending</span>
                            ) : (
                              <span style={{ color: "green" }}>Approved</span>
                            )}
                          </td>
                          <td>{`${
                            hh === "0" && mm === "00"
                              ? `${`${dayDiff + 1} days`}`
                              : hh > "01"
                              ? `${hh}:${mm} hours `
                              : `${hh}:${mm} hour`
                          }`}</td>
                          <td>
                            {moment(item.created_at).format("DD-MM-YYYY")}
                          </td>
                          <td>
                            {moment(item.updated_at).format("DD-MM-YYYY")}
                          </td>
                          <td>{item.who_denied ? item.who_denied : "-"}</td>
                          <td
                            style={{ color: "blue", cursor: "pointer" }}
                            onClick={() => {
                              setShowDetail(true);
                              setLeave({
                                Name: `${item.first_name} ${item.last_name}`,
                                LeaveType: item.leave_type,
                                Createdate: moment(item.created_at).format(
                                  "YYYY-MM-DD"
                                ),
                                SubstituteRequired:
                                  item.substitute_required === 0 ? "No" : "Yes",
                                FromDate: moment(item.from_date).format(
                                  "YYYY-MM-DD"
                                ),
                                ToDate: moment(item.to_date).format(
                                  "YYYY-MM-DD"
                                ),
                                Reason: item?.reason ? item?.reason : "-",
                                StartTime:
                                  item.from_time === "00:00:00"
                                    ? "-"
                                    : item.from_time,
                                EndTime:
                                  item.to_time === "00:00:00"
                                    ? "-"
                                    : item.to_time,
                                Comment: item?.additional_comment
                                  ? item?.additional_comment
                                  : "-",
                                Parentapercentage:
                                  item.Parental_leave_percentage,
                                ChildName: item.child_first_name,
                                BirthDate: item?.parental_birth_date
                                  ? moment(item?.parental_birth_date).format(
                                      "YYYY-MM"
                                    )
                                  : "N/A",
                                AdComment: item.parental_additional_comment,
                                DueDate: item?.appro_due_date
                                  ? moment(item?.appro_due_date).format(
                                      "YYYY-MM-DD"
                                    )
                                  : "N/A",
                                Denyreason: item.deny_reason,
                                DeniedDate: moment(item?.updated_at).format(
                                  "YYYY-MM-DD"
                                ),
                              });
                            }}
                          >
                            {" "}
                            View
                          </td>
                        </>
                      ) : (
                        ""
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </FlexWrapper>
        )}
      </CardWraper>
    </PageWrapper>
  );
};

export default DenyleaveList;
