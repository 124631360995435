/* eslint-disable react/jsx-pascal-case */
import { ReactElement } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { FlexWrapper, PageWrapper, SectionTitle } from "../../../../components";
import HQ_MyDraft from "../CoverManagament/MyDraft";
import HQ_PublishedVacancies from "../Vacancies/PublishedVacancies";
import CreateVacancy from "./createVacancy";
import { CardWraper, TabWrapper } from "./subcomponent";

const HQ_UnCoveredLesson = (): ReactElement => {
  return (
    <PageWrapper id="container">
      <CardWraper> 
        <FlexWrapper>
          <SectionTitle noMargin title="Un Covered Lessons" hasBackButton />
        </FlexWrapper>
        <TabWrapper>
          <Tabs
            defaultActiveKey="createVacancy"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey={"createVacancy"} title="Create Vacancy">
              <CreateVacancy />
            </Tab>
            <Tab eventKey={"draftList"} title="My Draft"> 
              <HQ_MyDraft width="100%" />
            </Tab>
            <Tab eventKey={"publishList"} title="Publish Vacancy">
              <HQ_PublishedVacancies width="100%" />
            </Tab>
          </Tabs>
        </TabWrapper>
      </CardWraper>
    </PageWrapper>
  );
};

export default HQ_UnCoveredLesson;
