import { createAsyncThunk } from "@reduxjs/toolkit";
import apiEndpoints from "../../../const/apiEndpoints";
import history from "../../../const/history";
import ROUTES from "../../../const/routes";
import api from "../../../services";
import { RootState } from "../../store";
import { getMyChatListProps } from "./types";

export const getChatList = createAsyncThunk(
  "chat/getMyChat",
  async (requestPayload: getMyChatListProps): Promise<any> => {
    const response = await api.post(
      `${apiEndpoints.getmychatList}`,
      requestPayload
    );
    return response?.data.message;
  }
);

export const getChatMessage = createAsyncThunk(
  "chat/getchatMessage",
  async (requestPayload: any): Promise<any> => {
    const response = await api.post(
      `${apiEndpoints.getmychatMessage}`,
      requestPayload
    );
    if (response.data?.message) {
      history.push(ROUTES.HQ_MANAGEMENT.HQ_CHAT_MESSAGE);
    }
    return response?.data.message;
  }
);

export const sendMessage = createAsyncThunk(
  "message/sendMessage",
  async (requestPayload: any, { getState, dispatch }): Promise<any> => {
    const {
      chat: { updateChatId },
    } = getState() as RootState;
    const response = await api.post(
      `${apiEndpoints.sendChatMessage}`,
      requestPayload
    );
    if (response.data) {
      dispatch(
        getChatMessage({
          chat_id: updateChatId,
        })
      );
    }
    return response?.data.message;
  }
);
