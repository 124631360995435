import styled from "styled-components";
import TextField from "@mui/material/TextField";
import { InputWrapperProps } from "./types";
import fonts, { weight } from "../../const/fonts";
import { colors } from "../../const/theme";
import { MenuItem } from "@mui/material";

export const InputWrapper = styled.div<InputWrapperProps>`
  width: ${({ width }) => width || "100%"};
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ hasPadding }) => (hasPadding ? "5px" : "0")};
  height: ${({ height }) => (height ? height : "70px")};

  #edu-input {
    ::placeholder {
      font-size: ${fonts.small}px;
      font-weight: ${weight.normal};
      font-family: "montserrat" !important;
    }
  }
  @media (max-width: 415px) {
    width: 100%;
  }
  ${({ isDisabled }) =>
    isDisabled &&
    `
pointer-events: none;
opacity: 0.9;
`} 
  .is-invalid { 
    border:10px solid yellow;
    box-shadow: none;
  }
  input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
`;

export const FormControl = styled(TextField)<InputWrapperProps>`
  font-family: "Montserrat" !important;
  font-size: 15px !important;
  color: #002c99 !important;
  .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
    color: ${colors.darkBlue} !important;
    font-family: "Montserrat" !important;
  }
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    color: #002c99 !important;
    font-family: "Montserrat" !important;
  }

  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    height: ${({ inputHeight }) => (inputHeight ? inputHeight : null)};
    font-family: "Montserrat" !important;
    &:hover {
      border: none;
    }
  }
  .is-invalid { 
    border:10px solid yellow;
    box-shadow: none;
  }
`;

export const MenuItems = styled(MenuItem)`
  color: #002c99 !important;
  font-size: 15px !important;
  font-family: "Montserrat" !important;
`;
