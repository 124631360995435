import styled from "styled-components";

const PageWrapper = styled.div`
  margin-top: 8%;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 12px;
  @media (max-width: 415px) {
    width: 98%; 
  }
  h5 {
    font-weight: 700;
    color: #2097f5;
    font-size: 18px;
  }
  span {
    color: darkblue;
    font-size: 15px;
    font-weight: 700;
  }
`;

export default PageWrapper;
