/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import { ReactElement, useEffect, useState } from "react";
import { Modal, ModalHeader, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  ActionButton,
  DropdownWrapper,
  EditableDropdown,
  FlexWrapper,
  Input,
  Loader,
  Modals,
  PageWrapper,
  SectionTitle,
  ToastMessage,
} from "../../../../../components";
import {
  approveThevacancy,
  GetCompletedVacancyApi,
  giveFeedBack,
} from "../../../../../redux/Hq_Management/Vacancy/api";
import { AppDispatch, RootState } from "../../../../../redux/store";
import { CardWraper, SliderIcon } from "../PublishedVacancies/subcomponents";
import { Rating } from "react-simple-star-rating";
import { updateSelectedcosultId } from "../../../../../redux/Hq_Management/Vacancy/action";
import { addMyConsultant } from "../../../../../redux/Common/api";
import { DropdownListProps } from "../../../../../components/EditableDropdown/typings";
import { TYPE } from "../../../../../const/getType";
import moment from "moment";
import { Title } from "../../../../../components/EditableDropdown/subcomponents";

const HQ_CompletedVacancies = (): ReactElement => {
  const dispatch: AppDispatch = useDispatch();
  const {
    completedVacancy,
    isLoading,
    id,
    userInfo,
    setSelectedConsultantId,
    lang,
    addMyConsultantMsg,
    isFeedBackGiven,
  } = useSelector((state: RootState) => ({
    completedVacancy: state.Vacancy.getCompletedVacancy,
    isLoading: state.Vacancy.isLoading,
    isFeedBackGiven: state.Vacancy.isFeedBackGiven,
    setSelectedConsultantId: state.Vacancy.setSelectedConsultantId,
    id: state.login.userInfo?.data,
    userInfo: state.login.userInfo,
    lang: state.Language.language.Tables.completed,
    addMyConsultantMsg: state.common.addMyConsultantMsg,
  }));

  const [showFeedBack, setshowFeedBack] = useState<any>([]);
  const [rating, setRating] = useState(0);
  const [cmtValues, setcmtValues] = useState("");
  const [show, setShow] = useState(false);
  const [dropDownId, setdropDownId] = useState("01");
  const [detail, setDetail] = useState(false);
  const [detaildData, setDetailData] = useState({
    Coveredby: "",
    Position: "",
    Vacancydate: "",
    Day: "",
    Originaltime: "",
    Breaktime: "",
    preparationTime: "",
    handoverTime: "",
    Reportedtime: "",
    Reportedhours: "",

    Totalapprovedhours: "",

    Location: "",
    Vacancystatus: "",
    Cancelledbyconsultant: "",
    Cancellationcharges: "",
  });
  const [approvedetail, setApproveDetail] = useState(false);
  const [editApprovedetail, setEditApproveDetail] = useState(false);
  const [Approvedata, setApproveData] = useState<any>();
  const [ApprovedHours, setApprovedHours] = useState<any>({
    approveStart: "",
    approveEnd: "",
    ApprovedHours: "",
  });
  const {
    sectionTitle,
    tableHeader,
    dropList,
    placeholder: { dropdown },
  } = lang;

  useEffect(() => {
    if (userInfo?.type === TYPE.INSTITUTE) {
      dispatch(GetCompletedVacancyApi({ id: id?.id }));
    } else {
      dispatch(GetCompletedVacancyApi({ id: id?.ins_id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFeedBackGiven) {
      setshowFeedBack([]);
      setRating(0);
      setcmtValues("");
    }
  }, [isFeedBackGiven]);
  const [filter, setfilter] = useState("");
  console.log(Approvedata, "ApprovedataApprovedata");
  return (
    <PageWrapper>
      <Modal
        show={approvedetail}
        onHide={() => {
          setApproveDetail(false);
        }}
        size="sm"
      >
        <ModalHeader closeButton style={{ textAlign: "center" }}>
          Approve vacancies
        </ModalHeader>
        <Modal.Body>
          <FlexWrapper justifyContent="center" noPadding>
            <Title style={{ textAlign: "center" }}>
              Do you approve these reported hours?
              <br />
              <br />
              Reported tota1: {""}
              {Approvedata?.report_total_whours}
            </Title>
          </FlexWrapper>
          <FlexWrapper justifyContent="center" noPadding noMargin>
            <ActionButton
              onClick={() => {
                const payload = {
                  id: Approvedata?.vid,
                  approved_stime: Approvedata?.report_start_time,
                  approved_etime: Approvedata?.report_end_time,
                  total_approved_hrs: Approvedata?.report_total_whours,
                  reportBreakTime: Approvedata?.report_break_time,
                  feedback: "N/A",
                  unique_id: "",
                  cons_id: Approvedata?.assigned_to_external,
                  save_cons: false,
                };
                dispatch(approveThevacancy(payload))
                  .then(() => {
                    alert("Approved");
                  })
                  .catch(() => {
                    alert("Failed to approve");
                  });
                setApproveDetail(false);
              }}
            >
              ok
            </ActionButton>
            <ActionButton
              onClick={() => {
                setApproveDetail(false);
                setEditApproveDetail(true);
              }}
            >
              Edit
            </ActionButton>
          </FlexWrapper>
        </Modal.Body>
      </Modal>
      <Modal
        show={editApprovedetail}
        onHide={() => {
          setEditApproveDetail(false);
        }}
        size="sm"
      >
        <ModalHeader closeButton style={{ textAlign: "start" }}>
          Select Approved Time
        </ModalHeader>
        <Modal.Body>
          <FlexWrapper justifyContent="center" noPadding>
            <Title style={{ textAlign: "start" }}>
              <b>Completed by:</b> {Approvedata?.first_name}
              {Approvedata?.last_name} <br /> <br />
              <b>Date:</b>
              {Approvedata?.v_date}
              <br /> <br />
              <b>Day:</b>
              {""}
              {""}
              {Approvedata?.day}
              <br /> <br />
              <b>Position:</b>
              {""}
              {""}
              {Approvedata?.position}
              <br /> <br />
              <b>Original Time:</b>
              {""}
              {""}
              {Approvedata?.from_time} - {Approvedata?.to_time}
              <br /> <br />
              <b>Reported Time:</b>
              {""}
              {""}
              {Approvedata?.report_total_whours}
            </Title>

            <Input
              label="Approved Start Time"
              value={ApprovedHours.approveStart}
              inputType={"time"}
              isRequired
              height="90px"
              // onBlur={() => {
              //   ValidateField("start_time");
              // }}
              // error={errors.start_time}
              onChange={(value: string) => {
                setApprovedHours({ ...ApprovedHours, approveStart: value });
              }}
            />
            <Input
              label="Approved End Time"
              value={ApprovedHours.approveEnd}
              inputType={"time"}
              isRequired
              height="90px"
              onChange={(value: string) => {
                setApprovedHours({ ...ApprovedHours, approveEnd: value });
              }}
            />
            <Input
              label="Total Approved hours"
              value={ApprovedHours.ApprovedHours}
              inputType={"time"}
              isRequired
              height="90px"
              onChange={(value: string) => {
                setApprovedHours({ ...ApprovedHours, ApprovedHours: value });
              }}
            />
          </FlexWrapper>
          <FlexWrapper justifyContent="center" noPadding noMargin>
            <ActionButton
              onClick={() => {
                console.log(
                  ApprovedHours.approveStart,
                  ApprovedHours.approveEnd
                );
                if (ApprovedHours.approveStart >= ApprovedHours.approveEnd) {
                  alert(
                    "The end time should be greater than start time . Please adjust the  times to proceed."
                  );
                } else if (
                  !ApprovedHours.approveStart ||
                  !ApprovedHours.approveEnd
                ) {
                  alert(
                    "Please select the approve start time and approve end time"
                  );
                } else if (
                  ApprovedHours.approveStart === ApprovedHours.approveEnd
                ) {
                  alert(
                    "The start time and end time should not be the same time . Please adjust the  times to proceed."
                  );
                } else if (!ApprovedHours.ApprovedHours) {
                  alert("Please select the total approve hours");
                } else {
                  const payload = {
                    id: Approvedata?.vid,
                    approved_stime: ApprovedHours.approveStart,
                    approved_etime: ApprovedHours.approveEnd,
                    total_approved_hrs: ApprovedHours.ApprovedHours,
                    reportBreakTime: Approvedata?.report_break_time,
                    feedback: "N/A",
                    unique_id: "",
                    cons_id: Approvedata?.assigned_to_external,
                    save_cons: false,
                  };
                  dispatch(approveThevacancy(payload))
                    .then(() => {
                      alert("Approved");
                      setEditApproveDetail(false);
                    })
                    .catch(() => {
                      alert("Failed to approve");
                      setEditApproveDetail(false);
                    });
                }
              }}
            >
              Approve
            </ActionButton>
          </FlexWrapper>
        </Modal.Body>
      </Modal>
      <Modal
        show={detail}
        onHide={() => {
          setDetail(false);
        }}
        size="sm"
      >
        <ModalHeader closeButton style={{ textAlign: "center" }}>
          Detail View
        </ModalHeader>
        <Modal.Body>
          <FlexWrapper justifyContent="center" noPadding>
            <Title style={{ textAlign: "start" }}>
              <b>Completed by:</b> {detaildData.Coveredby}
              <br /> <br />
              <b>Position:</b>
              {detaildData.Position}
              <br /> <br />
              <b>Vacancy date:</b> {detaildData.Vacancydate}
              <br /> <br />
              <b> Day:</b>
              {detaildData.Day}
              <br /> <br />
              <b>Original time:</b> {detaildData.Originaltime}
              <br /> <br />
              <b> Break time:</b>
              {detaildData.Breaktime}
              <br /> <br />
              <b>preparationTime:</b>
              {detaildData.preparationTime}
              <br /> <br />
              <b>handover Time:</b> {detaildData.handoverTime}
              <br /> <br />
              <b>Reported time:</b> {detaildData.Reportedtime}
              <br /> <br />
              <b>Reported hours:</b> {detaildData.Reportedhours}
              <br /> <br />
              <b>Total approved hours:</b>
              {detaildData.Totalapprovedhours}
              <br /> <br />
              <b>Location:</b> {detaildData.Location}
              <br /> <br />
              <b>Vacancy status: </b> {detaildData.Vacancystatus}
              <br /> <br />
              <b>Cancelled by consultant:</b>{" "}
              {detaildData.Cancelledbyconsultant}
              <br /> <br />
              <b>Cancellation charges:</b>
              {detaildData.Cancellationcharges}
              <br /> <br />
            </Title>
          </FlexWrapper>
          <FlexWrapper justifyContent="center" noPadding noMargin>
            <ActionButton
              onClick={() => {
                setDetail(false);
              }}
            >
              Close
            </ActionButton>
          </FlexWrapper>
        </Modal.Body>
      </Modal>
      <CardWraper>
        <FlexWrapper
          backgroundColor="#eff3fa"
          noMargin
          justifyContent="space-between"
        >
          <SectionTitle title={sectionTitle} noMargin />
          <SliderIcon icon={["fas", "sliders"]} />
        </FlexWrapper>
        <FlexWrapper justifyContent="center" noPadding marginbtm="0">
          <EditableDropdown
            width="50"
            dropdownList={dropList}
            placeholder={dropdown}
            handleSelect={(value: DropdownListProps) => {
              setdropDownId(value.id);
            }}
            defaultValue={dropList[0]}
          />
        </FlexWrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {dropDownId === "01" && (
              <FlexWrapper justifyContent="center">
                <FlexWrapper justifyContent="center" noPadding>
                  <SectionTitle title="Internally" />
                </FlexWrapper>
                <FlexWrapper justifyContent="center" noPadding>
                  <EditableDropdown
                    hideIndupt
                    width="50"
                    dropdownList={[
                      {
                        id: "01",
                        name: "Approved",
                      },
                      {
                        id: "02",
                        name: "Pending",
                      },
                      {
                        id: "03",
                        name: "Cancelled",
                      },
                      {
                        id: "02",
                        name: "All",
                      },
                    ]}
                    placeholder={dropdown}
                    handleSelect={(value: DropdownListProps) => {
                      setfilter(value.name);
                    }}
                    defaultValue={dropList[0]}
                  />
                </FlexWrapper>
                <Table striped borderless hover style={{ marginBottom: "0" }}>
                  <thead
                    style={{
                      backgroundColor: "#2097f5",
                      color: "white",
                      fontFamily: "Montserrat",
                    }}
                  >
                    <tr style={{ textAlign: "center" }}>
                      {tableHeader.map((data, index) => (
                        <th key={`Header Unique id -${index}`}>{data}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {completedVacancy.Customer?.filter((listValuess) => {
                      if (filter === "") {
                        return listValuess;
                      } else if (filter === "All") {
                        return listValuess;
                      } else if (filter === "Approved") {
                        return listValuess?.vacancy_is_approved == 1;
                      } else if (filter === "Pending") {
                        return listValuess?.vacancy_is_approved == 0;
                      } else if (filter === "Cancelled") {
                        return listValuess?.is_canceled == 1;
                      }
                    })?.map((item, index) => (
                      <tr key={index} style={{ textAlign: "center" }}>
                        <td>{++index}</td>
                        <td>{`${item.first_name}${item.last_name}`}</td>
                        {/* <td>{item.day}</td> */}
                        <td>{moment(item.v_date).format("DD-MM-YYYY")}</td>
                        <td>{item.from_time}</td>
                        <td>{item.to_time}</td>
                        <td>{item.position}</td>
                        <td>
                          {item.from_time}-{item.to_time}
                        </td>
                        <td>
                          {item.report_start_time}-{item.report_end_time}
                        </td>
                        <td>
                          {item.approved_stime}-{item.approved_etime}
                        </td>
                        <td>
                          {item.is_canceled == 1
                            ? "Cancelled"
                            : item.vacancy_is_approved == 0
                            ? "Pending"
                            : "Approved"}
                        </td>
                        <td
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={() => {
                            setDetail(true);
                            setDetailData({
                              Coveredby: `${item.first_name}${item.last_name}`,
                              Position: item.position,
                              Vacancydate: moment(item.v_date).format(
                                "DD-MM-YYYY"
                              ),
                              Day: item.day,
                              Originaltime: `${item.from_time}-${item.to_time}`,
                              Breaktime: item.break_time
                                ? item.break_time
                                : "N/A",
                              preparationTime: item.preparationTime
                                ? item.preparationTime
                                : "N/A",
                              handoverTime: item.handoverTime
                                ? item.handoverTime
                                : "N/A",
                              Reportedtime: `${item.report_start_time}-${item.report_end_time}`,
                              Reportedhours: item.report_total_whours,
                              Totalapprovedhours: item.total_approved_hrs,
                              Location: item.location,
                              Vacancystatus:
                                item.is_canceled == 1
                                  ? "Cancelled"
                                  : item.vacancy_is_approved == 0
                                  ? "Pending"
                                  : "Approved",
                              Cancelledbyconsultant:
                                item.cancelled_by_consultant === 0
                                  ? "No"
                                  : "yes",
                              Cancellationcharges: item.cancellation_charges,
                            });
                          }}
                        >
                          view
                        </td>
                        <td>
                          <ActionButton
                            onClick={() => {
                              setshowFeedBack([item]);
                              dispatch(
                                updateSelectedcosultId({
                                  id: item?.id,
                                })
                              );
                            }}
                          >
                            Give FeedBack
                          </ActionButton>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </FlexWrapper>
            )}
            {dropDownId === "02" && (
              <FlexWrapper justifyContent="center">
                <FlexWrapper justifyContent="center" noPadding>
                  <SectionTitle title="Externally" />
                </FlexWrapper>
                <FlexWrapper justifyContent="center" noPadding>
                  <EditableDropdown
                    hideIndupt
                    width="50"
                    dropdownList={[
                      {
                        id: "01",
                        name: "Approved",
                      },
                      {
                        id: "02",
                        name: "Pending",
                      },
                      {
                        id: "03",
                        name: "Cancelled",
                      },
                      {
                        id: "02",
                        name: "All",
                      },
                    ]}
                    placeholder={dropdown}
                    handleSelect={(value: DropdownListProps) => {
                      setfilter(value.name);
                    }}
                    defaultValue={dropList[0]}
                  />
                </FlexWrapper>
                <Table striped borderless hover style={{ marginBottom: "0" }}>
                  <thead
                    style={{
                      backgroundColor: "#2097f5",
                      color: "white",
                      fontFamily: "Montserrat",
                    }}
                  >
                    <tr style={{ textAlign: "center" }}>
                      {tableHeader.map((data, index) => (
                        <th key={`Header Unique id -${index}`}>{data}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {completedVacancy.Consultant?.filter((listValuess) => {
                      if (filter === "") {
                        return listValuess;
                      } else if (filter === "All") {
                        return listValuess;
                      } else if (filter === "Approved") {
                        return listValuess?.vacancy_is_approved == 1;
                      } else if (filter === "Pending") {
                        return listValuess?.vacancy_is_approved == 0;
                      } else if (filter === "Cancelled") {
                        return listValuess?.is_canceled == 1;
                      }
                    })?.map((item, index) => (
                      <tr key={index} style={{ textAlign: "center" }}>
                        <td>{++index}</td>
                        <td>{`${item.first_name}${item.last_name}`}</td>
                        {/* <td>{item.day}</td> */}
                        <td>{moment(item.v_date).format("DD-MM-YYYY")}</td>
                        <td>{item.from_time}</td>
                        <td>{item.to_time}</td>
                        <td>{item.position}</td>
                        <td>
                          {item.from_time}-{item.to_time}
                        </td>
                        <td>
                          {item.report_start_time}-{item.report_end_time}
                        </td>
                        <td>
                          {/* {item.approved_stime}-{item.approved_etime} */}
                          {item.vacancy_is_approved === 1 &&
                          item.approved_stime === null &&
                          item.approved_etime === null
                            ? `${item?.report_start_time} - ${item?.report_end_time}`
                            : `${item.approved_stime} - ${item.approved_etime}`}
                        </td>
                        <td>
                          {item.is_canceled == 1
                            ? "Cancelled"
                            : item.vacancy_is_approved == 0
                            ? "Pending"
                            : "Approved"}
                        </td>
                        <td
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={() => {
                            setDetail(true);
                            setDetailData({
                              Coveredby: `${item.first_name}${item.last_name}`,
                              Position: item.position,
                              Vacancydate: moment(item.v_date).format(
                                "DD-MM-YYYY"
                              ),
                              Day: item.day,
                              Originaltime: `${item.from_time}-${item.to_time}`,
                              Breaktime: item.break_time
                                ? item.break_time
                                : "N/A",
                              preparationTime: item.preparationTime
                                ? item.preparationTime
                                : "N/A",
                              handoverTime: item.handoverTime
                                ? item.handoverTime
                                : "N/A",
                              Reportedtime: `${item.report_start_time}-${item.report_end_time}`,
                              Reportedhours: item.report_total_whours,
                              Totalapprovedhours:
                                item.vacancy_is_approved === 1 &&
                                item.total_approved_hrs === null
                                  ? item.report_total_whours
                                  : item.total_approved_hrs,
                              Location: item.location,
                              Vacancystatus:
                                item.is_canceled == 1
                                  ? "Cancelled"
                                  : item.vacancy_is_approved == 0
                                  ? "Pending"
                                  : "Approved",
                              Cancelledbyconsultant:
                                item.cancelled_by_consultant === 0
                                  ? "No"
                                  : "yes",
                              Cancellationcharges: item.cancellation_charges
                                ? item.cancellation_charges
                                : "N/A",
                            });
                          }}
                        >
                          view
                        </td>
                        <td>
                          {item.vacancy_is_approved == 0 ? (
                            <ActionButton
                              style={{ height: "50px" }}
                              onClick={() => {
                                setApproveData(item);
                                setApproveDetail(true);
                              }}
                            >
                              Approve vacancies
                            </ActionButton>
                          ) : (
                            <>
                              {" "}
                              <ActionButton
                                style={{ height: "50px" }}
                                onClick={() => {
                                  setshowFeedBack([item]);
                                  dispatch(
                                    updateSelectedcosultId({
                                      id: item?.id,
                                    })
                                  );
                                }}
                              >
                                Give FeedBack
                              </ActionButton>
                              <ActionButton
                                style={{ height: "50px" }}
                                backgroundcolors="#50c878"
                                onClick={() => {
                                  dispatch(
                                    addMyConsultant({
                                      cons_id: item.id,
                                      unique_id: item.ins_id,
                                    })
                                  );
                                  setShow(true);
                                }}
                              >
                                Add to My Consultant
                              </ActionButton>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </FlexWrapper>
            )}
          </>
        )}
        <ToastMessage
          onCloseHandler={() => {
            setShow(false);
          }}
          show={show}
          top="-25px"
          bg={
            addMyConsultantMsg === "Consultant added  successfully"
              ? "success"
              : "danger"
          }
          message={
            addMyConsultantMsg === "Consultant added  successfully"
              ? "Consultant added  successfully"
              : "The Consultant Already Exist"
          }
        />
      </CardWraper>
      {showFeedBack?.length && (
        <Modals
          center={true}
          show={showFeedBack?.length}
          handleSubmit={() => {
            dispatch(
              giveFeedBack({
                feedback: cmtValues,
                rating: rating,
                cons_id: setSelectedConsultantId?.id,
                vid: showFeedBack[0]?.vid,
                created_by: userInfo?.data?.unique_id,
                postion: showFeedBack[0]?.position,
                v_date: showFeedBack[0]?.v_date,
              })
            );
          }}
          handleCancel={() => setshowFeedBack([])}
          title="FeedBack"
          sizeModal={"lg"}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <FlexWrapper justifyContent="center">
              <DropdownWrapper width="100">
                <Rating
                  onClick={(rate: number) => {
                    setRating(rate);
                  }}
                  ratingValue={rating}
                  iconsCount={5}
                  transition={true}
                />
              </DropdownWrapper>
              <DropdownWrapper width="100">
                <Input
                  value={cmtValues}
                  label="Comment"
                  inputType="textarea"
                  placeholder="Your Comment"
                  onChange={(value: string) => {
                    setcmtValues(value);
                  }}
                />
              </DropdownWrapper>
            </FlexWrapper>
          )}
        </Modals>
      )}
    </PageWrapper>
  );
};

export default HQ_CompletedVacancies;
