import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../services";
import apiEndpoints from "../../../const/apiEndpoints";
import {
  GetLeaveTypes,
  getMyAbsenceByIdProps,
  RegisterLeave,
  response,
} from "./types";
import history from "../../../const/history";
import ROUTES from "../../../const/routes";

export const GetLeaveTypeApi = createAsyncThunk(
  "Absence/GetLeaveType",
  async (): Promise<Array<GetLeaveTypes>> => {
    const response = await api.get(apiEndpoints.getLeaveTypes);
    return response.data.message;
  }
);

export const RegisterAbsenceApi = createAsyncThunk(
  "Absence/RegisterAbsence",
  async (requestPayload: RegisterLeave): Promise<response> => {
    const response = await api.post(
      apiEndpoints.RegisterAbsence,
      requestPayload
    );

    return response.data;
  }
);

export const getMyAbsencybyTeacherId = createAsyncThunk(
  "get/getMyAbsence",
  async (requestPayload: any): Promise<any> => {
    const response = await api.post(
      apiEndpoints.getMyAbsencybyTeacher,
      requestPayload
    );
    return response.data.message;
  }
);

export const getMyAbsencyById = createAsyncThunk(
  "Absence/getMyAbsenceById",
  async (requestPayload: { id: any }): Promise<any> => {
    const response = await api.post(
      apiEndpoints.getMyAbsencybyid,
      requestPayload
    );
    return response.data.message;
  }
);

export const EditAbsenceApi = createAsyncThunk(
  "Absence/EditAbsence",
  async (requestPayload: getMyAbsenceByIdProps): Promise<response> => {
    const response = await api.post(apiEndpoints.editAbsence, requestPayload);
    if (response?.data) {
      history.push(ROUTES.TEACHER.TEC_MY_ABSENCE);
    }
    return response.data;
  }
);
