import { ReactElement } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../../../../redux/store";
import { IsPendingActionProps } from "./typings";

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ApproveButton = styled(Button)`
  color: white;
  background-color: #50c878;
  border: none;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  &:hover,
  &:active,
  &:focus {
    color: white;
    background-color: #50c878;
    border: none;
    box-shadow: 0px 2px 5px gray;
  }
`;

export const DenyButton = styled(Button)`
  color: white;
  background-color: red;
  border: none;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  &:hover,
  &:active,
  &:focus {
    color: white;
    background-color: red;
    border: none;
    box-shadow: 0px 2px 5px gray;
  }
`;

export const IsPendingAction = ({
  handleApprove,
  handleDeny,
}: IsPendingActionProps): ReactElement => {
  const { lang } = useSelector((state: RootState) => ({
    lang: state.Language.language.Tables.pending,
  }));

  const { button } = lang;
  return (
    <ActionWrapper>
      <ApproveButton onClick={handleApprove}>{button.approve}</ApproveButton>
      <DenyButton onClick={handleDeny}>{button.deny}</DenyButton>
    </ActionWrapper>
  );
};
