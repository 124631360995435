import { ReactElement, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DropdownWrapper,
  FlexWrapper,
  Input,
  ToastMessage,
} from "../../../components";
import { Title } from "../../../components/EditableDropdown/subcomponents";
import Input2 from "../../../components/Input2";
import { RootState } from "../../../redux/store";
import { StyledOutlineInput } from "../subcomponent";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { addApplicationUser } from "../../../redux/Common/action";
import { ApplicationProps } from "../../../redux/Common/types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FormControl as Formupload } from "react-bootstrap";

const EducationalDetails = ({
  error,
  validateforms,
}: Partial<ApplicationProps>): ReactElement => {
  const dispatch = useDispatch();
  const {
    string,

    valuess,
  } = useSelector((state: RootState) => ({
    string: state.Language.language,
    common: state.common,
    valuess: state.common.userApplicationProsp as ApplicationProps,
  }));
  const {
    register: { nameofIns, selectDp, YESORNO },
    applicationForm: {
      areQualifiedTeacher,
      qualifiedSubject,
      qualifiedAgeGroups,
      swedishlärarlegitimation,
      uploadLärarlegitimation,
      TeachingExperience,
      ageGroupsExperience,
      currentlyQWualifiedTeacher,
      willQualifiedAgeGroups,
      willQualifiedSubjects,
      subjectList,
      ageGroupList,
      studyingteacher,
      DATES,
    },
  } = string;

  const [fileValidat, setFileValidat] = useState(false);
  const [fileSize, setFileSize] = useState(false);
  const [fileSelected, setFileSelected] = useState(false);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 4;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        fontSize: "15px",
        color: "#002c99",
        fontFamily: "Montserrat",
      },
    },
  };

  const selectedDate = valuess.qualifiedteacherdate
    ? typeof valuess.qualifiedteacherdate === "string"
      ? new Date(valuess.qualifiedteacherdate)
      : valuess.qualifiedteacherdate
    : null;

  // const isDateInvalid = submitted && (!selectedDate || selectedDate === null);
  return (
    <>
      <DropdownWrapper width="20" marginTop="-10px">
        <Title style={{ marginBottom: "2%", marginLeft: "1%" }}>
          {areQualifiedTeacher}
        </Title>
        <Input2
          value={
            valuess.qualifiedTeacher
              ? YESORNO[0].name
              : valuess?.qualifiedTeacher === false
              ? YESORNO[1].name
              : ""
          }
          label={selectDp}
          error={error.qualifiedTeacher}
          onBlur={() => {
            validateforms("qualifiedTeacher");
          }}
          dropdownList={YESORNO}
          onChange={(value) => {
            dispatch(
              addApplicationUser({
                qualifiedTeacher: value === YESORNO[0].name ? true : false,
              })
            );
          }}
        />
      </DropdownWrapper>
      {valuess.qualifiedTeacher === true ? (
        <FlexWrapper width="100%" noMargin noPadding>
          {/* <DropdownWrapper marginTop="1%" width="20">
             <Title style={{ marginBottom: "4%" }}>{nameofIns}</Title> 
            <Input2 value={""} label={nameofIns} onChange={() => {}} />
          </DropdownWrapper> */}

          <DropdownWrapper width="30">
            <Title style={{ marginBottom: "2%" }}>{qualifiedSubject}</Title>
            <FormControl
              sx={{
                width: "100%",
                fontSize: "10px",
                color: "#002c99",
                fontFamily: "Montserrat",
              }}
              size="small"
              error={error.subjectlist}
            >
              <InputLabel id="demo-multiple-checkbox-label">
                {selectDp}
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={valuess.subjectlist}
                onChange={(
                  event: SelectChangeEvent<typeof valuess.subjectlist>
                ) => {
                  const {
                    target: { value },
                  } = event;
                  dispatch(
                    addApplicationUser({
                      subjectlist:
                        typeof value === "string" ? value.split(",") : value,
                    })
                  );
                }}
                input={<OutlinedInput label="Select" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {subjectList.map((item) => (
                  <MenuItem key={item.id} value={item.name}>
                    <Checkbox
                      checked={valuess.subjectlist.indexOf(item.name) > -1}
                    />
                    <ListItemText primary={item.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DropdownWrapper>

          <DropdownWrapper width="30">
            <Title style={{ marginBottom: "2%" }}>{qualifiedAgeGroups}</Title>
            <FormControl
              sx={{
                width: "100%",
                fontSize: "15px",
                color: "#002c99",
                fontFamily: "Montserrat",
              }}
              size="small"
              error={error.agegroup}
            >
              <InputLabel id="demo-multiple-checkbox-label">
                {selectDp}
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={valuess.agegroup}
                onChange={(
                  event: SelectChangeEvent<typeof valuess.agegroup>
                ) => {
                  const {
                    target: { value },
                  } = event;
                  dispatch(
                    addApplicationUser({
                      agegroup:
                        typeof value === "string" ? value.split(",") : value,
                    })
                  );
                }}
                input={<OutlinedInput label="Select" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {ageGroupList.map((item) => (
                  <MenuItem key={item.id} value={item.name}>
                    <Checkbox
                      checked={valuess.agegroup.indexOf(item.name) > -1}
                    />
                    <ListItemText primary={item.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DropdownWrapper>

          <DropdownWrapper width="30">
            <Title style={{ marginBottom: "2%" }}>
              {swedishlärarlegitimation}
            </Title>
            <Input2
              value={
                valuess.swedishLara
                  ? YESORNO[0].name
                  : valuess?.swedishLara === false
                  ? YESORNO[1].name
                  : ""
              }
              label={selectDp}
              dropdownList={YESORNO}
              error={error.swedishLara}
              onChange={(value) => {
                dispatch(
                  addApplicationUser({
                    swedishLara: value === YESORNO[0].name ? true : false,
                  })
                );
              }}
            />
          </DropdownWrapper>
          {valuess.swedishLara === true ? (
            <DropdownWrapper width="30">
              <Title style={{ marginBottom: "2%" }}>
                {uploadLärarlegitimation}
              </Title>
              <Formupload
                type="file"
                isValid={fileSelected} // Apply isValid class when a valid file is selected
                isInvalid={fileSelected === false} // Apply isInvalid class when no file is selected or there's an error
                onChange={(e: any) => {
                  const file = e.target.files[0]; // Get the selected file

                  if (!file) {
                    // No file selected
                    setFileSelected(false); // Set fileSelected to false for validation
                    return; // Don't proceed further
                  }

                  if (
                    file.type !== "application/pdf" &&
                    file.type !== "image/jpeg" &&
                    file.type !== "image/jpg" &&
                    file.type !== "image/png" &&
                    file.type !== "image/gif"
                  ) {
                    setFileValidat(true);
                    setFileSize(false);
                    setFileSelected(false); // Set fileSelected to false for validation
                    window.scrollTo({ top: 0 });
                  } else if (file.size > 1000000) {
                    setFileSize(true);
                    setFileValidat(false);
                    setFileSelected(false); // Set fileSelected to false for validation
                    window.scrollTo({ top: 0 });
                  } else {
                    // Valid file selected
                    setFileSelected(true); // Set fileSelected to true
                    // Read the file as a Data URL
                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                      dispatch(
                        addApplicationUser({
                          fileswedishlara: reader.result,
                        })
                      );
                    };
                  }
                }}
              />
            </DropdownWrapper>
          ) : null}
        </FlexWrapper>
      ) : valuess.qualifiedTeacher === false ? (
        <FlexWrapper width="100%" noMargin noPadding>
          <DropdownWrapper width="40" marginTop="2.4vw">
            <Title style={{ marginBottom: "2%" }}>{TeachingExperience}</Title>
            <FormControl
              sx={{
                width: "100%",
                fontSize: "15px",
                color: "#002c99",
                fontFamily: "Montserrat",
              }}
              size="small"
              error={error.subjectlistno}
            >
              <InputLabel id="demo-multiple-checkbox-label">
                {selectDp}
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={valuess.subjectlistno}
                onChange={(
                  event: SelectChangeEvent<typeof valuess.subjectlistno>
                ) => {
                  const {
                    target: { value },
                  } = event;
                  dispatch(
                    addApplicationUser({
                      subjectlistno:
                        typeof value === "string" ? value.split(",") : value,
                    })
                  );
                }}
                input={<StyledOutlineInput label="Select" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {subjectList.map((item) => (
                  <MenuItem key={item.id} value={item.name}>
                    <Checkbox
                      checked={valuess.subjectlistno.indexOf(item.name) > -1}
                    />
                    <ListItemText primary={item.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DropdownWrapper>

          <DropdownWrapper
            width="40"
            marginTop="2.4vw"
            style={{ marginBottom: "4%" }}
          >
            <Title style={{ marginBottom: "2%" }}>{ageGroupsExperience}</Title>
            <FormControl
              sx={{
                width: "100%",
                fontSize: "15px",
                color: "#002c99",
                fontFamily: "Montserrat",
              }}
              size="small"
              error={error.agegroupno}
            >
              <InputLabel id="demo-multiple-checkbox-label">
                {selectDp}
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={valuess.agegroupno}
                onChange={(
                  event: SelectChangeEvent<typeof valuess.agegroupno>
                ) => {
                  const {
                    target: { value },
                  } = event;
                  dispatch(
                    addApplicationUser({
                      agegroupno:
                        typeof value === "string" ? value.split(",") : value,
                    })
                  );
                }}
                input={<StyledOutlineInput label="Select" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {ageGroupList.map((item) => (
                  <MenuItem key={item.id} value={item.name}>
                    <Checkbox
                      checked={valuess.agegroupno.indexOf(item.name) > -1}
                    />
                    <ListItemText primary={item.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DropdownWrapper>
          <DropdownWrapper width="30" marginTop="-10px">
            <Title style={{ marginBottom: "2%", marginLeft: "1%" }}>
              {studyingteacher}
            </Title>
            <Input2
              value={
                valuess.studyingteacher
                  ? YESORNO[0].name
                  : valuess?.studyingteacher === false
                  ? YESORNO[1].name
                  : ""
              }
              label={selectDp}
              dropdownList={YESORNO}
              error={error.studyingteacher}
              onChange={(value) => {
                dispatch(
                  addApplicationUser({
                    studyingteacher: value === YESORNO[0].name ? true : false,
                  })
                );
              }}
            />
          </DropdownWrapper>
        </FlexWrapper>
      ) : null}

      {valuess.studyingteacher === true ? (
        <>
          <DropdownWrapper>
            <Title style={{ marginTop: "2%", marginLeft: "1%" }}>
              {currentlyQWualifiedTeacher}
            </Title>
            <DatePicker
              selected={selectedDate}
              dateFormat="yyyy/MM"
              showIcon
              showPopperArrow
              showMonthDropdown
              placeholderText={DATES}
              onChange={(value) => {
                dispatch(
                  addApplicationUser({
                    qualifiedteacherdate: value,
                  })
                );
              }}
              customInput={
                <Input
                  error={error.qualifiedteacherdate}
                  value={selectedDate}
                  placeholder="Select"
                />
              }
            />
          </DropdownWrapper>
          <DropdownWrapper width="30" marginTop="2.3vw">
            <Title style={{ marginBottom: "2%" }}>
              {willQualifiedSubjects}
            </Title>
            <FormControl
              sx={{
                width: "100%",
                fontSize: "15px",
                color: "#002c99",
                fontFamily: "Montserrat",
              }}
              size="small"
              error={error.subjectlistyes}
            >
              <InputLabel>{selectDp}</InputLabel>
              <Select
                multiple
                value={valuess.subjectlistyes}
                onChange={(
                  event: SelectChangeEvent<typeof valuess.subjectlistyes>
                ) => {
                  const {
                    target: { value },
                  } = event;
                  dispatch(
                    addApplicationUser({
                      subjectlistyes:
                        typeof value === "string" ? value.split(",") : value,
                    })
                  );
                }}
                input={<StyledOutlineInput label="Select" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {subjectList.map((item) => (
                  <MenuItem
                    sx={{
                      fontSize: "15px",
                      color: "#002c99",
                      fontFamily: "Montserrat",
                    }}
                    key={item.id}
                    value={item.name}
                  >
                    <Checkbox
                      checked={valuess.subjectlistyes.indexOf(item.name) > -1}
                    />
                    <ListItemText primary={item.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DropdownWrapper>

          <DropdownWrapper width="30" marginTop="2.3vw">
            <Title style={{ marginBottom: "2%" }}>
              {willQualifiedAgeGroups}
            </Title>
            <FormControl
              sx={{
                width: "100%",
                fontSize: "15px",
                color: "#002c99",
                fontFamily: "Montserrat",
              }}
              size="small"
              error={error.agegroupyes}
            >
              <InputLabel id="demo-multiple-checkbox-label">
                {selectDp}
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={valuess.agegroupyes}
                onChange={(
                  event: SelectChangeEvent<typeof valuess.agegroupyes>
                ) => {
                  const {
                    target: { value },
                  } = event;
                  dispatch(
                    addApplicationUser({
                      agegroupyes:
                        typeof value === "string" ? value.split(",") : value,
                    })
                  );
                }}
                input={<StyledOutlineInput label="Select" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {ageGroupList.map((item) => (
                  <MenuItem key={item.id} value={item.name}>
                    <Checkbox
                      checked={valuess.agegroupyes.indexOf(item.name) > -1}
                    />
                    <ListItemText primary={item.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DropdownWrapper>

          <DropdownWrapper width="22.5" marginTop="2%">
            <Input2
              value={valuess.instutionstudyteacher}
              label={nameofIns}
              error={error.instutionstudyteacher}
              onChange={(value) => {
                dispatch(
                  addApplicationUser({
                    instutionstudyteacher: value,
                  })
                );
              }}
            />
          </DropdownWrapper>
        </>
      ) : null}
      {fileValidat && (
        <ToastMessage
          show={!!fileValidat}
          bg="danger"
          top="4%"
          right="40%"
          onCloseHandler={() => {
            setFileValidat(false);
          }}
          message="File format should be PDF, JPEG, JPG, PNG, or GIF. "
        ></ToastMessage>
      )}
      {fileSize && (
        <ToastMessage
          show={!!fileSize}
          bg="danger"
          top="4%"
          right="40%"
          onCloseHandler={() => {
            setFileSize(false);
          }}
          message="File is too large"
        ></ToastMessage>
      )}
    </>
  );
};

export default EducationalDetails;
