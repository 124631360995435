import { ReactElement, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import english from "../../locale/eng";
import swedish from "../../locale/se";
import { updateLanguage } from "../../redux/Language/action";
import { Dropdown, Forms } from "./subcomponent";

interface Props {
  top?: string;
}

const LangugeDropdown = ({ top }: Props): ReactElement => {
  const [lang, setLang] = useState("SV");

  const dispatch = useDispatch();

  useEffect(() => {
    switch (lang) {
      case "SV":
        dispatch(updateLanguage(swedish));
        break;
      case "EN":
        dispatch(updateLanguage(english));
        break;
      default:
        dispatch(updateLanguage(english));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  return (
    <Forms top={top}>
      <Dropdown
        defaultValue={"SV"}
        data={["SV", "EN"]}
        onChange={(value: any) => setLang(value)}
      />
    </Forms>
  );
};

export default LangugeDropdown;
