import { colors } from "../../const/theme";
import Button from "react-bootstrap/esm/Button";
import styled from "styled-components";

export const PrimaryButton = styled(Button)` 
  color: #2097f5;
  border-color: #2097f5;
  background: #cbeeff;
  margin: auto 4px;
  width: ${({ width }) => (width ? width : "auto")};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : null)};
  cursor: pointer;
  &:hover,
  &:focus,
  &:hover,
  &:active {
    background: #2097f5;
    color: white;
    border-color: #cbeeff;
  }
  @media (max-width: 415px) {
    font-size: 12px;
  }
`;

export const SecondaryButton = styled(Button)`
  background: ${colors.white} !important;
  background-color: ${colors.white};
  color: ${({ theme }) => theme.button.primary};
  border-color: ${({ theme }) => theme.button.primary};
  &:hover,
  &:active,
  &:focus {
    background: ${({ theme }) => theme.button.secondary} !important;
    color: ${({ theme }) => theme.button.primary};
    border-color: ${({ theme }) => theme.button.primary};
  }
  margin: auto 1px;
`;
