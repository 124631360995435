import { useState } from "react";
import { Accordion } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FlexWrapper } from "../../components";
import ROUTES from "../../const/routes";
import { AccorHead } from "./subcomponents";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
const JobSeekerQ = () => {
  const history = useHistory();
  const [active, setActive] = useState("");

  const { lang } = useSelector((state: RootState) => ({
    lang: state.Language.language,
  }));

  const {
    jobans1,
    jobans2,
    jobans3_1,
    jobans3_2,
    jobans3_3,
    jobques1,
    jobques2,
    jobques3,
    jobques4,
    jobans4,
  } = lang.signUp.faq;

  return (
    <FlexWrapper noMargin noPadding>
      <div
        style={{
          width: "95%",
        }}
      >
        <Accordion>
          <Accordion.Item
            eventKey="0"
            onClick={() => {
              setActive("0");
            }}
          >
            <Accordion.Header>
              <AccorHead
                style={{
                  color: active === "0" ? "#2097f5" : "#002C99",
                }}
              >
                {jobques1}
              </AccorHead>
            </Accordion.Header>
            <Accordion.Body>{jobans1}</Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="1"
            onClick={() => {
              setActive("1");
            }}
          >
            <Accordion.Header>
              <AccorHead
                style={{
                  color: active === "1" ? "#2097f5" : "#002C99",
                }}
              >
                {jobques2}
              </AccorHead>
            </Accordion.Header>
            <Accordion.Body>{jobans2}</Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="2"
            onClick={() => {
              setActive("2");
            }}
          >
            <Accordion.Header>
              <AccorHead
                style={{
                  color: active === "2" ? "#2097f5" : "#002C99",
                }}
              >
                {jobques3}
              </AccorHead>
            </Accordion.Header>
            <Accordion.Body>
              {jobans3_1}{" "}
              <span
                style={{ color: "#1F94F3", cursor: "pointer" }}
                onClick={() => {
                  history.push(ROUTES.JOB_SEEKER_FORM);
                }}
              >
                {" "}
                {jobans3_2}{" "}
              </span>
              {jobans3_3}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="3"
            onClick={() => {
              setActive("3");
            }}
          >
            <Accordion.Header>
              <AccorHead
                style={{
                  color: active === "3" ? "#2097f5" : "#002C99",
                }}
              >
                {jobques4}
              </AccorHead>
            </Accordion.Header>
            <Accordion.Body>{jobans4}</Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </FlexWrapper>
  );
};

export default JobSeekerQ;
