import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AddScheduleApi,
  EditScheduleApi,
  GetScheduleApi,
  GetSingleSchedule,
} from "./api";
import { InitialState, getMySchedule, scheduleProps } from "./types";

export const initialState: InitialState = {
  isLoading: false,
  AddSchedule: {
    dates_info: [
      {
        unique_id: "",
        day: "",
        cus_id: "",
        ins_id: "",
        start_time: "",
        end_time: "",
        classroom: "",
        subject: "",
        year_group: "",
      },
    ],
  },
  getSchedule: [],
  reload: false,
  getseletedSchedule: {
    unique_id: "",
    day: "",
    cus_id: "",
    ins_id: "",
    start_time: "",
    end_time: "",
    classroom: "",
    subject: "",
    year_group: "",
  },
};

const MyScheduleSlice = createSlice({
  name: "mySchedule",
  initialState,
  reducers: {},
  extraReducers: {
    [AddScheduleApi.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [AddScheduleApi.fulfilled.toString()]: (state) => {
      state.isLoading = false;
    },
    [AddScheduleApi.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [GetScheduleApi.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [GetScheduleApi.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<getMySchedule>>
    ) => {
      state.isLoading = false;
      state.getSchedule = action.payload;
    },
    [GetScheduleApi.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [GetSingleSchedule.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [GetSingleSchedule.fulfilled.toString()]: (
      state,
      action: PayloadAction<scheduleProps>
    ) => {
      state.isLoading = false;
      state.getseletedSchedule = action.payload;
    },
    [GetSingleSchedule.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [EditScheduleApi.pending.toString()]: (state) => {
      state.isLoading = true;
      state.reload = false;
    },
    [EditScheduleApi.fulfilled.toString()]: (state) => {
      state.isLoading = false;
      state.reload = true;
    },
    [EditScheduleApi.rejected.toString()]: (state) => {
      state.isLoading = false;
      state.reload = false;
    },
  },
});

export default MyScheduleSlice.reducer;
