import { ReactElement, useEffect, useState } from "react";
import {
  DropdownWrapper,
  EditableDropdown,
  FlexWrapper,
  Input,
  Loader,
  Modals,
  PageWrapper,
  SectionTitle,
} from "../../../../../components";
import { Table } from "react-bootstrap";
import { CardWraper } from "../myCoveredLesson/subcomponents";
import { CreateScheduleButton, MyScheduleAction } from "./subcomponents";
import { useHistory } from "react-router-dom";
import ROUTES from "../../../../../const/routes";
import {
  EditScheduleApi,
  GetScheduleApi,
  GetSingleSchedule,
} from "../../../../../redux/Teachers/MySchedule/api";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import { CommonDelete } from "../../../../../redux/Common/api";
import { DropdownListProps } from "../../../../../components/EditableDropdown/typings";
import { DayDropdown } from "./AddSchedule/const";
import { getMySchedule } from "../../../../../redux/Teachers/MySchedule/types";

const MySchedule = (): ReactElement => {
  const dispatch: AppDispatch = useDispatch();
  const {
    userInfo,
    getScheduleDate,
    mySchedule,
    isLoading,
    reload,
    afterEditReload,
  } = useSelector((state: RootState) => ({
    userInfo: state.login.userInfo,
    getScheduleDate: state.MySchedule.getSchedule,
    isLoading: state.MySchedule.isLoading,
    mySchedule: state.Language.language.Tables.teacher.mySchedule,
    reload: state.common.reload,
    afterEditReload: state.MySchedule.reload,
    getSelectedSchedule: state.MySchedule.getseletedSchedule,
  }));

  const [editModal, setEditModal] = useState(false);
  const [Value, setValue] = useState<any>();

  useEffect(() => {
    dispatch(
      GetScheduleApi({
        unique_id: userInfo?.data.id,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload || afterEditReload]);

  const history = useHistory();

  const { sectionTitle, tableHeader } = mySchedule;

  const weekDays: any = {
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
    Sunday: 7,
  };

  const orderedScheduleList = [...getScheduleDate].sort((a: any, b: any) => {
    return weekDays[a.title] - weekDays[b.title];
  });

  function compare(a: any, b: any) {
    if (
      a.end_time < b.end_time ||
      (a.end_time === b.end_time && a.start_time > b.start_time)
    )
      return -1;
    if (
      a.end_time > b.end_time ||
      (a.end_time == b.end_time && a.start_time < b.start_time)
    )
      return 1;
    return 0;
  }

  let sortedArray: Array<getMySchedule> = [];

  orderedScheduleList?.forEach((dd) => {
    sortedArray?.push({
      title: dd?.title,
      DayData: [...dd?.DayData].sort(compare),
    });
  });

  return (
    <PageWrapper>
      <CardWraper>
        <FlexWrapper
          backgroundColor="#eff3fa"
          noMargin
          justifyContent="space-between"
        >
          <SectionTitle noMargin title={sectionTitle} />
          <CreateScheduleButton
            onClick={() => {
              history.push(ROUTES.TEACHER.TEC_ADD_SCHEDULE);
            }}
          >
            Create Schedule
          </CreateScheduleButton>
        </FlexWrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Table borderless hover>
              <thead
                style={{
                  backgroundColor: "#2097f5",
                  color: "white",
                  fontFamily: "Montserrat",
                }}
              >
                <tr style={{ textAlign: "center" }}>
                  {tableHeader.map((data, index) => (
                    <th key={`Header Unique id - ${index}`}>{data}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {sortedArray?.map((data, index) => {
                  const { title, DayData } = data;

                  return (
                    <tr
                      style={{ borderBottom: "1px solid lightgray" }}
                      key={index}
                    >
                      <td
                        style={{
                          background: "#eff3fa",
                          fontSize: "15px",
                          textAlign: "center",
                          verticalAlign: "middle",
                          fontWeight: "500",
                        }}
                      >
                        {title}
                      </td>
                      <td>
                        {DayData?.map((data) => (
                          <td style={{ display: "block" }}>
                            {data.start_time}
                          </td>
                        ))}
                      </td>
                      <td>
                        {DayData.map((data) => (
                          <td style={{ display: "block" }}>{data.end_time}</td>
                        ))}
                      </td>
                      <td>
                        {DayData.map((data) => (
                          <td style={{ display: "block" }}>{data.subject}</td>
                        ))}
                      </td>
                      <td>
                        {DayData.map((data) => (
                          <td style={{ display: "block" }}>
                            {data.year_group}
                          </td>
                        ))}
                      </td>
                      <td>
                        {DayData.map((data) => (
                          <td style={{ display: "block" }}>{data.classroom}</td>
                        ))}
                      </td>
                      <td>
                        {DayData.map((data) => (
                          <td style={{ display: "block" }}>
                            <MyScheduleAction
                              handleDelete={() => {
                                dispatch(
                                  CommonDelete({
                                    id: data.id,
                                    endPoint: "delete_my_schedule   ",
                                  })
                                );
                              }}
                              handleEdit={() => {
                                dispatch(GetSingleSchedule({ id: data.id }));
                                setEditModal(true);
                                setValue(data);
                              }}
                            />
                          </td>
                        ))}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <Modals
              center={true}
              show={editModal}
              handleCancel={() => {
                setEditModal(false);
              }}
              handleSubmit={() => {
                dispatch(EditScheduleApi(Value)).then((res) => {
                  if (res?.meta?.requestStatus === "fulfilled") {
                    dispatch(
                      GetScheduleApi({
                        unique_id: userInfo?.data.id,
                      })
                    );
                  }
                });
                setEditModal(false);
                history.push(ROUTES.TEACHER.TEC_MY_SCHEDULE);
              }}
              sizeModal="lg"
              title="Edit Schedule"
            >
              <FlexWrapper justifyContent="space-around">
                <DropdownWrapper width="25">
                  <EditableDropdown
                    dropdownList={DayDropdown}
                    defaultValue={{
                      name: Value?.day,
                      id: Value?.id,
                    }}
                    title="Day"
                    isRequired
                    placeholder="Select Day"
                    handleSelect={(value: DropdownListProps) => {
                      setValue({ ...Value, day: value.name });
                    }}
                  />
                </DropdownWrapper>
                <DropdownWrapper width="25">
                  <Input
                    value={Value?.start_time}
                    label="Start time"
                    inputType={"time"}
                    onChange={(value: string) => {
                      setValue({ ...Value, start_time: value });
                    }}
                  />
                </DropdownWrapper>
                <DropdownWrapper width="25">
                  <Input
                    value={Value?.end_time}
                    label="End time"
                    inputType={"time"}
                    onChange={(value: string) => {
                      setValue({ ...Value, end_time: value });
                    }}
                  />
                </DropdownWrapper>
                <DropdownWrapper width="25">
                  <Input
                    value={Value?.subject}
                    label="Subject/Reference"
                    placeholder="Subject/Reference"
                    inputType={"text"}
                    onChange={(value: string) => {
                      setValue({ ...Value, subject: value });
                    }}
                  />
                </DropdownWrapper>
                <DropdownWrapper width="25">
                  <Input
                    value={Value?.year_group}
                    label="Year group/Name"
                    placeholder={"Year group/Name"}
                    inputType={"text"}
                    onChange={(value: string) => {
                      setValue({ ...Value, year_group: value });
                    }}
                  />
                </DropdownWrapper>
                <DropdownWrapper width="25">
                  <Input
                    value={Value?.classroom}
                    label="Location/Room/Dept."
                    placeholder={"Location/Room/Dept."}
                    inputType={"text"}
                    onChange={(value: string) => {
                      setValue({ ...Value, classroom: value });
                    }}
                  />
                </DropdownWrapper>
              </FlexWrapper>
            </Modals>
          </>
        )}
      </CardWraper>
    </PageWrapper>
  );
};

export default MySchedule;
