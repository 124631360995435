import {
  validateRequired,
  validateEmail,
  validatePhone, 
  validateBooleanSuccess
} from "../../../../helpers/formValidation";
import { ValidateRegisterForm } from "./types";

const validateofRegisterForm = ({
  values,
  errors,
  field,
}: ValidateRegisterForm): Record<string, string> => {
  let formErrors: Record<string, string> = { ...errors };
  const {
    first_name,
    last_name,
    address,
    email_id,
    postalcode,
    phone_number,
    area_name,
    city, 
    iam_retired_pensioner,  reg_i_have_swedish,
    reg_iam_teacher_student, 
    name_of_institutition,
    proof_of_studies,
    state_hours_weeks_student,
    other_occupations, 
    date_of_brith,
    reg_short_description, 
    specify_date,
    info_other_work_experience,
    info_work_exp_in_school,
    info_work_exp_out_school,
    work_exp_in_school,
    work_exp_out_school,
    other_work_experience,
    working_hours_or_weeks,
    language_skills,
    Driving_license,
    police_clearance_report,
    How_did_you_hear_about_us,
    right_to_work_in_Sweden,type_of_study,as_soon_as_possible
  } = values;

  switch (field) {
    case "first_name":
      formErrors["first_name"] = validateRequired(first_name);
      
      break;
    case "last_name":
      formErrors["last_name"] = validateRequired(last_name);
      
      break;
    case "address":
      formErrors["address"] = validateRequired(address);
      break;
    case "email_id":
      formErrors["email_id"] = validateEmail(email_id);
      break;
    case "postalcode":
      formErrors["postalcode"] = validateRequired(postalcode);
      break;
    case "phone_number":
      formErrors["phone_number"] = validateRequired(phone_number);
      break;
    case "area_name":
      formErrors["area_name"] = validateRequired(area_name);
      break;
    case "city":
      formErrors["city"] = validateRequired(city);
      break;
     
    case "iam_retired_pensioner":
      formErrors["iam_retired_pensioner"] =validateBooleanSuccess(
        iam_retired_pensioner
      );
      break;
    case "reg_i_have_swedish":
      formErrors["reg_i_have_swedish"] =
        validateBooleanSuccess(reg_i_have_swedish);
      break;
      case "as_soon_as_possible":
        formErrors["as_soon_as_possible"] =
        validateBooleanSuccess(as_soon_as_possible);
        break;
    case "reg_iam_teacher_student":
      formErrors["reg_iam_teacher_student"] = validateBooleanSuccess(reg_iam_teacher_student);
      break;
    case "type_of_study":
      formErrors["type_of_study"] = validateBooleanSuccess(type_of_study);
      break;
    case "name_of_institutition":
      formErrors["name_of_institutition"] = validateRequired(
        name_of_institutition
      );
      break;
    case "proof_of_studies":
      formErrors["proof_of_studies"] = validateRequired(proof_of_studies);
      break;
    case "state_hours_weeks_student":
      formErrors["state_hours_weeks_student"] = validateRequired(
        state_hours_weeks_student
      );
      break;
    case "other_occupations":
      formErrors["other_occupations"] = validateBooleanSuccess(other_occupations);
      break;
    
    case "date_of_brith":
      formErrors["date_of_brith"] = validateRequired(date_of_brith);
      break;
    case "reg_short_description":
      formErrors["reg_short_description"] = validateRequired(reg_short_description);
      break; 
    case "specify_date":
      formErrors["specify_date"] = validateRequired(specify_date);
      break;

    case "work_exp_in_school":
      formErrors["work_exp_in_school"] = validateBooleanSuccess(work_exp_in_school);
      break;
    case "work_exp_out_school":
      formErrors["work_exp_out_school"] =validateBooleanSuccess(work_exp_out_school);
      break;
    case "other_work_experience":
      formErrors["other_work_experience"] = validateBooleanSuccess(
        other_work_experience
      );
      break;
    case "info_other_work_experience":
      formErrors["info_other_work_experience"] = validateRequired(
        info_other_work_experience
      );
      break;
    case "info_work_exp_in_school":
      formErrors["info_work_exp_in_school"] = validateRequired(
        info_work_exp_in_school
      );
      break;
    case "info_work_exp_out_school":
      formErrors["info_work_exp_out_school"] = validateRequired(
        info_work_exp_out_school
      );
      break;
    case "working_hours_or_weeks":
      formErrors["working_hours_or_weeks"] = validateRequired(
        working_hours_or_weeks
      );
      break;
      case "language_skills":
        formErrors["language_skills"] = validateRequired(
         language_skills
        );
        break;
        case "Driving_license":
        formErrors["Driving_license"] = validateBooleanSuccess(
         Driving_license
        );
        break;
        case "police_clearance_report":
          formErrors["police_clearance_report"] = validateBooleanSuccess(
          police_clearance_report
          );
          break;
          case "right_to_work_in_Sweden":
            formErrors["right_to_work_in_Sweden"] = validateBooleanSuccess(
           right_to_work_in_Sweden
            );
            break;
            case "How_did_you_hear_about_us":
              formErrors["How_did_you_hear_about_us"] = validateRequired(
              How_did_you_hear_about_us
              );
              break;
    default:
      formErrors["first_name"] = validateRequired(first_name);
      formErrors["last_name"] = validateRequired(last_name);
      formErrors["address"] = validateRequired(address);
      formErrors["email_id"] = validateRequired(email_id);
      formErrors["phone_number"] = validatePhone(phone_number);
      formErrors["post_code"] = validateRequired(postalcode);
      formErrors["area_name"] = validateRequired(phone_number);
      formErrors["city"] = validateRequired(city);
     
      formErrors["date_of_brith"] = validateRequired(date_of_brith);
      
      formErrors["other_occupations"] = validateBooleanSuccess(other_occupations);
      formErrors["state_hours_weeks_student"] = validateRequired(
        state_hours_weeks_student
      );
      formErrors["proof_of_studies"] = validateRequired(proof_of_studies);
      formErrors["name_of_institutition"] = validateRequired(
        name_of_institutition
      );
      formErrors["reg_short_description"] = validateRequired(reg_short_description); 
      formErrors["specify_date"] = validateRequired(specify_date);
      formErrors["work_exp_in_school"] = validateBooleanSuccess(work_exp_in_school);
      formErrors["work_exp_out_school"] = validateBooleanSuccess(work_exp_out_school);
      formErrors["other_work_experience"] =validateBooleanSuccess(
        other_work_experience
      );
      formErrors["info_other_work_experience"] = validateRequired(
        info_other_work_experience
      );
      formErrors["info_work_exp_in_school"] = validateRequired(
        info_work_exp_in_school
      );
      formErrors["info_work_exp_out_school"] = validateRequired(
        info_work_exp_out_school
      );
      formErrors[" working_hours_or_weeks"] = validateRequired(
        working_hours_or_weeks
      );
      formErrors["Driving_license"] =validateBooleanSuccess(
        Driving_license
       );
       formErrors["How_did_you_hear_about_us"] = validateRequired(
        How_did_you_hear_about_us
        );
        formErrors["right_to_work_in_Sweden"] = validateBooleanSuccess(
          right_to_work_in_Sweden
           );
           formErrors["police_clearance_report"] = validateBooleanSuccess(
            police_clearance_report
            );
  }

  return formErrors;
};

export default validateofRegisterForm;
