import styled from "styled-components"; 
import Button from "react-bootstrap/esm/Button";

const Buttons = styled(Button)`
  //background-color: ${({ theme }) => theme.button.primary};
  color: #2097f5;
  border-color: #2097f5;
  background: #cbeeff;
  &:focus,
  &:hover,
  &:active {
    background: #2097f5;
    color: white;
    border-color: #cbeeff;
  }
  margin: auto 4px;
`;

export default Buttons;
