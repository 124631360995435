const ROUTES = {
  LANDING_PAGE: "/",
  MY_PROFILE: "/myProfile",
  SIGN_IN_OPTION: "/signInOption",
  REGISTER_IN_OPTION: "/regiseter",
  REGISTER_FORM: "/registerForm",
  DEVELOPMENT: "/development",
  RECRUITMENT: "/recruitment",
  STAFF_ABSENCE: "/staffAbsence",
  DASHBOARD: "/dashboard",
  STATIC_FAQ: "/staticSiteFAQ",
  FAQ: "/FAQ",
  ABOUT: "/about",
  CONTACT_FORM: "/contactForm",
  OUR_SOLUTION: "/ourSolution",
  CUSTOMER: "/customer",
  SUBSTITUTE: "/substitute",
  JOB_SEEKER: "/jobSeeker",
  VACANCIES: "/vacancies",
  NEWS: "/news",
  NEWSLEARNMORE: "/newsdetails",
  JOB_SEEKER_FORM: "/jobSeekerForm",
  VACANCIES_APPLICATION_FORM: "/vacancyapplicationform",
  CREATE_VACANCY: "/create_vvacancy",
  UNCOVERED_VACANCY_W_LESSON: "/uncovered_vacancy_without_lesson",
  TEACHER: {
    TEC_MY_COVERED_LESSON: "/tcmyCoveredLesson",
    TEC_ICOVERED_LESSON: "/tciCoveredLesson",
    TEC_MY_ABSENCE: "/tcmyAbsence",
    TEC_MY_ASSIGNED_COVER: "/tcmyAssignedCover",
    TEC_MY_COMPLETED_COVER: "/tcmyCompletedCover",
    TEC_REGISTER_LEAVE: "/tcregisterLeave",
    TEC_MY_SCHEDULE: "/tcmySchedule",
    TEC_ADD_SCHEDULE: "/tcaddSchedule",
    TEC_DASHBOARD: "/tcDashboard",
    TEC_MY_ASSIGNEDVACANCY: "/tcAssignedVacacncy",
  },

  SCHOOL: {
    PUBLISHED_VACANCIES: "/scpublishedVacancies",
    COMPLETED_VACANCIES: "/sccompletedVacancies",
    ABCENT_STAFF: "/scabcentStaff",
    COVERED: "/sccovered",
    MY_DRAFT: "/scmyDraft",
    APPROVED_LEAVE_REQUEST: "/scapprovedLeaveRequest",
    PENDING_LEAVE: "/scpendingLeave",
    MY_ABSENCE: "/scmyAbsence",
  },

  HQ_MANAGEMENT: {
    HQ_PUBLISHED_VACANCIES: "/hqpublishedVacancies",
    HQ_COMPLETED_VACANCIES: "/hqcompletedVacancies",
    HQ_PENDING_LEAVE: "/hqpendingLeave",
    HQ_APPROVED_LEAVE_REQUEST: "/hqapprovedLeaveRequest",
    HQ_DENIED_LEAVE_REQUEST: "/hqDeniedLeaveRequest",
    HQ_ABCENT_STAFF: "/hqabsentStaff",
    HQ_COVERED: "/hqcovered",
    HQ_MY_DRAFT: "/hqmyDraft",
    HQ_UN_COVERED_LESSON: "/hqunCoverdLessons",
    HQ_MY_ABSENCE: "/hqmyAbsence",
    HQ_UNCOVERED_TABEL: "/hqUncoveredTabel",
    HQ_UNCOVEREDSEPERATEVACANCY: "/hqCreateVacancy",
    CHAT: "/chat",
    HQ_CHAT_MESSAGE: "/message",
  },
  INSTITUTE: {
    CREATED_CUSTOMER: "/createdCustomer",
    CREATE_CUSTOMER: "/createCustomer",
    MY_CONSULTANTS: "/myConsultants",
    STATICSTIS: "/statistic",
  },
  PRIVACY_POLICY: "/privacyPolicy",
  TERMS_CONDITIONS: "/terms&Conditions",
  EMPLOYEMENT: "/employement",
  RESERVE: "/reserve",
};

export default ROUTES;
