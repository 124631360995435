import { useState } from "react";
import { Accordion } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FlexWrapper } from "../../components";
import ROUTES from "../../const/routes";
import { AccorHead } from "./subcomponents";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const SubstituteQ = () => {
  const history = useHistory();
  const [active, setActive] = useState("");

  const { lang } = useSelector((state: RootState) => ({
    lang: state.Language.language,
  }));
  const {
    subques1,
    subans1,
    subques2,
    subans2,
    subques3,
    subans3_1,
    subans3_2,
    subans3_3,
    subques_4,
    subans_4,
    subans5,
    subans6,
    subques5,
    subques6,
    subques7,
    subans7_1,
    subans7_2,
    subans7_3,
  } = lang.signUp.faq;

  return (
    <FlexWrapper noMargin noPadding>
      <div
        style={{
          width: "95%",
        }}
      >
        <Accordion>
          <Accordion.Item
            eventKey="0"
            onClick={() => {
              setActive("0");
            }}
          >
            <Accordion.Header>
              <AccorHead
                style={{
                  color: active === "0" ? "#2097f5" : "#002C99",
                }}
              >
                {subques1}
              </AccorHead>
            </Accordion.Header>
            <Accordion.Body>{subans1}</Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="1"
            onClick={() => {
              setActive("1");
            }}
          >
            <Accordion.Header>
              <AccorHead
                style={{
                  color: active === "1" ? "#2097f5" : "#002C99",
                }}
              >
                {subques2}
              </AccorHead>
            </Accordion.Header>
            <Accordion.Body>
              {subans2}
              <br />
              <a
                href="https://polisen.se/tjanster-tillstand/belastningsregistret/skola-eller-forskola/"
                target="_blank"
                rel="noreferrer"
              >
                https://polisen.se/tjanster-tillstand/belastningsregistret/skola-eller-forskola/
              </a>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="2"
            onClick={() => {
              setActive("2");
            }}
          >
            <Accordion.Header>
              <AccorHead
                style={{
                  color: active === "2" ? "#2097f5" : "#002C99",
                }}
              >
                {subques3}
              </AccorHead>
            </Accordion.Header>
            <Accordion.Body>
              {subans3_1}{" "}
              <span
                style={{ color: "#1F94F3", cursor: "pointer" }}
                onClick={() => {
                  history.push(ROUTES.REGISTER_FORM);
                }}
              >
                {" "}
                {subans3_2}{" "}
              </span>{" "}
              {subans3_3}
              {}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="3"
            onClick={() => {
              setActive("3");
            }}
          >
            <Accordion.Header>
              <AccorHead
                style={{
                  color: active === "3" ? "#2097f5" : "#002C99",
                }}
              >
                {subques_4}
              </AccorHead>
            </Accordion.Header>
            <Accordion.Body>{subans_4}</Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="4"
            onClick={() => {
              setActive("4");
            }}
          >
            <Accordion.Header>
              <AccorHead
                style={{
                  color: active === "4" ? "#2097f5" : "#002C99",
                }}
              >
                {subques5}
              </AccorHead>
            </Accordion.Header>
            <Accordion.Body>{subans5}</Accordion.Body>
          </Accordion.Item>

          <Accordion.Item
            eventKey="5"
            onClick={() => {
              setActive("5");
            }}
          >
            <Accordion.Header>
              <AccorHead
                style={{
                  color: active === "5" ? "#2097f5" : "#002C99",
                }}
              >
                {subques6}
              </AccorHead>
            </Accordion.Header>
            <Accordion.Body>{subans6}</Accordion.Body>
          </Accordion.Item>

          <Accordion.Item
            eventKey="6"
            onClick={() => {
              setActive("6");
            }}
          >
            <Accordion.Header>
              <AccorHead
                style={{
                  color: active === "6" ? "#2097f5" : "#002C99",
                }}
              >
                {subques7}
              </AccorHead>
            </Accordion.Header>
            <Accordion.Body>
              {subans7_1}{" "}
              <span
                style={{ color: "#1F94F3", cursor: "pointer" }}
                onClick={() => {
                  history.push(ROUTES.REGISTER_FORM);
                }}
              >
                {subans7_2}
              </span>{" "}
              {subans7_3}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </FlexWrapper>
  );
};

export default SubstituteQ;
