import { ReactElement, useEffect } from "react";
import {
  DropdownWrapper,
  FlexWrapper,
  Input,
  Loader,
  PageWrapper,
  SectionTitle,
} from "../../../../../components";
import { PrimaryButton } from "../../../../../components/Button";
import { TableHeaderData } from "./const";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { GetMyAssignedCover } from "../../../../../redux/Teachers/CoverManagenment/api";
import { CardWraper } from "../MyAssignedCover/subcomponents";

const MyCompletedCover = (): ReactElement => {
  const dispatch = useDispatch();

  const { AssignedCover, id, isLoading } = useSelector((state: RootState) => ({
    AssignedCover: state.coverManagement.getMyAssignedCover,
    isLoading: state.coverManagement.isLoading,
    id: state.login.userInfo?.data.id,
  }));

  useEffect(() => {
    dispatch(GetMyAssignedCover({ cus_id: id, status: 2 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper>
      <CardWraper>
        <FlexWrapper
          backgroundColor="#eff3fa"
          noPadding
          noMargin
          justifyContent="space-between"
        >
          <SectionTitle noMargin title="My Completed Vacancy" />
          <DropdownWrapper paddingBottom="1%">
            <Input
              value={""}
              inputType="date"
              height="auto"
              placeholder="Select Date"
              onChange={() => {}}
            />
            <PrimaryButton>Search</PrimaryButton>
          </DropdownWrapper>
        </FlexWrapper>
        <FlexWrapper justifyContent="center">
          {isLoading ? (
            <Loader />
          ) : (
            <Table striped borderless hover>
              <thead
                style={{
                  backgroundColor: "#2097f5",
                  color: "white",
                  fontFamily: "Montserrat",
                }}
              >
                <tr style={{ textAlign: "center" }}>
                  {TableHeaderData.map((data, index) => (
                    <th key={`Header Unique id - ${index}`}>{data}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {AssignedCover.map((data, index) => {
                  const { day, position, from_time, to_time } = data;
                  return (
                    <tr key={index} style={{ textAlign: "center" }}>
                      <td>{index + 1}</td>
                      <td>{position}</td>
                      <td>{day}</td>
                      <td>{from_time}</td>
                      <td>{to_time}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </FlexWrapper>
      </CardWraper>
    </PageWrapper>
  );
};

export default MyCompletedCover;
