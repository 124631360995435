import React, { ReactElement } from "react";
import { PageWrapper } from "../../../components";

const TermsConditions = (): ReactElement => {
  return (
    <div style={{ marginBottom: "0" }}>
      <PageWrapper>
        <div>
          <h5 style={{ fontSize: "25px" }}>
            Allmänna villkor - uthyrningstjänster
          </h5>
          <br />
          <h5>ALLMÄNNA FÖRUTSÄTTNINGAR </h5>
          <br />
          Dessa villkor, härmed kallade "Villkor", gäller för alla som
          registrerar sig som Kund och skapar ett företagskonto via
          DoHR-mobilappen. Genom att skapa ett företagskonto via DoHR-mobilappen
          godkänner Kunden dessa villkor och förbinder sig att följa dem vid
          publicering av varje Uppdrag. En registrering av företagskonto är en
          förutsättning för att få tillgång till uthyrningstjänster. Alla
          ändringar till Villkor kommer att framgå av senast publicerade
          Villkoren på DoHR-mobilappen/Mer/Villkor och policyer. Kunden kommer
          att underrättas om sådana ändringar via mejl. Dessa Villkor gäller vid
          varje tillfälle Kunden publicerar Uppdrag via DoHR mobilapp.
          <br />
          <br />
          Dessa Villkor tillämpas vid användning av uthyrningstjänster som
          Dimensions of HR (DoHR), härmed kallad "Leverantören" erbjuder
          beställaren av tjänsten, härmed kallad "Kunden". Kunden ska beställa
          tjänster som omfattar uthyrningstjänster vid tillfälligt behov av
          personal. Leverantören erbjuder Kunden uthyrningstjänster vid
          tillfälligt behov av personal. Kostnaderna för beställd tjänst uppges
          när Kunden publicerar varje enskilt jobb via DoHR mobilappen.
          <br />
          <br />
          Härefter kommer "Uppdrag" att användas för att referera till varje
          beställd tjänst. Härmed kommer “Inhyrd konsult” att användas för att
          hänvisa till en konsult som accepterar Uppdrag. Alla avtalshandlingar
          som gäller för Uppdraget utgörs av dessa Villkor och de Uppdrag som
          läggs ut löpande via DoHR mobilapp.
          <br />
          <br /> I händelse av att bestämmelser i de olika handlingarna skulle
          strida mot varandra har de följande företrädesordning:
          <ul>
            <li>
              1. Eventuella särskilda skriftliga handlingar som parterna har
              kommit överens om{" "}
            </li>
            <li>2. Dessa Villkor</li>
            <li> 3. Uppdrag publicerade via DoHR mobilappen</li>
          </ul>
          <br />
          Beställning av tjänster bekräftas via DoHR mobilapp vid publicering av
          Uppdrag tillsammans med särskild information om Uppdragets typ och
          tidsåtgång.
          <br />
          <br />
          <h5>LEVERANTÖRENS ÅTAGANDE</h5>
          Enligt det publicerade Uppdraget åtar sig Leverantören till att förse
          Kunden med personal för att tillgodose deras behov.
          <br />
          <br />
          Tidsramen som anges i det publicerade Uppdraget måste följas när den
          slutförs. Leverantören ska förse Kunden med en lämplig ersättare så
          snart det är praktiskt möjligt i händelse av att en Inhyrd konsult
          säger upp sitt jobb hos Leverantören, blir sjuk eller råkar ut för
          någon annan liknande incident. Leverantören kan dock inte garantera
          att en ersättare kommer att finnas tillgänglig för att slutföra
          <br />
          <br />
          Uppdraget inom den tid som anges i publicerade Uppdrag. <br />
          <br />
          Enligt Villkoren måste Leverantören se till att alla konsulter lämnar
          utdrag från belastningsregister.
          <br />
          <br /> Leverantören har arbetsgivaransvar för Inhyrd konsult vad
          gäller löner, sociala avgifter och andra avtalsenliga lagstadgade
          utgifter.
          <br />
          <br /> Leverantörens mål är att ge Kunden tillgång till
          DOHR-mobilappen 24 timmar om dygnet, 7 dagar i veckan. Men
          Leverantören förbehåller sig rätten att tillfälligt, men begränsat,
          stänga av DOHR-mobilappen för underhåll eller uppdateringar. Dessutom
          ger Leverantören ingen garanti för att DoHR-mobilappen alltid är
          tillgänglig, och Leverantören frånsäger sig allt ansvar för eventuell
          direkt eller indirekt skada som Kunden kan lida som ett resultat av
          att DoHR-mobilappen inte är tillgänglig vid en viss tidpunkt.
          Leverantören har rätt att göra ändringar till DoHR-mobilappen av
          operativa skäl, såsom att förbättra användarupplevelsen, anpassa sig
          till en ny teknisk miljö eller möta en större användarbas.
          <br />
          <br />
          <h5>KUNDENS ÅTAGANDE</h5>
          Kunden ansvarar för arbetsledningen och ska förse Inhyrd konsult med
          nödvändig materiel för Uppdragets utförande. <br />
          <br /> Det är Kundens ansvar att under Inhyrd konsultens första
          arbetsdag att göra identitetskontroll, detta då det är omöjligt för
          Leverantören att göra det. <br />
          <br />
          Genom att publicera Uppdraget bekräftar Kunden beställningen och
          godkänner att uppdraget publiceras för Leverantörens Inhyrda
          konsulter. I fall uppdraget är inte tillsatt 60 minuter innan angiven
          starttid kommer Kunden att bli informerad om detta. Om en beställning
          görs minst tre timmar innan Uppdraget ska påbörjas strävar
          Leverantören efter 100% tillsättningsgrad av behov. Kunden erhåller en
          timmes rabatt på nästa beställning om Leverantören inte kan leverera.
          Ett undantag gäller när Kunden har valt bort eventuell möjlighet att
          publicera jobbet till alla konsulter. <br />
          <br /> Kunden kommer att informeras när Inhyrd konsulters rapporterade
          timmar är tillgängliga för godkännande. I händelse av att Kunden inte
          kan godkänna aktivt kommer rapporterade timmar automatiskt att
          godkännas 48 timmar efter att timmarna rapporterades. <br />
          <br />
          Kunden kommer att ha tillgång till alla slutförda och godkända Uppdrag
          under 30 dagar via DoHR-mobilappen. Om Kunden behöver information om
          dessa Uppdrag efter 30 dagar kan Kunden kontakta Leverantören. Om
          Kunden söker anlita eller på annat sätt konsultera någon av
          Leverantörens Inhyrda konsulter utan att först gå via Leverantören ska
          det föreligga sex månaders väntetid. Det sist genomförda Uppdraget
          används för att beräkna sex månaders tidsram för en förmedlande Inhyrd
          Konsult. <br />
          <br /> Om Kunden vill bortse från sex månaders väntetid måste Kunden
          betala till Leverantören rekryteringsarvode på 30 000 SEK exklusive
          moms. <br />
          <br />
          Avbokning av Uppdrag sker kostnadsfritt fram till den tidpunkt då en
          viss Inhyrd konsult har accepterat Uppdraget. Tillsatt Uppdrag som
          avbokas efter kl. 16.00 dagen innan det förfaller, kommer att
          debiteras i dess helhet, dock max 8 timmar. <br />
          <br />
          Utöver avtalat pris betalar Kunden för övertidsersättning och
          eventuella obekväma arbetade timmar av Inhyrd konsult som anlitas
          efter behov, i förekommande fall. Övertid ska begäras skriftligen av
          <br />
          <br /> Kunden och övertidsersättningen ska utgå i enlighet därmed.
          Kunden samtycker till att behandla de Inhyrda konsulterna likvärdigt
          och rättvist, och i enlighet med rådande arbetsmarknadsnormer. <br />
          <br />
          Kunden ansvarar för eventuella personuppgifter om den Inhyrda
          konsulten som Leverantören delar med dem. Leverantören kommer endast
          att dela Inhyrd konsultens information med Kunden om Konsulten ger
          sitt tillstånd att dela sina personuppgifter. Kunden ska ta hänsyn
          till ny LAS. Enligt nya LAS ska Inhyrd konsult erbjudas en
          tillsvidareanställning efter att ha varit inhyrd till samma
          driftsenhet mer än 24 månader under en period om 36 månader.
          Anställning hos bemanningsföretag upphör de fall där den inhyrda
          konsulten accepterar erbjudandet. Bolaget som hyr in personalen kan
          dock välja att erbjuda 2 månadslöner i stället för en
          tillsvidareanställning. Betalningen måste dock ske inom samma tid som
          ett eventuellt erbjudande om tillsvidareanställningen egentligen
          skulle ha lämnats. Kunden ansvarar för att registrerade uppgifter i
          Profil på DoHR-mobilappen är alltid korrekta. Det är Kundens
          skyldighet att säkerställa att de uppgifter som publiceras via
          DoHR-mobilappen är korrekta och inte strider mot relevanta lagar och
          föreskrifter, regler eller dessa Villkor. Leverantören förbehåller sig
          rätten att radera all information som Kunden publicerar helt eller
          delvis, samt att stänga av Kundens konto om Leverantören anser att
          informationen är stötande, olaglig, på annat sätt otillåten eller
          bryter mot dessa Villkor. Kunder kan när som helst kontakta
          support@dohr.io för att begära att deras användarkonton inaktiveras.
          Se integritetspolicyn för att veta vilka uppgifter som måste sparas
          för lagliga ändamål.
          <br />
          <br />
          <h5>UPPDRAG</h5>
          Parterna ska så snabbt som möjligt kommunicera med varandra om
          relevanta omständigheter eller händelser, såsom ändrade
          omständigheter, förseningar eller annat som kan ha inverkan på hur
          Uppdraget kommer att genomföras.
          <br />
          <br />
          <h5>ERSÄTTNING OCH FAKTURERING</h5>
          Om inget annat anges kan arbetstiden under Uppdrag variera på vardagar
          mellan 06:00 och 18:00. Fakturor för uthyrningstjänster skickas ut
          löpande efter att rapporterade timmar för varje Uppdrag godkänts.
          Betalning ska ske 30 dagar efter fakturadatum. Dröjsmålsränta
          fastställs enligt räntelagen efter förfallodagen. Leverantören
          förbehåller sig rätten att påföra en administrativ avgift på 50 kronor
          utöver dröjsmålsränta vid försenad betalning. Fakturan levereras via
          e-post till den mejladress som Kunden uppgav vid registreringen. Alla
          priser för uthyrningstjänster anges i DoHR mobilapp vid varje
          beställning och är utan mervärdesskatt. Om Kunden och Inhyrd konsult
          kommer överens om att arbeta mer timmar samma dag än vad som anges i
          det publicerade Uppdraget, kommer slutfakturan att baseras på den
          godkända tidrapporten, vilket kan påverka den totala kostnaden.
          Leverantören förbehåller sig rätten att ändra timpriset för
          uthyrningstjänster och detta gäller endast nya Uppdrag. Om Uppdraget
          kräver resor tillkommer merkostnader enligt Skatteverkets regler och
          faktureras med tilläggskostnad. <br /> <br />
          <h5>ANSVAR OCH FÖRSÄKRINGAR</h5>
          Leverantören kan inte hållas ansvarig för uteblivna Uppdrag eller
          förseningar på grund av omständigheter utanför dennes kontroll, såsom
          sjukdom, väderförhållanden, trafik eller andra omständigheter. Om en
          Inhyrd konsult inte kan fullfölja ett Uppdrag på grund av oplanerad
          anledning, såsom sjukdom, kommer Leverantören om möjligt att försöka
          hitta en ersättare. Leverantören kan inte hållas ansvarig för
          eventuella indirekta skador som kan uppstå, såsom förlorade intäkter,
          förlust av information eller andra skador som uppstår härav.
          Leverantören ansvarar för att den egna verksamheten och personal är
          försäkrade på lämpligt sätt vid eventuella skador som kan uppstå under
          avtalsperioden. Kunden ansvarar för att dess verksamhet och personal
          är försäkrad, inklusive försäkring för den Inhyrd konsult som anlitas
          av Kunden och som ska behandlas på samma sätt som Kundens egna
          anställda. Det innebär att Kunden är även ansvarig för eventuella fel
          eller skador som Inhyrd konsult orsakar Kunden eller tredje part.
          Personskadeansvar regleras av svensk lag. Skadeståndsanspråk måste
          göras skriftligen inom två månader efter slutförandet av Uppdraget och
          ska innehålla uppgifter om skadans typ, omfattning och storlek.
          <br />
          <br />
          <h5>REKLAMATION</h5>
          Fel eller brister i utförandet av Uppdraget ska upplysas till
          Leverantören så snart de upptäcks av Kunden, dock senast två dagar
          efter att Uppdraget avslutats. Viktiga anmärkningar om Leverantörens
          konsulter ska göras direkt till Leverantören, och inte personen i
          fråga. Reklamationen måste vara skriftlig och innehålla exakta
          uppgifter om felets typ och omfattning. Kundens rätt att göra anspråk
          eller på annat sätt begära avtalsenlig påföljd till följd av detta går
          förlorad om Kunden inte reklamerar inom angiven tid och på angivet
          sätt. Efter reklamation eller anmärkning ska Leverantören ges
          möjlighet att kostnadsfritt och inom rimlig tid ersätta den Inhyrd
          konsult som anmärkning eller reklamation var riktad mot.
          <br />
          <br />
          <h5>SEKRETESS</h5>
          Leverantörens konsulter har samtyckt till tystnadsplikt vid
          anställningstillfället. Leverantören har inga invändningar om Kunden
          begär att ett separat sekretessavtal tecknas för fullgörande av
          Uppdraget. Även när Uppdraget är avslutat kvarstår tystnadsplikten.{" "}
          <br />
          <br />
          Parterna är överens om att de inte kommer att avslöja någon
          information som de har erhållit från dem till tredje part och som bör
          betraktas som deras affärshemlighet, varken under detta avtals löptid
          eller längre fram. Parterna måste också vidta nödvändiga åtgärder för
          att förhindra att personal avslöjar affärshemligheter för andra.
          <br />
          <br />
          <h5>ÖVERLÅTELSE</h5>
          Utan den andra partens föregående skriftliga godkännande får ingen av
          parterna helt eller delvis överlåta sina rättigheter eller
          skyldigheter enligt dessa Villkor.
          <br />
          <br />
          <h5>TVIST</h5>
          Eventuella tvister rörande dessa Villkor som inte kan hanteras i godo
          ska först avgöras frivilligt med en medlares hjälp och därefter ska de
          avgöras fullt ut i enlighet med föreskrifterna från Stockholms
          Handelskammare Skiljedomsinstitut.
        </div>
      </PageWrapper>
    </div>
  );
};

export default TermsConditions;
