import { ReactElement, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { FlexWrapper, Footers } from "../../components";
import { Modal } from "react-bootstrap";
import ROUTES from "../../const/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { StartButton } from "../Login/ourSolution/staffAbsence/subcomponent";
import {
  Container,
  PageWrapper,
  SecondContainer,
  SecondContentHeader,
  InputWrapper,
  ApplyButton,
  CaptionsParaSub,
  Title,
  Small,
  CloseButton,
} from "./subcomponents";
import { Link } from "react-scroll";
import { placeDropdown, sectorDropdown } from "./const";
import { Card } from "react-bootstrap";
import { colors } from "../../const/theme";
import { AppText, ContentContainer } from "../GetStart/JobSeeker/subcomponents";
import { Header, Para } from "../GetStart/Customer/subcomponent";
import Input2 from "../../components/Input2";
import vacancies from "../../asserts/vacancies.png";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Getwebsitead } from "../../redux/Common/api";
import styled from "styled-components";
import { websitecreatead } from "../../redux/Common/types";

const NavigationButton = styled.button`
  font-size: 24px; /* Increase the button font size */
  background-color: transparent; /* Remove background color if not needed */
  color: #002c99; /* Set the button color */
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px; /* Set the button width */
  height: 50px; /* Set the button height */
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #2097f5; /* Change background color on hover */
    color: white; /* Change text color on hover */
  }
`;

const Vacancies = (): ReactElement => {
  const { websitegetadlist } = useSelector((state: RootState) => ({
    websitegetadlist: state.common.Websitecreateadapp,
    isLoading: state.common.isLoading,
  }));

  const dispatch = useDispatch();

  const history = useHistory();
  const [values, setValues] = useState({
    location: "",
    sector: "",
    search: "",
  });
  const { lang } = useSelector((state: RootState) => ({
    lang: state.Language.language,
  }));

  const {
    openapplication,
    see,
    talent,
    open,
    current,
    apply,
    location,
    sector,
    search,
    readmore,
    close,
  } = lang.signUp.vacancies;

  useEffect(() => {
    dispatch(
      Getwebsitead({
        type: 2,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const filteredData = websitegetadlist.filter((ad) => {
    const locationMatch =
      values?.location === "" ||
      ad?.location?.toLowerCase().includes(values.location.toLowerCase());
    const sectorMatch =
      values?.sector === "" ||
      ad?.sector?.toLowerCase().includes(values.sector.toLowerCase());
    const searchMatch =
      values?.search === "" ||
      ad?.ad_title?.toLowerCase().includes(values.search.toLowerCase());

    return locationMatch && sectorMatch && searchMatch;
  });

  const numCardsToShow = filteredData.length === 1 ? 1 : 2;

  const sliderSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: numCardsToShow, // Adjust this to control the number of cards shown at a time
    slidesToScroll: 2,
    centerPadding: "20px",
    arrows: filteredData?.length === 0 ? false : true,
  };

  const sliderRef = useRef<Slider>(null); // Create a ref for the slid

  const scrollToNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext(); // Programmatically scroll to the next slide
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedAdvertisementId, setSelectedAdvertisementId] = useState<
    string | null
  >(null);
  const handleApplyClick = (advertisementId: string) => {
    setSelectedAdvertisementId(advertisementId);
    history.push({
      pathname: ROUTES.VACANCIES_APPLICATION_FORM,
      search: `?id=${advertisementId}`,
    });
  };

  const scrollToPrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev(); // Programmatically scroll to the previous slide
    }
  };

  const [showModal, setShowModal] = useState(false);
  const [selectedAd, setSelectedAd] = useState<websitecreatead | null>(null);

  const fetchAdDetailsById = (adId: any) => {
    // Replace this with your actual data fetching logic
    // For example, you might have an array of ad data where you search for the ad by ID
    const adDetails = websitegetadlist.find((ad) => ad.id === adId);

    // Return the ad details
    return adDetails;
  };

  const toggleModal = (adId: any) => {
    if (adId) {
      // Fetch ad details by ID here, and then set the selectedAd state
      const adDetails = fetchAdDetailsById(adId); // Replace with your actual fetching logic

      if (adDetails) {
        setSelectedAd(adDetails);
        setShowModal(!showModal);
      } else {
        setSelectedAd(null); // No ad found, set selectedAd to null
        setShowModal(false);
      }
    }
  };

  return (
    <PageWrapper>
      <Container>
        <FlexWrapper
          justifyContent="center"
          noMargin
          noPadding
          className="mt-5"
        >
          <Title>{open}</Title>
        </FlexWrapper>
        <FlexWrapper justifyContent="center" noPadding>
          <CaptionsParaSub>
            {talent}
            <br /> {see}
          </CaptionsParaSub>
        </FlexWrapper>
        <FlexWrapper justifyContent="center" noPadding>
          <Link to="openApp" smooth={true}>
            <StartButton onClick={() => {}} marginleft="0px" margintop="2vw">
              {openapplication}
            </StartButton>
          </Link>
        </FlexWrapper>
      </Container>
      <SecondContainer>
        <FlexWrapper noMargin justifyContent="center">
          <SecondContentHeader color="#002C99">{current}</SecondContentHeader>
        </FlexWrapper>
        <FlexWrapper noMargin noPadding>
          <FlexWrapper width="100%" noPadding style={{ marginLeft: "3%" }}>
            <InputWrapper>
              <Input2
                value={values.location}
                label={location}
                dropdownList={placeDropdown}
                onChange={(value: any) =>
                  setValues({ ...values, location: value })
                }
              />
            </InputWrapper>
            {openapplication === "Open applications" ? (
              <InputWrapper>
                <Input2
                  onChange={(value: any) =>
                    setValues({ ...values, sector: value })
                  }
                  label={sector}
                  value={values.sector}
                  dropdownList={sectorDropdown}
                />
              </InputWrapper>
            ) : (
              <InputWrapper>
                <Input2
                  onChange={(value: any) =>
                    setValues({ ...values, sector: value })
                  }
                  label={sector}
                  value={values.sector}
                  dropdownList={sectorDropdown}
                />
              </InputWrapper>
            )}
            <InputWrapper style={{ width: "40%" }}>
              <Input2
                value={values.search}
                label={search}
                onChange={(value: any) =>
                  setValues({ ...values, search: value })
                }
              />
            </InputWrapper>
          </FlexWrapper>
        </FlexWrapper>
        <FlexWrapper justifyContent="space-between">
          <NavigationButton onClick={scrollToPrev}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </NavigationButton>
          {/* <div>{`${entriesLabel}`}</div> Display the entries label */}
          <NavigationButton onClick={scrollToNext}>
            <FontAwesomeIcon icon={faChevronRight} />
          </NavigationButton>
        </FlexWrapper>
        <Slider {...sliderSettings} ref={sliderRef}>
          {filteredData.map((ad, index) => (
            <div
              style={{
                flexDirection: "column",
                justifyContent: "space-between",
                height: "50%",
                margin: "auto",
              }}
            >
              <Card
                style={{ width: "80%", margin: "50px", border: "none" }}
                key={index}
              >
                <Card.Body>
                  <Card.Title
                    style={{
                      marginBottom: "6%",
                      color: `${colors.darkBlue}`,
                      fontSize: "1.5vw",
                    }}
                  >
                    {ad.ad_title} {/* Use the ad title */}
                  </Card.Title>
                  <Card.Subtitle
                    className="mb-2 text-muted"
                    style={{
                      fontSize: "100%",
                      width: "140%",
                    }}
                  >
                    {`${ad.location} | ${ad.sector}`}{" "}
                    {/* Use location and sector as subtitle */}
                  </Card.Subtitle>
                  <Card.Text
                    style={{
                      fontSize: "100%",
                    }}
                  >
                    {ad.descrption.length > 50 ? (
                      <p>
                        {ad.descrption.substring(0, 50)}...{" "}
                        <span
                          style={{
                            cursor: "pointer",
                            color: colors.darkBlue,
                            textDecoration: "none",
                          }}
                          onClick={() => toggleModal(ad.id)}
                        >
                          {readmore}
                        </span>
                      </p>
                    ) : (
                      <p>{ad.descrption}</p>
                    )}
                  </Card.Text>
                  <Card.Link href="#">
                    <ApplyButton
                      onClick={() => {
                        handleApplyClick(ad.id);
                      }}
                    >
                      {apply}
                    </ApplyButton>
                  </Card.Link>
                </Card.Body>
              </Card>
            </div>
          ))}
        </Slider>
      </SecondContainer>
      <ContentContainer id="openApp">
        <div
          data-aos="fade-right"
          style={{ padding: "5vw 0px 3vw 4vw", width: "60vw" }}
        >
          <Header color="#002C99">{openapplication}</Header>
          {openapplication === "Open applications" ? (
            <>
              <Para width="100%" color="#002C99">
                We recognize the value of professional networking, <br /> which
                is why we invite you to fill out our <br />
                <span
                  style={{
                    cursor: "pointer",
                    color: "#2097F5",
                  }}
                  onClick={() => {
                    history.push(ROUTES.JOB_SEEKER_FORM);
                  }}
                >
                  {" "}
                  open application form
                </span>{" "}
                so we can help you <br /> find the role that fits.
              </Para>
              <AppText width="100%" color="#002C99" bold="500">
                We will review your application and contact you <br /> within
                2-3 weeks. Meanwhile, we recommend that <br /> you continue to
                monitor our vacancies.
              </AppText>
              <AppText width="100%" color="#002C99" bold="500">
                Visit our{" "}
                <span
                  style={{
                    cursor: "pointer",
                    color: "#2097F5",
                  }}
                  onClick={() => {
                    history.push(ROUTES.STATIC_FAQ);
                  }}
                >
                  FAQ
                </span>{" "}
                for more information.{" "}
              </AppText>
            </>
          ) : (
            <>
              <Para width="100%" color="#002C99">
                Vi tror på vikten av professionellt nätverkande, <br />
                och vi inbjuder dig att fylla i vår <br />
                <span
                  style={{
                    cursor: "pointer",
                    color: "#2097F5",
                  }}
                  onClick={() => {
                    history.push(ROUTES.JOB_SEEKER_FORM);
                  }}
                >
                  {" "}
                  spontanansökan
                </span>{" "}
                så att vi kan hitta ett jobb <br /> som passar just dig.
              </Para>
              <AppText width="100%" color="#002C99" bold="500">
                Vi kommer att granska din ansökan och kontakta <br /> dig inom
                2-3 veckor. Under tiden du väntar på återkoppling från oss,{" "}
                <br /> rekommenderar vi att du fortsätter att hålla utkik efter
                lediga jobb, <br /> eftersom fler nya jobb dyker upp hela tiden.
              </AppText>
              <AppText width="100%" color="#002C99" bold="500">
                Om du har ytterligare frågor, besök gärna vår sida med{" "}
                <span
                  style={{
                    cursor: "pointer",
                    color: "#2097F5",
                  }}
                  onClick={() => {
                    history.push(ROUTES.STATIC_FAQ);
                  }}
                >
                  Vanliga frågor.
                </span>{" "}
              </AppText>{" "}
            </>
          )}
        </div>
        <FlexWrapper noMargin noPadding>
          <img style={{ width: "40vw", height: "auto" }} src={vacancies} alt="" />
        </FlexWrapper>
      </ContentContainer>
      <Footers />
      <Modal
        show={showModal}
        size="xl"
        centered
        animation
        scrollable
        style={{ marginTop: "45px" }}
      >
        <Modal.Header>
          <Modal.Title>{selectedAd?.ad_title}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "70vh", overflowY: "auto" }}>
          <p>
            {selectedAd?.location} | {selectedAd?.sector}
          </p>
          <Small>{selectedAd?.descrption}</Small>
        </Modal.Body>
        <Modal.Footer>
          <CloseButton variant="secondary" onClick={toggleModal}>
            {close}
          </CloseButton>
          <ApplyButton onClick={() => handleApplyClick(selectedAd?.id)}>
            {apply}
          </ApplyButton>
        </Modal.Footer>
      </Modal>
    </PageWrapper>
  );
};

export default Vacancies;
