import { RegisterPaylaod } from "../../../../../redux/login/types";

export function validateForm(values: RegisterPaylaod) {
  const errors = {
    first_name: values.first_name ? "" : "Enter your first name",
    last_name: values.last_name ? "" : "Enter your last name",
    phone_number: values.phone_number ? "" : "Enter your phone number",
    email_id: values.email_id ? "" : "Enter your email",
    address: values.address ? "" : "Enter your address",
    postalcode: values.postalcode ? "" : "Enter your postal code",
    swedish_bank_account: "",
    area_name: values.area_name ? "" : "Enter your area name",
    date_of_brith: values.date_of_brith ? "" : "enter Date of birth",
    city: values.city ? "" : "Enter your city",
    self_employed: "",
    other_occupations: "",
    preferred_work_institution: values.preferred_work_institution
      ? ""
      : "Enter your preferred work institution",

    type_of_study: "",
    state_hours_weeks_student: "",
    name_of_institutition: "",
    work_exp_in_school:
      typeof values.work_exp_in_school === "boolean"
        ? ""
        : "Enter your work experience in school",
    info_work_exp_in_school: values.work_exp_in_school
      ? values.info_work_exp_in_school
        ? ""
        : "Enter information about your work experience in school"
      : "",

    work_exp_out_school:
      typeof values.work_exp_out_school === "boolean"
        ? ""
        : "Enter your work experience outside of school",
    info_work_exp_out_school: values.work_exp_out_school
      ? values.info_work_exp_out_school
        ? ""
        : "Enter information about your work experience outside of school"
      : "",

    other_work_experience:
      typeof values.other_work_experience === "boolean"
        ? ""
        : "Enter other work experience",
    info_other_work_experience: values.other_work_experience
      ? values.info_other_work_experience
        ? ""
        : "Enter information about other work experience"
      : "",
    as_soon_as_possible: values.as_soon_as_possible
      ? ""
      : "Select your availability",
    specify_date: "",
    working_hours_or_weeks: values.working_hours_or_weeks
      ? ""
      : "Enter your working hours or weeks",

    working_evening_or_weekends:
      typeof values.working_evening_or_weekends === "boolean"
        ? ""
        : "Select your availability for working evenings or weekends",
    language_skills: values.language_skills ? "" : "Enter your language skills",
    Iam_EU_citizen: "",
    Iam_non_EU_citizen: "",
    police_clearance_report:
      typeof values.police_clearance_report === "boolean"
        ? ""
        : "Enter your police clearance report",
    police_report: "",
    Driving_license:
      typeof values.Driving_license === "boolean"
        ? ""
        : "Enter your Driving License",
    How_did_you_hear_about_us: values.How_did_you_hear_about_us
      ? ""
      : "Select how you heard about us",
    // marketing_purpose: typeof values.marketing_purpose === "boolean" ? "" :  "Select your marketing purpose",
    // accurate_information: typeof values.accurate_information === "boolean" ? "" : "Select the accuracy of your information",
    // terms_and_condition: typeof values.terms_and_condition === "boolean" ? "" : "Accept our terms and conditions",

    proof_of_studies: "",
    iam_retired_pensioner: "",
  };

  if (values.first_name === "") {
    errors.first_name = "required";
  }

  if (values.reg_iam_teacher_student === true) {
    // eslint-disable-next-line no-whitespace-before-property
    if (!values.type_of_study) {
      errors.type_of_study = "Enter your education type";
    }
    if (values.education_type === "Part Time") {
      if (!values.state_hours_weeks_student) {
        errors.state_hours_weeks_student = "Enter the amount of time";
      }
    }
    if (!values.name_of_institutition) {
      errors.name_of_institutition = "Enter the name of your institution";
    }
    if (!values.proof_of_studies) {
      errors.proof_of_studies = "Enter your proof of studies";
    }
  }

  if (values.reg_iam_teacher_student === false) {
    if (
      typeof values.self_employed !== "boolean" &&
      values.self_employed !== ""
    ) {
      errors.self_employed = "Select your self-employment status";
    }
    if (
      typeof values.iam_retired_pensioner !== "boolean" &&
      values.iam_retired_pensioner !== ""
    ) {
      errors.iam_retired_pensioner = "Select your retired pensioner status";
    }
    if (
      typeof values.other_occupations !== "boolean" &&
      values.other_occupations !== ""
    ) {
      errors.other_occupations = "Enter other occupations";
    }
  }

  if (
    values.work_exp_in_school &&
    values.work_exp_out_school &&
    values.other_work_experience
  ) {
    if (!values.info_work_exp_in_school) {
      errors.info_work_exp_in_school =
        "Enter information about your work experience in school";
    }
    if (!values.info_work_exp_out_school) {
      errors.info_work_exp_out_school =
        "Enter information about your work experience outside of school";
    }
    if (!values.info_other_work_experience) {
      errors.info_other_work_experience =
        "Enter information about other work experience";
    }
  }

  if (values.as_soon_as_possible === false) {
    if (!values.specify_date) {
      errors.specify_date = "Enter a specific date";
    }
  } else {
    errors.specify_date = ""; // Reset the specify_date error message
  }

  if (
    values.as_soon_as_possible !== true &&
    values.as_soon_as_possible !== false
  ) {
    errors.as_soon_as_possible = "Select your availability";
  } else {
    errors.as_soon_as_possible = ""; // Reset the as_soon_as_possible error message
  }

  return errors;
}
