import { ReactElement } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DropdownWrapper, FlexWrapper, Input } from "../../../components";
import Input2 from "../../../components/Input2";
import { 
  DropdownWrapperfor,
} from "./subcomponent"; 
import { addVacanciesApplicationUser } from "../../../redux/Common/action"; 
import { RootState } from "../../../redux/store"; 
import { colors } from "../../../const/theme";
import { Accordion } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Title } from "../../../components/EditableDropdown/subcomponents"; 
import { TitleCard } from "../../../container/Login/ChooseSignIn/register/subcomponent"; 
import { VacanciesApplicationProps } from "../../../redux/Common/types";
const PersonalDetails = ({
  errorss,
  validateForms,
}: Partial<VacanciesApplicationProps>): ReactElement => {
  const { 
    values,
  } = useSelector(
    (state: RootState) => ({
      string: state.Language.language,
      common: state.common,
      values: state.common.VacanciesuserApplicationProsp as VacanciesApplicationProps,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const { lang } = useSelector((state: RootState) => ({
    lang: state.Language.language,
  }));

 
  const {
    firstName,
    lastName,
    Post_code,
    address,
    area_name,
    city,
    dob,
    email,
    telusbriefly,
    personaldetails,
    DOB,
    phoneNumber,
  } = lang.applicationForm;
 
 

  const selectedDate = values.date_of_birth
    ? typeof values.date_of_birth === "string"
      ? new Date(values.date_of_birth)
      : values.date_of_birth
    : null;

  return (
    <>
      <Accordion defaultActiveKey="">
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <Title>{personaldetails}</Title>
          </Accordion.Header>
          <Accordion.Body>
            {" "}
            <FlexWrapper
              noMargin
              noPadding
              style={{ marginTop: "2%", marginLeft: "2%" }}
              width="100%"
            >
              <DropdownWrapper>
                <Input2
                  value={values.first_name}
                  label={firstName}
                  onBlur={() => {
                    validateForms("first_name");
                  }}
                  onChange={(value) => {
                    dispatch(
                      addVacanciesApplicationUser({ first_name: value })
                    );
                    validateForms("first_name");
                  }}
                  error={errorss.first_name}
                />
              </DropdownWrapper>
              <DropdownWrapper>
                <Input2
                  value={values.last_name}
                  label={lastName}
                  onBlur={() => {
                    validateForms("last_name");
                  }}
                  onChange={(value) => {
                    dispatch(addVacanciesApplicationUser({ last_name: value }));
                    validateForms("last_name");
                  }}
                  error={errorss.last_name}
                />
              </DropdownWrapper>
            </FlexWrapper>
            <FlexWrapper noMargin noPadding style={{ marginLeft: "2%" }}>
              <DropdownWrapper>
                <Input2
                  value={values.address}
                  label={address}
                  onBlur={() => {
                    validateForms("address");
                  }}
                  onChange={(value) => {
                    dispatch(addVacanciesApplicationUser({ address: value }));
                    validateForms("address");
                  }}
                  error={errorss.address}
                />
              </DropdownWrapper>
              <DropdownWrapper width="20">
                <Input2
                  value={values.postalcode}
                  onBlur={() => {
                    validateForms("postalcode");
                  }}
                  label={Post_code}
                  onChange={(value) => {
                    dispatch(
                      addVacanciesApplicationUser({ postalcode: value })
                    );
                    validateForms("postalcode");
                  }}
                  error={errorss.postalcode}
                />
              </DropdownWrapper>
              <DropdownWrapper>
                <Input2
                  value={values.area_name}
                  label={area_name}
                  onBlur={() => {
                    validateForms("area_name");
                  }}
                  onChange={(value) => {
                    dispatch(addVacanciesApplicationUser({ area_name: value }));
                    validateForms("area_name");
                  }}
                  error={errorss.area_name}
                />
              </DropdownWrapper>
              <DropdownWrapper>
                <Input2
                  value={values.city}
                  label={city}
                  onBlur={() => {
                    validateForms("city");
                  }}
                  onChange={(value) => {
                    dispatch(addVacanciesApplicationUser({ city: value }));
                    validateForms("city");
                  }}
                  error={errorss.city}
                />
              </DropdownWrapper>
            </FlexWrapper>
            <FlexWrapper noMargin noPadding style={{ marginLeft: "2%" }}>
              {/* <DropdownWrapper>
                <Input2 value={""} label={phoneNumber} onChange={() => {}} />
              </DropdownWrapper> */}
              <DropdownWrapper>
                <Input2
                  value={values.phone_number}
                  inputType="number"
                  onBlur={() => validateForms("phone_number")}
                  error={errorss.phone_number}
                  label={phoneNumber}
                  onChange={(value) => {
                    dispatch(
                      addVacanciesApplicationUser({ phone_number: value })
                    );
                  }}
                />
              </DropdownWrapper>
              <DropdownWrapper>
                <Input2
                  value={values.email_id}
                  label={email}
                  onBlur={() => {
                    validateForms("email_id");
                  }}
                  onChange={(value) => {
                    dispatch(addVacanciesApplicationUser({ email_id: value }));
                    validateForms("email_id");
                  }}
                  error={errorss.email_id}
                />
              </DropdownWrapper>

              <DropdownWrapper marginTop="-7px">
                <DatePicker
                  selected={selectedDate}
                  dateFormat="yyyy/MM/dd"
                  showYearDropdown
                  yearDropdownItemNumber={50}
                  showMonthDropdown
                  scrollableYearDropdown
                  placeholderText={DOB}
                  onChange={(value) => {
                    dispatch(
                      addVacanciesApplicationUser({ date_of_birth: value })
                    );
                    validateForms("date_of_birth");
                  }}
                  customInput={
                    <Input
                      value={selectedDate}
                      label={dob}
                      placeholder="Select date"
                      error={errorss.date_of_birth}
                      onBlur={() => {
                        validateForms("date_of_birth");
                      }}
                    />
                  }
                />
              </DropdownWrapper>
            </FlexWrapper>
            <FlexWrapper
              noPadding
              style={{ flexDirection: "column", marginLeft: "2%" }}
            >
              <TitleCard style={{ marginLeft: "2%", color: colors.darkBlue }}>
                {telusbriefly}
              </TitleCard>

              <DropdownWrapperfor width="40">
                <Input
                  height="auto"
                  value={values?.description}
                  onBlur={() => validateForms("description")}
                  inputType="textarea"
                  error={errorss.description}
                  onChange={(value) => {
                    dispatch(
                      addVacanciesApplicationUser({ description: value })
                    );
                    validateForms("description");
                  }}
                />
              </DropdownWrapperfor>
            </FlexWrapper>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default PersonalDetails;
