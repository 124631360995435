export const DayDropdown = [
  { id: "1", name: "Monday" },
  { id: "2", name: "Tuesday" },
  { id: "3", name: "Wednesday" },
  { id: "4", name: "Thursday" },
  { id: "5", name: "Friday" },
  { id: "5", name: "Saturday" },
  { id: "5", name: "Sunday" },
];

export const resetInitialValue = {
  day: false,
};
