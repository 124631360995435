import { ReactElement, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FlexWrapper, Footers } from "../../../../components";
import ROUTES from "../../../../const/routes";
import {
  CardText,
  CardWrapper,
  SigninButton,
  Image,
  FlexWrapperSign,
} from "../subcomponents";
import absenceIcon from "../../../../asserts/data-transfer.png";
import substituteIcon from "../../../../asserts/replace.png";
import jobSekersIcon from "../../../../asserts/search-job.png";
import { PageWrapper } from "./subcomponent";
import { ModalText } from "../../subcomponent";
import Aos from "aos";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";


const Register = (): ReactElement => {
  const history = useHistory();
  const { lang } = useSelector((state: RootState) => ({
    lang: state.Language.language,
  }));
  useEffect(() => {
    Aos.init({
      duration: 900,
      once: false,
    });
    window.scrollTo({ top: 0 });
  }, []);
const {
  header_1,header_2,subheading_1, subheading_2,subheading_3,button
}=lang.signUp.getstartpage
  return (
    <PageWrapper>
      <FlexWrapper justifyContent="center" noMargin noPadding>
        <ModalText data-aos="fade-down" data-aos-duration="3000" width="90%">
          {header_1} <br /> {header_2}
        </ModalText>
      </FlexWrapper>
      <FlexWrapperSign>
        <CardWrapper top="0%">
          <FlexWrapper noMargin noPadding justifyContent="center">
            <Image src={absenceIcon} alt="" />
            <CardText>{subheading_1}</CardText>
            <SigninButton
              onClick={() => {
                history.push(ROUTES.OUR_SOLUTION);
              }}
            >
             {button}
            </SigninButton>
          </FlexWrapper>
        </CardWrapper>
        <CardWrapper width="24" top="0%">
          <FlexWrapper noMargin noPadding justifyContent="center">
            <Image src={substituteIcon} alt="" />
            <CardText>{subheading_2}</CardText>
            <SigninButton
              onClick={() => {
                history.push(ROUTES.SUBSTITUTE);
              }}
            >
           {button}
            </SigninButton>
          </FlexWrapper>
        </CardWrapper>
        <CardWrapper top="0%">
          <FlexWrapper noMargin noPadding justifyContent="center">
            <Image src={jobSekersIcon} alt="" />
            <CardText>{subheading_3}</CardText>
            <SigninButton
              onClick={() => {
                history.push(ROUTES.JOB_SEEKER);
              }}
            >
            {button}
            </SigninButton>
          </FlexWrapper>
        </CardWrapper>
      </FlexWrapperSign>
      <Footers />
    </PageWrapper>
  );
};

export default Register;
