import { OutlinedInput } from "@mui/material";
import { Card, Form } from "react-bootstrap";
import styled from "styled-components";
import fonts, { weight } from "../../../const/fonts";

export const PageContainer = styled.div`
  display: flex;
  margin-top: 3%;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 30px;
  padding: 0 12px;
  @media (max-width: 415px) {
    width: 98%;
    margin-top: 3%;
    margin: 24px 0;
  }
`;

interface Props {
  width?: string;
}

export const CheckBoxWrapper = styled.div<Props>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => (width ? width : "21%")};
  margin-top: 1%;
  margin-left: 2%;
`;

export const CardWrapper = styled(Card)`
  margin-top: 2vw;
`;

export const FormCheckBox = styled(Form.Check)`
  font-size: 15px;
  .form-check {
    min-width: 5rem;
  }
`;

export const TitleCard = styled.div`
  padding-left: 14px;
  margin: 7px 0 7px 0;
  color: #111111;
  font-size: ${fonts.small}px;
  font-weight: ${weight.xnormal};
  @media (max-width: 415px) {
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
`;

export const StyledOutlineInput = styled(OutlinedInput)`
  font-family: "Montserrat" !important;
  font-size: 15px !important;
  color: #002c99 !important;
`;

export interface DropdownWrapperProps {
  width?: string;
  height?: string;
  marginRight?: string;
  paddingBottom?: string;
}


export const DropdownWrapperfor = styled.div<DropdownWrapperProps>`
  display: flex;
  margin: 0px 1px 10px;
  width: ${({ width }) => (width ? `${width}%` : "22%")};
  margin-left: 2%;
  margin-top: 1%;
  border-radius: 8px;
  padding-bottom: ${({ paddingBottom }) =>
    paddingBottom ? paddingBottom : null};
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : null)};
  @media (max-width: 415px) {
    display: block;
    width: 100%;
    margin: 8px;
  }
  #editable-dropdown {
    height: 100%;
  }
  &.errors input[type="checkbox"] {
    border: 1px solid red;
  }
 &.error {
    background-color: transparent; /* Light red background color */
    color: #ff0000; /* Red text color */
    padding: 0px; /* Add some padding for better visibility */
 }
`;