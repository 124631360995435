import moment from "moment";
import { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FlexWrapper, PageWrapper, SectionTitle } from "../../../../components";
import {
  getChatMessage,
  sendMessage,
} from "../../../../redux/Hq_Management/chat/api";
import { RootState } from "../../../../redux/store";
import { CardWraper } from "../../AbsenceManagement/Vacancies/PublishedVacancies/subcomponents";
import {
  ChatMappingWrapper,
  ChatWrapper,
  ConsaltantMessage,
  ConsaltantMessageWrapper,
  CustomerMessage,
  CustomerMessageWrapper,
  FormInput,
  Icon,
  InputWrapper,
  TimeStamp,
} from "./subcomponent";

const ChatMessage = (): ReactElement => {
  const {
    chatMessage,
    chat: { getChatMessageData, updateChatId, isLoadingChatSent },
  } = useSelector((state: RootState) => ({
    chatMessage: state.Language.language.Tables.chats,
    chat: state.chat,
  }));

  const { sectionTitle } = chatMessage;
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(
        getChatMessage({
          chat_id: updateChatId,
          type: "2",
        })
      );
    }, 4000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper>
      <CardWraper>
        <FlexWrapper backgroundColor="#eff3fa" noMargin height={55}>
          <SectionTitle title={sectionTitle} noMargin hasBackButton />
        </FlexWrapper>
        <ChatWrapper>
          <ChatMappingWrapper>
            {getChatMessageData.length === 0 ? (
              <div
                style={{
                  padding: "50px",
                  textAlign: "center",
                  fontWeight: "600",
                  background: "whitesmoke",
                }}
              >
                Let Start your Chat here.....
              </div>
            ) : (
              getChatMessageData?.map((data, index) => (
                <div key={index} style={{ margin: "10px" }}>
                  <CustomerMessageWrapper key={index}>
                    <CustomerMessage
                      dispaly={data.con_message === null ? "none" : "flex"}
                    >
                      {data.con_message}
                      <TimeStamp>
                        {data.con_message === null
                          ? ""
                          : moment(data.created_at).format(
                              "DD-MM-YYYY-hh:mm:A"
                            )}
                      </TimeStamp>
                    </CustomerMessage>
                  </CustomerMessageWrapper>
                  <ConsaltantMessageWrapper>
                    <ConsaltantMessage
                      dispaly={data.cus_message === null ? "none" : "flex"}
                    >
                      {data.cus_message}
                      <TimeStamp>
                        {data.cus_message === null
                          ? ""
                          : moment(data.created_at).format(
                              "DD-MM-YYYY-hh:mm:A"
                            )}
                      </TimeStamp>
                    </ConsaltantMessage>
                  </ConsaltantMessageWrapper>
                </div>
              ))
            )}
          </ChatMappingWrapper>
          <InputWrapper>
            <FormInput
              value={message}
              type="textarea"
              placeholder="Type your message here..."
              onChange={(e: any) => {
                setMessage(e.target.value);
              }}
            />
            <Icon
              icon={
                isLoadingChatSent ? ["fas", "spinner"] : ["fas", "paper-plane"]
              }
              size="lg"
              onClick={() => {
                dispatch(
                  sendMessage({
                    chat_id: updateChatId,
                    type: 1,
                    message: message,
                  })
                );
                setMessage("");
              }}
            />
          </InputWrapper>
        </ChatWrapper>
      </CardWraper>
    </PageWrapper>
  );
};

export default ChatMessage;
