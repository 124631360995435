import api from "../../../services";
import { createAsyncThunk } from "@reduxjs/toolkit";
import apiEndpoints from "../../../const/apiEndpoints";
import history from "../../../const/history";
import ROUTES from "../../../const/routes";
import { RootState } from "../../store";

export const GetMyCovered = createAsyncThunk(
  "cover/getMyCovered",
  async (requestPayload: { id: any }): Promise<Array<any>> => {
    const response = await api.post(apiEndpoints.getMyCovered, requestPayload);
    return response.data.message;
  }
);

export const GetICovered = createAsyncThunk(
  "cover/getICovered",
  async (requestPayload: { id: any }): Promise<Array<any>> => {
    const response = await api.post(apiEndpoints.getIcovered, requestPayload);
    return response.data.message;
  }
);

export const GetMyAssignedCover = createAsyncThunk(
  "cover/getMyAssigned",
  async (requestPayload: {
    cus_id?: string;
    status?: number;
  }): Promise<Array<any>> => {
    const response = await api.post(
      apiEndpoints.getMyAssignedCover,
      requestPayload
    );
    return response.data.message;
  }
);

export const ApprovemyAssignedVacancy = createAsyncThunk(
  "Approve",
  async (requestPayload: any): Promise<any> => {
    const response = await api.post(
      `${apiEndpoints.approveVacancy}`,
      requestPayload
    );
    if (response.data) {
      history.push(ROUTES.TEACHER.TEC_ICOVERED_LESSON);
    }
    return response?.data;
  }
);

export const ApprovemyAssignedVacancy2 = createAsyncThunk(
  "Approve",
  async (requestPayload: any): Promise<any> => {
    const response = await api.post(
      `${apiEndpoints.approveVacancy}`,
      requestPayload
    );
    if (response.data) {
      history.push(ROUTES.TEACHER.TEC_MY_COMPLETED_COVER);
    }
    return response?.data;
  }
);

export const getassignedCoverCount = createAsyncThunk(
  "getCount/teacherCount",
  async (requestPayload: any): Promise<any> => {
    const response = await api.post(
      `${apiEndpoints.getAssignedCount}`,
      requestPayload
    );
    return response?.data.count;
  }
);

export const getTmroassignedCoverCount = createAsyncThunk(
  "getCounttmro/TmroteacherCount",
  async (requestPayload: any): Promise<any> => {
    const response = await api.post(
      `${apiEndpoints.getAssignedCount}`,
      requestPayload
    );
    return response?.data.count;
  }
);

export const getCustomassignedCoverCount = createAsyncThunk(
  "getCountCus/CusteacherCount",
  async (requestPayload: any): Promise<any> => {
    const response = await api.post(
      `${apiEndpoints.getAssignedCount}`,
      requestPayload
    );
    return response?.data.count;
  }
);

export const denyMyAssignedVacancy = createAsyncThunk(
  "deny/assignedDeny",
  async (requestPayload: any, { dispatch, getState }): Promise<any> => {
    const {
      login: { userInfo },
    } = getState() as RootState;
    const response = await api.post(
      `${apiEndpoints.denyAssignVacn}`,
      requestPayload
    );
    if (response?.status === 200) {
      dispatch(GetMyAssignedCover({ cus_id: userInfo?.data?.id, status: 0 }));
    }
    return response?.data;
  }
);

export const getFeedBack = createAsyncThunk(
  "feedBack/getFeedBack",
  async (requestPayload: any): Promise<any> => {
    const response = await api.post(
      `${"Get_FeedBack_Vacancy"}`,
      requestPayload
    );
    return response?.data;
  }
);
