import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Nav } from "react-bootstrap";
import { Link } from "react-scroll";
import styled from "styled-components";
import { colors } from "../../../const/theme";

export const HeaderWrapper = styled.header`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  text-align: center;
  background: #ffffff;
  padding: 0.5vw 2vw 0.5vw 0px;
  z-index: 10000;
  box-shadow: 0 10px 10px -10px #7d7d7d;
  position: fixed;
  right: 0;
  transition: 0.3s;
`;

export const Icon = styled(Button)`
  background: none;
  border: none;
  color: ${colors.white};
  outline: none;
  margin: 0 auto;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  &:hover,
  &:focus,
  &:active {
    background: none;
    border: none;
    outline: none;
  }
  @media (min-width: 800px) {
    display: none;
  }
`;

export const HumburgerIcon = styled(FontAwesomeIcon)`
  color: black;
`;

export const ChervonIcon = styled(FontAwesomeIcon)`
  padding-left: 0.5rem;
  font-size: 11px;
`;

export const LogoWrapper = styled.div`
  cursor: pointer;
`;

export const Logo = styled.img`
  width: 35%;
  height: auto;
`;

export const NavbarDesktopWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  @media (max-width: 800px) {
    display: none;
  }
`;

export const NavbarMobileWrapper = styled.div`
  position: absolute;
  @media (max-width: 800px) {
    height: 180px;
    flex-direction: row;
    background-color: #2097f5;
    box-shadow: 0px 5px 10px gray;
    position: absolute;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
    top: 60px;
  }
  @media (max-width: 450px) {
    top: 100%;
  }
  @media (min-width: 800px) {
    display: none;
  }
`;

export const NavBar = styled(Nav)`
  width: 250%;
  @media (max-width: 910px) {
    width: 300%;
  }
  @media (max-width: 800px) {
    flex-direction: column;
    display: flex;
    justify-content: center;
    transition: all 0.5s ease;
  }
  @media (max-width: 415px) {
    width: auto;
  }
`;
export const NavItems = styled(Link)`
  color: ${colors.darkBlue};
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  font-size: 1.4vw;
  text-decoration: none;
  margin: 10px 0px 0px 2vw;
  border: none;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    transition: 0.3s;
    color: ${colors.darkBlue};
    margin-top: 1vw;
    border-bottom: 2px solid ${colors.darkBlue};
  }
  @media (max-width: 550px) {
    font-size: 10px;
  }
`;

export const DropDownItems = styled.div`
  display: none;
  position: absolute;
  margin-top: 3px;
  margin-left: -2%;
  background: #ffffff;
  color: #2097f5;
  min-width: 160px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  z-index: 1;
  ${NavItems}:hover & {
    display: block;
  }
`;

export const DropDownAnchor = styled.div`
  float: none;
  color: ${colors.darkBlue};
  padding: 10px 16px;
  text-decoration: none;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  font-size: 1.1vw;
  display: block;
  text-align: left;
  &:hover,
  &:focus,
  &:active {
    color: white;
    background-color: ${colors.darkBlue};
  }
  @media (max-width: 450px) {
    font-size: 2vw;
  }
`;

interface ButtonProps {
  Background?: string;
  color?: string;
  border?: string;
}

export const NavrightItems = styled(Button)<ButtonProps>`
  background: ${({ Background }) =>
    Background ? Background : colors.darkBlue};
  color: ${({ color }) => (color ? color : colors.white)};
  border: ${({ border }) => (border ? border : `2px solid ${colors.darkBlue}`)};
  font-size: 1.1vw;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  border-radius: 4vw;
  width: auto;
  margin-right: 1vw;
  padding: 0.3vw 1vw;
  &:hover,
  &:focus {
    background: ${({ Background }) =>
      Background ? Background : colors.darkBlue};
    color: ${({ color }) => (color ? color : colors.white)};
    border: 2px solid ${colors.darkBlue};
    box-shadow: none;
  }
  @media (max-width: 550px) {
    font-size: 10px;
    width: 50px;
  }
`;

export const Select = styled.select`
  font-family: "Montserrat", sans-serif;
  width: 100px;
  padding: 4%;
  color: #2097f5;
  font-weight: 600;
  width: 100px;
  font-size: 15.5px;
  height: 35px;
  outline: none;
  border-radius: 20px;
  border: none;
  box-shadow: 0px 0px 1px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: relative;
  left: -20px;
  margin: 0 auto;
`;
