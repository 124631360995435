import { Button } from "react-bootstrap";
import styled from "styled-components";
import fonts, { weight } from "../../../../const/fonts";
import { colors } from "../../../../const/theme";

export const PageContainer = styled.div`
  display: flex;
  margin-top: 3%;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 30px;
  padding: 0 12px;
  @media (max-width: 415px) {
    width: 98%;
    margin-top: 3%;
    margin: 24px 0;
  }
`;

export interface DropdownWrapperProps {
  width?: string;
  height?: string;
  marginRight?: string;
  paddingBottom?: string;
}

export const DropdownWrapperfor = styled.div<DropdownWrapperProps>`
  display: flex;
  margin: 0px 1px 10px;
  width: ${({ width }) => (width ? `${width}%` : "22%")};
  margin-left: 2%;
  margin-top: 1%;
  border-radius: 8px;
  padding-bottom: ${({ paddingBottom }) =>
    paddingBottom ? paddingBottom : null};
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : null)};
  @media (max-width: 550px) {
    display: block;
    width: 100%;
    margin: 8px;
    display: flex;
  }
  #editable-dropdown {
    height: 100%;
  }
  &.errors input[type="checkbox"] {
    border: 1px solid red;
  }
 &.error {
    background-color: transparent; /* Light red background color */
    color: #ff0000; /* Red text color */
    padding: 0px; /* Add some padding for better visibility */
 }
`;

export const CheckBoxTitle = styled.div``;

export const CheckBoxWrapper = styled.div`
  display: flex;
  margin: 6px 12px 10px 10px;
  
`;

export const TitleCard = styled.div`
  padding-right: 10px;
  margin: 7px 0px 0px 0px;
  color: ${({ color }) => (color ? color : colors.darkBlue)};
  font-weight: 500;
  font-size: ${fonts.small}px;
    &.error {
    color: #ff0000; /* Red text color */
  }
  
`;
export const LangContainer = styled.div`
  width: 20%;
  /* @media (max-width:550px) {
    width:100%;
  } */
`

export const PageWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden !important;
  font-family: "Montserrat";
  @media (max-width: 460px) {
    width: 100%;
    overflow: auto;
  }
`;

export const PlusButton = styled(Button)`
  background-color: transparent;
  color: ${({ color }) => (color ? color : colors.darkBlue)};
  border: transparent;
  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    color: ${({ color }) => (color ? color : colors.darkBlue)};
    border: transparent;
    font-size: 20px;
  }
`;
