export const placeDropdown = [
  { id: '1', name: 'Stockholm' },
  { id: '2', name: 'Malmö' },
  { id: '3', name: 'Göteborg' },
  { id: '4', name: 'Uppsala' },
  { id: '5', name: 'Obestämd ort' }
];

export const sectorDropdown = [
  { id: '1', name: 'Utbildning' },
  { id: '2', name: ' Vård/Omsorg' },
  { id: '3', name: 'Kontor & Administration' },
  { id: '4', name: 'IT' },
  { id: '5', name: ' Övrig' }
];

// export const sectorDropdownswe = [
//   { id: '1', name: 'Utbildning' },
//   { id: '2', name: ' Vård/Omsorg' },
//   { id: '3', name: 'Kontor & Administration' },
//   { id: '4', name: 'IT' },
//   { id: '5', name: ' Övrig' }
// ];
