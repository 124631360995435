export interface ManagementStatus {
  id: string;
  value: number;
}

export interface InitialState {
  isLoading: boolean;
  message: string;
}

export const initialState: InitialState = {
  isLoading: false,
  message: "",
};
