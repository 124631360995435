import { Button } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "../../const/theme";

export const Icon = styled(Button)`
  color: ${colors.gray};
  background: none; 
  border: none; 
  &:hover,
  &:focus {
    border-color: ${colors.white};
    background: none;
    color: ${colors.dohrBlue};
    transform: scale(1.1);
  }
  &:disabled {
    color: gray;
    border-color: ${colors.white};
    background: none;
  }
`;
