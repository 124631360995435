import {
  validateEmail,
  validateRequired,
} from "../../../../helpers/formValidation";
import { ValidateCustomerForm } from "./types";

const validateCustomerForm = ({
  values,
  errors,
  field,
}: ValidateCustomerForm): Record<string, string> => {
  let formErrors: Record<string, string> = { ...errors };

  const {
    first_name,
    title,
    last_name,
    email_id,
    password,
    telephone_number,
    address,
    postal_code,
    invoice_address,
    invoice_area_name,
    invoice_email_id,
    invoice_postal_code,
    area_name,
  } = values;

  switch (field) {
    case "first_name":
      formErrors["first_name"] = validateRequired(first_name);
      break;
    case "title":
      formErrors["title"] = validateRequired(title);
      break;
    case "last_name":
      formErrors["last_name"] = validateRequired(last_name);
      break;
    case "email_id":
      formErrors["email_id"] = validateEmail(email_id);
      break;
    case "password":
      formErrors["password"] = validateRequired(password);
      break;
    case "telephone_number":
      formErrors["telephone_number"] = validateRequired(telephone_number);
      break;
    case "address":
      formErrors["address"] = validateRequired(address);
      break;
    case "postal_code":
      formErrors["postal_code"] = validateRequired(postal_code);
      break;
    case "invoice_address":
      formErrors["invoice_address"] = validateRequired(invoice_address);
      break;
    case "area_name":
      formErrors["area_name"] = validateRequired(area_name);
      break;
    case "invoice_postal_code":
      formErrors["invoice_postal_code"] = validateRequired(invoice_postal_code);
      break;
    case "invoice_email_id":
      formErrors["invoice_email_id"] = validateRequired(invoice_email_id);
      break;
    case "invoice_area_name":
      formErrors["invoice_area_name"] = validateRequired(invoice_area_name);
      break;

    default:
      formErrors["email_id"] = validateEmail(email_id);
      formErrors["last_name"] = validateRequired(last_name);
      formErrors["address"] = validateRequired(address);
      formErrors["password"] = validateRequired(password);
      formErrors["telephone_number"] = validateRequired(telephone_number);
      formErrors["first_name"] = validateRequired(first_name);
      formErrors["postal_code"] = validateRequired(postal_code);
      formErrors["invoice_address"] = validateRequired(invoice_address);
      formErrors["title"] = validateRequired(title);
      formErrors["invoice_email_id"] = validateRequired(invoice_email_id);
      formErrors["invoice_postal_code"] = validateRequired(invoice_postal_code);
      formErrors["invoice_area_name"] = validateRequired(invoice_area_name);
      formErrors["area_name"] = validateRequired(area_name);
  }
  return formErrors;
};

export default validateCustomerForm;
