import user from "../../../asserts/user.png";

export const categoryList = [
  {
    id: "01",
    name: "For customers",
  },
  {
    id: "02",
    name: "For substitutes",
  },
  {
    id: "03",
    name: "For other job seekers",
  },
  {
    id: "04",
    name: "All",
  },
];

export const NewsListswe = [
 
  {
    id: "02",
    title: "Snabbare bemanning med DoHR: digitalt, effektivt och omedelbart",
    img: user, // Assuming "user" is a placeholder for an image URL
    para: `Vi på DoHR, lanserar stolt vår DoHR mobile app – en plattform som erbjuder en
snabb, effektiv och digitaliserad lösning för företag som söker flexibel och tillfällig
arbetskraft med kort varsel.

DoHR mobilapp ansluter företag med erfarna vikarier redo att hålla verksamheten
igång, oavsett om det är arbete på skolor, förskolor, omsorg, restauranger,
butikssupport eller administrativa uppgifter.

Anslut ditt företag till framtiden: prova DOHR mobilapp för snabbare bemanning. För
ditt företag innebär denna app tillgång till en pool av kvalificerade vikarier som söker
flexibla jobb 24/7. Genom en smidig och användarvänlig mobilapp kan företag skapa
jobbannonser och få sina lediga jobb tillsatta med lämpliga kandidater på rekordtid,
vilket minimerar stress, onödigt pappersarbete och optimerar arbetskraften.
För dig som söker extra jobb innebär DoHR mobilapp att du kan själv välja dina
arbetspass när det passar dig. Detta skapar en vinn-vinn-situation där företag får
tillfällig arbetskraft när de behöver det mest, och du får möjligheten att balansera
extra jobb med din huvudsakliga sysselsättning.

Vi tror på tillgänglighet och möjlighet för alla. Därför är vår app för snabb bemanning
helt gratis att använda. Ladda ner vår app och upplev fördelarna med en modern och
digitaliserad arbetskraftshandling.

För mer information, kontakta oss på support@dohr.io

Om DoHR

Vi är DoHR (/ˈdɔr/) - Dimensions of HR. Vårt uppdrag är att hjälpa våra kunder att
hitta de bästa möjliga bemanning- och rekrytering lösningarna, oavsett om
företagets behov är permanenta, tillfälliga eller sista minuten.
Vi erbjuder mobilapp- och webbaserade lösningar för att hjälpa våra kunder att få
vikarier sista minuten och underlätta för våra vikarier att acceptera olika uppdrag hos
våra kunder.
`,
  },
];

export const NewsListeng = [
  
  {
    id: "02",
    title: "Snabbare bemanning med DoHR: digitalt, effektivt och omedelbart",
    img: user, // Assuming "user" is a placeholder for an image URL
    para: `
Vi på DoHR, lanserar stolt vår DoHR mobile app – en plattform som erbjuder en
snabb, effektiv och digitaliserad lösning för företag som söker flexibel och tillfällig
arbetskraft med kort varsel.

DoHR mobilapp ansluter företag med erfarna vikarier redo att hålla verksamheten
igång, oavsett om det är arbete på skolor, förskolor, omsorg, restauranger,
butikssupport eller administrativa uppgifter.

Anslut ditt företag till framtiden: prova DOHR mobilapp för snabbare bemanning. För
ditt företag innebär denna app tillgång till en pool av kvalificerade vikarier som söker
flexibla jobb 24/7. Genom en smidig och användarvänlig mobilapp kan företag skapa
jobbannonser och få sina lediga jobb tillsatta med lämpliga kandidater på rekordtid,
vilket minimerar stress, onödigt pappersarbete och optimerar arbetskraften.
För dig som söker extra jobb innebär DoHR mobilapp att du kan själv välja dina
arbetspass när det passar dig. Detta skapar en vinn-vinn-situation där företag får
tillfällig arbetskraft när de behöver det mest, och du får möjligheten att balansera
extra jobb med din huvudsakliga sysselsättning.

Vi tror på tillgänglighet och möjlighet för alla. Därför är vår app för snabb bemanning
helt gratis att använda. Ladda ner vår app och upplev fördelarna med en modern och
digitaliserad arbetskraftshandling.

För mer information, kontakta oss på support@dohr.io

Om DoHR

Vi är DoHR (/ˈdɔr/) - Dimensions of HR. Vårt uppdrag är att hjälpa våra kunder att
hitta de bästa möjliga bemanning- och rekrytering lösningarna, oavsett om
företagets behov är permanenta, tillfälliga eller sista minuten.
Vi erbjuder mobilapp- och webbaserade lösningar för att hjälpa våra kunder att få
vikarier sista minuten och underlätta för våra vikarier att acceptera olika uppdrag hos
våra kunder.
`,
  },
];
