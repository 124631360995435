import { Button } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "../../const/theme";
import { motion } from "framer-motion";
import laptop from "../../asserts/back1.jpg";
import maze from "../../asserts/maze.jpg";
import ours from "../../asserts/ours.jpg";
import light from "../../asserts/homepage.png";

export const PageWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden !important;
  font-family: "Montserrat";
  @media (max-width: 450px) {
    width: 100%;
    overflow: auto;
  }
`;

interface ButtonProps {
  marginBottom?: string;
  marginLeft?: string;
  margintop?: string;
  rotate?: boolean;
}

//-----------------------------------FirstSection-----------------------------------------------

export const FirstWrapper = styled.section`
  height: auto;
  width: 100%;
  display: flex;
  padding: 2% 0px;
  justify-content: center;
  background: linear-gradient(to right, #002c99, #2097f5);
`;

export const ZeroWrapper = styled.section`
  /* background-image: url(${laptop});
  background-color: rgb(242, 243, 243);
  background-size: cover;
  background-repeat: no-repeat; */
  background: linear-gradient(to right, #002c99, #2097f5);
  margin-top: 3vw;
  padding-left: 1vw;
  padding-right: 1vw;
`;

export const ZeroWrapper2 = styled.section`
  background-image: url(${light});
  background-color: rgb(242, 243, 243);
  background-size: cover;
  background-repeat: no-repeat;
  height: 50vw;
  margin-top: 4vw;
`;

// linear-gradient(
//       rgba(255, 255, 255, 0.5),
//       rgba(255, 255, 255, 0.5)
//     ),

export const Container = styled.div`
  width: 100%;
  padding-bottom: 2vw;
  padding-top: 1vw;
`;

export const StyledContainer = styled.div`
  width: 55%;
  padding: 4vw 0px 3vw 5vw;
`;

interface BoldTextProps {
  size?: string;
}

export const BoldText = styled.h1<BoldTextProps>`
  font-size: ${({ size }) => (size ? size : "3vw")};
  font-weight: 600;
  color: white;
  padding-bottom: 0px;
`;

export const CaptionsPara = styled(motion.span)`
  width: 100%;
  color: white;
  font-size: 1.5vw;
  font-weight: 400;
  padding-top: 1vw;
  &:hover,
  &:focus {
    color: white;
  }
  @media (max-width:500px) {
    font-size: 8px;
    padding: 2px;
  }
`;

export const CaptionsParaSub = styled.p`
  font-size: 1.5vw;
  font-weight: 600;
  color: ${({ color }) => (color ? color : "white")};
  padding-bottom: 1.3vw;
  @media (max-width: 500px) {
    font-size: 10px;
    padding-bottom: 5px;
  }
`;

export const StartButton = styled(Button)`
  color: ${({ color }) => (color ? color : `${colors.dohrBlue}`)};
  background: ${({ background }) => (background ? background : "white")};
  font-size: 1.1vw;
  font-weight: 600;
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : "0px")};
  border: 2px solid white;
  border-radius: 4vw;
  padding: 0.4vw 1vw;
  &:hover {
    background-color: #002c99;
    color: white;
    border: 2px solid white;
  }
  @media (max-width: 500px) {
    font-size: 10px;
  }
`;

//------------------------------------------------------------------------------------------

//-----------------------------------------secondSection------------------------------------

export const SecondWrapper = styled.section`
  height: auto;

  display: flex;
  justify-content: space-between;
  background-color: white;
`;

export const ContentWrapper = styled.div`
  width: 60vw;
  padding: 8vw 2vw 4vh 4vw;
  @media (max-width: 500px) {
    padding: 10px;
  }
`;

export const BoldText2 = styled.p`
  font-size: 3vw;
  font-weight: 600;
  color: ${colors.darkBlue};
  @media (max-width: 500px) {
    font-size: 20px;
  }
`;
interface Props {
  width?: string;
}
export const CaptionsType = styled(motion.span)<Props>`
  width: ${({ width }) => (width ? width : "50%")};
  color: ${colors.darkBlue};
  font-size: 1.5vw;
  &:hover,
  &:focus {
    color: ${colors.darkBlue};
  }
  @media (max-width: 500px) {
    font-size: 10px;
    padding-bottom: 5px;
    
  }
`;
export const DivOne = styled.div`
  height: 4vw;
  @media (max-width:500px) {
    height:30px;
  }
`
export const DivTwo = styled.div`
  height: 6vw;
  @media (max-width:500px) {
    height:70px;
  }
`

export const CaptionsParaSub2 = styled.p`
  font-size: 1.5vw;
  margin-bottom: 2vw;
  font-weight: 600;
  color: ${colors.darkBlue};
  @media (max-width:500px) {
    font-size: 10px;
    padding-top: 10px;
  }
`;

export const ApplyButton = styled(Button)<ButtonProps>`
  color: ${colors.white};
  background: ${colors.darkBlue};
  font-size: 1.1vw;
  font-weight: 600;
  border: 2px solid ${colors.darkBlue};
  border-radius: 4vw;
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : null)};
  padding: 0.5vw 1vw;
  transform: ${({ rotate }) => (rotate ? "rotate(6deg)" : null)};
  margin-top: ${({ margintop }) => (margintop ? margintop : null)};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : null)};
  &:hover {
    background: ${colors.darkBlue};
    color: white;
    border: 2px solid ${colors.darkBlue};
  }
  @media (max-width: 500px) {
    font-size: 8px;
  }
`;

//------------------------------------------------------------------------------------------

//-----------------------------------------thirdSection-------------------------------------

export const ThirdWrapper = styled.section`
  height: auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url(${maze}); */
  background-color: rgb(242, 243, 243);
  background-size: cover;
  background-repeat: no-repeat;
`;

export const OurSolutionWrapper = styled.section`
  height: auto;
  width: 100%;

  //justify-content: space-between;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.744),
      rgba(255, 255, 255, 0.744)
    ),
    url(${ours});
  background-color: rgb(242, 243, 243);
  background-size: cover;
  background-repeat: no-repeat;
`;

export const ThirdSectionPara = styled.p`
  width: 100%;
  color: ${({ color }) => (color ? color : "white")};
  font-size: 1.5vw;
  padding-bottom: 1.3vw;
  @media (max-width: 500px) {
    font-size: 10px;
    padding-bottom:0px;
  }
`;

//------------------------------------------------------------------------------------------

//--------------------------------------------------------------image-----------------------

export const ImageWrapper = styled.div`
  width: auto;
`;

export const Image = styled.img`
  width: ${({ width }) => (width ? width : "200px")};
  height: 50vw;
  float: right;
`;

export const SecondImageWrapper = styled.div`
  width: auto;
`;

export const SecondImage = styled.img`
  width: 35vw;
`;
//------------------------------------------------------------------------------------------

interface Props {
  width?: string;
}

export const ModalText = styled.p<Props>`
  margin-bottom: 0px;
  text-align: center;
  color: ${colors.white};
  font-size: 3.5vw;
  font-weight: 800;
  margin-top: 10%;
  width: ${({ width }) => (width ? width : "60%")};
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  background-image: linear-gradient(
    225deg,
    #473680 0%,
    #44107a 29%,
    #ff1361 67%,
    #0b06fa 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 4s linear infinite;
  @keyframes textclip {
    to {
      background-position: -200% center;
    }
  }
  @media (max-width: 1000px) {
    font-size: 24px;
  }
  @media (max-width: 900px) {
    font-size: 22px;
  }
  @media (max-width: 800px) {
    font-size: 20px;
    margin-top: 16%;
  }
  @media (max-width: 650px) {
    font-size: 16px;
    margin-top: 20%;
  }
  /* @media (max-width: 450px) {
    font-size: 16px;
  } */
`;

export const Light = styled.img`
  width: 40%;
  height: 24vw;
  margin-right: 2%;
  margin-top: 8vw;
`;

export const ColorText = styled.p<Props>`
  margin-bottom: 0px;
  text-align: left;
  color: ${colors.white};
  font-size: 3.6vw;
  font-weight: 800;
  margin-top: 6%;
  width: ${({ width }) => (width ? width : "60%")};
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  background-image: linear-gradient(
    225deg,
    #473680 0%,
    #44107a 29%,
    #ff1361 67%,
    #0b06fa 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 4s linear infinite;
  @keyframes textclip {
    to {
      background-position: -200% center;
    }
  }
`;
