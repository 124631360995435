import moment from "moment";
import { validateRequired } from "../../../../../../helpers/formValidation";
import { ConditionCheckingProsp, ValidateScheduleForm } from "./typings";

const ScheduleValidation = ({
  AddSchedule,
  errors,
  field,
}: ValidateScheduleForm): Record<string, string> => {
  let formErrors: Record<string, string> = { ...errors };
  const { start_time, end_time, subject, day, year_group, classroom } =
    AddSchedule;
  switch (field) {
    case "start_time":
      formErrors["start_time"] = validateRequired(start_time);
      break;
    case "end_time":
      formErrors["end_time"] = validateRequired(end_time);
      break;
    case "subject":
      formErrors["subject"] = validateRequired(subject);
      break;
    case "year_group":
      formErrors["year_group"] = validateRequired(year_group);
      break;
    case "classroom":
      formErrors["classroom"] = validateRequired(classroom);
      break;
    default:
      formErrors["start_time"] = validateRequired(start_time);
      formErrors["end_time"] = validateRequired(end_time);
      formErrors["day"] = validateRequired(day);
      formErrors["subject"] = validateRequired(subject);
      formErrors["year_group"] = validateRequired(year_group);
      formErrors["classroom"] = validateRequired(classroom);
  }
  return formErrors;
};

export default ScheduleValidation;

export const CheckingTimeInDb = ({
  schduleList,
  schduleValues,
}: ConditionCheckingProsp) => {
  const betweenindb = schduleList?.DayData.some((items) => {
    let isBooleanValue;
    const isBetweenStart = moment(
      schduleValues?.start_time,
      "HH:mm:ss"
    ).isBetween(
      moment(items?.start_time, "HH:mm:ss"),
      moment(items?.end_time, "HH:mm:ss")
    );
    const isBetweenEnd = moment(schduleValues?.end_time, "HH:mm:ss").isBetween(
      moment(items?.start_time, "HH:mm:ss"),
      moment(items?.end_time, "HH:mm:ss")
    );
    let isSameTime = moment(schduleValues?.start_time, "HH:mm:ss").isSame(
      moment(items.start_time, "HH:mm:ss")
    );
    let addingStartTime = schduleValues?.start_time;
    let addingEndTime = schduleValues?.end_time;
    let dbStartTime = items?.start_time?.slice(0, 5);
    let dbEndTime = items?.end_time?.slice(0, 5);
    if (
      isBetweenStart ||
      isBetweenEnd ||
      isSameTime ||
      (addingStartTime < dbStartTime && addingEndTime > dbEndTime)
    ) {
      isBooleanValue = true;
    }
    return isBooleanValue;
  });
  return betweenindb;
};

export const CheckingTimeInFinalvalue = ({
  schduleArrayvalue,
  schduleValues,
}: ConditionCheckingProsp) => {
  const between = schduleArrayvalue?.some((item) => {
    let isBooleanValue;
    const isBetweenStart = moment(
      schduleValues?.start_time,
      "HH:mm:ss"
    ).isBetween(
      moment(item.start_time, "HH:mm:ss"),
      moment(item.end_time, "HH:mm:ss")
    );
    const isBetweenEnd = moment(schduleValues?.end_time, "HH:mm:ss").isBetween(
      moment(item.start_time, "HH:mm:ss"),
      moment(item.end_time, "HH:mm:ss")
    );
    if (isBetweenStart || isBetweenEnd) {
      isBooleanValue = true;
    }
    return isBooleanValue;
  });
  return between;
};
