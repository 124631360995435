import styled from "styled-components";

export const FlipWrapper = styled.div`
  margin-top: 8%;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center; 
  span {
    position: relative;
    margin-top: 20%;
    position: relative;
    display: inline-block;
    font-size: 250%;
    color: #fff;
    animation: flip 2s infinite;
    animation-delay: calc(0.2s * var(--i));
    @keyframes flip {
      0%,
      80% {
        transform: rotateY(360deg);
      }
    }
  }
`;
