import Card from "react-bootstrap/Card";
import styled from "styled-components";

const CardWrapper = styled(Card)`
  box-shadow: 0 1px 10px 0 ${({ theme }) => theme.card.border};
  width: ${({ width }) => (width ? `${width}%` : "45%")};
  height: ${({ height }) => (height ? height : "auto")};
  padding: ${({ padding }) => (padding ? padding : "20px")};
  background-color: ${({ color }) => (color ? color : "")};
  margin: ${({ marigin }) => (marigin ? marigin : '0 auto 20px')};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "auto")};
  background-image: ${({ linearGradiant }) =>
    linearGradiant ? linearGradiant : "none"};
  @media (max-width: 415px) {
    width: 80%;
    margin-bottom: 16px;
  } 
`;

export default CardWrapper;
