import { ReactElement, useEffect, useState } from "react";
import { Button, Modal, Tab, Tabs } from "react-bootstrap";
import {
  ActionButton,
  CardWrapper,
  DropdownWrapper,
  FlexWrapper,
  Input,
  PageWrapper,
} from "../../components";
import moment from "moment";
import {
  CardHeader,
  Cards,
  Count,
  DashBoardWrapper,
  Hour,
  ModalHeader,
  StyledCalendar,
  TabWrapper,
  Tommorow,
  Min,
  SideTitle,
  Spliter,
} from "./subcomponents";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import {
  createTopup,
  getAbsenceCount,
  getCoverCount,
  getInsHours,
  getLessonCount,
  getTodayAbsenceCount,
  getTodayCoverCount,
  getTodayLessonCount,
  getTodayUnCoverCount,
  getTodayUnLessonCount,
  getUnCoverCount,
  getUnLessonCount,
} from "../../redux/Common/api";
import { updateLanguage } from "../../redux/Language/action";
import swedish from "../../locale/se";
import { TYPE } from "../../const/getType";
import { useHistory } from "react-router-dom";
import ROUTES from "../../const/routes";
import { PrimaryButton } from "../../components/Button";

const Dashboard = (): ReactElement => {
  const dispatch: AppDispatch = useDispatch();
  const {
    institute,
    otherDash,
    Type,
    todayCover,
    todayAbsence,
    todayUncover,
    todayLesson,
    todayUnLesson,
    customCover,
    customAbsence,
    customUncover,
    customLesson,
    customUnLesson,
    lang,
    selectedLanguage,
    getInsHoursList,
    Role,
  } = useSelector((state: RootState) => ({
    institute: state.login.userInfo?.data.id,
    otherDash: state.login.userInfo?.data.ins_id,
    Type: state.login.userInfo?.type,
    Role: state.login.userInfo?.Role,
    todayCover: state.common.todaycoverCount,
    todayUncover: state.common.todayunCoverCount,
    todayAbsence: state.common.todayabsenceCount,
    todayLesson: state.common.todaylessonCount,
    todayUnLesson: state.common.todayUnLessonCount,
    customCover: state.common.customcoverCount,
    customAbsence: state.common.customabsenceCount,
    customUncover: state.common.customunCoverCount,
    customLesson: state.common.customlessonCount,
    customUnLesson: state.common.customUnLessonCount,
    getInsHoursList: state.common.getInsHoursList,
    selectedLanguage: state.Language.selectedLang.toLocaleLowerCase(),
    lang: state.Language.language,
  }));

  const [showCustom, setShowCustom] = useState(false);
  const [modal, setModal] = useState(false);
  const [dateState, setDateState] = useState(new Date());
  const changeDate = (e: any) => {
    setDateState(e);
    setShowCustom(true);
  };
  //date management
  const today = new Date();

  const [topUp, setTopUp] = useState({
    requesting_hours: "",
  });

  const FormatedToday = moment(today).format("YYYY-MM-DD");

  const FormatedCustomDate = moment(dateState).format("YYYY-MM-DD");
  const { tab, balance } = lang.dashboard;

  const shouldShowRA = Type === TYPE.CUSTOMER;

  useEffect(() => {
    if (selectedLanguage === "swedish") {
      dispatch(updateLanguage(swedish));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    switch (Type) {
      case TYPE.INSTITUTE:
        dispatch(
          getCoverCount({
            ins_id: institute,
            status: "1",
            date: FormatedCustomDate,
          })
        );
        dispatch(
          getLessonCount({
            ins_id: institute,
            status: "1",
            date: FormatedCustomDate,
          })
        );
        dispatch(
          getUnCoverCount({
            ins_id: institute,
            status: "0",
            date: FormatedCustomDate,
          })
        );
        dispatch(
          getUnLessonCount({
            ins_id: institute,
            status: "0",
            date: FormatedCustomDate,
          })
        );
        dispatch(
          getAbsenceCount({ ins_id: institute, date: FormatedCustomDate })
        );
        break;
      default:
        dispatch(
          getCoverCount({
            ins_id: otherDash,
            status: "1",
            date: FormatedCustomDate,
          })
        );
        dispatch(
          getLessonCount({
            ins_id: otherDash,
            status: "1",
            date: FormatedCustomDate,
          })
        );
        dispatch(
          getUnCoverCount({
            ins_id: otherDash,
            status: "0",
            date: FormatedCustomDate,
          })
        );
        dispatch(
          getUnLessonCount({
            ins_id: otherDash,
            status: "0",
            date: FormatedCustomDate,
          })
        );
        dispatch(
          getAbsenceCount({ ins_id: otherDash, date: FormatedCustomDate })
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateState]);

  useEffect(() => {
    switch (Type) {
      case TYPE.INSTITUTE:
        dispatch(
          getTodayCoverCount({
            ins_id: institute,
            status: "1",
            date: FormatedToday,
          })
        );

        dispatch(
          getTodayLessonCount({
            ins_id: institute,
            status: "1",
            date: FormatedToday,
          })
        );

        dispatch(
          getTodayUnCoverCount({
            ins_id: institute,
            status: "0",
            date: FormatedToday,
          })
        );

        dispatch(
          getTodayUnLessonCount({
            ins_id: institute,
            status: "0",
            date: FormatedToday,
          })
        );

        dispatch(
          getTodayAbsenceCount({ ins_id: institute, date: FormatedToday })
        );

        break;
      default:
        dispatch(
          getTodayCoverCount({
            ins_id: otherDash,
            status: "1",
            date: FormatedToday,
          })
        );

        dispatch(
          getTodayLessonCount({
            ins_id: otherDash,
            status: "1",
            date: FormatedToday,
          })
        );

        dispatch(
          getTodayUnCoverCount({
            ins_id: otherDash,
            status: "0",
            date: FormatedToday,
          })
        );

        dispatch(
          getTodayUnLessonCount({
            ins_id: otherDash,
            status: "0",
            date: FormatedToday,
          })
        );

        dispatch(
          getTodayAbsenceCount({ ins_id: otherDash, date: FormatedToday })
        );
    }

    dispatch(
      getInsHours({
        ins_id: otherDash || institute,
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();

  return (
    <PageWrapper>
      <FlexWrapper>
        <DashBoardWrapper>
          {shouldShowRA && (
            <FlexWrapper justifyContent="end" noMargin noPadding>
              {Role == "HQ-Management" ? (
                ""
              ) : Role == "schoolmanagement" ? (
                ""
              ) : (
                <ActionButton
                  onClick={() => {
                    history.push(ROUTES.TEACHER.TEC_REGISTER_LEAVE);
                  }}
                >
                  Register Absence{" "}
                </ActionButton>
              )}
            </FlexWrapper>
          )}
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <CardWrapper width="70" padding="0px">
              <TabWrapper>
                <Tabs
                  defaultActiveKey="today"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey={"today"} title={tab.header.today}>
                    <FlexWrapper noMargin noPadding>
                      <Cards
                        width="22"
                        height="120px"
                        onClick={() => {
                          history.push(ROUTES.HQ_MANAGEMENT.HQ_ABCENT_STAFF);
                        }}
                      >
                        <p>{tab.content.absence}</p>
                        <Count>{todayAbsence}</Count>
                      </Cards>
                      <Cards width="22" height="120px">
                        <p>{tab.content.filled}</p>
                        <Count>{todayUnLesson}</Count>
                      </Cards>
                      <Cards width="22" height="120px">
                        <p>{tab.content.unfilled}</p>
                        <Count>{todayCover}</Count>
                      </Cards>
                      <Cards
                        width="22"
                        height="120px"
                        onClick={() => {
                          history.push(ROUTES.HQ_MANAGEMENT.HQ_COVERED);
                        }}
                      >
                        <p>{tab.content.covered}</p>
                        <Count>{todayLesson}</Count>
                      </Cards>
                    </FlexWrapper>
                    <FlexWrapper noPadding>
                      <Cards
                        width="22"
                        height="120px"
                        onClick={() => {
                          history.push(ROUTES.HQ_MANAGEMENT.HQ_UNCOVERED_TABEL);
                        }}
                      >
                        <p>{tab.content.unCovered}</p>
                        <Count>{todayUncover}</Count>
                      </Cards>
                    </FlexWrapper>
                  </Tab>
                  <Tab eventKey={"tommorow"} title={tab.header.tommorow}>
                    <Tommorow />
                  </Tab>
                  <Tab eventKey={"custom"} title={tab.header.custom}>
                    <FlexWrapper justifyContent="center">
                      <StyledCalendar
                        tileClassName="date"
                        className="day"
                        value={dateState}
                        onChange={changeDate}
                      />
                      {showCustom ? (
                        <Modal
                          show={showCustom}
                          onHide={() => {
                            setShowCustom(false);
                          }}
                          size="lg"
                        >
                          <ModalHeader closeButton>
                            {moment(dateState).format("YYYY-MM-DD")}
                          </ModalHeader>
                          <FlexWrapper noMargin noPadding>
                            <Cards
                              width="22"
                              height="120px"
                              onClick={() => {
                                history.push(
                                  ROUTES.HQ_MANAGEMENT.HQ_ABCENT_STAFF
                                );
                              }}
                            >
                              <p>{tab.content.absence}</p>
                              <Count>{customAbsence}</Count>
                            </Cards>
                            <Cards width="22" height="120px">
                              <p>{tab.content.filled}</p>
                              <Count>{customCover}</Count>
                            </Cards>
                            <Cards width="22" height="120px">
                              <p>{tab.content.unfilled}</p>{" "}
                              <Count>{customUnLesson}</Count>
                            </Cards>
                            <Cards
                              width="22"
                              height="120px"
                              onClick={() => {
                                history.push(ROUTES.HQ_MANAGEMENT.HQ_COVERED);
                              }}
                            >
                              <p>{tab.content.covered}</p>
                              <Count>{customLesson}</Count>
                            </Cards>
                          </FlexWrapper>
                          <FlexWrapper noPadding>
                            <Cards
                              width="22"
                              height="120px"
                              onClick={() => {
                                history.push(
                                  ROUTES.HQ_MANAGEMENT.HQ_UNCOVERED_TABEL
                                );
                              }}
                            >
                              <p>{tab.content.unCovered}</p>
                              <Count>{customUncover}</Count>
                            </Cards>
                          </FlexWrapper>
                        </Modal>
                      ) : null}
                    </FlexWrapper>
                  </Tab>
                </Tabs>
              </TabWrapper>
            </CardWrapper>{" "}
            <CardWrapper width="24" padding="0px" className="mt-4">
              <CardHeader>{balance.hoursBalance}</CardHeader>
              <FlexWrapper noMargin>
                <div>
                  <Hour>{getInsHoursList[0]?.total_hours}</Hour>
                  {/* <Min>hrs</Min> */}
                </div>
                <SideTitle>{balance.hoursPurchased}</SideTitle>
              </FlexWrapper>
              <Spliter />
              <FlexWrapper noMargin>
                <div>
                  <Hour>{getInsHoursList[0]?.used_hours}</Hour>
                  {/* <Min>hrs</Min> */}
                </div>
                <SideTitle>{balance.usedHours}</SideTitle>
              </FlexWrapper>
              <Spliter />
              <FlexWrapper noMargin>
                <div>
                  <Hour>{getInsHoursList[0]?.remaining_hours}</Hour>
                  {/* <Min>hrs</Min> */}
                </div>
                <SideTitle>{balance.remainingHours}</SideTitle>
              </FlexWrapper>
              <PrimaryButton
                onClick={() => {
                  setModal(true);
                }}
              >
                {balance.topUp}
              </PrimaryButton>
            </CardWrapper>
          </div>
        </DashBoardWrapper>
      </FlexWrapper>
      <Modal
        show={modal}
        centered
        onHide={() => {
          setModal(false);
        }}
      >
        <Modal.Header closeButton>Manually top-up hours</Modal.Header>
        <Modal.Body>
          <>
            <DropdownWrapper width="100%">
              <Input
                value={topUp?.requesting_hours}
                onChange={(value) => {
                  setTopUp({
                    ...topUp,
                    requesting_hours: value,
                  });
                }}
                placeholder="hh:mm"
              />
            </DropdownWrapper>
          </>
        </Modal.Body>
        <Modal.Footer>
          <FlexWrapper justifyContent="end">
            <Button
              variant="secondary"
              onClick={() => {
                setModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              style={{ marginLeft: "10px" }}
              onClick={() => {
                if (topUp?.requesting_hours?.length === 5) {
                  getInsHoursList?.length === 0
                    ? dispatch(
                        createTopup({
                          ...topUp,
                          ins_id: otherDash || institute,
                          total_hours: `00:00`,
                          used_hours: `00:00`,
                          remaining_hours: `00:00`,
                        })
                      ).then((res) => {
                        if (res?.meta?.requestStatus === "fulfilled") {
                          alert("Added");
                          dispatch(
                            getInsHours({
                              ins_id: otherDash || institute,
                            })
                          );
                          setModal(false);
                        } else if (res?.meta?.requestStatus === "rejected") {
                          alert("Server Error");
                          setModal(false);
                        }
                      })
                    : dispatch(
                        createTopup({
                          ...topUp,
                          ins_id: otherDash || institute,
                          total_hours: getInsHoursList[0]?.total_hours,
                          used_hours: getInsHoursList[0]?.used_hours,
                          remaining_hours: getInsHoursList[0]?.remaining_hours,
                        })
                      ).then((res) => {
                        if (res?.meta?.requestStatus === "fulfilled") {
                          alert("Added");
                          dispatch(
                            getInsHours({
                              ins_id: otherDash || institute,
                            })
                          );
                          setModal(false);
                        } else if (res?.meta?.requestStatus === "rejected") {
                          alert("Server Error");
                          setModal(false);
                        }
                      });
                } else {
                  alert("Format should be in hh:mm (e.g., 01:00).");
                }
              }}
            >
              Submit
            </Button>
          </FlexWrapper>
        </Modal.Footer>
      </Modal>
    </PageWrapper>
  );
};

export default Dashboard;
