import { Card, Modal } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "../../const/theme";
import { H3 } from "../../typography";
import Calendar from "react-calendar";
import { FlexWrapper } from "../../components";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getTommorowAbsenceCount,
  getTommorowCoverCount,
  getTommorowLessonCount,
  getTommorowUnCoverCount,
  getTommorowUnLessonCount,
} from "../../redux/Common/api";
import { useEffect } from "react";
import { RootState } from "../../redux/store";

export const CardWraper = styled(Card)`
  width: 100%;
  margin: 1rem auto;
`;

export const TabWrapper = styled.div`
  font-family: "Montserrat", sans-serif;
  .nav-tabs {
    border-bottom: 1px solid ${colors.lightGrey};
  }

  .nav-item {
    button {
      color: ${colors.gray};
    }
  }
  .nav-link.active {
    color: ${colors.white};
    background-color: #2097f5;
    border: none;
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
  }
`;

export const Count = styled.p`
  font-weight: 800;
  font-size: 28px;
`;

export const DashBoardWrapper = styled.div`
  margin-left: 4%;
  width: 100%;
`;

export const Cards = styled(Card)`
  box-shadow: 0 1px 10px 0 ${({ theme }) => theme.card.border};
  width: 22%;
  height: 120px;
  margin-top: 16px;
  padding: 1%;
  margin-left: 2%;
  cursor: pointer;
  &:hover {
    animation-duration: 2s;
    animation-name: filler;
    @keyframes filler {
      from {
        background-color: white;
        color: black;
      }

      to {
        background-color: #2097f5;
        color: white;
      }
    }
  }
`;

export const CardHeader = styled(H3)`
  background-color: #2097f5;
  text-align: center;
  color: white;
  padding: 0.5rem 1rem;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  border-bottom: 1px solid #2097f5;
  margin-bottom: 0;
`;

export const Hour = styled.p`
  font-size: 30px;
  font-weight: 700;
`;
export const Min = styled.p`
  margin-top: -28px;
  margin-left: 4px;
  font-family: "Montserrat", sans-serif;
`;

export const SideTitle = styled.p`
  margin-left: 16px;
  padding-top: 8px;
  font-size: 14px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
`;

export const Spliter = styled.hr`
  margin-top: -4px;
`;

export const StyledCalendar = styled(Calendar)`
  border-radius: 8px;
  width: 70%;
  .day {
    color: #2097f5;
  }
`;

export const ModalHeader = styled(Modal.Header)`
  background-color: #2097f5;
  font-size: 16px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  color: white;
`;

export const Tommorow = () => {
  const dispatch = useDispatch();

  const {
    institute,
    otherDash,
    Role,
    tommoCover,
    tommoAbsence,
    tommoUncover,
    tommoLesson,
    tommoUnLesson,
    lang,
  } = useSelector((state: RootState) => ({
    institute: state.login.userInfo?.data.id,
    otherDash: state.login.userInfo?.data.ins_id,
    Role: state.login.userInfo?.Role,
    tommoCover: state.common.tommorowcoverCount,
    tommoUncover: state.common.tommorowunCoverCount,
    tommoAbsence: state.common.tommorowabsenceCount,
    tommoLesson: state.common.tommorowlessonCount,
    tommoUnLesson: state.common.tommorowUnLessonCount,
    lang: state.Language.language.dashboard.tab.content,
  }));

  const role = Role?.toLocaleLowerCase();

  const today = new Date();
  const FormatedTommorow = moment(today.setDate(today.getDate() + 1)).format(
    "YYYY-MM-DD"
  );

  useEffect(() => {
    switch (role) {
      case "institute":
        dispatch(
          getTommorowCoverCount({
            ins_id: institute,
            status: "1",
            date: FormatedTommorow,
          })
        );

        dispatch(
          getTommorowLessonCount({
            ins_id: institute,
            status: "1",
            date: FormatedTommorow,
          })
        );

        dispatch(
          getTommorowUnCoverCount({
            ins_id: institute,
            status: "0",
            date: FormatedTommorow,
          })
        );

        dispatch(
          getTommorowUnLessonCount({
            ins_id: institute,
            status: "0",
            date: FormatedTommorow,
          })
        );

        dispatch(
          getTommorowAbsenceCount({ ins_id: institute, date: FormatedTommorow })
        );
        break;
      default:
        dispatch(
          getTommorowCoverCount({
            ins_id: otherDash,
            status: "1",
            date: FormatedTommorow,
          })
        );

        dispatch(
          getTommorowLessonCount({
            ins_id: otherDash,
            status: "1",
            date: FormatedTommorow,
          })
        );

        dispatch(
          getTommorowUnCoverCount({
            ins_id: otherDash,
            status: "0",
            date: FormatedTommorow,
          })
        );

        dispatch(
          getTommorowUnLessonCount({
            ins_id: otherDash,
            status: "0",
            date: FormatedTommorow,
          })
        );

        dispatch(
          getTommorowAbsenceCount({ ins_id: otherDash, date: FormatedTommorow })
        );
    }
  }, [FormatedTommorow, dispatch, institute, otherDash, role]);

  return (
    <>
      <FlexWrapper noMargin noPadding>
        <Cards width="22" height="120px" marginTop="16px">
          <p>{lang.absence}</p>
          <Count>{tommoAbsence}</Count>
        </Cards>
        <Cards width="22" height="120px" marginTop="16px">
          <p>{lang.filled}</p>
          <Count>{tommoCover}</Count>
        </Cards>
        <Cards width="22" height="120px" marginTop="16px">
          <p>{lang.unfilled}</p>
          <Count>{tommoUncover}</Count>
        </Cards>
        <Cards width="22" height="120px" marginTop="16px">
          <p>{lang.covered}</p>
          <Count>{tommoLesson}</Count>
        </Cards>
      </FlexWrapper>
      <FlexWrapper noPadding>
        <Cards width="22" height="120px" marginTop="16px">
          <p>{lang.unCovered}</p>
          <Count>{tommoUnLesson}</Count>
        </Cards>
      </FlexWrapper>
    </>
  );
};
