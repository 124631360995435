import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import english from "../../locale/eng";

const initialState = {
  language: english,
  selectedLang: "US",
};

export const LanguageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    updateLanguage: (state, action: PayloadAction<any>) => {
      state.language = action.payload;
    },
    updateSelectedLanguage: (state, action: PayloadAction<any>) => {
      state.selectedLang = action.payload;
    },
  },
});

export default LanguageSlice.reducer;
