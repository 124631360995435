import { Card } from "react-bootstrap";
import styled from "styled-components";

export const CardWraper = styled(Card)`
  display: flex;
  justify-content: center;
  width: 80%;
  margin: 1rem auto;
`;

export const ButtonWrapper = styled.div`
  margin-top: "50px";
`;
