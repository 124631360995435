import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormControl } from "react-bootstrap";
import styled from "styled-components";
import fonts, { weight } from "../../../../const/fonts";

interface messageProps {
  dispaly?: string;
}

export const ChatWrapper = styled.div`
  margin: 12px auto 15px auto;
  background: #ebebeb !important;
  width: 79%; 
  border-radius: 10px;
  border: 1px solid gray;
  @media (max-width: 415px) {
    width: auto;
  }
`;

export const CustomerMessageWrapper = styled.div`
  width: 100%;
  float: left;
  margin: 15px 15px 5px 15px;
`;
export const CustomerMessage = styled.div<messageProps>`
  padding: 10px 10px 10px 10px;
  background: white !important;
  display: flex;
  word-break: break-all;
  width: 350px;
  justify-content: space-between;
  font-weight: 500;
  border-radius: 0.75em 0.75em 0.75em 0;
  display: ${({ dispaly }) => (dispaly ? dispaly : "none")};
`;

export const ConsaltantMessageWrapper = styled.div`
  float: right;
  margin: 10px 10px 10px 10px;
`;
export const ConsaltantMessage = styled.div<messageProps>`
  padding: 10px 10px 10px 10px;
  display: flex;
  justify-content: space-between;
  background: #2097f5 !important;
  color: white;
  word-break: break-all;
  width: 350px;
  font-weight: 500;
  border-radius: 0.75em 0.75em 0 0.75em;
  display: ${({ dispaly }) => (dispaly ? dispaly : "none")};
`;

export const FormInput = styled(FormControl)`
  height: 50px;
  width: 80%;
  margin: 15px 10px 12px 14px;
  ::placeholder {
    font-size: ${fonts.tiny};
    font-weight: ${weight.xnormal};
  }
`;

export const Icon = styled(FontAwesomeIcon)`
  cursor: pointer;
  margin: 15px 18px 16px 22px;
  background-color: #2097f5;
  color: white;
  border-radius: 50%;
  padding: 15px;
`;

export const InputWrapper = styled.div`
  display: flex;
  height: 80px;
  background-color: white;
  width: 100%;
`;

export const TimeStamp = styled.span`
  font-size: 10px;
  padding-left: 50px;
  word-break: normal;
  font-weight: 550;
  margin-top: auto;
`;

export const ChatMappingWrapper = styled.div`
  /* overflow-y: scroll;
  overflow-x: hidden;
  height: 1000px;
  margin: 10px;
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: #00000080;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #000000cc;
  } */
`;
