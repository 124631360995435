export const TableHeaderData = [
  "SNo",
  "Date",
  "Start Time",
  "End Time",
  "Subject",
  "Year Group",
  "Location",
  "Absent Staff Name",
  'Instruction',
  "Actions",
];
