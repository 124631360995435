import { ReactElement, useEffect, useState } from "react";
import { NavBar, NavBarContainer } from "./subcomponent";
import menuItems from "../../const/menu";
import swedishMenu from "../../const/swedishMenu";
import MenuItems from "./MenuItems";
import { Menu } from "./typing";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const Navbar = (): ReactElement => {
  const [state, setState] = useState<Array<Menu>>(menuItems.teacher);
  const { basedMenu, selectedLanguage } = useSelector((state: RootState) => ({
    basedMenu: state.login.userInfo?.Role?.toLocaleLowerCase(),
    selectedLanguage: state.Language.selectedLang?.toLocaleLowerCase(),
  }));

  useEffect(() => {
    if (selectedLanguage === "swedish") {
      switch (basedMenu) {
        case "teacher":
          setState(swedishMenu.teacher);
          break;
        case "hq-management":
          setState(swedishMenu.hq_management);
          break;
        case "institute":
          setState(swedishMenu.Institute);
          break;
        default:
          setState(swedishMenu.teacher);
      }
    } else {
      switch (basedMenu) {
        case "teacher":
          setState(menuItems.teacher);
          break;
        case "hq-management":
          setState(menuItems.hq_management);
          break;
        case "institute":
          setState(menuItems.Institute);
          break;
        default:
          setState(menuItems.school);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  return (
    <NavBar>
      <NavBarContainer>
        {state.map((menu, index) => {
          const depthLevel = 0;
          return <MenuItems items={menu} key={index} depthLevel={depthLevel} />;
        })}
      </NavBarContainer>
    </NavBar>
  );
};

export default Navbar;
