import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import { Icon } from "../../../../components";
import { AdminActionProps } from "./types";

const ActionWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const AdminAction = ({ handleDelete, handleEdit }: AdminActionProps) => {
  return (
    <ActionWrapper>
      <Icon onClick={handleDelete}>
        <FontAwesomeIcon icon={["far", "trash-can"]} />
      </Icon>
    </ActionWrapper>
  );
};

export const Emoji = styled.img`
  width: 50px;
  margin-bottom: 10px;
  height: 50px;
  animation: bounce 1000ms;
  animation-iteration-count: 3;
  @keyframes bounce {
    50% {
      transform: translateX(-10px);
    }
  }
`;

export const CancleButton = styled(Button)`
  color: black;
  background: transparent;
  border: 1px solid black;
  padding: 5px 5px;
  font-size: 13px;
  font-weight: 600;
  margin: 0 10px;
  text-align: center;
`;
export const DeleteButton = styled(Button)`
  color: white;
  background: red;
  border: 1px solid red;
  padding: 5px 5px;
  font-size: 13px;
  font-weight: 600;
  margin: 0 10px;
  text-align: center;
  &:hover,
  &:focus {
    color: white;
    background: red;
    border: 1px solid red;
  }
`;
