const fonts = {
  tiny: 13,
  small: 15,
  medium: 18,
  large: 20,
  xLarge: 22,
  xxLarge: 28,
};

export const weight = {
  normal: 400,
  xnormal: 450,
  medium: 500,
  bold: 600,
  xBold: 700,
  xxBold: 800
}

export default fonts
