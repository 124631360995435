import { ReactElement, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DropdownWrapper,
  FlexWrapper,
  Input,
  Loader,
  PageWrapper,
  ToastMessage,
} from "../../../components";
import { handleAuthenticate } from "../../../redux/login/api";
import { LoginPayload } from "../../../redux/login/types";
import {
  CardText,
  StyledModal,
  CardHeader,
  ForgetPassword,
  ModalWrapper,
  CardWrapper,
  SigninButton,
  FlexWrapperSign,
  SignInButton,
  FormCheckForm,
  DIV,
  ForgotLock,
  Circle,
  ForgotHeading,
  ForgotPara,
  Contact,
} from "./subcomponents";
import { RootState } from "../../../redux/store";
import DOHR from "../../../asserts/dohrLogo.png";
import { updateHasError } from "../../../redux/login/action";
import api from "../../../services";
import apiEndpoints from "../../../const/apiEndpoints";
import absenceIcon from "../../../asserts/Saam.png";
import recruitmentIcon from "../../../asserts/new_recruitment.png";
import personelIcon from "../../../asserts/personal-growth.png";
import { Image } from "../ChooseSignIn/subcomponents";

const SignInOptions = (): ReactElement => {
  const {
    login: { hasError, isLoading },
    signUpModules,
    lang,
    language,
  } = useSelector((state: RootState) => ({
    login: state.login,
    signUpModules: state.Language.language.signUp,
    lang: state.Language.language.header.main.signIn,
    language: state.Language.language.header.main,
  }));

  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [forget, setForget] = useState(false);
  const [submited, setSubmited] = useState(false);
  const [login, setLogin] = useState<LoginPayload>({} as LoginPayload);
  const { absent_management, recruitment, personal_growth } = signUpModules;
  const [remember, setRemember] = useState(false);
  const [email, setEmail] = useState({
    email: "",
    loadingemail: false,
  });
  const {
    forgotPassword,
    para1,
    emailPlaceholder,
    submit,
    para2,
    checkYourEmail,
    para3,
    backToLogin,
    needHelp,
    contactSupport,
  } = language;

  const handleSubmitEmail = async () => {
    const response = await api.post(apiEndpoints.forgotPassword, {
      email_id: email.email,
    });
    setEmail({
      ...email,
      loadingemail: true,
    });
    if (response.data.success) {
      setEmail({
        ...email,
        loadingemail: false,
      });
      setSubmited(true);
      setForget(false);
    }
  };

  return (
    <PageWrapper>
      <FlexWrapperSign>
        <CardWrapper top="1rem">
          <FlexWrapper noMargin noPadding justifyContent="center">
            <Image src={absenceIcon} alt="" />
            <CardText>{absent_management}</CardText>
            <SigninButton
              onClick={() => {
                setShow(true);
              }}
              margintop="1vw"
            >
              {lang}
            </SigninButton>
          </FlexWrapper>
        </CardWrapper>
        <CardWrapper
          linearGradiant="linear-gradient(to top, #cbeeff , white);"
          width="24"
          top="2rem"
        >
          <FlexWrapper noMargin noPadding justifyContent="center">
            <Image src={recruitmentIcon} alt="" />

            <CardText>{recruitment}</CardText>
            <SigninButton onClick={() => {}}>{lang}</SigninButton>
          </FlexWrapper>
        </CardWrapper>
        <CardWrapper top="2rem">
          <FlexWrapper noMargin noPadding justifyContent="center">
            <Image src={personelIcon} alt="" />
            <CardText>{personal_growth}</CardText>
            <SigninButton onClick={() => {}}>{lang}</SigninButton>
          </FlexWrapper>
        </CardWrapper>
      </FlexWrapperSign>
      <ModalWrapper>
        <StyledModal
          size="sm"
          show={show}
          onHide={() => {
            setShow(false);
          }}
          dialogClassName="login-modal"
        >
          <StyledModal.Body>
            <FlexWrapper noMargin noPadding justifyContent="center">
              <CardHeader src={DOHR} alt="Dohr" />
            </FlexWrapper>
            <FlexWrapper justifyContent="center">
              <DropdownWrapper width="100">
                <Input
                  value={login.email_id}
                  placeholder="Email"
                  inputType={"text"}
                  onChange={(value: string) => {
                    setLogin({ ...login, email_id: value });
                  }}
                />
              </DropdownWrapper>
              <DropdownWrapper width="100">
                <Input
                  value={login.password}
                  placeholder={"Password"}
                  inputType={"password"}
                  onChange={(value: string) => {
                    setLogin({ ...login, password: value });
                  }}
                />
              </DropdownWrapper>
              {isLoading ? (
                <Loader />
              ) : (
                <SignInButton
                  onClick={() => {
                    dispatch(
                      handleAuthenticate({
                        ...login,
                      })
                    );
                  }}
                >
                  {lang}
                </SignInButton>
              )}
            </FlexWrapper>
            <FlexWrapper noMargin width="100%" justifyContent="space-between">
              <DIV>
                <FormCheckForm
                  name={"Remember me"}
                  label={"Remember me"}
                  checked={remember}
                  onClick={() => {
                    setRemember(!remember);
                  }}
                />
              </DIV>
              <ForgetPassword
                onClick={() => {
                  setForget(true);
                  setShow(false);
                }}
              >
                Forgotten password?
              </ForgetPassword>
            </FlexWrapper>
          </StyledModal.Body>
          <ToastMessage
            show={hasError}
            onCloseHandler={() => {
              dispatch(updateHasError(false));
            }}
            right="auto"
            message="Incorrect username or password. Please check your credentials and try again!"
            bg={"primary"}
          />
        </StyledModal>
      </ModalWrapper>
      <ModalWrapper>
        <StyledModal
          size="sm"
          show={forget}
          onHide={() => {
            setForget(false);
          }}
          dialogClassName="login-modal"
        >
          <StyledModal.Body>
            <FlexWrapper noMargin noPadding justifyContent="center">
              <CardHeader src={DOHR} alt="Dohr" />
            </FlexWrapper>
            <Circle>
              <ForgotLock>
                {/* <FontAwesomeIcon icon={["fas", "lock-keyhole"]} size="lg" /> */}
              </ForgotLock>
            </Circle>
            <ForgotHeading>{forgotPassword}</ForgotHeading>
            <ForgotPara>{para1}</ForgotPara>
            <DropdownWrapper width="100">
              <Input
                value={email.email}
                placeholder={emailPlaceholder}
                inputType={"text"}
                onChange={(value: string) => {
                  setEmail({
                    ...email,
                    email: value,
                  });
                }}
              />
            </DropdownWrapper>
            <FlexWrapper noPadding justifyContent="center">
              {email.loadingemail ? (
                <Loader />
              ) : (
                <SignInButton
                  onClick={() => {
                    handleSubmitEmail();
                  }}
                >
                  {submit}
                </SignInButton>
              )}
            </FlexWrapper>
            <ForgotPara>{para2}</ForgotPara>
          </StyledModal.Body>
        </StyledModal>
      </ModalWrapper>
      <ModalWrapper>
        <StyledModal
          size="sm"
          show={submited}
          onHide={() => {
            setSubmited(false);
          }}
          dialogClassName="login-modal"
        >
          <StyledModal.Body>
            <FlexWrapper noMargin noPadding justifyContent="center">
              <CardHeader src={DOHR} alt="Dohr" />
            </FlexWrapper>
            <Circle>
              <ForgotLock>
                {/* <FontAwesomeIcon icon={["fas", "lock-keyhole"]} size="lg" /> */}
              </ForgotLock>
            </Circle>
            <ForgotHeading>{checkYourEmail}</ForgotHeading>
            <ForgotPara>{para3}</ForgotPara>

            <FlexWrapper noPadding justifyContent="center">
              <SignInButton
                onClick={() => {
                  setShow(true);
                  setSubmited(false);
                }}
              >
                {backToLogin}
              </SignInButton>
            </FlexWrapper>
            <div>
              <ForgotPara>{needHelp}</ForgotPara>
              <Contact>{contactSupport}</Contact>
            </div>
          </StyledModal.Body>
        </StyledModal>
      </ModalWrapper>
    </PageWrapper>
  );
};

export default SignInOptions;
