import { ReactElement } from "react";
import { H2, Span } from "../../typography";
import Title from "./subcomponent";
import { colors } from "../../const/theme";
import BackButton from "../BackButton";
import { useHistory } from "react-router-dom";

interface SectionProps {
  title: string;
  helpText?: string;
  hasBorder?: boolean;
  noMargin?: boolean;
  hasBackButton?: boolean
}

const SectionTitle = ({
  title,
  helpText,
  hasBorder,
  noMargin,
  hasBackButton
}: SectionProps): ReactElement => {
  const history = useHistory()
  return (
    <Title noMarginLeft={noMargin} hasBorder={hasBorder}>
      {hasBackButton && (
        <BackButton
          handleBack={() => history.goBack()}
        />
      )}
      <H2 color={colors.gray}>{title}</H2>
      {helpText && (
        <div>
          <Span isHelpText={true}>{helpText}</Span>
        </div>
      )}
    </Title>
  );
};

export default SectionTitle;
