import styled from "styled-components";

export interface DropdownWrapperProps {
  width?: string;
  height?: string;
  marginRight?: string;
  paddingBottom?: string;
  marginTop?: string;
  paddingLeft?: string;
  paddingTop?: string;
}

const DropdownWrapper = styled.div<DropdownWrapperProps>`
  margin: 0 1px 10px;
  width: ${({ width }) => (width ? `${width}%` : "22%")};
  margin-left: 2%;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "1%")};
  padding-top: ${({ paddingTop }) => (paddingTop ? paddingTop : null)};
  padding-left: ${({ paddingLeft }) => (paddingLeft ? paddingLeft : null)};
  padding-bottom: ${({ paddingBottom }) =>
    paddingBottom ? paddingBottom : null};
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : null)};
  @media (max-width: 600px) {
    width: 100%;
    margin: 8px;
  }
  #editable-dropdown {
    height: 100%;
  }

  /* .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    font-family: "Montserrat" !important;
    color: #002c99 !important;
    font-size: 15px !important;
  } */
`;

export default DropdownWrapper;
