import styled from "styled-components";
import { InputGroup, FormControl } from "react-bootstrap";
import { colors } from "../../const/theme";
import { EditDropdownWrapperProps } from "./typings";
import { H3 } from "../../typography";
import fonts, { weight } from "../../const/fonts";

export const ListInput = styled(InputGroup)`
  #dropdown-id {
    border: 1px solid ${({ theme }) => theme.border};
    &:hover,
    &:focus {
      background: #edfae9;
      color: #111111;
    }
  }
  .dropdown-menu.show {
    max-height: 300px;
    overflow: auto;
    transform: translate(0, 40px) !important;
  }
`;

export const Title = styled(H3)`
  margin-bottom: 5px;
  font-size: ${fonts.small}px;
  font-weight: ${weight.xnormal};
  font-family: "Montserrat", sans-serif;
  color: ${colors.darkBlue};
   &.error {
    color: #ff0000; /* Red text color */
  }
`;

export const DropdownInput = styled(FormControl)`
  background: ${colors.white} !important;
  ::placeholder {
    font-size: 0.95rem;
  }
  &.is-valid + #dropdown-id {
    border-color: green;
  }

  &.is-invalid + #dropdown-id {
    border-color: #dc3545;
  }
`;

export const EditDropdownWrapper = styled.div<EditDropdownWrapperProps>`
  width: ${({ width }) => width || "100%"};
  @media (max-width: 415px) {
    width: 100%;
  }

  ${({ isDisabled }) =>
    isDisabled &&
    `
pointer-events: none;
opacity: 0.7;
`}
`;
