import styled from "styled-components";
import { colors } from "../../const/theme";
import DropdownList from "react-widgets/DropdownList";

interface FormProps {
  top?: string;
}

export const Forms = styled.form<FormProps>`
  margin-top: ${({ top }) => (top ? top : "16px")};
  width: 6vw;
`;

export const Dropdown = styled(DropdownList)`
  text-align: center;
  .rw-widget-input {
    background-color: white;
    color: ${colors.darkBlue};
    font-weight: 600;
    box-shadow: none;
    cursor: pointer;
    font-size: 1vw;
    caret-color: transparent;
    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
    }
    
  }
  .rw-widget-picker {
    border: 1px solid transparent;
    border-radius: 20px;
    min-height: 30px;
    width: 6vw;
    @media (max-width: 550px) {
      width: 40px;
    }
  }
  .rw-picker-caret {
    color: ${colors.darkBlue};
    font-weight: 600;
  }
  .rw-popup-container {
    color: ${colors.darkBlue};
    font-weight: 600;
    font-size: 1vw;
    border: none;
    @media (max-width: 550px) {
      font-size: 6px;
    }
  }
  .rw-popup {
    border: none;
  }
  .rw-dropdown-list-value {
    @media (max-width: 550px) {
      font-size: 10px;
    }
  }
`;
