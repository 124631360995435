import React, { ReactElement, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  ActionButton,
  DropdownWrapper,
  FlexWrapper,
  Footers,
  Input,
  ToastMessage,
  Loader,
} from "../../../../../components";
import { RootState } from "../../../../../redux/store";
import {
  updateHasError,
  updateRegister,
} from "../../../../../redux/login/action";
import {
  ContactCardWraper,
  Container,
  Coversation,
  Header,
  PageContainer,
  SubHeader,
  TextWrapper,
} from "./subcomponents";
import Aos from "aos";
import Input2 from "../../../../../components/Input2";

import { contactRegister } from "../../../../../redux/login/api";

const ContactForm = (): ReactElement => {
  const {
    contactForm,
    login: {
      contactResponse,
      hasError,
      hasRegister,
      getRegisterResponse,
      isLoading,
      
    },
  } = useSelector((state: RootState) => ({
    login: state.login,
    contactForm: state.Language.language.contactForm,
  }));

  const {
    fill,
    CompanyName,
    FirstName,
    LastName,
    EnterMailid,
    EnterPhoneNumber,
    descriptionPlaceHolder,
    submit,
    messages,
  } = contactForm;

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  }, []);

  const [values, setValues] = useState(contactResponse);
  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    message: "",
  });

  const handleSubmit = (e: any) => {
    const newErrors = {
      first_name: values.first_name ? "" : "Enter your first name",
      last_name: values.last_name ? "" : "Enter your last name",
      phone_number: values.phone_number ? "" : "Enter your phone number",
      email: values.email ? "" : "Enter your email",
      message: values.message ? "" : "Enter your message here",
    };
    // Email validation
    if (!newErrors.email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(values.email)) {
        newErrors.email = "Invalid email address";
      }
    }

    // Phone number validation
    if (!newErrors.phone_number) {
      const phoneRegex = /^\d{10}$/; // Assuming a 10-digit phone number
      if (!phoneRegex.test(values.phone_number)) {
        newErrors.phone_number = "Invalid phone number";
      }
    }
    setErrors(newErrors);

    if (Object.values(newErrors).every((error) => !error)) {
      dispatch(
        contactRegister({
          ...values,
          know_more: false,
          cons_id: 0,
          cus_id: 0,
          from_web: true,
        })
      );
      setValues({
        first_name: "",
        last_name: "",
        phone_number: "",
        email: "",
        company_name: "",
        message: "",
      });
    }
  };

  return (
    <PageContainer>
      <Container>
        {CompanyName === "Company name" ? (
          <Header>
            Let's start a<Coversation> conversation...</Coversation> <br />
          </Header>
        ) : (
          <Header>
            Hör av dig <Coversation> till oss...</Coversation> <br />
          </Header>
        )}
        <SubHeader>{fill}</SubHeader>
      </Container>
      <ContactCardWraper data-aos="zoom-in">
        <FlexWrapper noMargin noPadding justifyContent="space-between">
          <DropdownWrapper width="45">
            <Input2
              value={values?.first_name}
              label={FirstName}
              onChange={(value: string) => {
                setValues({
                  ...values,
                  first_name: value,
                });
              }}
            />
            <span style={{ color: "red" }}>{errors.first_name}</span>
          </DropdownWrapper>
          <DropdownWrapper width="45">
            <Input2
              value={values?.last_name}
              label={LastName}
              onChange={(value: string) => {
                setValues({
                  ...values,
                  last_name: value,
                });
              }}
            />
            <span style={{ color: "red" }}>{errors.last_name}</span>
          </DropdownWrapper>
        </FlexWrapper>
        <FlexWrapper noMargin noPadding justifyContent="space-between">
          <DropdownWrapper width="45">
            <Input2
              value={values.phone_number}
              label={EnterPhoneNumber}
              onChange={(value: string) => {
                setValues({
                  ...values,
                  phone_number: value,
                });
              }}
            />
            <span style={{ color: "red" }}>{errors.phone_number}</span>
          </DropdownWrapper>
          <DropdownWrapper width="45">
            <Input2
              value={values.email}
              label={EnterMailid}
              onChange={(value: string) => {
                setValues({
                  ...values,
                  email: value,
                });
              }}
            />
            <span style={{ color: "red" }}>{errors.email}</span>
          </DropdownWrapper>
        </FlexWrapper>
        <FlexWrapper noMargin noPadding justifyContent="space-between">
          <DropdownWrapper width="45">
            <Input2
              value={values.company_name}
              label={CompanyName}
              onChange={(value: string) => {
                setValues({
                  ...values,
                  company_name: value,
                });
              }}
            />
          </DropdownWrapper>
        </FlexWrapper>
        <FlexWrapper noPadding noMargin>
          <DropdownWrapper width="100">
            <TextWrapper>
              <Input
                height="auto"
                label={descriptionPlaceHolder}
                value={values?.message}
                inputType="textarea"
                // placeholder={descriptionPlaceHolder}
                onChange={(value: string) => {
                  setValues({
                    ...values,
                    message: value,
                  });
                }}
              />
            </TextWrapper>
            <span style={{ color: "red" }}>{errors.message}</span>
          </DropdownWrapper>
        </FlexWrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <FlexWrapper justifyContent="center">
            <ActionButton onClick={handleSubmit}>{submit}</ActionButton>
          </FlexWrapper>
        )}
      </ContactCardWraper>
      <Footers />
      {hasRegister && (
        <ToastMessage
          show={!!hasRegister}
          bg="success"
          top="12%"
          right="40%"
          onCloseHandler={() => {
            dispatch(updateRegister(false));
          }}
          message={messages}
        ></ToastMessage>
      )}
      {hasError && (
        <ToastMessage
          show={!!hasError}
          top="12%"
          right="40%"
          bg="danger"
          onCloseHandler={() => {
            dispatch(updateHasError(false));
          }}
          message="Please Fill out All Fields"
        ></ToastMessage>
      )}
    </PageContainer>
  );
};

export default ContactForm;
