import { ReactElement, useEffect, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { ActionButton, FlexWrapper, ToastMessage } from "../../components";
import { colors } from "../../const/theme";
import { RootState } from "../../redux/store";
import Common from "./common";
import PersonalDetails from "./personalDetails";
import { PageContainer, CardWrapper } from "./subcomponent";
import { ApplicationProps } from "../../redux/Common/types";
import { generateInitialErrors } from "./helper";
import { ValidateforApplicationForm } from "./helper";
import { ApplicationField } from "./types";
import { handleOpenApplication } from "../../redux/Common/api";
import { Modal, Button } from "react-bootstrap";
import {
  updateRegister,
  updateopenapplicationuser,
} from "../../redux/Common/action";
import { initialValuesBoolan } from "../../container/Login/ChooseSignIn/register/const";
import { addApplicationUser } from "../../redux/Common/action";
import {
  expDetailsProps,
  EmploymenHistory,
  RelevantEducation,
} from "../../redux/Common/types";

const JobSeekerForm = (): ReactElement => {
  const {
    login: { emailvalidation },
    hasRegister,
    values,
    Language: { language },
    register,
  } = useSelector(
    (state: RootState) => ({
      login: state.login,
      common: state.common,
      hasRegister: state.common.hasRegister,
      register: state.Language.language.register,
      Language: state.Language,
      emailvalidation: state.login.emailvalidation,
      values: state.common.userApplicationProsp as ApplicationProps,
    }),
    shallowEqual
  );

  const {
    applicationForm: { title, titledetails, submit },
  } = language;
  const {
    message,
    mail,
    errormsgcheck,
    Native,
    Fluent,
    Proficient,
    Intermediate,
    Beginner,
  } = register;
  useEffect(() => {
    window.scroll({ top: 0 });
  }, []);

  const dispatch = useDispatch();

  const [errors, setErrors] = useState({} as Record<string, string>);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [checkBox1, setcheckBox1] = useState(initialValuesBoolan);
  const [worIn, setWorkIn] = useState({} as expDetailsProps);
  const [worOut, setWorkOut] = useState({} as expDetailsProps);
  const [workHistory, setWorkHistory] = useState({} as EmploymenHistory);
  const [relevantEdu, setRelevantEdu] = useState({} as RelevantEducation);
  const [rating, setRating] = useState({ swedish: 0, english: 0, other: 0 });

  const validateForms = (field: ApplicationField): void => {
    setErrors(
      ValidateforApplicationForm({
        values,
        errors,
        field,
      })
    );
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const isLanguageSkillsValid = () => {
    // Check if at least one checkbox is selected or the "other" language section is filled out
    if (values?.language_skills?.swedish || values?.language_skills?.english) {
      return true; // Validation success
    }
    return false; // Validation failed
  };

  const handleToggleTerms = () => {
    setcheckBox1({ ...checkBox1, terms: !checkBox1.terms });
    if (!checkBox1?.terms) {
      dispatch(
        addApplicationUser({
          terms_and_condition: !checkBox1?.terms,
        })
      );
    }
  };

  const handleToggleAccurateInfo = () => {
    setcheckBox1({ ...checkBox1, accurate_info: !checkBox1.accurate_info });
    if (!checkBox1?.accurate_info) {
      dispatch(
        addApplicationUser({
          accurate_information: !checkBox1?.accurate_info,
        })
      );
    }
  };

  const handleToggleMarketing = () => {
    setcheckBox1({ ...checkBox1, marketing: !checkBox1.marketing });
    dispatch(addApplicationUser({ marketing_purpose: !checkBox1?.marketing }));
  };

  const emailMessage = emailvalidation.message;

  const addFormFields = () => {
    if (
      workHistory.from ||
      workHistory.to ||
      workHistory.role ||
      workHistory.responsibility
    ) {
      dispatch(
        addApplicationUser({
          employment_history: [
            ...values.employment_history,
            { ...workHistory },
          ],
        })
      );
    }

    setWorkHistory({
      from: null,
      to: null,
      role: "",
      responsibility: "",
      employer: "",
    });
  };

  const addFormFields2 = () => {
    if (
      relevantEdu.from ||
      relevantEdu.to ||
      relevantEdu.education ||
      relevantEdu.ins_name
    ) {
      dispatch(
        addApplicationUser({
          relevant_education: [
            ...values.relevant_education,
            { ...relevantEdu },
          ],
        })
      );
    }

    setRelevantEdu({
      from: null,
      to: null,
      education: "",
      ins_name: "",
    });
  };

  const removeFormFields = (i: any) => {
    let newFormValues = [...values.employment_history];
    newFormValues.splice(i, 1);
    dispatch(
      addApplicationUser({
        employment_history: newFormValues,
      })
    );
  };

  const removeFormFields2 = (i: any) => {
    let newFormValues = [...values.relevant_education];
    newFormValues.splice(i, 1);
    dispatch(
      addApplicationUser({
        relevant_education: newFormValues,
      })
    );
  };

  const addFormFieldsin = () => {
    if (
      worIn.duration ||
      worIn.key_res ||
      worIn.name_of_emp ||
      worIn.title_of_role
    ) {
      dispatch(
        addApplicationUser({
          info_work_exp_in_school: [
            ...values.info_work_exp_in_school,
            { ...worIn },
          ],
        })
      );
    }

    setWorkIn({
      name_of_emp: "",
      duration: "",
      title_of_role: "",
      key_res: "",
    });
  };

  const addFormFieldsOut = () => {
    if (
      worOut.duration ||
      worOut.key_res ||
      worOut.name_of_emp ||
      worOut.title_of_role
    ) {
      dispatch(
        addApplicationUser({
          info_work_exp_out_school: [
            ...values.info_work_exp_out_school,
            { ...worOut },
          ],
        })
      );
    }
    setWorkOut({
      name_of_emp: "",
      duration: "",
      title_of_role: "",
      key_res: "",
    });
  };

  const removeFormFieldsin = (i: any) => {
    let newFormValues = [...values.info_work_exp_in_school];
    newFormValues.splice(i, 1);
    dispatch(
      addApplicationUser({
        info_work_exp_in_school: newFormValues,
      })
    );
  };

  const removeFormFieldsOut = (i: any) => {
    let newFormValues = [...values.info_work_exp_out_school];
    newFormValues.splice(i, 1);
    dispatch(
      addApplicationUser({
        info_work_exp_out_school: newFormValues,
      })
    );
  };

  const selectedDateFrom = workHistory.from
    ? typeof workHistory.from === "string"
      ? new Date(workHistory.from)
      : workHistory.from
    : null;

  const selectedDateTo = workHistory.to
    ? typeof workHistory.to === "string"
      ? new Date(workHistory.to)
      : workHistory.to
    : null;

  const selectedDateFromedu = relevantEdu.from
    ? typeof relevantEdu.from === "string"
      ? new Date(relevantEdu.from)
      : relevantEdu.from
    : null;

  const selectedDateToedu = relevantEdu.to
    ? typeof relevantEdu.to === "string"
      ? new Date(relevantEdu.to)
      : relevantEdu.to
    : null;

  const isDateRangeValid =
    selectedDateFrom && selectedDateTo && selectedDateTo > selectedDateFrom;
  const isDateRangeValidedu =
    selectedDateFromedu &&
    selectedDateToedu &&
    selectedDateToedu > selectedDateFromedu;

  const isDateRangeValids = (
    fromDate: Date | null | string,
    toDate: Date | null | string
  ) => {
    return fromDate && toDate && toDate > fromDate;
  };

  const employer = (value: any) => {
    setWorkHistory({
      ...workHistory,
      employer: value,
    });
  };
  const from = (value: any) => {
    setWorkHistory({
      ...workHistory,
      from: value,
    });
  };
  const to = (value: any) => {
    setWorkHistory({
      ...workHistory,
      to: value,
    });
    const isRangeValid = isDateRangeValids(workHistory.from, value);
    if (!isRangeValid) {
      setModalMessage(
        "Invalid date range: 'to' date must be greater than 'from' date"
      );
      setShowModal(true);
    }
  };
  const responsiblity = (value: any) => {
    setWorkHistory({
      ...workHistory,
      responsibility: value,
    });
  };

  const roleof = (value: any) => {
    setWorkHistory({
      ...workHistory,
      role: value,
    });
  };

  const insname = (value: any) => {
    setRelevantEdu({
      ...relevantEdu,
      ins_name: value,
    });
  };

  const frominsname = (value: any) => {
    setRelevantEdu({
      ...relevantEdu,
      from: value,
    });
  };
  const toinsname = (value: any) => {
    setRelevantEdu({
      ...relevantEdu,
      to: value,
    });
    const isRangeValid = isDateRangeValids(relevantEdu.from, value);
    if (!isRangeValid) {
      setModalMessage(
        "Invalid date range: 'to' date must be greater than 'from' date"
      );
      setShowModal(true);
    }
  };
  const eduinsname = (value: any) => {
    setRelevantEdu({
      ...relevantEdu,
      education: value,
    });
  };

  const name_of_emp = (value: any) => {
    setWorkIn({
      ...worIn,
      name_of_emp: value,
    });
  };

  const duration = (value: any) => {
    setWorkIn({
      ...worIn,
      duration: value,
    });
  };
  const title_of_role = (value: any) => {
    setWorkIn({
      ...worIn,
      title_of_role: value,
    });
  };
  const key_res = (value: any) => {
    setWorkIn({
      ...worIn,
      key_res: value,
    });
  };

  const name_of_empout = (value: any) => {
    setWorkIn({
      ...worOut,
      name_of_emp: value,
    });
  };

  const durationout = (value: any) => {
    setWorkOut({
      ...worOut,
      duration: value,
    });
  };
  const title_of_roleout = (value: any) => {
    setWorkOut({
      ...worOut,
      title_of_role: value,
    });
  };
  const key_resout = (value: any) => {
    setWorkOut({
      ...worOut,
      key_res: value,
    });
  };

  const validateWorkHistory = () => {
    if (
      !workHistory.from ||
      !workHistory.to ||
      !workHistory.role ||
      !workHistory.responsibility ||
      !workHistory.employer
    ) {
      // At least one of the properties is missing or empty, so set an error message
      return "Please fill in all fields for work history.";
    } else {
      // All properties are filled, no validation error
      return "";
    }
  };

  const validateRelevanteducation = () => {
    if (
      !relevantEdu.from ||
      !relevantEdu.to ||
      !relevantEdu.education ||
      !relevantEdu.ins_name
    ) {
      // At least one of the properties is missing or empty, so set an error message
      return "Please fill in all fields for work history.";
    } else {
      // All properties are filled, no validation error
      return "";
    }
  };

  const english = (rate: any) => {
    if (rate === 1) {
      dispatch(
        addApplicationUser({
          language_skills: {
            ...values?.language_skills,
            english: Beginner,
          },
        })
      );

      setRating({ ...rating, english: rate });
    } else if (rate === 2) {
      dispatch(
        addApplicationUser({
          language_skills: {
            ...values?.language_skills,
            english: Intermediate,
          },
        })
      );

      setRating({ ...rating, english: rate });
    } else if (rate === 3) {
      dispatch(
        addApplicationUser({
          language_skills: {
            ...values?.language_skills,
            english: Proficient,
          },
        })
      );

      setRating({ ...rating, english: rate });
    } else if (rate === 4) {
      dispatch(
        addApplicationUser({
          language_skills: {
            ...values?.language_skills,
            english: Fluent,
          },
        })
      );

      setRating({ ...rating, english: rate });
    } else if (rate === 5) {
      dispatch(
        addApplicationUser({
          language_skills: {
            ...values?.language_skills,
            english: Native,
          },
        })
      );

      setRating({ ...rating, english: rate });
    }
  };

  const swedishlang = (rate: any) => {
    if (rate === 1) {
      dispatch(
        addApplicationUser({
          language_skills: {
            ...values?.language_skills,
            swedish: Beginner,
          },
        })
      );

      setRating({ ...rating, swedish: rate });
    } else if (rate === 2) {
      dispatch(
        addApplicationUser({
          language_skills: {
            ...values?.language_skills,
            swedish: Intermediate,
          },
        })
      );

      setRating({ ...rating, swedish: rate });
    } else if (rate === 3) {
      dispatch(
        addApplicationUser({
          language_skills: {
            ...values?.language_skills,
            swedish: Proficient,
          },
        })
      );

      setRating({ ...rating, swedish: rate });
    } else if (rate === 4) {
      dispatch(
        addApplicationUser({
          language_skills: {
            ...values?.language_skills,
            swedish: Fluent,
          },
        })
      );

      setRating({ ...rating, swedish: rate });
    } else if (rate === 5) {
      dispatch(
        addApplicationUser({
          language_skills: {
            ...values?.language_skills,
            swedish: Native,
          },
        })
      );

      setRating({ ...rating, swedish: rate });
    }
  };

  const handlesubmit = async (e: any) => {
    e.preventDefault(); // Prevent the form from actually submitting

    setSubmitted(true);

    const newErrors = generateInitialErrors(values);
    // Set the new error state
    setErrors(newErrors);

    // Check if any errors exist
    if (Object.values(newErrors).some((error) => error !== "")) {
      // If there are validation errors, log them and show the modal
      setModalMessage("Please fill in all required fields");
      setShowModal(true);
      return; // Stop further processing
    }

    if (
      !checkBox1?.terms ||
      !checkBox1?.accurate_info ||
      !checkBox1?.marketing
    ) {
      // Show a separate alert message or modal
      setModalMessage(errormsgcheck);
      setShowModal(true);
      return; // Stop further processing
    }

    if (!isLanguageSkillsValid()) {
      // Show a separate alert message or modal for language skills validation
      setModalMessage("Please select atleast one language");
      setShowModal(true);
      return; // Stop further processing
    }

    if (validateWorkHistory()) {
      setModalMessage("Please fill all fields of Employment history");
      setShowModal(true);
      return; // Stop further processing
    }

    if (validateRelevanteducation()) {
      setModalMessage("Please fill all fields of Relevant education");
      setShowModal(true);
      return; // Stop further processing
    }

    if (!isDateRangeValid) {
      setModalMessage(
        "Invalid date range: 'to' date must be greater than 'from' date-Employment history"
      );
      setShowModal(true);
      return; // Stop further processing
    }

    if (!isDateRangeValidedu) {
      setModalMessage(
        "Invalid date range: 'to' date must be greater than 'from' date-Relevant education"
      );
      setShowModal(true);
      return; // Stop further processing
    }
    // If there are no errors, proceed with form submission
    await dispatch(
      handleOpenApplication({
        ...values,
        employment_history: [...values.employment_history, workHistory],
        relevant_education: [...values.relevant_education, relevantEdu],
        info_work_exp_in_school: [...values.info_work_exp_in_school, worIn],
        info_work_exp_out_school: [...values.info_work_exp_out_school, worOut],
      })
    );

    dispatch(updateopenapplicationuser());
    setcheckBox1(initialValuesBoolan);
    setRating({ swedish: 0, english: 0, other: 0 });
    setWorkIn({
      duration: "",
      key_res: "",
      name_of_emp: "",
      title_of_role: "",
    });
    setWorkHistory({
      employer: "",
      role: "",
      from: "",
      to: "",
      responsibility: "",
    });
    setRelevantEdu({
      education: "",
      from: "",
      to: "",
      ins_name: "",
    });
    setSubmitted(false);
  };

  return (
    <PageContainer>
      <CardWrapper>
        <FlexWrapper
          backgroundColor="#2097F5"
          height={55}
          noMargin
          justifyContent="center"
        >
          <p
            style={{
              fontWeight: "600",
              fontSize: "20px",
              paddingBottom: "0px",
              color: colors.darkBlue,
            }}
          >
            {title}
          </p>
        </FlexWrapper>
        <FlexWrapper
          backgroundColor="#2097F5"
          noMargin
          noPadding
          justifyContent="center"
        >
          <p style={{ paddingBottom: "0px", color: colors.darkBlue }}>
            {titledetails}
          </p>
        </FlexWrapper>
        <PersonalDetails errorss={errors} validateForms={validateForms} />
        <Common
          errors={errors}
          from={from}
          todate={to}
          employer={employer}
          responsiblity={responsiblity}
          validateForms={validateForms}
          selectedDateFrom={selectedDateFrom}
          selectedDateTo={selectedDateTo}
          workHistory={workHistory}
          isLanguageSkillsValid={isLanguageSkillsValid}
          validateRelevanteducation={validateRelevanteducation}
          validateWorkHistory={validateWorkHistory}
          checkBox1={checkBox1}
          toggleTerms={handleToggleTerms}
          toggleAccurateInfo={handleToggleAccurateInfo}
          toggleMarketing={handleToggleMarketing}
          submitted={submitted}
          addFormFields={addFormFields}
          removeFormFields={removeFormFields}
          roleof={roleof}
          insname={insname}
          frominsname={frominsname}
          toinsname={toinsname}
          eduinsname={eduinsname}
          addFormFields2={addFormFields2}
          removeFormFields2={removeFormFields2}
          relevantEdu={relevantEdu}
          selectedDateFromedu={selectedDateFromedu}
          selectedDateToedu={selectedDateToedu}
          worIn={worIn}
          worOut={worOut}
          name_of_emp={name_of_emp}
          key_res={key_res}
          title_of_role={title_of_role}
          duration={duration}
          addFormFieldsin={addFormFieldsin}
          addFormFieldsOut={addFormFieldsOut}
          removeFormFieldsin={removeFormFieldsin}
          removeFormFieldsOut={removeFormFieldsOut}
          name_of_empout={name_of_empout}
          durationout={durationout}
          title_of_roleout={title_of_roleout}
          key_resout={key_resout}
          rating={rating}
          englishlang={english}
          swedishlang={swedishlang}
          engSkills={values?.language_skills?.english}
          sweSkills={values?.language_skills?.swedish}
        />
        <FlexWrapper justifyContent="center">
          <ActionButton onClick={handlesubmit}>{submit}</ActionButton>
        </FlexWrapper>
      </CardWrapper>
      {hasRegister && (
        <ToastMessage
          show={!!hasRegister}
          bg="success"
          top="4%"
          right="40%"
          onCloseHandler={() => {
            dispatch(updateRegister(false));
          }}
          message={message}
        ></ToastMessage>
      )}
      {emailMessage === false && (
        <ToastMessage
          show={emailMessage === false}
          bg="danger"
          top="4%"
          right="25%"
          message={mail}
        ></ToastMessage>
      )}
      <Modal show={showModal} onHide={handleCloseModal} style={{ top: "12%" }}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </PageContainer>
  );
};

export default JobSeekerForm;
