import { CreateInternalVacancyApi, GetCompletedVacancyApi } from "./api";
import { vacancySlice } from "./reducer";

const {
  updateSelectedUncoveredDetails,
  resetValues,
  updatehasCreateVacancySuccess,
  updateSelectedTeacherId,
  updateSelectedVacancyDetails,
  updateSelectedcosultId,
  updateAssignInternal,
  updateAssignExternal,
  updateVacancyDetails,
  updateCreateVacancyErr,
  updateCreateExternalVanc,
  updateCreateExternalVancError,
  updateHasSuccess,
} = vacancySlice.actions;

export {
  CreateInternalVacancyApi,
  GetCompletedVacancyApi,
  updateSelectedUncoveredDetails,
  resetValues,
  updatehasCreateVacancySuccess,
  updateSelectedTeacherId,
  updateSelectedVacancyDetails,
  updateSelectedcosultId,
  updateAssignInternal,
  updateAssignExternal,
  updateCreateExternalVancError,
  updateVacancyDetails,
  updateCreateVacancyErr,
  updateCreateExternalVanc,
  updateHasSuccess,
};
