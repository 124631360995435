export interface Vacancy {
  position?: string;
  position_id?: string;
  v_date?: string;
  f_time?: string;
  t_time?: string;
  location?: string;
  name_of_institution?: string;
  institution_type?: string;
  institution_type_id?: string;
  address?: string;
  postcode?: string;
  description?: string;
  other_info?: string;
  publish_to_All?: boolean;
  assigned_to?: string;
  assigned_from?: any;
  is_draft?: boolean;
  absence_id?: string;
  consultants?: string;
  ins_id?: string | any;
  consultants_id?: string;
  total_whours?: string | any;
  my_consultant?: any;
  internal_staff?: any;
  publish_to_internal: any;
  publish_to_external: any;
  break_time: any;
  total_whrs: any;
}
export interface CreateVacancyProps {
  Vacancy: Array<Vacancy>;
  assigned_to: string;
}

export interface ExternalVacancy {
  position?: string;
  position_id?: string;
  v_date?: string;
  other_info?: string;
  absence_id?: string;
  break_time?: any;
  total_whrs?: any;
  day?: string;
  start_time?: any;
  end_time?: string;
  assigned_to_external?: string;
  publish_to_external?: boolean;
  is_draft?: boolean;
  ins_id?: string;
  ins_name?: string;
  ins_type?: string;
  uncovered_id?: string;
  location?: string;
  postal_code?: string;
  created_by?: string;
  assigned_from?: any;
  subject?: any;
}

export interface ExternalVacancyPayload {
  //externalVacancy: Array<ExternalVacancy>;
  from_time: string;
  to_time: string;
  discription: Array<string>;
  publish_to: string;
  publish_to_id: string;
  break_time: any;
  total_whrs: any;
  location: string;
  isdraft: boolean;
  choosecons20min: boolean;
  publishedtoall30min: boolean;
  position: string;
  position_id: string;
  v_date: string;
  day: string;
  ins_id: string;
  uncovered_id: string;
  other_info: string;
  assigned_to_external: string | number;
  assigned_to_internal: string | number;
  absence_id: string;
  vacancy_status: string;
  publish_to_internal: string;
  publish_to_external: string;
  assigned_from: string;
  created_by: string;
  subject?: any;
}

export interface InternalVacancyPayload {
  Vacancy: Array<InternalVacancy>;
  assigned_to: string;
}

export interface InternalVacancy {
  position?: string;
  position_id?: string;
  v_date?: string;
  other_info?: string;
  assigned_from?: any;
  absence_id?: string;
  break_time?: any;
  total_whrs?: any;
  day?: string;
  start_time: string;
  end_time: any;
  publish_to_internal?: string | boolean;
  is_draft?: boolean;
  ins_id?: string;
  uncovered_id?: string;
  created_by?: string;
  assigned_to_internal?: string;
  subject?: string;
}

export interface ctreateSeperateVacancyProps {
  position: string;
  position_id: string;
  v_date: string;
  f_time: string;
  t_time: string;
  location: string;
  name_of_institution: string;
  institution_type: string;
  institution_type_id: string;
  address: string;
  postcode: string;
  description: string;
  other_info: string | any;
  consultants: string;
  consultants_id: string;
  break_time: string | any;
  total_whours: string | any;
  ins_id: string | any;
  publish_to_All: boolean | any;
  is_draft: boolean;
  internal_staff: any;
  assigned_to: any;
  my_consultant: any;
}

export interface Role {
  id: string;
  title: string;
}

export interface GetOrganizationProps {
  id: string;
  organization_type: string;
  is_active: string;
}

export interface Data {
  teacher_name?: any;
  Parental_leave_percentage: string;
  absence_id: string;
  address: string;
  assigned_from: string;
  appro_due_date: string;
  area_name: string;
  child_first_name: string;
  child_last_name: string;
  classroom: string;
  year_group: string;
  company_name: string;
  contact_number: string;
  created_at: any;
  cus_email_id: string;
  cus_id: string;
  date_info: string;
  day: string;
  day_no: string;
  email_id: string;
  end_time: any;
  first_name: string;
  from_date: string;
  from_time: any;
  v_date: any | Date;
  id: any;
  ins_id: string;
  institute_domin: string;
  institute_id: string;
  institute_name: string;
  institute_type: string;
  institute_type_id: string;
  invoice_address: string;
  invoice_area_name: string;
  invoice_email_id: string;
  invoice_postal_code: string;
  is_active: string;
  is_approved: string;
  is_covered: string;
  is_external: string;
  last_name: string;
  leave_type: string;
  leave_type_id: string;
  locality: string;
  organization_no: string;
  organization_type: string;
  organization_type_id: string;
  password: string;
  postal_code: string;
  postcode: string;
  reason: string;
  schedule_id: string;
  start_time: string;
  subject: string;
  substitute_required: string;
  telephone_number: string;
  title: string;
  title_id: string;
  to_date: string;
  to_time: any;
  uncovered_id: any;
  unique_id: string;
  updated_at: string;
  with_pay: string;
  without_pay: string;
  ddd: string;
}
export interface getUncoveredResponse {
  title: string;
  Data: Array<Data>;
}

export interface getDraftandPublishVacancy {
  absence_id: string;
  assigned_from: string;
  institute_name: string;
  institute_type: string;
  assigned_to_external: string;
  assigned_to_internal: string;
  break_time: string;
  created_at: string;
  day: string;
  id: string;
  discription: string;
  to_time: string;
  external_type: string;
  position: string;
  position_id: string;
  publish_to_external: string;
  publish_to_internal: string;
  from_time: string;
  total_whrs: string;
  uncovered_id: string;
  updated_at: string;
  v_date: string;
  vacancy_status: string;
  is_canceled: any;
  handoverTime: any;
  preparationTime: any;
  location: any;
  cancelled_by_consultant: any;
  cancellation_charges: any;
}

export interface completedVacancyprops {
  Consultant: Array<getConsultantList>;
  Customer: Array<GetCompletedVacancy>;
}

export interface getConsultantList {
  absence_id: string;
  assigned_from: string;
  assigned_to_external: string;
  assigned_to_internal: string;
  day: string;
  first_name: any;
  last_name: any;
  break_time: string;
  external_type: string;
  from_time: string;
  iam_student: string;
  id: string;
  in_id: string;
  ins_id: string;
  is_active: string;
  is_approved: string;
  is_draft: string;
  location: string;
  name_of_institutition: string;
  phone_number: string;
  position: string;
  position_id: string;
  preferred_work_institution: string;
  specify_date: string;
  terms_and_condition: string;
  to_time: string;
  total_whrs: string;
  uncovered_id: string;
  v_date: string;
  vacancy_status: string;
  vid: string;
  report_start_time: string;
  report_end_time: string;
  vacancy_is_approved: any;
  is_canceled: any;
  approved_stime: string;
  approved_etime: string;
  preparationTime: any;
  handoverTime: any;
  report_total_whours: string;
  cancelled_by_consultant: any;
  cancellation_charges: any;
  total_approved_hrs: any;
}

export interface GetCompletedVacancy {
  id: string;
  position: string;
  position_id: string;
  v_date: string;
  from_time: string;
  break_time: string;
  to_time: string;
  day: string;
  first_name: any;
  last_name: any;
  location: string;
  name_of_institution: string;
  institution_type: string;
  institution_type_id: string;
  address: string;
  postcode: string;
  description: string;
  other_info: string;
  is_draft: boolean;
  consultants: string;
  consultants_id: string;
  is_active: string;
  is_uncovered: string;
  absence_id: string;
  vacancy_status: string;
  report_start_time: string;
  report_end_time: string;
  vacancy_is_approved: any;
  is_canceled: any;
  approved_stime: string;
  approved_etime: string;
  preparationTime: any;
  handoverTime: any;
  report_total_whours: string;
  cancelled_by_consultant: any;
  cancellation_charges: any;
  total_approved_hrs: any;
}

export interface GetTeacher {
  address: string;
  area_name: string;
  company_name: string;
  created_at: string;
  email_id: string;
  first_name: string;
  id: string;
  ins_id: string;
  is_active: string;
  last_name: string;
  assigned_work_time: any;
  organization_no: string;
  organization_type: string;
  organization_type_id: string;
  password: string;
  postal_code: string;
  telephone_number: string;
  title: string;
  title_id: string;
  unique_id: string;
}

export interface TeacherId {
  id: string;
}

export interface selectedVacancy {
  external: boolean;
  internal: boolean;
}

export interface consultantId {
  id: any;
}

export interface CoveredProps {
  Parental_leave_percentage: string;
  absence_id: string;
  address: string;
  appro_due_date: string;
  area_name: string;
  child_first_name: string;
  child_last_name: string;
  classroom: string;
  company_name: string;
  contact_number: string;
  created_at: string;
  cus_email_id: string;
  cus_id: string;
  date_info: string;
  day: string;
  day_no: string;
  email_id: string;
  end_time: string;
  year_group: string;
  first_name: string;
  from_date: string;
  from_time: string;
  id: string;
  ins_id: string;
  institute_domin: string;
  institute_id: string;
  institute_name: string;
  institute_type: string;
  institute_type_id: string;
  invoice_address: string;
  invoice_area_name: string;
  invoice_email_id: string;
  invoice_postal_code: string;
  is_active: string;
  is_approved: string;
  is_covered: string;
  is_external: any;
  last_name: string;
  leave_type: string;
  leave_type_id: string;
  locality: string;
  organization_no: string;
  organization_type: string;
  organization_type_id: string;
  postal_code: string;
  postcode: string;
  reason: string;
  schedule_id: string;
  start_time: string;
  subject: string;
  substitute_required: string;
  telephone_number: string;
  title: string;
  title_id: string;
  to_date: string;
  to_time: string;
  unique_id: string;
  v_date: string;
  coveredByName: string;
  coveredByInternal: boolean;
  coveredByExternal: boolean;
}

export interface GetUncoveredWithoutLessonResponse {
  id: any;
  position: string;
  position_id: any;
  v_date: string;
  day: string;
  from_time: string;
  to_time: string;
  break_time: string;
  total_whrs: string;
  ins_id: any;
  uncovered_id: any;
  other_info: string;
  assigned_to_internal: any;
  assigned_to_external: any;
  absence_id: any;
  vacancy_status: any;
  report_start_time: string;
  report_end_time: string;
  report_break_time: string;
  report_total_whours: string;
  report_reason: string;
  publish_to_internal: any;
  publish_to_external: any;
  external_type: any;
  created_at: string;
  updated_at: string;
  is_active: string;
  is_draft: string;
  assigned_from: string;
  location: string;
  discription: string;
  created_by: string;
  createdBy_phoneNumber: string;
  publish_to: string;
  publish_to_id: any;
  flexible_time: any;
  to_all_30: any;
  to_all_20: any;
  approved_stime: any;
  approved_etime: any;
  feedback: any;
  vacancy_is_approved: any;
  rate_your_day: any;
  rate_info: any;
  rate_sufficiency: any;
  total_approved_hrs: any;
  my_consultant: string;
  is_canceled: any;
  cancellation_charges: any;
  totalhrs: string;
  preparationTime: string;
  handoverTime: string;
  is_expired: any;
  I_am_a_full_time_student: any;
  self_employed_F_Skatt_or_freelancer_third_party: any;
  agree_terms_of_employment_contract: any;
  is_feedback_given: any;
  is_paid: any;
  vacancy_Accepted_Primary_time: string;
  vacancy_is_Accepted_Primary: any;
  first_Accepted_cons_id: any;
  cancelled_by_consultant: any;
  institute_type: any;
}

export interface InitialState {
  isLoggedIn: boolean;
  isLoading: boolean;
  hasCreateVacancySuccess: boolean;
  role: Array<Role>;
  GetOrganization: Array<GetOrganizationProps>;
  createVacancy: CreateVacancyProps;
  getUncoveredData: Array<getUncoveredResponse>;
  getCompletedVacancy: completedVacancyprops;
  createSeperateVacancy: ctreateSeperateVacancyProps;
  updateUncovered: Data | null;
  getMyDraft: Array<getDraftandPublishVacancy>;
  getTeacherDetails: Array<GetTeacher>;
  setSelectedTeacherId: TeacherId;
  setSelectedConsultantId: consultantId;
  selectedUpdateTeacherExternal: Array<any>;
  getCovered: Array<CoveredProps>;
  setAssignInternal: any;
  setAssignExternal: any;
  setVacancyStatus: any;
  createVacancyErr: boolean;
  selectedDraft: any;
  hasExternalError: boolean;
  isFeedBackGiven: boolean;
  getWithoutLessonUncoveredList: Array<GetUncoveredWithoutLessonResponse>;
  hasSuccess: boolean;
}

export const initialState: InitialState = {
  isLoggedIn: false,
  isLoading: false,
  role: [],
  GetOrganization: [],
  getUncoveredData: [],
  getCompletedVacancy: {
    Customer: [],
    Consultant: [],
  },
  createVacancy: {
    assigned_to: "",
    Vacancy: [],
  },
  updateUncovered: null,
  createSeperateVacancy: {
    position: "",
    assigned_to: "",
    position_id: "",
    internal_staff: "",
    my_consultant: "",
    v_date: "",
    f_time: "",
    t_time: "",
    location: "",
    name_of_institution: "",
    institution_type: "",
    institution_type_id: "",
    address: "",
    postcode: "",
    description: "",
    other_info: "",
    consultants: "",
    consultants_id: "",
    break_time: "",
    total_whours: "",
    ins_id: "",
    publish_to_All: false,
    is_draft: false,
  },
  getMyDraft: [],
  hasCreateVacancySuccess: false,
  getTeacherDetails: [],
  setSelectedTeacherId: {
    id: "",
  },
  selectedUpdateTeacherExternal: [],
  getCovered: [],
  setSelectedConsultantId: {
    id: "",
  },
  setAssignInternal: undefined,
  setAssignExternal: undefined,
  setVacancyStatus: undefined,
  createVacancyErr: false,
  selectedDraft: "",
  hasExternalError: false,
  isFeedBackGiven: false,
  getWithoutLessonUncoveredList: [],
  hasSuccess: false,
};
