export const tableHeader = [
  "Position",
  "Vacancy Date",
  "Start time",
  "End time",
  "Location",
  "Published To",
  "Published date-time",
  "Covered by",
  "Status ",
  "Detail view",
];
