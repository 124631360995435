import { ReactElement, useEffect } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  ActionButton,
  FlexWrapper,
  Loader,
  PageWrapper,
  SectionTitle,
} from "../../../components";
import { updateChatId } from "../../../redux/Hq_Management/chat/action";
import {
  getChatList,
  getChatMessage,
} from "../../../redux/Hq_Management/chat/api";
import { RootState } from "../../../redux/store";
import { CardWraper } from "../AbsenceManagement/Vacancies/PublishedVacancies/subcomponents";

const Chat = (): ReactElement => {
  const {
    chats,
    userInfo,
    chat: { getChatDataList, isLoading },
  } = useSelector((state: RootState) => ({
    chats: state.Language.language.Tables.chats,
    userInfo: state.login.userInfo,
    chat: state.chat,
  }));

  const { sectionTitle, tableHeader } = chats;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getChatList({
        id: userInfo?.data?.unique_id,
        type: 1,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper>
      <CardWraper>
        <FlexWrapper backgroundColor="#eff3fa" noMargin height={55}>
          <SectionTitle title={sectionTitle} noMargin />
        </FlexWrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <FlexWrapper>
            <Table striped borderless hover>
              <thead
                style={{
                  backgroundColor: "#2097f5",
                  color: "white",
                  fontFamily: "Montserrat",
                }}
              >
                <tr style={{ textAlign: "center" }}>
                  {tableHeader.map((data, index) => (
                    <th key={`Header Unique id -${index}`}>{data}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {getChatDataList?.map((data, index) => {
                  const { consultant_fname, consultant_lname, id, vdate } =
                    data;
                  return (
                    <tr key={index} style={{ textAlign: "center" }}>
                      <td>{index + 1}</td>
                      <td>{`${consultant_fname}${consultant_lname}`}</td>
                      <td>{"Consultant"}</td>
                      <td>{vdate?.slice(0, 4)}/2024</td>
                      <td>{vdate?.slice(5, 10)}</td>
                      <td>{vdate?.slice(12, 18)}</td>
                      <td>
                        <ActionButton
                          onClick={() => {
                            dispatch(
                              getChatMessage({
                                chat_id: id,
                                type: "2",
                              })
                            );
                            dispatch(updateChatId(id));
                          }}
                        >
                          Chat
                        </ActionButton>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </FlexWrapper>
        )}
      </CardWraper>
    </PageWrapper>
  );
};

export default Chat;
