import styled from "styled-components";

interface TitleProps {
  hasBorder?: boolean;
  noMarginLeft?: boolean;
}

export const Title = styled.div<TitleProps>`
  display: flex;
  margin-left: ${({ noMarginLeft }) => (noMarginLeft ? "0px" : "-12%")};
  font-family: "Montserrat", sans-serif;
  padding: ${({ hasBorder }) => (hasBorder ? "16px 0" : "0")};
  border-bottom: ${({ hasBorder, theme }) =>
    hasBorder ? `1px solid ${theme.border}` : "0"};
`;

export default Title;
