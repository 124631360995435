import { ReactElement } from "react";
import { Button, Card } from "react-bootstrap";
import styled from "styled-components";
import { IsApprovedActionProps } from "./typings";

export const CardWraper = styled(Card)`
  display: flex;
  justify-content: center;
  width: 90%;
  margin: 1rem auto;
`;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ApproveButton = styled(Button)`
  color: white;
  background-color: #50c878;
  border: none;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  &:hover,
  &:active,
  &:focus {
    color: white;
    background-color: #50c878;
    border: none;
    box-shadow: 0px 2px 5px gray;
  }
`;

const DenyButton = styled(Button)`
  color: white;
  background-color: red;
  border: none;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  &:hover,
  &:active,
  &:focus {
    color: white;
    background-color: red;
    border: none;
    box-shadow: 0px 2px 5px gray;
  }
`;

export const IsApprovedAction = ({
  handleApprove,
  handleDeny,
}: IsApprovedActionProps): ReactElement => {
  return (
    <ActionWrapper>
      <ApproveButton onClick={handleApprove}>Accept</ApproveButton>
      <DenyButton onClick={handleDeny}>Decline</DenyButton>
    </ActionWrapper>
  );
};
