import { ApplicationProps } from "../../../redux/Common/types";
import { ValidateApplicationForm } from "./types";
import {
  validateRequired,
  validateBooleanSuccess,
  validateEmail,
  validateLettersOnlylastname,
  validateLettersOnlyFirstname,
  validatePhone,
} from "../../../helpers/formValidation";

export const generateInitialErrors = (values: ApplicationProps) => {
  const errors: any = {
    first_name: values.first_name ? "" : "Enter your first name",
    last_name: values.last_name ? "" : "Enter your last name",
    phone_number: values.phone_number ? "" : "Enter your phone number",
    email_id: values.email_id ? "" : "Enter your email",
    address: values.address ? "" : "Enter your address",
    postalcode: values.postalcode ? "" : "Enter your postal code",
    area_name: values.area_name ? "" : "Enter your area name",
    date_of_birth: values.date_of_birth ? "" : "Enter your date of birth",
    city: values.city ? "" : "Enter your city",

    occup_sector: values.occup_sector ? "" : "Enter your occup_sector",
    studyingteacher: "",
    // working_evening_or_weekends:
    //   typeof values.working_evening_or_weekends === "boolean"
    //     ? ""
    //     : "Select if you can work evenings or weekends",
    qualifiedteacherdate:
      values.qualifiedTeacher === true && values.qualifiedteacherdate
        ? "Enter your qualified teacher date"
        : "",
    work_exp_in_school: "",
    language_skills: values.language_skills ? "" : "Enter your language skills",
    info_work_exp_in_school: "",
    work_exp_out_school: "",
    info_work_exp_out_school: "",
    subjectlist:
      values.qualifiedTeacher === true && values.subjectlist.length === 0
        ? "Select subjects"
        : "",
    subjectlistno:
      values.qualifiedTeacher === false && values.subjectlistno.length === 0
        ? "Select subjects"
        : "",
    agegroupno: values.qualifiedTeacher === false && values.agegroupno.length === 0 ? "fgfgf" : "",

    subjectlistyes:
      values.studyingteacher === true && values.subjectlistyes.length === 0
        ? "Select subjects"
        : "",
    specificField: "",
    agegroup:
      values.qualifiedTeacher === true && values.agegroup.length === 0
        ? "Select age groups"
        : "",

    agegroupyes:
      values.studyingteacher === true && values.agegroupyes.length === 0
        ? "Select age groups"
        : "",
    // employment_history:
    //   values.employment_history?.length === 0
    //     ? ""
    //     : "Enter your employment history",
    // relevant_education:
    //   values.relevant_education?.length === 0
    //     ? ""
    //     : "Enter your relevant education",
    qualifiedTeacher: "",
    swedishLara: "",
    instutionstudyteacher: "",
    preferWorkIn: "",
    occupation: "",
    position: "",
    work_start:
      typeof values.work_start === "boolean"
        ? ""
        : "Enter your work start date",
    fileswedishlara: "",
    howDoYouKnowUs: values.howDoYouKnowUs
      ? ""
      : "Select how you heard about us",
    Driving_license:
      typeof values.Driving_license === "boolean"
        ? ""
        : "Enter your Driving License",
    // marketing_purpose: values.marketing_purpose ? "" : "Select your marketing purpose",
    // accurate_information: values.accurate_information ? "" : "Select if the information is accurate",
    // terms_and_conditions: values.terms_and_conditions ? "" : "Agree to the terms and conditions",
  };

  // for (const key in values) {
  //   if (Array.isArray(values[key])) {
  //     // Check if it's an array
  //     if (values[key].length === 0) {
  //       errors[key] = `${key} is required`;
  //     } else {
  //       errors[key] = "";
  //     }
  //   } else {
  //     // Check for other non-array fields
  //     if (!values[key]) {
  //       errors[key] = `${key} is required`;
  //     } else {
  //       errors[key] = "";
  //     }
  //   }
  // }



  // if (
  //   typeof values.swedish_bank_account !== "boolean" &&
  //   values.swedish_bank_account !== ""
  // ) {
  //   errors.swedish_bank_account = "Select your swedish bank account";
  // }
  if (values.occup_sector === "Other") {
    if (!values.specificField) {
      errors.specificField = "Enter Specfic field";
    }
  }

   

  if (values.position === "Teaching positions") {
    if (!values.occupation) {
      errors.occupation = "Enter your occupation";
    }

    if (values.qualifiedTeacher === null) {
      errors.qualifiedTeacher = "Select if you are a qualified teacher";
    }
  }

  if (values.position === "Non-teaching positions") {
    if (!values.occupation) {
      errors.occupation = "Enter your occupation";
    }

    if (values.work_exp_in_school === null) {
      errors.work_exp_in_school = "Enter your work experience in schools";
    }
    // // If work_exp_in_school is true, check for info_work_exp_in_school

    // if (values.work_exp_in_school === true) {
    //   if (values.info_work_exp_in_school.length === 0)
    //     errors.info_work_exp_in_school =
    //       "Enter information about your work experience in schools";
    // }
    if (values.work_exp_in_school === false) {
      if (values.work_exp_out_school === null)
        errors.work_exp_out_school =
          "Enter your work experience outside schools";
    }

    // if (values.work_exp_out_school === true) {
    //   // If work_exp_out_school is true, check for info_work_exp_out_school
    //   if (values.info_work_exp_out_school) {
    //     errors.info_work_exp_out_school =
    //       "Enter information about your work experience outside schools";
    //   }
    // }
  }

  if (values.qualifiedTeacher === false) {
    if (values.studyingteacher === null) {
      errors.studyingteacher = "Enter about studying teacher";
    }
    if (values.studyingteacher === true) {
      if (!values.instutionstudyteacher) {
        errors.instutionstudyteacher = "Enter institution name";
      }
    }
  }
  return errors;
};

export const ValidateforApplicationForm = ({
  values,
  errors,
  field,
}: ValidateApplicationForm): Record<string, string> => {
  let formErrors: Record<string, string> = { ...errors };
  const {
    first_name,
    last_name,
    address,
    email_id,
    postalcode,
    phone_number,
    area_name,
    city,
    swedish_bank_account,
    name_of_institutition,
    proof_of_studies,
    state_hours_weeks_student,
    other_occupations,
    info_other_occupations,
    date_of_birth,
    description,
    self_employed,
    specify_date,
    info_work_exp_in_school,
    info_work_exp_out_school,
    work_exp_in_school,
    work_exp_out_school,
    working_hours_or_weeks,
    language_skills,
    Driving_license,
    How_did_you_hear_about_us,
    right_to_work_in_Sweden,
    occup_sector,
    employment_history,
    otheroccupationfield,
    subjectlist,
    qualifiedTeacher,
    relevant_education,
    agegroup,
    agegroupno,
    agegroupyes,
    subjectlistno,
    subjectlistyes,
    instutionstudyteacher,
    occupation,
    position,
    preferWorkIn,
    qualifiedteacherdate,
    work_start,
    specificField,
    working_evening_or_weekends,
    howDoYouKnowUs,
    studyingteacher,
    swedishLara,
  } = values;

  switch (field) {
    case "first_name":
      formErrors["first_name"] = validateRequired(first_name);
      formErrors["first_name"] = validateLettersOnlyFirstname(first_name);
      break;
    case "last_name":
      formErrors["last_name"] = validateRequired(last_name);
      formErrors["last_name"] = validateLettersOnlylastname(last_name);
      break;
    case "address":
      formErrors["address"] = validateRequired(address);
      break;
    case "email_id":
      formErrors["email_id"] = validateEmail(email_id);
      break;
    case "postalcode":
      formErrors["postalcode"] = validateRequired(postalcode);
      break;
    case "phone_number":
      formErrors["phone_number"] = validateRequired(phone_number);
      break;
    case "area_name":
      formErrors["area_name"] = validateRequired(area_name);
      break;
    case "city":
      formErrors["city"] = validateRequired(city);
      break;
    case "employment_history":
      formErrors["employment_history"] = validateRequired(employment_history);
      break;

    case "swedish_bank_account":
      formErrors["swedish_bank_account"] =
        validateBooleanSuccess(swedish_bank_account);
      break;
    case "specificField":
      formErrors["specificField"] = validateBooleanSuccess(specificField);
      break;
    case "name_of_institutition":
      formErrors["name_of_institutition"] = validateRequired(
        name_of_institutition
      );
      break;
    case "occup_sector":
      formErrors["occup_sector"] = validateRequired(occup_sector);
      break;
    case "state_hours_weeks_student":
      formErrors["state_hours_weeks_student"] = validateRequired(
        state_hours_weeks_student
      );
      break;
    case "qualifiedteacherdate":
      formErrors["qualifiedteacherdate"] =
        validateRequired(qualifiedteacherdate);
      break;
    case "preferWorkIn":
      formErrors["preferWorkIn"] = validateRequired(preferWorkIn);
      break;

    case "date_of_birth":
      formErrors["date_of_birth"] = validateRequired(date_of_birth);
      break;
    case "description":
      formErrors["description"] = validateRequired(description);
      break;

    case "subjectlist":
      formErrors["subjectlist"] = validateRequired(subjectlist);
      break;
    case "qualifiedTeacher":
      formErrors["qualifiedTeacher"] = validateBooleanSuccess(qualifiedTeacher);
      break;

    case "specify_date":
      formErrors["specify_date"] = validateRequired(specify_date);
      break;

    case "work_exp_in_school":
      formErrors["work_exp_in_school"] =
        validateBooleanSuccess(work_exp_in_school);
      break;
    case "work_exp_out_school":
      formErrors["work_exp_out_school"] =
        validateBooleanSuccess(work_exp_out_school);
      break;
    case "info_work_exp_in_school":
      formErrors["info_work_exp_in_school"] = validateRequired(
        info_work_exp_in_school
      );
      break;
    case "info_work_exp_out_school":
      formErrors["info_work_exp_out_school"] = validateRequired(
        info_work_exp_out_school
      );
      break;
    case "working_hours_or_weeks":
      formErrors["working_hours_or_weeks"] = validateRequired(
        working_hours_or_weeks
      );
      break;

    case "agegroup":
      formErrors["agegroup"] = validateRequired(agegroup);
      break;
    case "agegroupno":
      formErrors["agegroupno"] = validateRequired(agegroupno);
      break;
    case "agegroupyes":
      formErrors["agegroupyes"] = validateRequired(agegroupyes);
      break;
    case "subjectlistno":
      formErrors["subjectlistno"] = validateRequired(subjectlistno);
      break;
    case "subjectlistyes":
      formErrors["subjectlistyes"] = validateRequired(subjectlistyes);
      break;

    case "swedishLara":
      formErrors["swedishLara"] = validateBooleanSuccess(swedishLara);
      break;
    case "working_evening_or_weekends":
      formErrors["working_evening_or_weekends"] = validateBooleanSuccess(
        working_evening_or_weekends
      );
      break;
    case "work_start":
      formErrors["work_start"] = validateBooleanSuccess(work_start);
      break;

    case "relevant_education":
      formErrors["relevant_education"] = validateRequired(relevant_education);
      break;
    case "language_skills":
      formErrors["language_skills"] = validateRequired(language_skills);
      break;
    case "Driving_license":
      formErrors["Driving_license"] = validateBooleanSuccess(Driving_license);
      break;
    case "studyingteacher":
      formErrors["  studyingteacher"] = validateBooleanSuccess(studyingteacher);
      break;
    case "occupation":
      formErrors["occupation"] = validateRequired(occupation);
      break;
    case "instutionstudyteacher":
      formErrors["instutionstudyteacher"] = validateRequired(
        instutionstudyteacher
      );
      break;

    case "position":
      formErrors["position"] = validateRequired(position);
      break;
    case "otheroccupationfield":
      formErrors["otheroccupationfield"] =
        validateRequired(otheroccupationfield);
      break;
    case "right_to_work_in_Sweden":
      formErrors["right_to_work_in_Sweden"] = validateBooleanSuccess(
        right_to_work_in_Sweden
      );
      break;
    case "howDoYouKnowUs":
      formErrors["howDoYouKnowUs"] = validateRequired(howDoYouKnowUs);
      break;
    default:
      formErrors["first_name"] = validateRequired(first_name);
      formErrors["last_name"] = validateRequired(last_name);
      formErrors["address"] = validateRequired(address);
      formErrors["email_id"] = validateRequired(email_id);
      formErrors["phone_number"] = validatePhone(phone_number);
      formErrors["post_code"] = validateRequired(postalcode);
      formErrors["area_name"] = validateRequired(phone_number);
      formErrors["city"] = validateRequired(city);
      formErrors["date_of_birth"] = validateRequired(date_of_birth);
      formErrors["other_occupation_field"] = validateRequired(
        info_other_occupations
      );
      formErrors["subjectlist"] = validateRequired(subjectlist);
      formErrors["other_occupations"] =
        validateBooleanSuccess(other_occupations);
      formErrors["state_hours_weeks_student"] = validateRequired(
        state_hours_weeks_student
      );
      formErrors["proof_of_studies"] = validateRequired(proof_of_studies);
      formErrors["name_of_institutition"] = validateRequired(
        name_of_institutition
      );
      formErrors["description"] = validateRequired(description);
      formErrors["self_employed"] = validateBooleanSuccess(self_employed);
      formErrors["specify_date"] = validateRequired(specify_date);
      formErrors["work_exp_in_school"] =
        validateBooleanSuccess(work_exp_in_school);
      formErrors["work_exp_out_school"] =
        validateBooleanSuccess(work_exp_out_school);
      formErrors["info_work_exp_in_school"] = validateRequired(
        info_work_exp_in_school
      );
      formErrors[" working_hours_or_weeks"] = validateRequired(
        working_hours_or_weeks
      );
      formErrors["Driving_license"] = validateBooleanSuccess(Driving_license);
      formErrors["How_did_you_hear_about_us"] = validateRequired(
        How_did_you_hear_about_us
      );
      formErrors["right_to_work_in_Sweden"] = validateBooleanSuccess(
        right_to_work_in_Sweden
      );
      formErrors["occupation"] = validateRequired(occupation);
  }

  return formErrors;
};
