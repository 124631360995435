import { ReactElement, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  DropdownWrapper,
  FlexWrapper,
  Input,
  PageWrapper,
  SectionTitle,
  EditableDropdown,
  CardWrapper,
  ToastMessage,
} from "../../../../components";
import { PrimaryButton } from "../../../../components/Button";
import { DropdownListProps } from "../../../../components/EditableDropdown/typings";
import { AppDispatch, RootState } from "../../../../redux/store";
import Modal from "../../../../components/Modal";
import { CardWraper } from "../CoveredManagement/myCoveredLesson/subcomponents";
import { LeaveTypeDropdown } from "../../../../helpers/dropdown";
import {
  GetLeaveTypeApi,
  RegisterAbsenceApi,
} from "../../../../redux/Teachers/RegisterAbsence/api";
import { Form } from "react-bootstrap";
import { CardHeader, FormLabel } from "./subcomponents";
import { initialValues } from "./const";
import { updateHasRegisterError } from "../../../../redux/Teachers/RegisterAbsence/action";
import { RegisterLeave } from "../../../../redux/Teachers/RegisterAbsence/types";
import moment from "moment";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import ROUTES from "../../../../const/routes";

const RegisterAbsence = (): ReactElement => {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();

  const { TypeofLeave, RegisterFieldProps, UserInfo, hasResgisterError } =
    useSelector(
      (state: RootState) => ({
        TypeofLeave: state.Absence.LeaveType,
        RegisterFieldProps: state.Absence.registerLeave,
        hasResgisterError: state.Absence.hasResgisterError,
        UserInfo: state.login?.userInfo?.data,
        message: state.Absence.registerLeaveResponse.message,
      }),
      shallowEqual
    );
  //States
  const [showModal, setShowModal] = useState(false);
  const [showTimeInput, setShowTimeInput] = useState(false);
  const [checked, setChecked] = useState(false);
  const [RegisterForm, setRegisterForm] = useState(
    RegisterFieldProps as RegisterLeave
  );
  const [values, setValues] = useState(initialValues);
  const [showReason, setShowReason] = useState(false);
  const [dateError, setDateError] = useState(false);
  //dropdowns
  const LeaveTypeDropDown = TypeofLeave ? LeaveTypeDropdown(TypeofLeave) : [];

  const dep = moment(values.start_date).isSame(values.end_date);

  //otherFunctions
  const HandleSubmit = () => {
    if (!canSubmit) {
      alert("Please ensure all mandatory fields are completed.");
    } else {
      if (values.end_date < values?.start_date) {
        setDateError(true);
      } else {
        dispatch(
          RegisterAbsenceApi({
            ...RegisterForm,
            substitute_required:
              RegisterForm?.substitute_required === "Yes" ? true : false,
            first_name: UserInfo?.first_name,
            last_name: UserInfo?.last_name,
            from_date: moment(values?.start_date).format("YYYY-MM-DD"),
            to_date: moment(values?.end_date).format("YYYY-MM-DD"),
            from_time: dep ? values?.start_time : "00:00:00",
            to_time: dep ? values?.end_Time : "00:00:00",
            unique_id: UserInfo?.unique_id,
            ins_id: UserInfo?.ins_id,
            additional_comment: values?.additional_comment,
            Parental_leave_percentage: RegisterForm?.Parental_leave_percentage
              ? RegisterForm?.Parental_leave_percentage
              : RegisterForm?.others,
          })
        ).then((res) => {
          if (res?.meta?.requestStatus === "fulfilled") {
            alert("Your leave is now submitted.");
            history.push(ROUTES.TEACHER.TEC_MY_ABSENCE);
          }
        });
      }
    }
  };

  //useEffects
  useEffect(() => {
    if (values.end_date && values.start_date !== "") {
      if (moment(values.start_date).isSame(values.end_date)) {
        setShowTimeInput(true);
      } else {
        setShowTimeInput(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dep]);

  //Get Calls
  useEffect(() => {
    dispatch(GetLeaveTypeApi());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const canSubmit =
    !!values?.start_date &&
    !!values?.end_date &&
    !!RegisterForm?.leave_type &&
    !!RegisterForm?.substitute_required;

  return (
    <PageWrapper>
      <CardWraper>
        <FlexWrapper backgroundColor="#eff3fa" noMargin>
          <SectionTitle title="Register absence" noMargin />
        </FlexWrapper>
        <FlexWrapper>
          <DropdownWrapper>
            <DatePicker
              selected={values?.start_date}
              dateFormat="yyyy/MM/dd"
              showMonthDropdown
              showYearDropdown
              minDate={new Date()}
              yearDropdownItemNumber={50}
              scrollableYearDropdown
              placeholderText={"YYYY-MM-DD"}
              onChange={(value) => {
                setValues({ ...values, start_date: value });
              }}
              customInput={
                <Input
                  boldLabel
                  value={values?.start_date}
                  width="100%"
                  isRequired
                  label="Start date"
                  height="0px"
                />
              }
            />
          </DropdownWrapper>{" "}
          <DropdownWrapper>
            <DatePicker
              selected={values.end_date}
              dateFormat="yyyy/MM/dd"
              showMonthDropdown
              showYearDropdown
              minDate={new Date()}
              yearDropdownItemNumber={50}
              scrollableYearDropdown
              placeholderText={"YYYY-MM-DD"}
              onChange={(value) => {
                setValues({ ...values, end_date: value });
              }}
              customInput={
                <Input
                  boldLabel
                  width="100%"
                  value={values.end_date}
                  label="End date"
                  isRequired
                  height="0px"
                />
              }
            />
          </DropdownWrapper>
          {showTimeInput ? (
            <>
              <DropdownWrapper>
                <Input
                  isRequired
                  label="Start Time"
                  value={values.start_time}
                  inputType={"time"}
                  onChange={(value: string) => {
                    setValues({ ...values, start_time: value + ":00" });
                  }}
                />
              </DropdownWrapper>
              <DropdownWrapper>
                <Input
                  isRequired
                  label="End Time"
                  value={values.end_Time}
                  inputType={"time"}
                  onChange={(value: string) => {
                    setValues({ ...values, end_Time: value + ":00" });
                  }}
                />
              </DropdownWrapper>
            </>
          ) : null}
          <DropdownWrapper>
            <EditableDropdown
              isRequired
              title="Leave type"
              dropdownList={LeaveTypeDropDown}
              placeholder={"Select"}
              handleSelect={(value: DropdownListProps) => {
                setRegisterForm({
                  ...RegisterForm,
                  leave_type_id: value.id,
                  leave_type: value.name,
                });
                if (value.name === "Parental leave") {
                  setShowModal(true);
                } else if (value.name === "Leave with Pay") {
                  setShowReason(true);
                } else if (value.name === "Leave without pay") {
                  setShowReason(true);
                }
              }}
            />
          </DropdownWrapper>
          <DropdownWrapper>
            <EditableDropdown
              title="Is substitute required?"
              dropdownList={[
                { id: "1", name: "Yes" },
                { id: "2", name: "No" },
              ]}
              isRequired
              placeholder={"Choose one"}
              handleSelect={(value: DropdownListProps) => {
                setRegisterForm({
                  ...RegisterForm,
                  substitute_required: value.name,
                });
              }}
            />
          </DropdownWrapper>
          <DropdownWrapper>
            <Input
              boldLabel
              inputType="textarea"
              label="Additional comments"
              value={values.additional_comment}
              onChange={(value: string) => {
                setValues({ ...values, additional_comment: value });
              }}
            />
          </DropdownWrapper>
        </FlexWrapper>

        <FlexWrapper justifyContent="center">
          {}
          <PrimaryButton onClick={HandleSubmit}>Submit</PrimaryButton>
        </FlexWrapper>
        {showModal ? (
          <Modal
            submitButtonText={"Save"}
            show={showModal}
            sizeModal="lg"
            center={true}
            title="Parental leave details"
            handleCancel={() => {
              setRegisterForm({
                ...RegisterForm,
                Parental_leave_percentage: "",
                child_first_name: "",
                child_personal_number: "",
                appro_due_date: "",
                LeaveOfAbsence: "",
                reason: "",
              });
              setChecked(false);
              setShowModal(false);
            }}
            handleSubmit={() => {
              setChecked(false);
              setShowModal(false);
            }}
          >
            <FlexWrapper justifyContent="space-around">
              <FormLabel>Specify percentage of your parental leave*</FormLabel>
              <Form>
                <Form.Group controlId="LeavePercent">
                  <Form.Check
                    value="100%"
                    type="radio"
                    aria-label="radio 1"
                    label="100%"
                    onChange={(e: any) => {
                      setRegisterForm({
                        ...RegisterForm,
                        Parental_leave_percentage: e.target.value,
                      });
                    }}
                    checked={RegisterForm.Parental_leave_percentage === "100%"}
                  />
                  <Form.Check
                    value="75%"
                    type="radio"
                    aria-label="radio 2"
                    label="75%"
                    onChange={(e: any) => {
                      setRegisterForm({
                        ...RegisterForm,
                        Parental_leave_percentage: e.target.value,
                      });
                    }}
                    checked={RegisterForm.Parental_leave_percentage === "75%"}
                  />
                  <Form.Check
                    value="25%"
                    type="radio"
                    aria-label="radio 2"
                    label="25%"
                    onChange={(e: any) => {
                      setRegisterForm({
                        ...RegisterForm,
                        Parental_leave_percentage: e.target.value,
                      });
                    }}
                    checked={RegisterForm.Parental_leave_percentage === "25%"}
                  />
                </Form.Group>
              </Form>
            </FlexWrapper>
            <FlexWrapper justifyContent="space-between" noMargin>
              <FormLabel>
                Specify the percentage, if different (for example, 80%)
              </FormLabel>
              <DropdownWrapper width="40">
                <Input
                  value={RegisterForm?.others}
                  placeholder={"State %"}
                  inputType={"text"}
                  onChange={(value: string) => {
                    setRegisterForm({
                      ...RegisterForm,
                      others: value,
                    });
                  }}
                />
              </DropdownWrapper>
            </FlexWrapper>

            <CardWrapper marigin="10px" width="100%" padding="0">
              <CardHeader>
                Provide brief details about who this leave relates to
              </CardHeader>
              <FlexWrapper justifyContent="space-between" noMargin>
                <FormLabel>First initial of the name</FormLabel>
                <DropdownWrapper width="40">
                  <Input
                    value={RegisterForm.child_first_name}
                    placeholder={"Provide initials"}
                    inputType={"text"}
                    onChange={(value: string) => {
                      setRegisterForm({
                        ...RegisterForm,
                        child_first_name: value,
                      });
                    }}
                  />
                </DropdownWrapper>
              </FlexWrapper>
              <FlexWrapper justifyContent="space-between" noMargin>
                <FormLabel>
                  Specify the year and month of the birth date
                </FormLabel>
                <DropdownWrapper width="40">
                  {/* <Input
                    value={RegisterForm.child_personal_number}
                    inputType={"text"}
                    placeholder={"YYYY/MM"}
                    onChange={(value: string) => {
                      setRegisterForm({
                        ...RegisterForm,
                        child_personal_number: value,
                      });
                    }}
                  /> */}
                  <DatePicker
                    selected={RegisterForm.child_personal_number}
                    dateFormat="yyyy/MM"
                    showMonthDropdown
                    showYearDropdown
                    yearDropdownItemNumber={50}
                    scrollableYearDropdown
                    placeholderText={"YYYY/MM"}
                    onChange={(value: any) => {
                      setRegisterForm({
                        ...RegisterForm,
                        child_personal_number: value,
                      });
                    }}
                    customInput={
                      <Input value={RegisterForm.child_personal_number} />
                    }
                  />
                </DropdownWrapper>
              </FlexWrapper>
              <FlexWrapper>
                <Form.Group>
                  <Form.Check
                    value=""
                    aria-label="radio 4"
                    label="Child not born yet"
                    onChange={() => {
                      setChecked((prev) => !prev);
                    }}
                    checked={checked}
                  />
                </Form.Group>
              </FlexWrapper>
              {checked ? (
                <>
                  <FlexWrapper justifyContent="space-between" noMargin>
                    <FormLabel>Approximate due date</FormLabel>
                    <DropdownWrapper width="40">
                      <DatePicker
                        selected={RegisterForm.appro_due_date}
                        dateFormat="yyyy/MM/dd"
                        showMonthDropdown
                        showYearDropdown
                        minDate={new Date()}
                        yearDropdownItemNumber={50}
                        scrollableYearDropdown
                        placeholderText={"YYYY-MM-DD"}
                        onChange={(value: any) => {
                          setRegisterForm({
                            ...RegisterForm,
                            appro_due_date: value,
                          });
                        }}
                        customInput={
                          <Input value={RegisterForm.appro_due_date} />
                        }
                      />

                      {/* <Input
                      placeholder="none"
                        value={RegisterForm.appro_due_date}
                        inputType={"date"}
                        onChange={(value: string) => {
                          setRegisterForm({
                            ...RegisterForm,
                            appro_due_date: value,
                          });
                        }}
                      /> */}
                    </DropdownWrapper>{" "}
                  </FlexWrapper>
                  <FlexWrapper justifyContent="space-between" noMargin>
                    <FormLabel>Additional comments</FormLabel>
                    <DropdownWrapper width="40">
                      <Input
                        value={RegisterForm.comment}
                        placeholder={"Write if applicable"}
                        inputType={"text"}
                        onChange={(value: string) => {
                          setRegisterForm({
                            ...RegisterForm,
                            comment: value,
                          });
                        }}
                      />
                    </DropdownWrapper>
                  </FlexWrapper>
                </>
              ) : null}
            </CardWrapper>
          </Modal>
        ) : null}
        <Modal
          show={showReason}
          sizeModal="sm"
          center={true}
          title="Reason"
          handleCancel={() => {
            setRegisterForm({
              ...RegisterForm,
              reason: "",
            });
            setShowReason(false);
          }}
          handleSubmit={() => {
            setShowReason(false);
          }}
        >
          <FlexWrapper justifyContent="space-between" noMargin>
            <DropdownWrapper width="85">
              <Input
                value={RegisterForm.reason}
                inputType={"textarea"}
                onChange={(value: string) => {
                  setRegisterForm({
                    ...RegisterForm,
                    reason: value,
                  });
                }}
              />
            </DropdownWrapper>
          </FlexWrapper>
        </Modal>
      </CardWraper>
      <ToastMessage
        show={hasResgisterError}
        onCloseHandler={() => {
          dispatch(updateHasRegisterError(false));
        }}
        bg="primary"
        top="auto"
        right="30%"
        message="Leave registration is only allowed based on your schedule. Go to 'My Schedule' to review the details."
      ></ToastMessage>
      <ToastMessage
        show={dateError}
        onCloseHandler={() => {
          setDateError(false);
        }}
        bg="primary"
        top="auto"
        right="30%"
        message="End date cannot be earlier than the Start date"
      ></ToastMessage>
    </PageWrapper>
  );
};

export default RegisterAbsence;
