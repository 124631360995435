import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const TrashIcon = styled(FontAwesomeIcon)`
  color: red;
  :hover {
    color: red;
  }
`;

export const DisabledIcon = styled(FontAwesomeIcon)`
  color: gray;
`;

export const EditIcon = styled(FontAwesomeIcon)`
  color: red;
  :hover {
    color: red;
  }
`;

export const SliderIcon = styled(FontAwesomeIcon)`
  font-size: 22px;
  margin-left: 8px;
`;
export const Reasonbar = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: auto;
  width: 400px;
  max-width: 400px;
  position: absolute;
  z-index: 999;
  // right: 0px;
  top: 60px;
  background-color: red;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 5px;
  border-width: 2px;
  border-color: #453737;
  border-style: solid;
`;
