export const teacherHeader = ["Name", "Email", "Organization-Type", "Action"];

export interface ModalProps {
  publish_to_external: boolean;
  publish_to_internal: boolean;
  is_draft: boolean;
  break_time: string;
  total_whrs: string;
  assigned_to_internal: string;
  assigned_to_external: string;
  vacancy_status: string;
}

export const initialValues = {
  publish_to_external: false,
  publish_to_internal: true,
  is_draft: false,
  break_time: "",
  total_whrs: "",
  assigned_to_internal: "",
  assigned_to_external: "",
  vacancy_status: "",
};

export const withoutLessonValues: any = {
  position: "",
  position_id: "",
  v_date: "",
  day: "",
  start_time: "",
  end_time: "",
  break_time: "",
  total_whrs: "",
  ins_id: "",
  uncovered_id: 0,
  discription: "",
  assigned_to_internal: 0,
  assigned_to_external: 0,
  absence_id: 0,
  vacancy_status: 0,
  report_start_time: "",
  report_end_time: "",
  report_break_time: "",
  report_total_whours: "",
  report_reason: "",
  publish_to_internal: "",
  publish_to_external: "",
  is_draft: 0,
  external_type: "",
  location: "",
  created_by: "",
  publish_to: "",
  publish_to_id: "",
  flexible_time: "",
  published_to_all_30min: true,
  choose_cons_20min: true,
  my_consultant: "",
  totalhrs: "",
  preparationTime: "",
  handoverTime: "",
  institute_type: "",
  assigned_from: 0,
  id: 1,
};
