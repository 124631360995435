import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EditCustomerStatus } from "./api";
import { initialState } from "./types";

export const InstituteSlice = createSlice({
  name: "Institute",
  initialState,
  reducers: {},
  extraReducers: {
    [EditCustomerStatus.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [EditCustomerStatus.fulfilled.toString()]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.isLoading = false;
      state.message = action.payload;
    },
    [EditCustomerStatus.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
  },
});

export default InstituteSlice.reducer;
