import api from "../../services";
import apiEndpoints from "../../const/apiEndpoints";
import {
  AbsentStaff,
  ApplicationProps,
  DeleteTypes,
  VacanciesApplicationProps,
} from "./types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import history from "../../const/history";
import ROUTES from "../../const/routes";

const showAlertForNetworkError = (error: any) => {
  alert(`Network Error:  ${error.message}`); // You can customize the alert message
};

export const getAbsentStaff = createAsyncThunk(
  "common/absentStaff",
  async (requestPayload: { ins_id?: string }): Promise<Array<AbsentStaff>> => {
    const response = await api.post(
      apiEndpoints.getAbsentStaff,
      requestPayload
    );
    return response.data.message;
  }
);

export const getMyConsultants = createAsyncThunk(
  "common/MyConsultants",
  async (requestPayload: { ins_id?: string }): Promise<any> => {
    const response = await api.post(
      apiEndpoints.getMyConsultants,
      requestPayload
    );
    return response.data.message;
  }
);

export const addMyConsultant = createAsyncThunk(
  "common/AddMyConsultants",
  async (requestPayload: {
    cons_id: string;
    unique_id?: string;
  }): Promise<any> => {
    const response = await api.post(
      apiEndpoints.addMyConsultant,
      requestPayload
    );
    if (response?.data) {
      history.push(ROUTES.INSTITUTE.MY_CONSULTANTS);
    }
    return response.data.message;
  }
);

export const getmyInsConsultant = createAsyncThunk(
  "cons/getcons",
  async (requestPayload: any): Promise<any> => {
    const response = await api.post(
      apiEndpoints.getmyinsConsultant,
      requestPayload
    );
    return response.data.message;
  }
);

export const getTodayCoverCount = createAsyncThunk(
  "dashboard/TodayCoverCount",
  async (requestPayload: {
    ins_id?: string;
    status: string;
    date: string;
  }): Promise<any> => {
    const response = await api.post(apiEndpoints.getCoverCount, requestPayload);
    return response.data.count;
  }
);

export const getTodayUnCoverCount = createAsyncThunk(
  "dashboard/TodayUnCoverCount",
  async (requestPayload: {
    ins_id?: string;
    status: string;
    date: string;
  }): Promise<any> => {
    const response = await api.post(apiEndpoints.getCoverCount, requestPayload);
    return response.data.count;
  }
);

export const getTodayLessonCount = createAsyncThunk(
  "dashboard/TodayLessonCount",
  async (requestPayload: {
    ins_id?: string;
    status: string;
    date: string;
  }): Promise<any> => {
    const response = await api.post(
      apiEndpoints.getLessonCount,
      requestPayload
    );
    return response.data.count;
  }
);

export const getTodayUnLessonCount = createAsyncThunk(
  "dashboard/TodayUnLessonCount",
  async (requestPayload: {
    ins_id?: string;
    status: string;
    date: string;
  }): Promise<any> => {
    const response = await api.post(
      apiEndpoints.getLessonCount,
      requestPayload
    );
    return response.data.count;
  }
);

export const getTodayAbsenceCount = createAsyncThunk(
  "dashboard/TodayAbsenceCount",
  async (requestPayload: { ins_id?: string; date: string }): Promise<any> => {
    const response = await api.post(
      apiEndpoints.getAbsentCount,
      requestPayload
    );
    return response.data.count;
  }
);

/////////////////////////

export const getCoverCount = createAsyncThunk(
  "dashboard/CustomCoverCount",
  async (requestPayload: {
    ins_id?: string;
    status: string;
    date: string;
  }): Promise<any> => {
    const response = await api.post(apiEndpoints.getCoverCount, requestPayload);
    return response.data.count;
  }
);

export const getUnCoverCount = createAsyncThunk(
  "dashboard/CustomUnCoverCount",
  async (requestPayload: {
    ins_id?: string;
    status: string;
    date: string;
  }): Promise<any> => {
    const response = await api.post(apiEndpoints.getCoverCount, requestPayload);
    return response.data.count;
  }
);

export const getLessonCount = createAsyncThunk(
  "dashboard/CustomLessonCount",
  async (requestPayload: {
    ins_id?: string;
    status: string;
    date: string;
  }): Promise<any> => {
    const response = await api.post(
      apiEndpoints.getLessonCount,
      requestPayload
    );
    return response.data.count;
  }
);

export const getUnLessonCount = createAsyncThunk(
  "dashboard/CustomUnLessonCount",
  async (requestPayload: {
    ins_id?: string;
    status: string;
    date: string;
  }): Promise<any> => {
    const response = await api.post(
      apiEndpoints.getLessonCount,
      requestPayload
    );
    return response.data.count;
  }
);

export const getAbsenceCount = createAsyncThunk(
  "dashboard/CustomAbsenceCount",
  async (requestPayload: { ins_id?: string; date: string }): Promise<any> => {
    const response = await api.post(
      apiEndpoints.getAbsentCount,
      requestPayload
    );
    return response.data.count;
  }
);

////tommorow

export const getTommorowCoverCount = createAsyncThunk(
  "dashboard/TommorowCoverCount",
  async (requestPayload: {
    ins_id?: string;
    status: string;
    date: string;
  }): Promise<any> => {
    const response = await api.post(apiEndpoints.getCoverCount, requestPayload);
    return response.data.count;
  }
);

export const getTommorowUnCoverCount = createAsyncThunk(
  "dashboard/TommorowUnCoverCount",
  async (requestPayload: {
    ins_id?: string;
    status: string;
    date: string;
  }): Promise<any> => {
    const response = await api.post(apiEndpoints.getCoverCount, requestPayload);
    return response.data.count;
  }
);

export const getTommorowLessonCount = createAsyncThunk(
  "dashboard/TommorowLessonCount",
  async (requestPayload: {
    ins_id?: string;
    status: string;
    date: string;
  }): Promise<any> => {
    const response = await api.post(
      apiEndpoints.getLessonCount,
      requestPayload
    );
    return response.data.count;
  }
);

export const getTommorowUnLessonCount = createAsyncThunk(
  "dashboard/TommorowUnLessonCount",
  async (requestPayload: {
    ins_id?: string;
    status: string;
    date: string;
  }): Promise<any> => {
    const response = await api.post(
      apiEndpoints.getLessonCount,
      requestPayload
    );
    return response.data.count;
  }
);

export const getTommorowAbsenceCount = createAsyncThunk(
  "dashboard/TommorowAbsenceCount",
  async (requestPayload: { ins_id?: string; date: string }): Promise<any> => {
    const response = await api.post(
      apiEndpoints.getAbsentCount,
      requestPayload
    );
    return response.data.count;
  }
);

/////////////////////////////
export const getChooseConsultant = createAsyncThunk(
  "vacancy/chooseConsultant",
  async (): Promise<any> => {
    const response = await api.get(apiEndpoints.chooseConsultant);
    return response.data.message;
  }
);

export const getGraphCountInst = createAsyncThunk(
  "count/graphCount",
  async (requestPayload: any): Promise<any> => {
    const response = await api.post(
      `${apiEndpoints.getgraphCountIns}`,
      requestPayload
    );
    return response.data.count;
  }
);

export const CommonDelete = createAsyncThunk(
  "common/delete",
  async (requestPayload: DeleteTypes): Promise<any> => {
    const data = { id: requestPayload.id };
    const response = await api.post(`${requestPayload.endPoint}`, data);
    return response.data.message;
  }
);

export const handleOpenApplication = createAsyncThunk(
  "Openapplication/register",
  async (requestPayload: ApplicationProps, { rejectWithValue }) => {
    try {
      const response = await api.post(
        apiEndpoints.openapplicationregister,
        requestPayload
      );
      return response.status;
    } catch (error) {
      showAlertForNetworkError(error);
      return rejectWithValue("Network Error");
    }
  }
);

export const Getwebsitead = createAsyncThunk(
  "Websitead/getad",
  async (requestPayload: { type: number }): Promise<any> => {
    const response = await api.post(apiEndpoints.getwebsitead, requestPayload);
    return response?.data?.message;
  }
);

export const handleVacanciesApplication = createAsyncThunk(
  "VacanciesApplication/register",
  async (
    {
      values,
      advertisement_id,
    }: { values: VacanciesApplicationProps; advertisement_id: any },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.post(apiEndpoints.VacanciesApplication, {
        ...values,
        advertisement_id: advertisement_id,
      });
      return response.data.message;
    } catch (error) {
      showAlertForNetworkError(error);
      return rejectWithValue("Network Error");
    }
  }
);

export const NewsEmail = createAsyncThunk(
  "News/Email",
  async (requestPayload: { email: any }): Promise<any> => {
    const response = await api.post(apiEndpoints.NewsEmail, requestPayload);
    return response?.data?.message;
  }
);

export const createTopup = createAsyncThunk(
  "topup/createTopup",
  async (requestPayload: {
    ins_id?: string;
    total_hours: any;
    used_hours: any;
    remaining_hours: any;
    requesting_hours: string;
  }): Promise<any> => {
    const response = await api.post("top_up", requestPayload);
    return response.data.message;
  }
);

export const getInsHours = createAsyncThunk(
  "topup/getTopup",
  async (requestPayload: { ins_id?: string }): Promise<any> => {
    const response = await api.post("get_my_hours", requestPayload);
    return response.data.message;
  }
);

export const getPositions = createAsyncThunk(
  "positions/getpositions",
  async (): Promise<any> => {
    const response = await api.get("Get_Postion");
    return response.data.message;
  }
);
