export const OccupitationalList = [
  {
    id: "01",
    name: "Education",
  },

  {
    id: "02",
    name: "Social Care",
  },
  {
    id: "03",
    name: "Office and administration",
  },
  {
    id: "04",
    name: "IT",
  },
  {
    id: "05",
    name: "Other",
  },
];

export const position = [
  {
    id: "01",
    name: "Teaching positions",
  },
  {
    id: "02",
    name: "Non-teaching positions",
  },
];

export const teaching = [
  {
    id: "01",
    name: "Preschool teacher",
  },
  {
    id: "02",
    name: "School teacher",
  },
  {
    id: "03",
    name: "Gymnasium teacher",
  },
  {
    id: "04",
    name: "Special education teacher",
  },
];

export const non_teaching = [
  {
    id: "01",
    name: "Career counsellor",
  },
  {
    id: "02",
    name: "Fritidsledare",
  },
  {
    id: "03",
    name: "School nurse",
  },
  {
    id: "04",
    name: "School counsellor",
  },
  {
    id: "5",
    name: "Librarian",
  },
  {
    id: "6",
    name: "Administration",
  },
  {
    id: "7",
    name: "Student care",
  },
  {
    id: "8",
    name: "Leadership",
  },
  {
    id: "9",
    name: "IT",
  },
  {
    id: "10",
    name: "Other",
  },
];

export const subjectList = [
  {
    id: "01",
    name: "English",
  },
  {
    id: "02",
    name: "Mathematics",
  },
  {
    id: "03",
    name: "Science",
  },
  {
    id: "04",
    name: "Social Studies",
  },
  {
    id: "05",
    name: "Swedish",
  },
  {
    id: "06",
    name: "PE",
  },
  {
    id: "07",
    name: "Modern Languages",
  },
  {
    id: "08",
    name: "Art",
  },
  {
    id: "09",
    name: "Home Ec",
  },
  {
    id: "10",
    name: "Textiles",
  },
  {
    id: "11",
    name: "Woodwork",
  },
  {
    id: "12",
    name: "Music",
  },
  {
    id: "13",
    name: "Life skills",
  },
  {
    id: "14",
    name: " Computer skills",
  },
  {
    id: "15",
    name: "Class teacher",
  },
];

export const ageGrpList = [
  {
    id: "01",
    name: "Preschool",
  },
  {
    id: "02",
    name: "Grade F-3",
  },
  {
    id: "03",
    name: "Grade 4-6",
  },
  {
    id: "04",
    name: "Grade 7-9",
  },
  {
    id: "05",
    name: "Gymnasium",
  },
];

export const avalibalityList = [
  {
    id: "01",
    name: "Anytime ",
  },
  {
    id: "01",
    name: "Specify date",
  },
];

export const socialOccupationState = [
  {
    id: "01",
    name: "Personal assistant",
  },
  {
    id: "02",
    name: "Care-giver, Nurse",
  },
  {
    id: "03",
    name: "Counsellor",
  },
  {
    id: "04",
    name: "Other ",
  },
];
