import React, { ReactElement } from "react";
import { Movetop, PageWrapper } from "../../components";

const PrivacyPolicy = (): ReactElement => {
  Movetop();
  return (
    <div>
      <PageWrapper style={{ marginTop: "1%" }}>
        <div style={{ paddingLeft: "1%" }}>
          <h5 style={{ fontSize: "25px", color: "darkblue" }}>
            Integritetspolicy
          </h5>
          <p className="mt-4">
            I denna integritetspolicy framgår hur DoHR hanterar dina
            personuppgifter och använder dem i enlighet med gällande
            Dataskyddsförordning. Vi kommer att beskriva vad vi använder dina
            uppgifter till, vem som kan komma åt dem och under vilka villkor de
            kan göra det. Dessutom kommer vi beskriva dina rättigheter gentemot
            oss. <br />
            <br /> Om du är en besökare på vår webbsida, har registrerat dig i
            vår mobilapp/webbaserad app som kund, vikarie, konsult eller annan
            intressent, eller har ingått avtal om anställning eller
            konsultuppdrag med oss, kommer Dimensions of HR (DoHR) att behandla
            dina personuppgifter på ett säkert sätt.
            <br />
            <br />
            Denna integritetspolicy gäller inte behandling av personuppgifter
            som sker på externa webbplatser/plattformar som inte ägs eller
            administreras av DoHR där vi kan komma att marknadsföra/annonsera
            våra tjänster.
          </p>
          <h5>PERSONUPPGIFTSANSVARIG</h5>
          <p className="mt-4">
            DoHR är personuppgiftsansvarig för behandling av dina
            personuppgifter. Om du har några frågor om hur DoHR behandlar dina
            personuppgifter, vänligen mejla oss på support@dohr.io När en kund
            anlitar dig genom oss är denne ansvarig för att hantera dina
            personuppgifter.
            <br />
            <br />
            Vi vidtar åtgärder för att skydda dina personuppgifter när du
            överför dem via vår tjänst. Vi uppdaterar och testar kontinuerligt
            vår säkerhetsteknik och begränsar åtkomsten till dina
            personuppgifter till endast de anställda som behöver veta den
            informationen för att kunna tillhandahålla tjänsten till dig. Du
            ansvarar för att dina inloggningsuppgifter är säkra och att du inte
            delar dem med någon annan.
          </p>
          <h5>VEMS PERSONUPPGIFTER BEHANDLAR VI?</h5>
          <p className="mt-4">
            <span>ANSTÄLLDA</span>- i denna grupp ingår personer som är
            anställda i vår verksamhet.
            <br />
            <br />
            <span>ANVÄNDARE AV MOBIL</span>- ELLER WEBBASERADE APP – i denna
            grupp ingår personer som använder våra tjänster via mobilapp och
            webbaserad app. <br />
            <br />
            <span>ARBETSSÖKANDE/KANDIDATER/KONSULTER</span>- i denna grupp ingår
            personer som har registrerat sig på vår webbsida som arbetssökande,
            samt personer som vi har kommit i kontakt med angående jobb hos
            DoHR. I denna grupp ingår också inhyrda konsulter. <br />
            <br />
            <span>LEVERANTÖRER/UNDERLEVERANTÖRER</span>-i denna grupp ingår
            personer som jobbar på företag och organisationer som DoHR köper
            tjänster eller produkter av. <br />
            <br />
            <span>KUNDER OCH POTENTIELLA FRAMTIDA KUNDER –</span> – i denna
            grupp ingår personer som arbetar på företag och organisationer som
            köper tjänster eller produkter från DoHR, samt personer vi kommit i
            kontakt med om eventuella framtida samarbeten.
          </p>

          <h5>VILKA PERSONUPPGIFTER BEHANDLAS OCH VAD ANVÄNDS DEM TILL?</h5>
          <p className="mt-4">
            DoHR är enligt lag skyldig att berätta om de personuppgifter vi
            behandlar, för vilka ändamål och utifrån vilka rättsliga grunder vi
            anser att det är nödvändigt. Vi behandlar dina personuppgifter för
            att vi ska kunna genomföra våra bemannings- och rekryteringsuppdrag.
            Vi måste samla in vissa uppgifter enligt lag, och i vissa fall
            kommer vi att behöva behandla andra uppgifter för andra ändamål. Vi
            kommer att informera dig om detta separat.
          </p>
          <h5>Anställda/Konsulter/Användare av mobilapp/webbaserad app</h5>
          <p className="mt-4">
            Om du har konsultavtal med DoHR behöver vi behandla dina
            personuppgifter för att fullgöra våra avtalsförpliktelser med dig.
            När du är anställd hos DoHR behöver vi behandla följande
            personuppgifter för att följa svensk arbetsrätt och administrera
            anställningsförhållande med dig som anställd.
            <br />
            <br />
            Vi behöver behandla följande personuppgifter för att kunna betala ut
            din lön/ersättning, redovisa skatt och sociala avgifter, betala
            pensionsavgifter till försäkringsbolag samt kontakta dig om
            relaterade jobb:
            <ul>
              <li>
                Namn, personnummer, adress, mejladress, telefonnummer, foto
              </li>
              <li>
                Uppgifter för eventuell kontroll enligt lag (t.ex. legitimation
                enligt Socialstyrelsens regelverk).
              </li>
              <li>
                Anteckning om giltigt utdrag ur polisens belastningsregister
              </li>
              <li>
                Kontouppgifter, uppgift om lön/ersättning, uppgift om pension
                för anställda
              </li>
            </ul>
            <br />
            För att uppfylla anställningsavtal med anställda behandlas
            personuppgifter såsom bankkontouppgifter, anställningsinformation,
            lön, arbetsprestationer och bedömningar. Anställdas personuppgifter
            relaterade till sjukdom, barnomsorg, och liknande används för att
            beräkna deras sjuklön och andra frånvarorättigheter.
            Tidsrapporteringsuppgifter behandlas för löne-/ersättningshantering
            för anställda, kundfakturering, reklamationshantering,
            anställningsadministration och för bokföringsändamål. Konsulternas
            frånvarouppgifter behandlas endast i förhållande till avtal med
            konsulter och kunder. Uppgifter om varför konsulten var frånvarande
            sparas inte. Foto i DoHR mobil/webbaserad app används för personlig
            identifiering i säkerhetsrelaterade sammanhang.
            <br />
            <br />
            Vid en olycka eller annat akut ärende som involverar de medarbetarna
            eller konsulterna behöver vi kunna kontakta deras nödkontakter,
            därmed samlas och behandlas deras uppgifter såsom namn och
            telefonnummer
            <br />
            <br />
            Vi samlar in uppgifter om medarbetarnas och konsulternas
            prestationer för att kunna göra lämplighetsbedömningar för olika
            uppdrag hos våra kunder samt för referenser. De uppgifterna som är
            kopplade till medarbetarnas och konsulternas handlingar kan sparas
            för att behandla klagomål från kunder.
            <br />
            <br />
            Skollagen kräver att de som jobbar med barn och unga har ett giltigt
            utdrag ur polisens belastningsregister. Denna information kommer
            inte att registreras och den kommer inte att sparas.
            <br />
            <br />
            Vi kan även samla information om hur du använder våra system såsom
            din IP-adress, webbläsare, och annan kommunikationsdata, samt de
            webbplatser du besöker. Genom att tillhandahålla denna information
            kommer vi kunna förbättra användarupplevelsen av våra system samt
            deras säkerhet. Ditt samtycke är den rättsliga grunden för denna typ
            av behandling.
          </p>
          <h5>Arbetssökande/Kandidater</h5>
          <p className="mt-4">
            Om du registrerar dig som arbetssökande på vår webbsida och vill
            delta i vår rekryteringsprocess kommer vi att behöva samla och
            behandla följande personuppgifter för att kunna utvärdera din
            kandidatur:
            <br />
            <br />
            <ul>
              <li>
                Namn, födelsedatum, adress, epost, telefonnummer, personnummer
              </li>
              <li>
                Kopior på legitimation, utbildningsbevis, intyg, identifikation,
                uppehålls- och arbetstillstånd
              </li>
              <li>
                Erfarenheter, utbildning, kompetenser, språkkunskaper, och
                övriga personuppgifter som du skickar in i samband med din
                ansökan
              </li>
              <li>
                Utdrag ur belastningsregister – eventuell information ur
                utdraget kommer inte att registreras och utdraget kommer inte
                att sparas
              </li>
              <li>
                Uppgifter för eventuell lagstadgad kontroll (t.ex., legitimation
                enligt Socialstyrelsens regelverk)
              </li>
              <li>Referenser och anteckningar från intervju</li>
            </ul>
            <br />
            Vi kommer att använda dessa uppgifter för att utvärdera dina
            kvalifikationer och förmågor, och för att bedöma din lämplighet för
            de jobb eller tjänster vi erbjuder för våra kunder och hos oss. Den
            rättsliga grunden för att behandla övriga uppgifter är att det är
            nödvändigt för att vi ska kunna fullgöra vårt avtal med dig, vilket
            är kopplat till din registrering hos oss
          </p>
          <h5>
            Kunder/Potentiella kunder, Leverantörer, Underleverantörer,
            Användare av mobilapp/webbaserad app
          </h5>
          <p className="mt-4">
            Vi behöver behandla vissa personuppgifter av våra kunder,
            potentiella kunder, leverantörer samt underleverantörer för att
            upprätthålla vår professionella relation och för
            marknadsföringsändamål. Detta inkluderar data som ditt namn,
            kontaktinformation och annan information som kan hjälpa oss att
            kontakta eller förse dig med våra tjänster. Vi behandlar våra
            kunders uppgifter för att kunna uppfylla vårt avtal med dem.
            <br />
            <br />
            När du som anställd hos vår kund använder vår webbaserad app kan vi
            även samla in och behandla dina personuppgifter om din frånvaro.
            Detta kommer att hjälpa oss att tillhandahålla statistisk
            information till vår kund och hjälpa dem att administrera din
            sjuklön och andra frånvarorättigheter. Det finns inga registrerade
            uppgifter om frånvaron, såsom uppgifter om din hälsa eller typ av
            sjukdom. Vi kommer att samla in och bearbeta information om dig,
            inklusive ditt namn, arbetstelefonnummer och e-postadress. Foto i
            DoHR mobil/webbaserad app används för personlig identifiering
            <br />
            <br />
            Vi kan även samla in information om din användning av vår mobilapp
            eller webbaserad app när du använder dem för att förbättra
            användarupplevelse och säkerheten.
          </p>
          <h5>HUR SAMLAR VI IN PERSONUPPGIFTERNA?</h5>
          <p className="mt-4">
            DoHR samlar in personuppgifter som du lämnar när du registrerar dig
            för att använda våra tjänster, när du söker ett jobb hos oss eller
            när du kontaktar oss på något sätt
            <br />
            <br />
            Vi samlar också personuppgifter om dig som kommer dessutom från
            ytterligare källor, såsom referenser eller olika
            bakgrundskontroller.
          </p>
          <h5>VILKA LÄMNAR VI DINA UPPGIFTER TILL?</h5>
          <p className="mt-4">
            Om du har ett uppdrag hos någon av våra kunder kommer vi att förse
            kunden med relevant information om dig för att kunna fullgöra vårt
            avtal med kunden. Kunden är den som är ytterst ansvarig för de
            personuppgifter som vi har lämnat till dem när du har ett uppdrag
            hos kunden.
            <br />
            <br />
            Vi kan också behöva dela dina personuppgifter med våra leverantörer
            och underleverantörer för att hjälpa dem att tillhandahålla tjänster
            till oss. Dessa företag får endast använda dina personuppgifter för
            att behandla dem för DoHR, och vi ser till att dina uppgifter
            hanteras säkert och korrekt.
            <br />
            <br />
            DoHR kan behöva dela dina personuppgifter med de myndigheter som vi
            enligt lag är skyldiga att göra det med, till exempel
            Försäkringskassan eller Skatteverket.
          </p>
          <h5>VAR BEHANDLAR VI DINA UPPGIFTER?</h5>
          <p className="mt-5">
            DoHR behandlar dina uppgifter endast inom Europeiska unionen eller
            Europeiska ekonomiska samarbetsområdet., men de lagras på plattform
            i Irland.
            <br />
            <br />
            Dina personuppgifter kan komma att lagras i vår mobil- och
            webbaseradapp för att ge möjlighet att publicera eller acceptera
            lediga jobb. Vi lagrar även dina personuppgifter, såsom
            löneutbetalningar, deklarationer, kontaktuppgifter och annan
            information, i andra affärssystem för att kunna fullgöra våra
            skyldigheter som arbetsgivare och företagare.
          </p>
          <h5>HUR LÄNGE SPARAR VI DINA UPPGIFTER?</h5>
          <h5>Anställda/Konsulter/Användare av mobil app/webbaserad app</h5>
          <p className="mt-4">
            Dina personuppgifter kommer att sparas så länge som det är
            nödvändigt för att vi ska kunna utföra våra uppgifter i samband med
            att hantera dina avtal med oss, eller så länge som personuppgifterna
            behövs för det specifika ändamål för vilket de samlades in.
            <br />
            <br />
            Dina kontaktuppgifter, anställningsavtal och annan relevant
            information kommer att behållas efter att ditt kontrakt med oss har
            löpt ut så att vi kan ge referenser till eventuella nya arbetsgivare
            eller entreprenörer som du kan ha och för andra administrativa
            behov. Vi kommer att behålla den information vi är skyldiga att
            spara (så som ditt anställningsavtal, olika affärshändelser såsom
            uppgifter om lön, tidrapporter, deklarationer, med mera) tills vi
            kan uppfylla våra juridiska skyldigheter under avtalstid och i 10 år
            därefter. I de allra flesta fall kommer vi att inte spara övriga
            personuppgifter under längre tid än 1 år från och med avtalets
            upphörande, men under vissa förutsättningar kan vi behålla dina
            uppgifter under en längre tid än så. Nödkontakter sparas bara under
            anställningstid. Vi kommer inte att behålla dina personuppgifter för
            potentiella framtida anställningsändamål om du inte ger oss
            tillåtelse att göra det.
            <br />
            <br />
            Ditt konto kommer att förbli aktivt så länge du använder det.
            Vänligen informera oss via e-post om du inte längre vill ha ett
            konto hos oss. Såvida du inte informerar oss om att du vill få ditt
            konto återaktiverad, kommer ditt konto att bli vilande och all data
            kommer att raderas efter ytterligare 30 dagar.
            <br />
            <br />
            Under den tid som kunden har rätt att framföra klagomål på tjänsten
            relaterad till uppdraget som du har varit anställd för kommer vi
            även att föra register över relevanta dokument som använts som
            underlag för att utvärdera din kompetens och lämplighet för olika
            uppdrag med våra kunder
          </p>
          <h5>Kandidater</h5>
          <p className="mt-4">
            DoHR sparar dina uppgifter som du har lämnat in till oss under
            ansökning/rekryteringsprocess i 2 år enligt Diskrimineringslagen. Du
            kan be om att bli borttagen från vår datas när som helst, och vi
            kommer att göra det genom att radera dina personuppgifter.
          </p>
          <h5>Kunder/Leverantörer/Underleverantör</h5>
          <p className="mt-5">
            Vi behåller dina uppgifter så länge det krävs enligt bokföringslagen
            om du är leverantör, underleverantör eller kund till DoHR. Avtal
            samt uppgifter om olika affärshändelser, såsom lön, fakturor,
            bokslut, deklarationer, med mera sparar vi under avtalstiden och
            därefter för 10 år
            <br />
            <br />
            Vi sparar uppgifter om dig som kund, leverantör eller
            underleverantör under den tid som avtalet med dig gäller och under
            en skälig tid därefter. Det innebär i de allra flesta fall att vi
            inte sparar dina övriga personuppgifter under längre tid än ett år
            från och med avtalets upphörande, men under vissa förutsättningar
            kan vi behålla dina uppgifter under en längre tid än så. Det gäller
            exempelvis när det krävs enligt lag eller när uppgifterna kan
            behövas för att fastställa, utöva och bevaka rättsliga anspråk.
            <br />
            <br />
            Vi sparar uppgifter så länge det är till hjälp för att upprätthålla
            kommunikationen med dem som kontaktar oss via våra webbsida, mobil-
            och webbaserad app, via sociala medier, per telefon eller
            personligen.
          </p>
          <h5>DINA RÄTTIGHETER</h5>
          <p className="mt-4">
            De rättigheter du har i samband med behandlingen av dina
            personuppgifter beskrivs nedan. Vänligen kontakta oss på
            support@dohr.io om du har några frågor angående dina rättigheter.
            <br />
            <br />
            RÄTT TILL TILLGÅNG - Du har rätt att veta hur vi använder dina
            personuppgifter och hur vi använder den information vi har om dig.
            <br />
            <br />
            RÄTT TILL RÄTTELSE, RADERING OCH ÅTERKALLANDE AV SAMTYCKE - Du har
            rätt att begära att felaktig eller ofullständig information om dig
            korrigeras eller kompletteras. Om dina personuppgifter är inte
            längre aktuella av anledningen till att vi samlade in den, har du
            rätt att begära att vi raderar dem. Om det inte finns något lagkrav
            på att vi ska behålla dem kommer DoHR att radera dina
            personuppgifter på din begäran. Du har rätt att återkalla ditt
            samtycke om behandling av dina personuppgifter, men detta påverkar
            inte den rättsliga giltigheten av den behandling som skedde innan du
            drog tillbaka ditt samtycke.
            <br />
            <br />
            RÄTT TILL DATAPORTABILITET - Du har rätt att få dina personuppgifter
            från oss på ett strukturerat, allmänt accepterat och maskinläsbart
            sätt. Om behandlingen av dinapersonuppgifter är automatiserad eller
            är beroende av ditt samtycke har du rätt att begära att den överförs
            till en annan personuppgiftsansvarig.
            <br />
            <br />
            RÄTT ATT GÖRA BEGRÄNSNING AV BEHANDLING AV DINA PERSONUPPGIFTER - Du
            har rätt att begära att behandlingen av dina personuppgifter
            begränsas under vissa omständigheter.
            <br />
            <br />
            RÄTT ATT GÖRA INVÄNDNINGAR MOT ANVÄNDNING AV PERSONUPPGIFTER - Du
            har rätt att invända hur dina personuppgifter behandlas under vissa
            omständigheter. <br />
            <br />
            RÄTT ATT GÖRA INVÄNDNINGAR MOT ANVÄNDNING AV PERSONUPPGIFTER - Du
            har rätt att invända hur dina personuppgifter behandlas under vissa
            omständigheter. <br />
            <br /> RÄTT ATT INGE KLAGOMÅL - Du har rätt att lämna in ett
            klagomål till Integritetsskyddsmyndigheten om hur dina
            personuppgifter behandlas. Vänligen kontakta DoHR på support@dohr.io
            om du avser att skicka in en begäran om ett registerutdrag,
            dataportabilitet, rättelse, radering, invändning eller begränsning.
            RÄTT ATT INGE KLAGOMÅL - Du har rätt att lämna in ett klagomål till
            Integritetsskyddsmyndigheten om hur dina personuppgifter behandlas.{" "}
            <br />
            <br />
            Vänligen kontakta DoHR på support@dohr.io om du avser att skicka in
            en begäran om ett registerutdrag, dataportabilitet, rättelse,
            radering, invändning eller begränsning.
          </p>
          <h5>ÄNDRINGAR I INTEGRITETSPOLICYN</h5>
          <p className="mt-4">
            Vi förbehåller oss rätten att ändra denna integritetspolicy när som
            helst. Den senaste versionen av policyn finns på vår hemsida.
          </p>
          <h5 style={{ fontSize: "25px", color: "darkblue" }}>
            Användarvillkor
          </h5>
          <h5>INLEDNING</h5>
          <p className="mt-4">
            Genom att besöka och/eller använda våra tjänster på vår webbplats,
            webbaserad app eller mobilapp godkänner du våra användarvillkor
          </p>
          <h5>VÅRA TJÄNSTER</h5>
          <p className="mt-4">
            DoHR tillhandahåller bemanningstjänster genom våra mobil- och
            webbaserad appar. Genom att anmäla ditt intresse och skapa ett
            användarkonto får du tillgång till dessa tjänster. DoHR erbjuder
            också rekryteringstjänster med hjälp av vår webbaserad app och
            hemsida. Du kan söka jobb på egen hand eller skapa jobbevakningar om
            du skapar ett konto hos oss
            <br />
            <br />
            Vår avsikt är att tjänsterna ska vara tillgängliga och att
            eventuella fel åtgärdas snabbt och effektivt. DoHR lovar inte felfri
            serviceleverans eller att det inte kommer att uppstå några
            störningar, förseningar eller andra misstag av något slag. Ditt
            konto kommer att förbli aktivt så länge du använder det. Vänligen
            informera oss via e-post om du inte längre vill ha ett konto hos
            oss. Såvida du inte informerar oss om att du vill få ditt konto
            återaktiverad, kommer ditt konto att bli vilande och all data kommer
            att raderas efter ytterligare 30 dagar
          </p>
          <h5>ANVÄNDARKONTO OCH ANSVAR</h5>
          <p className="mt-4">
            Du måste registrera ett användarkonto för att kunna använda våra
            tjänster. Du kan använda vår mobilapp, webbaserad app eller hemsida
            för att åstadkomma detta. Du ansvarar för att tillhandahålla korrekt
            information och hålla den uppdaterad. För att du ska kunna skapa ett
            konto och använda våra tjänster måste du vara äldre än 18 år. Vi
            förbehåller oss rätten att vägra att göra innehåll tillgängligt om
            det bryter mot våra användarvillkor, tillämpliga lagar eller
            riktlinjer
            <br />
            <br />
            Du är ansvarig för att se till att ditt konto används för vad våra
            tjänster avser. Du är ansvarig för att hålla din användarinformation
            säker och oåtkomligt för andra personer. Om du tappar bort eller
            avslöjar dina användaruppgifter, frånsäger sig DoHR allt ansvar för
            eventuell skada eller förlust.
            <br />
            <br />
            Genom att mejla oss på support@dohr.io kan du när som helst välja
            att avregistrera ditt konto hos oss.
          </p>
          <h5>BEHANDLING AV PERSONUPPGIFTER</h5>
          <p className="mt-4">
            {" "}
            Dina personuppgifter kommer att behandlas av DoHR i enlighet med vår
            integritetspolicy.
          </p>
          <h5>ÄNDRING AV VILLKOR</h5>
          <p className="mt-4">
            DoHR förbehåller sig rätten att när som helst uppdatera innehållet i
            våra tjänster och dessa villkor utan föregående meddelande och tar
            inget ansvar för eventuella konsekvenser av sådana ändringar. Om du
            fortsätter att använda våra tjänster efter ändringar eller
            uppdateringar, samtycker du till dem.
          </p>
          <h5 style={{ fontSize: "25px", color: "darkblue" }}>
            Cookies policy
          </h5>
          <p className="mt-4">
            Cookie är en liten fil som lagras i din webbläsare eller din dator
            när du öppnar en webbsida. Vi använder cookies på vår hemsida för
            att förbättra användarupplevelse och säkerhet samt göra vissa
            anpassningar för våra användare.
            <br />
            <br />
            Vi använder statistik för att förbättra användarupplevelsen på vår
            hemsida och att spåra hur vår webbplats används, därför behöver vi
            ditt tillstånd att använda cookies för statistik. Vi använder också
            ett antal cookies för att säkerställa att vår hemsida fungerar
            korrekt och att dina användarpreferenser kan spåras. Dessa cookies
            underlättar ditt besök och navigering och kan placeras utan ditt
            samtycke.
            <br />
            <br />
            För att marknadsföra vår hemsida har vi integrerat innehåll från
            Facebook och Instagram. Vi också använder liknande cookies för
            marknadsföring. Dessa cookies kan vara spårningscookies som används
            för att spåra användare av denna eller andra webbplatser eller för
            lokal lagring.
            <br />
            <br />
            När du första gången besöker vår hemsida kommer du att få ett
            meddelande om cookies. Du samtycker till vår användning av cookies
            när du väljer alternativet "Ok". Du kan alltid inaktivera eller
            radera cookies i din webbläsare vilket kan leda till att vår
            webbplats inte fungerar som d et ska. Observera att om du väljer att
            radera cookies i din webbläsare kommer du att bli ombedd att ge ditt
            samtycke när du besöker vår hemsida igen. Se hjälpdelen av din
            webbläsare för att läsa mer om hur du kan inaktivera och/eller
            radera cookies.
          </p>
        </div>
      </PageWrapper>
    </div>
  );
};

export default PrivacyPolicy;
