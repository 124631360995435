import { Button, Card, Table } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "../../../const/theme";

interface AdditionalProps {
  background?: string;
  space?: string;
}

export const MobileWrapper = styled.section<AdditionalProps>`
  height: auto;
  padding: 7vw 0px 0px 4vw;
  display: flex;
  justify-content: space-between;
  background: ${({ background }) =>
    background ? background : colors.darkBlue};
`;

export const Container = styled.div<AdditionalProps>`
  width: 55%;
  height: auto;
  padding: ${({ space }) => (space ? space : "9vh 0px 4vh 3vw")};
  @media (max-width: 550px) {
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
  }
`;

export const Header = styled.h2`
  font-size: 2.5vw;
  font-weight: 700;
  color: ${({ color }) => (color ? color : "white")};
  @media (max-width: 500px) {
    font-size: 20px;
  }
`;

export const JobSeekerHeader = styled.p`
  margin-bottom: 0.5vw;
  text-align: center;
  color: ${colors.white};
  font-size: 4vw;
  font-weight: 700;
  line-height: 1.2;
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  background-image: linear-gradient(
    -225deg,
    #473680 0%,
    #44107a 29%,
    #ff1361 67%,
    #0b06fa 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 4s linear infinite;
  @keyframes textclip {
    to {
      background-position: -200% center;
    }
  }
`;

interface Props {
  centered?: boolean;
  width?: string;
}

export const Para = styled.h5<Props>`
  width: ${({ width }) => (width ? width : "70vw")};
  text-align: ${({ centered }) => (centered ? "center" : "left")};
  color: ${({ color }) => (color ? color : "white")};
  font-size: 1.4vw;
  line-height: 1.5;
  margin-top: 2vw;
  @media (max-width: 500px) {
    width:100%;
    font-size: 10px;
  }
`;

interface ImageProps {
  mt?: string;
}
export const MobileImageWrapper = styled.div<ImageProps>`
  padding-top: 0px;
  margin-top: ${({ mt }) => (mt ? mt : "-2vw")};
`;

export const Image = styled.img`
  width: 42vw;
  margin-right: 8vw;
`;

export const ImageWrapper = styled.div`
  margin-left: -1rem;
  margin-top: 1vw;
  a {
    margin: 10px 0;
  }
  img {
    width: 12vw;
  }
  &:hover,
  &:focus {
  }
  @media (max-width: 400px) {
    margin-left: 2px;
    width: 100%;
    a {
      width: 200px;
    }
  }
`;

export const ApplyButton = styled(Button)`
  color: ${colors.white};
  background: ${colors.dohrBlue};
  font-size: 13px;
  font-weight: 600;
  border: 2px solid ${colors.dohrBlue};
  border-radius: 20px;
  padding: 5px 20px;
  &:hover {
    background: blue;
    color: white;
    border: 2px solid blue;
  }
  @media (max-width: 460px) {
    font-size: 11px;
    font-weight: 600;
    padding: 2px 10px;
    margin: 0;
    margin-bottom: 1rem;
  }
`;

export const ContentContainer = styled.div`
  background-color: white;
  padding: 0 50px;
`;

export const InfoHeader = styled.p`
  font-size: 2.5vw;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  color: ${({ color }) => (color ? color : colors.darkBlue)};
  @media (max-width: 800px) {
    font-size: 16px;
  }
  @media (max-width: 600px) {
    font-size: 14px;
  }
  /* @media (max-width: 450px) {
    font-size: 14px;
  } */
`;

interface CardContainerProps {
  paddingTop?: string;
  paddingBottmo?: string;
  width?: string;
  left?: string;
}

export const CardContainer = styled.div<CardContainerProps>`
  display: flex;
  justify-content: space-evenly;
  width: ${({ width }) => (width ? width : "100%")};
  margin-left: ${({ left }) => (left ? left : "0px")};
  padding-top: ${({ paddingTop }) => (paddingTop ? paddingTop : "4%")};
  padding-bottom: ${({ paddingBottmo }) =>
    paddingBottmo ? paddingBottmo : null};
  @media (max-width: 400px) {
    flex-direction: column; 
    width:100%; 
    justify-content: center;
  }
`;
export const CardContainerTwo = styled.div<CardContainerProps>`
  display: flex;
  justify-content: space-evenly;
  width: ${({ width }) => (width ? width : "100%")};
  margin-left: ${({ left }) => (left ? left : "0px")};
  padding-top: ${({ paddingTop }) => (paddingTop ? paddingTop : "4%")};
  padding-bottom: ${({ paddingBottmo }) =>
    paddingBottmo ? paddingBottmo : null};
  @media (max-width: 400px) {
    flex-direction: column;
    margin-left: 0%;  
    width:100%; 
    justify-content: center;
  }
`;

export const CardImage = styled(Card.Img)`
  width: 60px;
  align-self: center;
`;

export const CardTitle = styled(Card.Title)`
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: ${({ color }) => (color ? color : "black")};
  @media (max-width: 600px) {
    font-size: 14px;
  }
  @media (max-width: 450px) {
    font-size: 12px;
  }
`;

export const GetStartButton = styled(Button)`
  color: ${colors.dohrBlue};
  background: white;
  font-size: 1.1vw;
  font-weight: 600;
  margin-top: 2vw;
  padding: 0.4vw 1vw;
  border: 2px solid white;
  border-radius: 4vw;
  &:hover {
    color: ${colors.dohrBlue};
    background: white;
    border: 2px solid white;
    box-shadow: 0px 2px 5px grey;
  }
  @media (max-width: 500px) {
    font-size: 10px;
    padding-top: 2px;
  }
`;

export const CardText = styled(Card.Text)`
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  color: ${colors.darkBlue};
  @media (max-width: 600px) {
    font-size: 12px;
  }
  @media (max-width: 450px) {
    font-size: 10px;
  }
`;

export const Tr = styled.tr``;

export const Td = styled.td`
  color: ${colors.darkBlue};
  text-align: left;
  padding: 1.5% 0px 1.5% 16px !important;
`;

export const BootStrapTable = styled(Table)`
  th {
    color: ${colors.darkBlue};
    text-align: left;
    padding: 2%;
    
  }
  thead {
    color: ${colors.darkBlue};
    text-align: center;
    border-bottom-width: 2px;
    
  }
`;
