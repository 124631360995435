/* eslint-disable jsx-a11y/anchor-is-valid */
import { ReactElement, useEffect } from "react";
import { PageWrapper } from "../../Login/subcomponent";
import {
  CardContainer,
  CardContainerTwo,
  CardImage,
  CardText,
  CardTitle,
  Container,
  ContentContainer,
  GetStartButton,
  Header, 
  ImageWrapper,
  InfoHeader, 
  MobileWrapper,
  Para,
} from "./subcomponent";
import playStore from "../../../asserts/google-play.png";
import appStore from "../../../asserts/iphone.png"; 
import Aos from "aos";
import { FlexWrapper, Footers } from "../../../components";
import { Card } from "react-bootstrap";
import download from "../../../asserts/Customer/smartphone.png";
import complete from "../../../asserts/Customer/medical-report.png";
import login from "../../../asserts/Customer/in.png";
import vacancy from "../../../asserts/Customer/human-resources.png";
import schedule from "../../../asserts/Customer/calendar.png";
import { useHistory } from "react-router-dom";
import ROUTES from "../../../const/routes";
import { colors } from "../../../const/theme";
import { StyledCard } from "../../Login/ourSolution/staffAbsence/subcomponent";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

const Customer = (): ReactElement => {
  const history = useHistory();
  const { lang } = useSelector((state: RootState) => ({
    lang: state.Language.language,
  }));
  useEffect(() => {
    Aos.init({
      duration: 1000,
      once: false,
    });
    window.scrollTo({ top: 0 });
  }, []);

  const { customer } = lang.signUp;

  return (
    <PageWrapper>
      <MobileWrapper background="#002c99">
        <Container space="1vw 0px 1vw 0px" data-aos="fade-right">
          <Header>
            {customer.stop} <br /> {customer.straight}
          </Header>
          {customer.there === "There's even more..." ? (
            <Para style={{ marginTop: "1vw" }}>
              Download the DoHR mobile app free of charge and experience on-time{" "}
              <br />
              arrival of our substitute, ready to fulfill the assignment.
            </Para>
          ) : (
            <Para style={{ marginTop: "1vw" }}>{customer.download}</Para>
          )}
          <ImageWrapper>
            <a href="#">
              <img src={appStore} alt="" />
            </a>
            <a href="#">
              <img src={playStore} alt="" />
            </a>
          </ImageWrapper>
          <Header style={{ marginTop: "1vw" }}>{customer.there}</Header>
          {customer.there === "There's even more..." ? (
            <Para style={{ marginTop: "1vw" }}>
              Our next level web-based solution can help you discover <br /> how
              you can proactively manage employees' absences, <br />{" "}
              substitutes, and get statistics -
              <span style={{ fontWeight: "bolder" }}>all in one place!</span>
            </Para>
          ) : (
            <Para style={{ marginTop: "1vw" }}>
              Med vår webbaserade lösning kan du enkelt hantera vikarier, <br />
              personalfrånvaro och få tillgång till statistik
              <span style={{ fontWeight: "bolder" }}>
                - allt på ett och samma ställe!
              </span>
            </Para>
          )}

          <GetStartButton
            onClick={() => {
              history.push(ROUTES.CONTACT_FORM);
            }}
          >
            {lang.header.main.contactUs}
          </GetStartButton>
        </Container>
      </MobileWrapper>

      <ContentContainer>
        <FlexWrapper justifyContent="center">
          <InfoHeader>{customer.how}</InfoHeader>
        </FlexWrapper>
        <CardContainer paddingTop="6px">
          <StyledCard style={{ width: "16rem" }} className="text-center">
            <CardImage data-aos="zoom-in" variant="top" src={download} />
            <Card.Body>
              <CardTitle color={colors.darkBlue}>{customer.app}</CardTitle>
              {customer.there === "There's even more..." ? (
                <CardText>
                  Get the DoHR app from <br /> Google Play or the App Store
                </CardText>
              ) : (
                <CardText>
                  Hämta DoHR appen från <br /> Google Play eller App Store
                </CardText>
              )}
            </Card.Body>
          </StyledCard>
          <StyledCard style={{ width: "16.5rem" }} className="text-center">
            <CardImage data-aos="zoom-in" variant="top" src={complete} />
            <Card.Body>
              <CardTitle
                color={colors.darkBlue}
                onClick={() => {
                  history.push(ROUTES.REGISTER_FORM);
                }}
              >
                {customer.complete}
              </CardTitle>
              {customer.there === "There's even more..." ? (
                <CardText>
                  Complete the step-by-step <br /> customer registration form
                </CardText>
              ) : (
                <CardText>
                  Slutför kontoregistreringen <br /> steg för steg
                </CardText>
              )}
            </Card.Body>
          </StyledCard>
          <StyledCard style={{ width: "16rem" }} className="text-center">
            <CardImage data-aos="zoom-in" variant="top" src={login} />
            <Card.Body>
              <CardTitle color={colors.darkBlue}>{customer.log}</CardTitle>
              {customer.there === "There's even more..." ? (
                <CardText>
                  Log in with the user name and <br /> password created at the
                  time <br /> of the registration
                </CardText>
              ) : (
                <CardText>
                  Logga in med användarnamnet och <br /> lösenordet som skapades
                  vid kontoregistreringen
                </CardText>
              )}
            </Card.Body>
          </StyledCard>
        </CardContainer>

        <CardContainerTwo
          width="80%"
          paddingTop="4px"
          paddingBottmo="20px"
          left="12%"
        >
          <StyledCard style={{ width: "16rem" }} className="text-center">
            <CardImage data-aos="zoom-in" variant="top" src={vacancy} />
            <Card.Body>
              <CardTitle color={colors.darkBlue}>{customer.publish} </CardTitle>
              {customer.there === "There's even more..." ? (
                <CardText>
                  Publish a vacancy, and <br /> have one of DoHR substitutes{" "}
                  <br /> accept it straight away
                </CardText>
              ) : (
                <CardText>
                  Publicera ett jobb så en av DoHR vikarierna <br /> kan
                  acceptera det direkt
                </CardText>
              )}
            </Card.Body>
          </StyledCard>

          <StyledCard
            style={{ width: "16rem", marginRight: "3%" }}
            className="text-center"
          >
            <CardImage data-aos="zoom-in" variant="top" src={schedule} />
            <Card.Body>
              <CardTitle color={colors.darkBlue}>{customer.approve}</CardTitle>
              {customer.there === "There's even more..." ? (
                <CardText>
                  You simply approve <br /> the paperless timesheet <br /> with
                  just one click
                </CardText>
              ) : (
                <CardText>
                  Du godkänner tidrapporten <br /> utan krångel
                </CardText>
              )}
            </Card.Body>
          </StyledCard>
        </CardContainerTwo>
      </ContentContainer>
      <Footers />
    </PageWrapper>
  );
};

export default Customer;
