export const initialValues = {
  first_name: "",
  last_name: "",
  email_id: "",
  phone_number: "",
  swedish_personal_number: "",
  self_employed: "",
  swedish_bank_account: "",
  preferred_work_institution: "",
  description: "",
  iam_student: "",
  name_of_institutition: "",
  work_exp_in_school: "",
  info_work_exp_in_school: "",
  work_exp_out_school: "",
  info_work_exp_out_school: "",
  other_occupations: "",
  info_other_occupations: "",
  as_soon_as_possible: "",
  specify_date: "",
  language_skills: "",
  police_clearance_report: "",
  police_report: "",
  Driving_license: "",
  terms_and_condition: "",
};

export const initialValuesBoolan = {
  sFluent: false,
  sIntermediate: false,
  sBeginner: false,
  eFluent: false,
  eIntermediate: false,
  eBeginner: false,
  oFluent: false,
  oIntermediate: false,
  oBeginner: false,
  other: false,
  preSchool: false,
  school: false,
  gym: false,
  careInstitution: false,
  terms: false,
  accept: false,
  stockholm: false,
  euCitizen: false,
  Iam_non_EU_citizen: false,
  other_location: false,
  marketing: false,
  accurate_info: false,
  other_occupation: false,
};

export const YESORNO = [
  {
    id: "01",
    name: "Yes",
  },
  {
    id: "02",
    name: "No",
  },
];

export const Time = [
  {
    id: "01",
    name: "Full Time",
  },
  {
    id: "02",
    name: "Part Time",
  },
];

export const HOURSELECTION = [
  {
    id: "01",
    name: "30+ hours per week ",
  },
  {
    id: "02",
    name: "  15 - 30 hours per week ",
  },
  {
    id: "03",
    name: " 5 - 15 hours per week ",
  },
  {
    id: "04",
    name: "Flexible, open to any of the above alternatives ",
  },
];

export const checkBoxData1 = [
  {
    id: "01",
    name: "Preschool ",
  },
  {
    id: "02",
    name: "School",
  },
  {
    id: "03",
    name: "Gymnasium",
  },
];

export const languageSelection = [
  {
    id: "01",
    name: "Fluent ",
  },
  {
    id: "02",
    name: "Intermediate",
  },
  {
    id: "03",
    name: "Beginner",
  },
];

export const howKnowAboutUs = [
  {
    id: "01",
    name: "Arbetsförmedlingen",
  },
  {
    id: "02",
    name: "Marketing event",
  },
  {
    id: "03",
    name: "Word of mouth",
  },
  {
    id: "04",
    name: "Job posting",
  },
  {
    id: "05",
    name: "Instagram",
  },
  {
    id: "06",
    name: "Facebook",
  },
  {
    id: "07",
    name: "Linkedin",
  },
  {
    id: "08",
    name: "Google",
  },
  {
    id: "09",
    name: "Tiktok",
  },
];
