import { ReactElement, useEffect, useState } from "react";
import { FlexWrapper, Footers } from "../../../../components";
import { PageWrapper } from "../../subcomponent";
import {
  ContentContainer,
  ContentHeader,
  Container,
  StartButton,
  InfoHeader,
  CardTitle,
  CardContainer,
  CardImage,
  GetStartButton,
  Imagine,
  ParaSub,
  InfoHeader2,
  GetStartButton2,
  ModalBody,
  StyledIcons,
  TableStartButton,
  SaamHeader,
  StyledCard,
} from "./subcomponent";
import absenceIcon from "../../../../asserts/SAM/screen-touch.png";
import manage from "../../../../asserts/SAM/activity.png";
import overview from "../../../../asserts/SAM/eye.png";
import recruitmentIcon from "../../../../asserts/SAM/sand-clock.png";
import manual from "../../../../asserts/SAM/edit.png";
import unfilled from "../../../../asserts/SAM/puzzle.png";
import { Card, Modal } from "react-bootstrap";
import Aos from "aos";
import { useHistory } from "react-router-dom";
import ROUTES from "../../../../const/routes";
import {
  BootStrapTable,
  Td,
  Tr,
} from "../../../GetStart/Customer/subcomponent";
import { colors } from "../../../../const/theme";
import { Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

const StaffAbsence = (): ReactElement => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const { head } = useSelector((state: RootState) => ({
    head: state.Language.language,
  }));

  const { signUp } = head;

  const {
    cover,
    pop,
    get,
    daily,
    stop,
    avoid,
    rightpeople,
    which,
    doMobile,
    doWeb,
    table,
    getMobileApp,
    learnSaam,
    popModal,
  } = signUp.saam;

  useEffect(() => {
    Aos.init({
      duration: 1000,
      once: false,
    });
    window.scrollTo({ top: 0 });
  }, []);
  
  return (
    <PageWrapper>
      <Container>
        <FlexWrapper justifyContent="center" noMargin noPadding>
          <ContentHeader color="#ffffff" data-aos="fade-right">
            <SaamHeader>{signUp.absent_management}</SaamHeader>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ParaSub style={{ width: "60%" }} data-aos="zoom-in">
                {signUp.saam.have}
              </ParaSub>
            </div>
          </ContentHeader>
        </FlexWrapper>
        <FlexWrapper justifyContent="center" noMargin noPadding>
          <FlexWrapper noMargin>
            <GetStartButton2
              onClick={() => {
                setShow(true);
              }}
            >
              {signUp.saam.learnSaam}
            </GetStartButton2>
          </FlexWrapper >
          <FlexWrapper noMargin>
            <StartButton
              marginleft="none"
              onClick={() => {
                history.push(ROUTES.CUSTOMER);
              }}
            >
              {signUp.saam.getMobileApp}
            </StartButton>
          </FlexWrapper>
        </FlexWrapper>
      </Container>
      <ContentContainer>
        <FlexWrapper justifyContent="center" noPadding noMargin>
          <InfoHeader fontSize="2vw" data-aos="zoom-in">
            {signUp.saam.whether}
          </InfoHeader>
        </FlexWrapper>
        <CardContainer>
          <StyledCard style={{ width: "16rem" }} className="text-center">
            <CardImage data-aos="zoom-in" variant="top" src={absenceIcon} />
            <Card.Body>
              <CardTitle>{cover}</CardTitle>
            </Card.Body>
          </StyledCard>
          <StyledCard style={{ width: "16rem" }} className="text-center">
            <CardImage data-aos="zoom-in" variant="top" src={recruitmentIcon} />
            <Card.Body>
              <CardTitle>{rightpeople}</CardTitle>
            </Card.Body>
          </StyledCard>
          <StyledCard style={{ width: "16rem" }} className="text-center">
            <CardImage data-aos="zoom-in" variant="top" src={unfilled} />
            <Card.Body>
              <CardTitle>{avoid}</CardTitle>
            </Card.Body>
          </StyledCard>
        </CardContainer>
        <CardContainer paddingTop="4px">
          <StyledCard style={{ width: "16rem" }} className="text-center">
            <CardImage data-aos="zoom-in" variant="top" src={manual} />
            <Card.Body>
              <CardTitle>{stop}</CardTitle>
            </Card.Body>
          </StyledCard>

          <StyledCard style={{ width: "16rem" }} className="text-center">
            <CardImage data-aos="zoom-in" variant="top" src={overview} />
            <Card.Body>
              <CardTitle>
                {daily}{" "}
                <Tooltip title={pop} placement="right">
                  <span>
                    <FontAwesomeIcon icon={["fas", "info-circle"]} size="sm" />
                  </span>
                </Tooltip>
              </CardTitle>
            </Card.Body>
          </StyledCard>

          <StyledCard style={{ width: "16rem" }} className="text-center">
            <CardImage data-aos="zoom-in" variant="top" src={manage} />
            <Card.Body>
              <CardTitle>
                {get}{" "}
                <Tooltip title={pop} placement="left">
                  <span>
                    <FontAwesomeIcon icon={["fas", "info-circle"]} size="sm" />
                  </span>
                </Tooltip>
              </CardTitle>
            </Card.Body>
          </StyledCard>
        </CardContainer>
      </ContentContainer>
      <FlexWrapper
        justifyContent="center"
        backgroundColor={colors.darkBlue}
        width="100%"
        data-aos="zoom-in"
        style={{ opacity: "0.8" }}
        noMargin
      >
        <span style={{ width: "100%" }}>
          <InfoHeader2 color="white">{which}</InfoHeader2>
        </span>
      </FlexWrapper>
      {show ? (
        <Modal
          show={show}
          onHide={() => {
            setShow(false);
          }}
          centered
          size="lg"
        >
          <Modal.Header closeButton></Modal.Header>
          <ModalBody>
            {popModal}
            <FlexWrapper justifyContent="center" noPadding>
              <GetStartButton
                onClick={() => {
                  history.push(ROUTES.CONTACT_FORM);
                }}
              >
                {head.header.main.contactUs}
              </GetStartButton>
            </FlexWrapper>
          </ModalBody>
        </Modal>
      ) : null}
      <FlexWrapper
        justifyContent="center"
        backgroundColor={"rgb(242, 243, 243)"}
        noMargin
      >
        <span style={{ width: "85%" }}>
          <BootStrapTable responsive>
            <thead>
              <tr>
                <th></th>
                <th style={{ textAlign: "center", paddingBottom: "4%" }}>
                  {doMobile}
                </th>
                <th style={{ textAlign: "center" }}>
                  {doWeb} <br /> (SAAM)
                </th>
              </tr>
            </thead>
            <tbody>
              <Tr>
                <Td>{table.publish}</Td>
                <td>
                  <StyledIcons icon={["fas", "check"]} size="lg" />
                </td>
                <td>
                  <StyledIcons icon={["fas", "check"]} size="lg" />
                </td>
              </Tr>
              <Tr>
                <Td>{table.overjobs}</Td>
                <td>
                  <StyledIcons icon={["fas", "check"]} size="lg" />
                </td>
                <td>
                  <StyledIcons icon={["fas", "check"]} size="lg" />
                </td>
              </Tr>
              <Tr>
                <Td>{table.time}</Td>
                <td>
                  <StyledIcons icon={["fas", "check"]} size="lg" />
                </td>
                <td>
                  <StyledIcons icon={["fas", "check"]} size="lg" />
                </td>
              </Tr>
              <Tr>
                <Td>{table.chat}</Td>
                <td>
                  <StyledIcons icon={["fas", "check"]} size="lg" />
                </td>
                <td>
                  <StyledIcons icon={["fas", "check"]} size="lg" />
                </td>
              </Tr>
              <Tr>
                <Td>{table.management}</Td>
                <td>
                  <StyledIcons icon={["fas", "xmark"]} color="red" size="lg" />
                </td>
                <td>
                  <StyledIcons icon={["fas", "check"]} size="lg" />
                </td>
              </Tr>
              <Tr>
                <Td>{table.register}</Td>
                <td>
                  <StyledIcons icon={["fas", "xmark"]} color="red" size="lg" />
                </td>
                <td>
                  <StyledIcons icon={["fas", "check"]} size="lg" />
                </td>
              </Tr>
              <Tr>
                <Td>{table.overview}</Td>
                <td>
                  <StyledIcons icon={["fas", "xmark"]} color="red" size="lg" />
                </td>
                <td>
                  <StyledIcons icon={["fas", "check"]} size="lg" />
                </td>
              </Tr>
              <Tr>
                <Td>{table.effective}</Td>
                <td>
                  <StyledIcons icon={["fas", "xmark"]} color="red" size="lg" />
                </td>
                <td>
                  <StyledIcons icon={["fas", "check"]} size="lg" />
                </td>
              </Tr>
              <Tr>
                <Td>{table.discount}</Td>
                <td>
                  <StyledIcons icon={["fas", "xmark"]} color="red" size="lg" />
                </td>
                <td>
                  <StyledIcons icon={["fas", "check"]} size="lg" />
                </td>
              </Tr>
              <Tr>
                <Td>{table.statics}</Td>
                <td>
                  <StyledIcons icon={["fas", "xmark"]} color="red" size="lg" />
                </td>
                <td>
                  <StyledIcons icon={["fas", "check"]} size="lg" />
                </td>
              </Tr>
              <Tr>
                <td></td>
                <td>
                  <FlexWrapper justifyContent="center">
                    <TableStartButton
                      marginleft="none"
                      onClick={() => {
                        history.push(ROUTES.CUSTOMER);
                      }}
                    >
                      {getMobileApp}
                    </TableStartButton>
                  </FlexWrapper>
                </td>
                <td>
                  <FlexWrapper justifyContent="center">
                    <TableStartButton
                      onClick={() => {
                        setShow(true);
                      }}
                    >
                      {learnSaam}
                    </TableStartButton>
                  </FlexWrapper>
                </td>
              </Tr>
            </tbody>
          </BootStrapTable>
        </span>
      </FlexWrapper>
      <Footers />
    </PageWrapper>
  );
};

export default StaffAbsence;
