const strings = {
  validationMessages: {
    field: {
      required: "",
    },
    email: {
      required: 'Email is required',
      invalid: 'Please enter valid email'
    },
    phone: {
      required: 'Phone number is required',
      numberOnly: 'Please enter only numbers',
      invalid: 'Please enter valid phone number'
    },
  },
  login: {
    login: "Login",
    message: "Login failed check the Credientls",
  },
};
export default strings;
