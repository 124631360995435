import styled from "styled-components";
import fonts, { weight } from "../const/fonts";
import { colors } from "../const/theme";

interface TypographyProps {
  color?: string;
  isHelpText?: boolean;
  hasPadding?: boolean;
  fontStyle?: boolean;
  titleStyle?: string;
}

export const H1 = styled.h1<TypographyProps>`
  font-size: ${fonts.xxLarge}px;
  font-weight: ${weight.bold};
  color: ${({ color }) => (color ? color : colors.black)};
`;

export const H2 = styled.h2<TypographyProps>`
  color: ${({ color }) => (color ? color : colors.white)};
  font-size: 16px;
  font-weight: 600;
  margin: auto 0;
  padding: 0 8px;
`;

export const H3 = styled.h3<TypographyProps>`
  color: ${({ color }) => (color ? color : colors.heavyGray)};
  font-size: ${fonts.medium}px;
  font-weight: ${weight.normal};
  font-family: ${({ fontStyle }) => (fontStyle ? "Montserrat" : null)};
  margin: auto 0;
  * > img {
    height: auto !important;
  }
`;

export const H4 = styled.h4<TypographyProps>`
  color: ${({ color }) => (color ? color : colors.heavyGray)};
  font-size: ${fonts.medium}px;
`;

export const Small = styled.small<TypographyProps>`
  font-size: ${fonts.small}px;
  opacity: ${({ isHelpText }) => (isHelpText ? "0.5" : "1")};
  padding: 2px ${({ hasPadding }) => (hasPadding ? "8px" : "0")};
  font-weight: ${({ isHelpText }) => (isHelpText ? 600 : 400)};
  color: ${({ color }) => (color ? color : colors.black)};
  text-align: center;
  @media (max-width: 415px) {
    font-size: 14px;
  }
`;

export const Large = styled.small<TypographyProps>`
  font-size: ${fonts.xLarge}px;
  padding: 0 8px;
  font-weight: ${weight.normal};
  color: ${({ color }) => (color ? color : colors.gray)};
`;

export const Body = styled.p<TypographyProps>`
  font-size: ${fonts.medium}px;
  font-weight: ${weight.normal};
  color: ${({ color }) => (color ? color : colors.gray)};
  margin: auto 0;
  padding: ${({ hasPadding }) => (hasPadding ? "0 8px" : "0")};
`;

export const Span = styled.span<TypographyProps>`
  color: ${({ color }) => (color ? color : colors.gray)};
`;

export const Label = styled.label<TypographyProps>`
  color: ${({ color }) => (color ? color : colors.black)};
  font-size: ${fonts.small}px;
  font-weight: ${weight.xxBold};
  font-family: "Montserrat", sans-serif;
  margin-bottom: 1%;
  margin-left: 1%;
`;
export const BoldLabel = styled(H3)`
  /* margin-bottom: 5px; */
  margin-top: 0px;
  font-size: ${fonts.small}px;
  font-weight: ${weight.xnormal};
  font-family: "Montserrat", sans-serif;
  color: ${colors.darkBlue};
  &.error {
    color: #ff0000; /* Red text color */
  }
`;
