import styled from "styled-components";

export const VacApplicationText = styled.p`
  color: ${({ color }) => (color ? color : "white")};
  font-size: 20px;
  font-weight: 600;
  margin-top: 4%;
`;

interface Props {
  center?: boolean;
  width?: string;
  size?: string;
  left?: string;
  bold?: string;
}

export const AppText = styled.p<Props>`
  color: ${({ color }) => (color ? color : "white")};
  text-align: ${({ center }) => (center ? "center" : "left")};
  font-size: ${({ size }) => (size ? size : "1.4vw")};
  width: ${({ width }) => (width ? width : "75%")};
  font-weight: ${({ bold }) => (bold ? bold : "700")};
  margin-top: 2%;
  margin-left: ${({ left }) => (left ? left : "0px")};
`;

export const HeadingWrapper = styled.p`
  padding-left: 4%;
`;

export const ContentContainer = styled.div`
  background: rgb(242, 243, 243);
  height: auto;
  display: flex;
  justify-content: space-between;
`;

export const ContentWrapper = styled.div`
  background-color: white;
  padding: 0 50px 50px 50px;
  height: 100%;
`;
