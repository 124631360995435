import React from "react";
import { PageWrapper } from "../../../components";

const Reserve = () => {
  return (
    <div>
      <PageWrapper>
        <div>
          <h5> Allmänna villkor - Reservpool</h5>
          <br />
          En reservpool är en samling av reservvikarier för att möta kundernas
          behov av vikarier med kort varsel. Vi sätter en kvot för hur många
          reservvikarier som behövs en viss dag. Det innebär att vikarier som
          vet sin tillgänglighet i förväg kan anmäla sig som reservvikarie. När
          du anmäler dig som reservvikarie <br /> <br /> förbinder du dig att
          acceptera ett av de lediga jobben mellan <b>06:45 och 09:45</b> för
          den dagen (inklusive jobben utanför ditt föredragna område dock inom
          ditt län). Se till att du är beredd att börja arbeta i tid och med
          kort varsel. Under din tid som reservvikarie kommer du att få din
          timlön för den tiden som du är reservvikarie, vilket innebär att du
          kommer att få betalt din ordinarie timlön från kl. 06.45 till
          starttiden När du anmäler dig som reservvikarie <br /> förbinder du
          dig att acceptera för det accepterade jobbet den dagen. <br />
          <br /> Du riskerar din ersättning om du väljer bort att tacka ja till
          något av de lediga jobben i ditt län i tid. Du kommer fortfarande att
          få din ordinarie lön för de timmar du har registrerat dig som
          reservvikarie i händelse av att det inte finns några lediga jobb den
          dagen. <br />
          <br /> Om du inte kan fortsätta som reservvikarie måste du
          avregistrera dig från reservpoolen. Du kan inte avregistrera dig samma
          dag som du är reservvikarie, du borde göra det minst dagen innan din
          reservdag. Det är inte möjligt att avregistrera sig med appen samma
          dag; i så fall måste du kontakta oss. Vid avanmälan från reservpoolen
          kommer villkoren inte längre att gälla. <br />
          <br /> Du kan inte tacka ja till ett jobb före kl. 06:45 den dag du
          registrerade dig som reservvikarie, men om du avregistrerar dig från
          reservpoolen kan du fortfarande tacka ja till ett jobb. Villkor för
          reservpoolen kommer inte längre att gälla. <br />
          <br /> Vi förbehåller oss rätten att göra ändringar i dessa villkor
          och ändra reservpoolens kvoter utan föregående meddelande.
        </div>
      </PageWrapper>
    </div>
  );
};

export default Reserve;
