import { useState, useEffect, useRef } from "react";
import {
  Anchor,
  ChevronIcon,
  MainMenuAnchor1,
  MenuItemButton,
  MenuItemList,
} from "./subcomponent";
import Dropdown from "./Dropdown";

interface D {
  items: any;
  depthLevel: any;
}

const MenuItems = ({ items, depthLevel }: D) => {
  const [dropdown, setDropdown] = useState(false);

  let ref = useRef(null);

  useEffect(() => {
    const handler = (event: any) => {
      if (dropdown && ref.current && event.target) {
        setDropdown(true);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    window.innerWidth > 600 && setDropdown(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 600 && setDropdown(false);
  };

  return (
    <MenuItemList
      ref={ref}
      className="menu-items"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {items.childsItem ? (
        <>
          <MenuItemButton
            onMouseOver={onMouseEnter}
            aria-expanded={dropdown ? "true" : "false"}
            onClick={() => setDropdown((prev) => !prev)}
          >
            <Anchor to={items.to}>{items.title}</Anchor>

            {depthLevel > 0 ? (
              <span className="line"> &raquo; </span>
            ) : (
              <ChevronIcon icon={["fas", "chevron-down"]} size="sm" />
            )}
          </MenuItemButton>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.childsItem}
            dropdown={dropdown}
          />
        </>
      ) : (
        <MainMenuAnchor1 to={items.to}>{items.title}</MainMenuAnchor1>
      )}
    </MenuItemList>
  );
};

export default MenuItems;
