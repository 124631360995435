import styled from "styled-components";
import DropdownList from "react-widgets/DropdownList";
import { colors } from "../../const/theme";
import { Button } from "react-bootstrap";

interface TypographyProps {
  color?: string;
  isHelpText?: boolean;
  hasPadding?: boolean;
}


export const Container = styled.div`
  background: linear-gradient(to right, #002c99, #2097f5);
  height: auto;
  padding: 8vw 0px 2vw 0px;
`;
export const SecondContainer = styled.div`
  height: auto;
`;

export const PageWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden !important;
  font-family: "Montserrat";
  @media (max-width: 460px) {
    width: 100%;
    overflow: auto;
  }
`;

export const ContentHeader = styled.h1`
  width: 80%;
  font-size: 52px;
  margin-top: 16%;
  font-weight: 600;
  text-align: center;
  color: ${({ color }) => (color ? color : "whitesmoke")};
  @media (max-width: 650px) {
    font-size: 20px;
    margin-top: 180px;
  }
  @media (max-width: 450px) {
    font-size: 16px;
    margin-top: 200px;
  }
`;

export const SecondContentHeader = styled.h1`
  width: 100%;
  font-size: 2.5vw;
  font-weight: 700;
  text-align: center;
  margin-top: 2%;
  color: ${({ color }) => (color ? color : "whitesmoke")};
`;

export const InputWrapper = styled.div`
  margin-left: 4%;
  width: 20%;
`;

export const Dropdown = styled(DropdownList)`
  padding: 0px;
`;

export const ApplyButton = styled(Button)`
  background-color: ${colors.darkBlue};
  color: white;
  border: 2px solid white;
  font-weight: 600;
  font-size: 1.1vw;
  border-radius: 4vw;
`;

export const CloseButton = styled(Button)`
  background-color: ${colors.gray};
  color: white;
  border: 2px solid white;
  font-weight: 600;
  font-size: 1.1vw;
  border-radius: 4vw;
`;

export const Content = styled.div`
  margin-top: 12%;
  @media (max-width: 1000px) {
    font-size: 20px;
    margin-top: 24%;
  }
  @media (max-width: 850px) {
    font-size: 20px;
    margin-top: 30%;
  }
  @media (max-width: 650px) {
    font-size: 16px;
    margin-top: 38%;
  }
  @media (max-width: 450px) {
    font-size: 20px;
    margin-top: 45%;
  }
  @media (max-width: 415px) {
    font-size: 16px;
  }
`;

export const CaptionsParaSub = styled.p`
  font-size: 1.7vw;
  font-weight: 600;
  color: white;
  text-align: center;
  @media (max-width: 1100px) {
    font-size: 20px;
    margin-top: 8%;
  }
  @media (max-width: 850px) {
    font-size: 18px;
  }
  @media (max-width: 650px) {
    font-size: 16px;
  }
  @media (max-width: 450px) {
    font-size: 12px;
  }
`;

export const Title = styled.p`
  margin-bottom: 1vw;
  text-align: center;
  color: ${colors.white};
  font-size: 4vw;
  font-weight: 700;
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  background-image: linear-gradient(
    -225deg,
    #473680 0%,
    #44107a 29%,
    #ff1361 67%,
    #0b06fa 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 4s linear infinite;
  @keyframes textclip {
    to {
      background-position: -200% center;
    }
  }
`;

export const Small = styled.small<TypographyProps>`
  font-size:18px;
  opacity: ${({ isHelpText }) => (isHelpText ? "0.5" : "1")};
  padding: 2px ${({ hasPadding }) => (hasPadding ? "8px" : "0")};
  font-weight: ${({ isHelpText }) => (isHelpText ? 600 : 400)};
  color: ${({ color }) => (color ? color : colors.black)};
  white-space: pre-wrap;
  word-wrap:break-word;
`;