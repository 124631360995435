import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createCustomer,
  getCreatedCustomer,
  getProfile,
  handleAuthenticate,
  handleRegister,
  contactRegister,
  EmailValidation,
} from "./api";
import {
  getMyCustomer,
  getProfileResponse,
  initialState,
  RegisterPaylaod,
  contactResponse,
  UserInfo,
} from "./types";

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    updateIsLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
    updateHasError: (state, action: PayloadAction<boolean>) => {
      state.hasError = action.payload;
    },
    updateRegister: (state, action: PayloadAction<boolean>) => {
      state.hasRegister = action.payload;
    },
    updateCustomerCre: (state, action: PayloadAction<boolean>) => {
      state.hascustomerCreated = action.payload;
    },
    updateCustomerRej: (state, action: PayloadAction<boolean>) => {
      state.hascustomerRejected = action.payload;
    },
    uodateUserInfo: (state, action: PayloadAction<UserInfo>) => {
      state.userInfo = action.payload;
    },
  },
  extraReducers: {
    [handleAuthenticate.pending.toString()]: (state) => {
      state.hasError = false;
      state.isLoading = true;
    },
    [handleAuthenticate.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.hasError = false;
      state.isLoggedIn = true;
      state.userInfo = action.payload;
    },
    [handleAuthenticate.rejected.toString()]: (state) => {
      state.isLoading = false;
      state.isLoggedIn = false;
      state.hasError = true;
    },
    [handleRegister.pending.toString()]: (state) => {
      state.isLoading = true;
      state.hasError = false;
    },
    [handleRegister.fulfilled.toString()]: (
      state,
      action: PayloadAction<RegisterPaylaod>
    ) => {
      state.isLoading = false;
      state.hasError = false;
      state.hasRegister = true;
      window.scrollTo({ top: 0 });
      state.registerPayload = action?.payload;
    },
    [handleRegister.rejected.toString()]: (state) => {
      state.isLoading = false;
      state.hasError = true;
      window.scrollTo({ top: 0 });
    },

    [createCustomer.pending.toString()]: (state, action) => {
      state.isLoading = true;
    },
    [createCustomer.fulfilled.toString()]: (state) => {
      state.isLoading = false;
      state.hascustomerCreated = true;
      window.scrollTo({ top: 0 });
    },
    [createCustomer.rejected.toString()]: (state) => {
      state.isLoading = false;
      state.hascustomerRejected = true;
      window.scrollTo({ top: 0 });
    },
    [getCreatedCustomer.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getCreatedCustomer.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<getMyCustomer>>
    ) => {
      state.isLoading = false;
      state.getCustomerdetaisl = action.payload;
    },
    [getCreatedCustomer.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [getProfile.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getProfile.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<getProfileResponse>>
    ) => {
      state.isLoading = false;
      state.getPrfileData = action.payload;
    },
    [getProfile.rejected.toString()]: (state) => {
      state.isLoading = false;
    },

    [contactRegister.pending.toString()]: (state) => {
      state.isLoading = true;
      state.hasError = false;
    },
    [contactRegister.fulfilled.toString()]: (
      state,
      action: PayloadAction<contactResponse>
    ) => {
      state.isLoading = false;
      state.contactResponse = action.payload;
      state.hasError = false;
      state.hasRegister = true;
      window.scrollTo({ top: 0 });
    },
    [contactRegister.rejected.toString()]: (state) => {
      state.isLoading = false;
      state.hasError = true;
      window.scrollTo({ top: 0 });
    },

    [EmailValidation.pending.toString()]: (state) => {
      state.isLoading = true;
      state.hasError = false;
    },
    [EmailValidation.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.hasError = false;
      state.emailvalidation = action.payload;
    },
    [EmailValidation.rejected.toString()]: (state) => {
      state.isLoading = false;
      state.hasError = true;
      // Handle the rejected case if needed
    },
  },
});

export default loginSlice.reducer;
