export const checkBox = [
  {
    id: "01",
    name: "Written and oral language skills in (specify language here) are required.",
  },

  {
    id: "04",
    name: "Comfortable clothing for Physical Education lesson is recommended.",
  },
  {
    id: "05",
    name: "Suitable outdoor clothing is recommended (pre-school).",
  },
  {
    id: "06",
    name: "Your arrival can be awaited up to 45 minutes due to short notice (unpaid).",
    code: true,
  },
  {
    id: "03",
    name: "A scheduled pedagogical lunch is required included (you will sit together with children/students and eat lunch at the scheduled time).",
  },
  {
    id: "02",
    name: "Free parking is avaliable.",
  },
];

export const preperationDropDown = [
  {
    id: "00:00:00",
    name: "0 min",
  },
  {
    id: "00:05:00",
    name: "5 min",
  },
  {
    id: "00:10:00",
    name: "10 min",
  },
  {
    id: "00:15:00",
    name: "15 min",
  },
  {
    id: "00:20:00",
    name: "20 min",
  },
];

export const handoverDropDown = [
  {
    id: "00:00:00",
    name: "0 min",
  },
  {
    id: "00:05:00",
    name: "5 min",
  },
  {
    id: "00:15:00",
    name: "15 min",
  },
  {
    id: "00:25:00",
    name: "25 min",
  },
  {
    id: "00:40:00",
    name: "40 min",
  },
];

export const BreakTimeDropDown = [
  {
    id: 30,
    name: "30 min",
  },
  {
    id: 45,
    name: "45 min",
  },
  {
    id: 60,
    name: "60 min",
  },
];

export const BreakTimeDropDown2 = [
  {
    id: 0,
    name: "0 min",
  },
  {
    id: 30,
    name: "30 min",
  },
  {
    id: 45,
    name: "45 min",
  },
  {
    id: 60,
    name: "60 min",
  },
];
