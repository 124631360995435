/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useEffect, useState } from "react";
import {
  PageWrapper,
  FirstWrapper,
  CaptionsPara,
  CaptionsParaSub,
  StartButton,
  SecondWrapper,
  ApplyButton,
  ContentWrapper,
  Container,
  BoldText,
  ThirdWrapper,
  ThirdSectionPara,
  ImageWrapper,
  Image,
  CaptionsType,
  SecondImageWrapper,
  SecondImage,
  BoldText2,
  CaptionsParaSub2,
  StyledContainer,
  ZeroWrapper,
  ModalText,
  Light,
  ColorText,
  ZeroWrapper2,
  DivOne,
  DivTwo,
} from "./subcomponent";
import Aos from "aos";
import { FlexWrapper, Footers } from "../../components";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import ROUTES from "../../const/routes";
import { useHistory } from "react-router-dom";
import Img1 from "../../asserts/Blind.png";
import Img2 from "../../asserts/dohrsubstitute.png";

import { AnimatePresence } from "framer-motion";

const LandingPage = (): ReactElement => {
  const {
    Language: {
      language: { landing, header },
    },
  } = useSelector(
    (state: RootState) => ({
      Language: state.Language,
    }),
    shallowEqual
  );

  const { imagin, sequence1, sequence2 } = landing;

  const history = useHistory();

  const [index, setIndex] = useState(0);
  const [subindex, setSubindex] = useState(0);
  const [height, setHeight] = useState("5.2vw");
  const [weight, setWeight] = useState("");

  useEffect(() => {
    Aos.init({
      duration: 900,
      once: false,
    });
    window.scrollTo(0, 0);
  }, []);

  const variants = {
    enter: {
      opacity: 0,
    },
    center: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
    },
  };

  useEffect(() => {
    setTimeout(() => {
      let next = index + 1;
      if (next === sequence1.length) {
        next = 0;
      }
      setIndex(next);
    }, 5 * 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  useEffect(() => {
    if (index < 5) {
      setHeight("5.2vw");
    } else {
      setHeight("7.2vw");
    }
  }, [index]);

  useEffect(() => {
    setTimeout(() => {
      let next = subindex + 1;
      if (next === sequence2.length) {
        next = 0;
      }
      setSubindex(next);
    }, 5 * 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subindex]);

  return (
    <PageWrapper>
      <ZeroWrapper>
        {/* <FlexWrapper
          noMargin
          noPadding
          justifyContent="center"
          style={{ marginTop: "4%" }}
        >
          <BoldText size="4vw">DoHR (/ˈdɔr/) TO NEW OPPORTUNITIES</BoldText>
        </FlexWrapper> */}

        <ZeroWrapper2>
          <FlexWrapper justifyContent="center" noMargin>
            {/* <ApplyButton
              onClick={() => {
                history.push(ROUTES.REGISTER_IN_OPTION);
              }}
              style={{ marginLeft: "28vw" }}
              margintop="16vw"
              //rotate
            >
              Get started
            </ApplyButton> */}
          </FlexWrapper>
        </ZeroWrapper2>
      </ZeroWrapper>

      {/* firstSection  */}
      <FirstWrapper>
        <Container>
          <BoldText style={{ textAlign: "center" }}>{imagin}</BoldText>
          <div style={{ height: "5vw" }}>
            <AnimatePresence>
              <CaptionsPara
                style={{ position: "absolute", textAlign: "center" }}
                variants={variants}
                key={index}
                initial={variants.enter}
                animate={variants.center}
                exit={variants.exit}
              >
                {sequence1[index]}
              </CaptionsPara>
            </AnimatePresence>
          </div>
          <CaptionsParaSub style={{ textAlign: "center" }}>
            {landing.findout}
          </CaptionsParaSub>
          <FlexWrapper noPadding noMargin justifyContent="center">
            <StartButton
              onClick={() => {
                history.push(ROUTES.OUR_SOLUTION);
              }}
            >
              {header.main.ourSolutions}
            </StartButton>
            <StartButton
              onClick={() => {
                history.push(ROUTES.CONTACT_FORM);
              }}
              marginLeft="2vw"
            >
              {header.main.contactUs}
            </StartButton>
          </FlexWrapper>
        </Container>
      </FirstWrapper>

      {/* secondSection */}
      <SecondWrapper>
        <SecondImageWrapper>
          <SecondImage src={Img2} />
        </SecondImageWrapper>
        <ContentWrapper>
          <BoldText2>{landing.extrawork}</BoldText2>
          {subindex < 1 ? (
            <DivOne>
              <AnimatePresence>
                <CaptionsType
                  style={{ position: "absolute" }}
                  variants={variants}
                  key={subindex}
                  initial="enter"
                  animate="center"
                  exit="exit"
                >
                  {sequence2[subindex]}
                </CaptionsType>
              </AnimatePresence>
            </DivOne>
          ) : (
            <DivTwo>
              <AnimatePresence>
                <CaptionsType
                  style={{ position: "absolute" }}
                  variants={variants}
                  key={subindex}
                  width="40%"
                  initial="enter"
                  animate="center"
                  exit="exit"
                >
                  {sequence2[subindex]}
                </CaptionsType>
              </AnimatePresence>
            </DivTwo>
          )}

          <CaptionsParaSub2>{landing.ifsounds}</CaptionsParaSub2>
          <ApplyButton
            onClick={() => {
              history.push(ROUTES.REGISTER_FORM);
            }}
          >
            {landing.apply}
          </ApplyButton>
          <ApplyButton
            marginLeft="2vw"
            onClick={() => {
              history.push(ROUTES.SUBSTITUTE);
            }}
          >
            {landing.learnmore}
          </ApplyButton>
        </ContentWrapper>
      </SecondWrapper>

      {/* thirdSection */}
      <ThirdWrapper>
        <StyledContainer>
          <BoldText2>{landing.seeking}</BoldText2>
          <ThirdSectionPara color="#002C99">{landing.great}</ThirdSectionPara>
          <ThirdSectionPara color="#002C99">{landing.readyto}</ThirdSectionPara>
          {landing.apply === "Ansök nu" ? (
            <ThirdSectionPara color="#002C99">
              Kolla in våra aktuella{" "}
              <span
                style={{
                  cursor: "pointer",
                  color: "#2097F5",
                }}
                onClick={() => {
                  history.push(ROUTES.VACANCIES);
                }}
              >
                lediga jobb
              </span>
              {"  "} och ansök redan idag!
            </ThirdSectionPara>
          ) : (
            <ThirdSectionPara color="#002C99">
              Check out our current{" "}
              <span
                style={{
                  cursor: "pointer",
                  color: "#2097F5",
                }}
                onClick={() => {
                  history.push(ROUTES.VACANCIES);
                }}
              >
                vacancies
              </span>
              {"  "} and simply apply!
            </ThirdSectionPara>
          )}
          {landing.apply === "Ansök nu" ? (
            <ThirdSectionPara color="#002C99">
              Om du inte hittar en roll som passar,
              <br /> skicka gärna en spontanansökan till oss.
            </ThirdSectionPara>
          ) : (
            <ThirdSectionPara color="#002C99">
              If you do not find a role that fits,
              <br /> we invite you to submit an open application form.
            </ThirdSectionPara>
          )}
          <ThirdSectionPara color="#002C99">{landing.refer}</ThirdSectionPara>
          <FlexWrapper noPadding>
            <ApplyButton
              onClick={() => {
                history.push(ROUTES.JOB_SEEKER_FORM);
              }}
            >
              {landing.open}
            </ApplyButton>{" "}
            <ApplyButton
              onClick={() => {
                history.push(ROUTES.JOB_SEEKER);
              }}
              marginLeft="2vw"
            >
              {landing.learnmore}
            </ApplyButton>
          </FlexWrapper>
        </StyledContainer>
        <ImageWrapper>
          <Image src={Img1} width="45vw" />
        </ImageWrapper>
      </ThirdWrapper>
      <Footers />
    </PageWrapper>
  );
};

export default LandingPage;
