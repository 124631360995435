import moment from "moment";
import { ReactElement, useEffect, useState } from "react";
import { Modal, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ActionButton, FlexWrapper, PageWrapper } from "../../../components";
import ROUTES from "../../../const/routes";
import { RootState } from "../../../redux/store";
import {
  getassignedCoverCount,
  getCustomassignedCoverCount,
  getTmroassignedCoverCount,
} from "../../../redux/Teachers/CoverManagenment/api";
import { ModalHeader, StyledCalendar } from "../../Dashboard/subcomponents";
import {
  Cards,
  CardWraper,
  Count,
  DashBoardWrapper,
  TabWrapper,
} from "./subcomponent";

const TeacherDashboard = (): ReactElement => {
  const {
    data,
    coverManagement: {
      gettodayAssignedCoverCount,
      gettommorowAssignedCoverCount,
    },
    dashboard,
  } = useSelector((state: RootState) => ({
    data: state.login.userInfo?.data,
    coverManagement: state.coverManagement,
    dashboard: state.Language.language.dashboard,
  }));

  const [dateState, setDateState] = useState(new Date());
  const [modal, setmodal] = useState(false);
  const history = useHistory();
  const { tab } = dashboard;

  const changeDate = (e: any) => {
    setDateState(e);
    setmodal(true);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getassignedCoverCount({
        cus_id: data?.id,
        date: moment(new Date()).format("YYYY-MM-DD"),
      })
    );
    dispatch(
      getTmroassignedCoverCount({
        cus_id: data?.id,
        date: moment(new Date()).add(1, "day").format("YYYY-MM-DD"),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      getCustomassignedCoverCount({
        cus_id: data?.id,
        date: moment(dateState).format("YYYY-MM-DD"),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateState]);

  return (
    <PageWrapper>
      <FlexWrapper justifyContent="center">
        <DashBoardWrapper>
          <FlexWrapper justifyContent="end" noMargin noPadding>
            <ActionButton
              onClick={() => {
                history.push(ROUTES.TEACHER.TEC_REGISTER_LEAVE);
              }}
            >
              Register Absence
            </ActionButton>
          </FlexWrapper>
          <CardWraper>
            <TabWrapper>
              <Tabs
                defaultActiveKey="today"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey={"today"} title={tab.header.today}>
                  <FlexWrapper noPadding>
                    <Cards
                      width="22"
                      height="120px"
                      marginTop="16px"
                      onClick={() => {
                        history.push(ROUTES.TEACHER.TEC_MY_ASSIGNED_COVER);
                      }}
                    >
                      <p>{tab.teacherContent.myAssignedCover}</p>
                      <Count>{gettodayAssignedCoverCount}</Count>
                    </Cards>
                  </FlexWrapper>
                </Tab>
                <Tab eventKey={"tommorow"} title={tab.header.tommorow}>
                  <FlexWrapper noPadding>
                    <Cards
                      width="22"
                      height="120px"
                      marginTop="16px"
                      onClick={() => {
                        history.push(ROUTES.TEACHER.TEC_MY_ASSIGNED_COVER);
                      }}
                    >
                      <p>{tab.teacherContent.myAssignedCover}</p>
                      <Count>{gettommorowAssignedCoverCount}</Count>
                    </Cards>
                  </FlexWrapper>
                </Tab>
                <Tab eventKey={"custom"} title={tab.header.custom}>
                  <FlexWrapper justifyContent="center">
                    <StyledCalendar
                      tileClassName="date"
                      className="day"
                      value={dateState}
                      onChange={changeDate}
                    />
                    {modal && (
                      <Modal
                        show={modal}
                        onHide={() => {
                          setmodal(false);
                        }}
                        size="sm"
                      >
                        <ModalHeader closeButton>
                          {moment(dateState).format("DD-MM-YYYY")}
                        </ModalHeader>
                        <FlexWrapper noPadding justifyContent="center">
                          <Cards
                            width="70"
                            height="120px"
                            onClick={() => {
                              history.push(
                                ROUTES.TEACHER.TEC_MY_ASSIGNED_COVER
                              );
                            }}
                          >
                            <p>{tab.teacherContent.myAssignedCover}</p>
                            <Count>{"0"}</Count>
                          </Cards>
                        </FlexWrapper>
                      </Modal>
                    )}
                  </FlexWrapper>
                </Tab>
              </Tabs>
            </TabWrapper>
          </CardWraper>
        </DashBoardWrapper>
      </FlexWrapper>
    </PageWrapper>
  );
};

export default TeacherDashboard;
