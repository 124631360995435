import Aos from "aos";
import { ReactElement, useEffect } from "react";
import { FlexWrapper, Footers } from "../../../components";
import {
  AboutPageWrapper,
  TitleText,
  AContainer,
  //Dohr,
  AboutPara,
  AboutImage,
  EscapeWrapper2,
  Bullets,
  AboutPara2,
  Bullets2,
  Bullets3,
  AboutPara3,
  Bullets4,
  AboutPara4,
  Dohr,
  NewStyledSection,
  NewSectionTitle,
  NewSectionline,
  NewSectionQuotes,
} from "./subcomponent";
import { PageWrapper } from "../subcomponent";
import DOHR from "../../../asserts/dohrWhite.png"; 
import { useHistory } from "react-router-dom";
import ROUTES from "../../../const/routes";
import { TableStartButton } from "../ourSolution/staffAbsence/subcomponent";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

const About = (): ReactElement => {
  const history = useHistory();
  const { lang } = useSelector((state: RootState) => ({
    lang: state.Language.language,
  }));

  const { openapplication } = lang.signUp.vacancies;

  useEffect(() => {
    Aos.init({
      duration: 1000,
      once: false,
    });
    window.scrollTo({ top: 0 });
  }, []);
  
  return (
    <PageWrapper>
      <AboutPageWrapper>
        {openapplication === "Open applications" ? (
          <AContainer>
            <TitleText>
              We are <Dohr data-aos="zoom-in">DoHR</Dohr> (/ˈdɔr/) - Dimensions
              of HR
            </TitleText>{" "}
            <FlexWrapper noMargin justifyContent="center">
              <Bullets>
                <AboutImage src={DOHR} width="2.5vw" />
              </Bullets>
              <EscapeWrapper2>
                <AboutPara>
                  Our mission is to provide affordable solutions for all your
                  staffing needs (whether your need is permanent, short term or
                  last minute) so you can focus on the day-to-day running of
                  your operation.
                </AboutPara>
              </EscapeWrapper2>
            </FlexWrapper>
            <FlexWrapper noMargin justifyContent="center">
              <Bullets2>
                <AboutImage src={DOHR} width="2.5vw" />
              </Bullets2>
              <EscapeWrapper2 margintop="0px">
                <AboutPara2>
                  We offer mobile and web-based solutions to help you manage
                  effectively different dimensions of HR.
                </AboutPara2>
              </EscapeWrapper2>
            </FlexWrapper>
            <FlexWrapper noMargin justifyContent="center">
              <Bullets3>
                <AboutImage src={DOHR} width="2.5vw" />
              </Bullets3>
              <EscapeWrapper2 margintop="0px">
                <AboutPara3>
                  We are driven to make the professional lives of our customers
                  stress and hassle free.
                </AboutPara3>
              </EscapeWrapper2>
            </FlexWrapper>
            <FlexWrapper noMargin justifyContent="center">
              <Bullets4>
                <AboutImage src={DOHR} width="2.5vw" />
              </Bullets4>
              <EscapeWrapper2 margintop="0px">
                <AboutPara4>
                  We always strive to match our candidates career aspirations
                  with our customer needs.
                </AboutPara4>
              </EscapeWrapper2>
            </FlexWrapper>
            {/* <FlexWrapper noMargin noPadding justifyContent="center">
            <Icon
              data-aos="zoom-out-down"
              data-aos-delay="600"
              size="2x"
              icon={["fas", "arrow-down-long"]}
            />
          </FlexWrapper> */}
          </AContainer>
        ) : (
          <AContainer>
            <TitleText>
              Vi är <Dohr data-aos="zoom-in">DoHR</Dohr> (/ˈdɔr/) - Dimensions
              of HR
            </TitleText>{" "}
            <FlexWrapper noMargin justifyContent="center">
              <Bullets>
                <AboutImage src={DOHR} width="2.5vw" />
              </Bullets>
              <EscapeWrapper2>
                <AboutPara>
                  Vårt uppdrag är att hjälpa våra kunder att hitta de bästa
                  möjliga och mest prisvärda bemanningslösningarna, oavsett om
                  behoven är permanenta, tillfälliga eller sista minuten.
                </AboutPara>
              </EscapeWrapper2>
            </FlexWrapper>
            <FlexWrapper noMargin justifyContent="center">
              <Bullets2>
                <AboutImage src={DOHR} width="2.5vw" />
              </Bullets2>
              <EscapeWrapper2 margintop="0px">
                <AboutPara2>
                  Vi erbjuder mobilapp- och webbaserade lösningar för att hjälpa
                  våra kunder att effektivt hantera olika Dimensions of HR.
                </AboutPara2>
              </EscapeWrapper2>
            </FlexWrapper>
            <FlexWrapper noMargin justifyContent="center">
              <Bullets3>
                <AboutImage src={DOHR} width="2.5vw" />
              </Bullets3>
              <EscapeWrapper2 margintop="0px">
                <AboutPara3>
                  Vårt mål är att ge våra kunder bästa möjliga service och göra
                  deras vardag så bekymmersfri som möjligt.
                </AboutPara3>
              </EscapeWrapper2>
            </FlexWrapper>
            <FlexWrapper noMargin justifyContent="center">
              <Bullets4>
                <AboutImage src={DOHR} width="2.5vw" />
              </Bullets4>
              <EscapeWrapper2 margintop="0px">
                <AboutPara4>
                  Vi strävar alltid efter att matcha våra kandidaters karriärmål
                  med våra kunders behov.
                </AboutPara4>
              </EscapeWrapper2>
            </FlexWrapper>
            {/* <FlexWrapper noMargin noPadding justifyContent="center">
            <Icon
              data-aos="zoom-out-down"
              data-aos-delay="600"
              size="2x"
              icon={["fas", "arrow-down-long"]}
            />
          </FlexWrapper> */}
          </AContainer>
        )}

        <FlexWrapper style={{ marginTop: "3vw" }} noMargin noPadding>
          <TableStartButton
            marginleft="10vw"
            marginbottom="4vw"
            data-aos="zoom-in"
            onClick={() => {
              history.push(ROUTES.REGISTER_IN_OPTION);
            }}
          >
            {lang.footer.gswith}
          </TableStartButton>
          <TableStartButton
            data-aos="zoom-in"
            marginbottom="4vw"
            onClick={() => {
              history.push(ROUTES.CONTACT_FORM);
            }}
            marginLeft="1.5vw"
          >
            {lang.header.main.contactUs}
          </TableStartButton>
        </FlexWrapper>
      </AboutPageWrapper>
      <NewStyledSection>
        <NewSectionQuotes>&#8220;</NewSectionQuotes>
        <NewSectionline />
        <NewSectionTitle data-aos="fade-right" data-aos-delay="600">
          {lang.signUp.about.C}
        </NewSectionTitle>
        {openapplication === "Open applications" ? (
          <NewSectionTitle
            data-aos="zoom-in"
            data-aos-delay="900"
            bold="600"
            size="40px"
          >
            While we love different kinds of music, <br /> cuisine, and other things,
            there is something <br /> that brings us together. It is our <br />{" "}
            <Dohr style={{ fontWeight: "800" }}>company</Dohr> and our shared
            vision, <br /> "make different{" "}
            <Dohr style={{ fontWeight: "800" }}>Dimensions of HR</Dohr> <br />
            more manageable and{" "}
            <Dohr style={{ fontWeight: "800", color: "blue" }}>
              {" "}
              affordable
            </Dohr>
            ".
          </NewSectionTitle>
        ) : (
          <NewSectionTitle
            data-aos="zoom-in"
            data-aos-delay="900"
            bold="600"
            size="40px"
          >
            Även om vi är olika och har olika favoritmusik, <br /> mat, och
            intressen, förenas vi tillsammans <br /> på grund av vår gemensamma
            starka vision; <br /> att göra olika{" "}
            <Dohr style={{ fontWeight: "800" }}>Dimensions of HR</Dohr>{" "}
            lätthanterade <br /> och mer kostnadseffektiva.
          </NewSectionTitle>
        )}
      </NewStyledSection>

      <Footers />
    </PageWrapper>
  );
};

export default About;
