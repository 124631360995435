import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./login/reducer";
import AbsenceManagementReducer from "./Teachers/RegisterAbsence/reducer";
import MyScheduleReducer from "./Teachers/MySchedule/reducer";
import VacancyReducer from "./Hq_Management/Vacancy/reducer";
import LeaveMasterReducer from "./Hq_Management/LeaveManagement/reducer";
import LanguageReducer from "./Language/reducer";
import CoverReducer from "./Teachers/CoverManagenment/reducer";
import CommonReducer from "./Common/reducer";
import chatReducer from "./Hq_Management/chat/reducer";
import instituteReducer from "./Institute/reducer";

const reducer = {
  login: loginReducer,
  Absence: AbsenceManagementReducer,
  MySchedule: MyScheduleReducer,
  Vacancy: VacancyReducer,
  LeaveMaster: LeaveMasterReducer,
  Language: LanguageReducer,
  coverManagement: CoverReducer,
  common: CommonReducer,
  chat: chatReducer,
  institute: instituteReducer,
};

const initialState = {};

export const store = configureStore({
  reducer: reducer,
  devTools: process.env.NODE_ENV !== "production",
  preloadedState: initialState,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
