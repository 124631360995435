/* eslint-disable jsx-a11y/anchor-is-valid */
import { ReactElement, useEffect } from "react";
import { PageWrapper } from "../../Login/subcomponent";
import {
  CardContainer,
  CardImage,
  CardText,
  CardTitle,
  Container,
  ContentContainer,
  Header,
  ImageWrapper,
  InfoHeader,
  MobileWrapper,
  Para,
} from "../Customer/subcomponent";
import playStore from "../../../asserts/google-play.png";
import appStore from "../../../asserts/iphone.png";
import Aos from "aos";
import { FlexWrapper, Footers } from "../../../components";
import { Card } from "react-bootstrap";
import complete from "../../../asserts/medical-report.png";
import login from "../../../asserts/Customer/in.png";
import recruitment from "../../../asserts/staff.png";
import assignment from "../../../asserts/person.png";
import pay from "../../../asserts/paid.png";
import { useHistory } from "react-router-dom";
import ROUTES from "../../../const/routes";
import { ApplyButton, FaqRedirec } from "./subcomponents";
import download from "../../../asserts/Customer/smartphone.png";
import { colors } from "../../../const/theme";
import { StyledCard } from "../../Login/ourSolution/staffAbsence/subcomponent";
import { useDispatch } from "react-redux";
import { updatefaq } from "../../../redux/Common/action";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

const Substitute = (): ReactElement => {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    Aos.init({
      duration: 1000,
      once: false,
    });
    window.scrollTo({ top: 0 });
  }, []);
  const { lang } = useSelector((state: RootState) => ({
    lang: state.Language.language,
  }));
  const {
    header,
    headerpara_1,
    headerpara_2,
    headerpara_3,
    headerpara_4,
    applynow,
    subheading,
    subheading_1,
    subheading_2,
    subheading_3,
    subheading_4,
    subheading_5,
    subheading_6,
    subheadingpara_1_1,
    subheadingpara_1_2,
    subheadingpara_2_1,
    subheadingpara_2_2,
    subheadingpara_3_1,
    subheadingpara_3_2,
    subheadingpara_3_3,
    subheadingpara_4_1,
    subheadingpara_4_3,
    subheadingpara_4_2,
    subheadingpara_5_1,
    subheadingpara_6_1,
    subheadingpara_6_2,
    subheadingpara_2_3,
    lastline_1,
    lastline_2,
    lastline_3,
  } = lang.signUp.getstarted_substitute;
  return (
    <PageWrapper>
      <MobileWrapper>
        <Container data-aos="fade-right" space="3vw 0px 0px 1vw">
          <Header>{header}</Header>
          <Para width="58vw">
            {headerpara_1} <br /> {headerpara_2}
          </Para>
          <Para width="50vw">
            {headerpara_3} <br />
            {headerpara_4}
          </Para>
          <ImageWrapper>
            <a href="">
              <img src={appStore} alt="" />
            </a>
            <a href="">
              <img src={playStore} alt="" />
            </a>
          </ImageWrapper>
          <ApplyButton
            className='mb-4'
            onClick={() => {
              history.push(ROUTES.REGISTER_FORM);
            }}
          >
            {applynow}
          </ApplyButton>
        </Container>
      </MobileWrapper>
      <ContentContainer>
        <FlexWrapper justifyContent="center">
          <InfoHeader>{subheading}</InfoHeader>
        </FlexWrapper>
        <CardContainer paddingTop="10px">
          <StyledCard style={{ width: "16rem" }} className="text-center">
            <CardImage data-aos="zoom-in" variant="top" src={download} />
            <Card.Body>
              <CardTitle color={colors.darkBlue}>{subheading_1}</CardTitle>
              <CardText>
                {subheadingpara_1_1} <br /> {subheadingpara_1_2}
              </CardText>
            </Card.Body>
          </StyledCard>
          <StyledCard style={{ width: "16.5rem" }} className="text-center">
            <CardImage data-aos="zoom-in" variant="top" src={complete} />
            <Card.Body>
              <CardTitle
                color={colors.darkBlue}
                // onClick={() => {
                //   history.push(ROUTES.REGISTER_FORM);
                // }}
              >
                {subheading_2}
              </CardTitle>
              <CardText>
                {subheadingpara_2_1} <br /> {subheadingpara_2_2}
                <br /> {subheadingpara_2_3}
              </CardText>
            </Card.Body>
          </StyledCard>
          <StyledCard style={{ width: "18rem" }} className="text-center">
            <CardImage data-aos="zoom-in" variant="top" src={recruitment} />
            <Card.Body>
              <CardTitle color={colors.darkBlue}>{subheading_3}</CardTitle>
              <CardText>
                {subheadingpara_3_1}
                <br /> {subheadingpara_3_2}
                <br />
                {subheadingpara_3_3}
              </CardText>
            </Card.Body>
          </StyledCard>
        </CardContainer>
        <CardContainer paddingTop="40px" paddingBottmo="30px">
          <StyledCard style={{ width: "16rem" }} className="text-center">
            <CardImage data-aos="zoom-in" variant="top" src={login} />
            <Card.Body>
              <CardTitle color={colors.darkBlue}>{subheading_4}</CardTitle>
              <CardText>
                {subheadingpara_4_1} <br /> {subheadingpara_4_2} <br />
                {subheadingpara_4_3}
              </CardText>
            </Card.Body>
          </StyledCard>
          <StyledCard style={{ width: "18rem" }} className="text-center">
            <CardImage data-aos="zoom-in" variant="top" src={assignment} />
            <Card.Body>
              <CardTitle color={colors.darkBlue}>{subheading_5}</CardTitle>
              <CardText>
                {subheadingpara_5_1}
                <br />
              </CardText>
            </Card.Body>
          </StyledCard>

          <StyledCard style={{ width: "16rem" }} className="text-center">
            <CardImage data-aos="zoom-in" variant="top" src={pay} />
            <Card.Body>
              <CardTitle color={colors.darkBlue}>{subheading_6}</CardTitle>
              <CardText>
                {subheadingpara_6_1} <br /> {subheadingpara_6_2}
              </CardText>
            </Card.Body>
          </StyledCard>
        </CardContainer>
      </ContentContainer>
      <FaqRedirec data-aos="zoom-in">
        {lastline_1}{" "}
        <span
          style={{
            cursor: "pointer",
            color: "#2097F5",
            textDecoration: "none",
          }}
          onClick={() => {
            dispatch(updatefaq("first"));
            history.push(ROUTES.STATIC_FAQ);
          }}
        >
          {lastline_2}
        </span>{" "}
        {lastline_3}
      </FaqRedirec>
      <Footers />
    </PageWrapper>
  );
};

export default Substitute;
