import { ReactElement } from "react";
import { FlexWrapper, PageWrapper, SectionTitle } from "../../../../components";
import { TableHeaderData } from "./const";
import { Table } from "react-bootstrap";
import { CardWraper } from "../../../HqManagement/AbsenceManagement/Vacancies/PublishedVacancies/subcomponents";
import { SliderIcon, TrashIcon } from "./subcomponents";

const HQ_MyAbsence = (): ReactElement => {
  return (
    <PageWrapper>
      <CardWraper>
        <FlexWrapper
          backgroundColor="#eff3fa"
          noMargin
          justifyContent="space-between"
        >
          <SectionTitle title="My Absence" noMargin />
          <SliderIcon icon={["fas", "sliders"]} />
        </FlexWrapper>
        <FlexWrapper justifyContent="center">
          <Table striped borderless hover>
            <thead
              style={{
                backgroundColor: "#2097f5",
                color: "white",
                fontFamily: "Montserrat",
              }}
            >
              <tr style={{ textAlign: "center" }}>
                {TableHeaderData.map((data, index) => (
                  <th key={`Header Unique id - ${index}`}>{data}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr style={{ textAlign: "center" }}>
                <td>From Date</td>
                <td>To Date</td>
                <td>Type of Leave</td>
                <td>Status</td>
                <td>
                  <TrashIcon icon={["far", "trash-can"]} />
                </td>
              </tr>
            </tbody>
          </Table>
        </FlexWrapper>
      </CardWraper>
    </PageWrapper>
  );
};

export default HQ_MyAbsence;
