import { ReactElement } from 'react'
import { CookiesWrapper } from "./supcomponent"

const CookiesConsent = (): ReactElement => {
    return (
        <div>
            <CookiesWrapper
                buttonText="Accept" 
                sameSite='Strict'
            >
                <p>
                We use cookies or similar technologies to enhance your browsing experience and provide personalised recommendations. By clicking "Accept", you consent to our use of cookies.
                    
                </p>
            </CookiesWrapper>
        </div>
    )
}

export default CookiesConsent
