import { ReactElement } from "react";
import { Modal as BootstrapModal, Button } from "react-bootstrap";
import { colors } from "../../const/theme";
import { Body, H3 } from "../../typography";
import ModalProps from "./typings";
import Buttons from "./subcomponent";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const Modals = ({
  title,
  sizeModal,
  handleCancel,
  handleSubmit,
  children,
  submitButtonText,
  cancelButtonText,
  disableButton,
  show,
  center,
  description,
}: ModalProps): ReactElement => {
  const { lang } = useSelector((state: RootState) => ({
    lang: state.Language.language,
  }));

  const{submits,cancel

  }=lang.register
  return (
    <BootstrapModal
      show={show}
      onHide={handleCancel}
      size={sizeModal}
      centered={center}
      backdrop="static"
      keyboard={false}
    >
      <BootstrapModal.Header closeButton>
        {title && (
          <H3 fontStyle color={colors.darkBlue}>
            {title}
          </H3>
        )}
      </BootstrapModal.Header>
      {description && (
        <BootstrapModal.Body>
          <Body>{description}</Body>
        </BootstrapModal.Body>
      )}
      {children && children}
      <BootstrapModal.Footer>
        <Button variant="danger" onClick={handleCancel}>
          {cancelButtonText || cancel}
        </Button>
        <Buttons onClick={handleSubmit} disabled={disableButton}>
          {submitButtonText || submits}
        </Buttons>
      </BootstrapModal.Footer>
    </BootstrapModal>
  );
};

export default Modals;
