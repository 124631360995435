import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getChatListResponse,
  getChatMessageResponse,
  initialState,
} from "./types";
import { getChatList, getChatMessage, sendMessage } from "./api";

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    updateChatId: (state, action: PayloadAction<any>) => {
      state.updateChatId = action?.payload;
    },
  },
  extraReducers: {
    [getChatList.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getChatList.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<getChatListResponse>>
    ) => {
      state.isLoading = false;
      state.getChatDataList = action.payload;
    },
    [getChatList.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [getChatMessage.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getChatMessage.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<getChatMessageResponse>>
    ) => {
      state.isLoading = false;
      state.getChatMessageData = action.payload;
    },
    [getChatMessage.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [sendMessage.pending.toString()]: (state) => {
      state.isLoadingChatSent = true;
    },
    [sendMessage.fulfilled.toString()]: (state) => {
      state.isLoadingChatSent = false;
    },
    [sendMessage.rejected.toString()]: (state) => {
      state.isLoadingChatSent = false;
    },
  },
});

export default chatSlice.reducer;
