import { ReactElement, useEffect, useState } from "react";
import { Accordion, Col, Nav, Row, Tab } from "react-bootstrap";
import { useSelector } from "react-redux";
import { SectionTitle, FlexWrapper, Footers } from "../../components";
import {
  ContainerWrapper,
  NewsHeader,
} from "../../container/More/News/subcomponent";
import { RootState } from "../../redux/store";
import { CardWraper } from "../HqManagement/AbsenceManagement/unCovered/subcomponent";
import CustomerQ from "./customerQ";
import JobSeekerQ from "./jobSeekerQ";
import { AccorHead, NavLink, PageWrapper, TabWrapper } from "./subcomponents";
import SubstituteQ from "./substituteQ";

export const StaticSiteFAQ = (): ReactElement => {
  const { sect, lang } = useSelector((state: RootState) => ({
    sect: state.common.faq,
    lang: state.Language.language.signUp,
  }));

  const { customer, substitutes, jobseekers } = lang.faq;
  const { title } = lang.faq;

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <PageWrapper margintop="4%">
      <ContainerWrapper>
        <FlexWrapper justifyContent="center" noMargin noPadding>
          <NewsHeader>{title}</NewsHeader>
        </FlexWrapper>
      </ContainerWrapper>
      <TabWrapper>
        <Tab.Container id="left-tabs-example" defaultActiveKey={sect}>
          <Row>
            <Col sm={2}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item style={{ padding: "4% 0px" }}>
                  <NavLink eventKey="first">{substitutes}</NavLink>
                </Nav.Item>
                <Nav.Item style={{ padding: "4% 0px" }}>
                  <NavLink eventKey="second">{customer}</NavLink>
                </Nav.Item>
                <Nav.Item style={{ padding: "4% 0px" }}>
                  <NavLink eventKey="third">{jobseekers}</NavLink>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={10}>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <SubstituteQ />
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <CustomerQ />
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  <JobSeekerQ />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </TabWrapper>
      <Footers />
    </PageWrapper>
  );
};

export const FAQ = (): ReactElement => {
  const [active1, setActive1] = useState("");
  return (
    <PageWrapper>
      <CardWraper>
        <FlexWrapper
          backgroundColor="#eff3fa"
          noMargin
          justifyContent="space-between"
        >
          <SectionTitle title="Frequently Asked Questions" noMargin />
        </FlexWrapper>
        <Accordion>
          <Accordion.Item
            eventKey="0"
            onClick={() => {
              setActive1("0");
            }}
          >
            <Accordion.Header>
              <AccorHead
                style={{ color: active1 === "0" ? "#2097f5" : "black" }}
              >
                How do I book a substitute for a longer period of time (for
                example more than one month) ?
              </AccorHead>
            </Accordion.Header>
            <Accordion.Body>
              You can contact DoHR - Insert email adress and we will be more
              than happy to help you with your request.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="1"
            onClick={() => {
              setActive1("1");
            }}
          >
            <Accordion.Header>
              <AccorHead
                style={{ color: active1 === "1" ? "#2097f5" : "black" }}
              >
                How do I report a complaint or an issue ?
              </AccorHead>
            </Accordion.Header>
            <Accordion.Body>
              You can contact DoHR - insert email adress and we will be more
              than happy to help you reach a resolution.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </CardWraper>
    </PageWrapper>
  );
};
