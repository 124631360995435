import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const TrashIcon = styled(FontAwesomeIcon)`
  :hover {
    color: red;
  }
`;

export const SliderIcon = styled(FontAwesomeIcon)`
  font-size: 22px;
`;
