import { Row, Col, Button } from "react-bootstrap";
import styled from "styled-components";

interface ContactUsContainerProps {
  marginTop?: string;
}
export const ContactUsContainer = styled.div<ContactUsContainerProps>`
  padding-top: 45px;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "0px")};
  padding-bottom: 40px;
  color: #ffffff;
  background-color: #002c99;
  box-sizing: border-box;
  @media (max-width: 415px) {
  }
`;
export const ContactRowWrapper = styled(Row)`
  color: white;
  padding-left: 5rem;
  padding-right: 3rem;
  @media (max-width: 415px) {
    padding-left: 2.5rem;
    padding-right: 0;
  }
  @media (max-width: 600px) {
    padding-left: 2.5rem;
    padding-right: 0;
  }
`;

interface ColoumnProps {
  paddingleft?: string;
}

export const ContactColWrapper = styled(Col)<ColoumnProps>`
  padding-left: ${({ paddingleft }) => (paddingleft ? paddingleft : "0")};
  .apple {
    left: -8px;
  }
  img {
    position: relative;
    top: 0;
    display: inline-block;
    margin: -9px -9px -9px -5px;
    left: 0;
    cursor: pointer;
    object-fit: contain;
    width: 162px;
    height: 69px;
    @media (max-width: 850px) {
      position: relative;
      right: 6px;
      width: 110%;
      height: 22%;
      padding: 10px 0 0 0;
    }
    @media (max-width: 600px) {
      position: relative;
      right: 6px;
      width: 29%;
      height: 63%;
    }
    @media (max-width: 415px) {
      position: relative;
      right: 6px;
      top: -10px;
      width: 38%;
      height: 58%;
    }
  }
  @media (max-width: 415px) {
    padding-left: 0;
    padding-top: 10px;
  }
  @media (max-width: 600px) {
    padding-left: 0;
    padding-top: 10px;
  }
  a {
    text-decoration: none;
  }
  i {
    width: 35px;
    height: 35px;
    display: inline-flex;
    border-radius: 50%;
    border: none;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    margin-right: 10px;
    margin-bottom: 23px;
    transition: all 0.5s ease;
    &:hover {
      transform: scale(1.1) translateY(-5px);
      border: none;
      transition: all 0.5s ease;
    }
  }
  #twi {
    color: #4267b2;
    background: white;
    &:hover {
      background: #4267b2;
      color: white;
    }
  }
  #link {
    color: #0a66c2;
    background: white;
    &:hover {
      background: #0a66c2;
      color: white;
    }
  }
  #insta {
    color: #bc1888;
    background: white;
    &:hover {
      background: linear-gradient(
        45deg,
        #f09433 0%,
        #e6683c 25%,
        #dc2743 50%,
        #cc2366 75%,
        #bc1888 100%
      );
      color: white;
    }
  }
`;

export const AppStore = styled.img`
  width: 161px;
  height: 69px;
`;
export const PalyStore = styled.img`
  width: 171px;
  height: 69px;
`;

export const Icon = styled(Button)`
  background: none;
  border: 0.5px solid #2097f5;
  font-size: 20px;
  :hover&,
  :focus&,
  :active {
    background: none;
    border: 0.5px solid #2097f5;
    transform: scale(1.1);
  }
`;
//-----------------------------------footer-----------------------------------------------
export const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  @media (max-width: 415px) {
    padding: 7px 1rem 40px 1rem;
    display: block;
  }
  @media (max-width: 600px) {
    padding: 7px 0 30px 1rem;
    display: block;
  }
`;

export const FooterDivider = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 415px) {
    display: flex;
    padding-bottom: 10px;
  }
  @media (max-width: 600px) {
    display: flex;
    padding-bottom: 10px;
  }
`;

interface FooterProps {
  noPadding?: boolean;
}

export const FooterText = styled.div<FooterProps>`
  font-size: 1.2vw;
  width: auto;
  cursor: pointer;
  padding-left: ${({ noPadding }) => (noPadding ? "0" : "3vw")};
`;
//-----------------------------------common-----------------------------------------------

interface MainHeaderProps {
  justifyContent?: string;
  padding?: boolean;
}

export const MainHeader = styled.h4<MainHeaderProps>`
  display: flex;
  flex-wrap: wrap;
  padding: ${({ padding }) => (padding ? "12px 0" : "0")};
  ${({ justifyContent }) =>
    justifyContent && `justify-content: ${justifyContent};`}
  font-size: 20px;
  @media (max-width: 415px) {
    font-size: 15px;
  }
`;

export const ParaWrapper = styled.p`
  @media (max-width: 415px) {
    font-size: 13px;
  }
`;

export const Header = styled.h4`
  font-size: 18px;
  text-align: start;
  font-weight: 600;
  padding-bottom: 10px;
`;

export const Content = styled.p`
  padding-bottom: 10px;
  padding-left: 3px;
  line-height: 1.6;
  font-size: 14.5px;
`;

interface BorderProps {
  color?: string;
}

export const BorderTop = styled.div<BorderProps>`
  margin: 0 0 1rem;
  width: 12.875rem;
  height: 0.5rem;
  border-radius: 1rem;
  background-color: ${({ color }) => (color ? color : "red")};
`;

export const Border = styled.hr`
  width: 93%;
  margin: 0 auto;
  @media (max-width: 415px) {
    width: 93%;
    margin-left: 16px;
  }
`;

export const FooterMenu = styled.p`
  cursor: pointer;
  margin-bottom: 2px;
  &:hover,
  &:focus,
  &:active {
    font-size: 14px;
  }
`;

export const FooterLogo = styled.img`
  width: 3vw !important;
  margin-right: 2.5vw;
  margin-left: 1vw;
`;
