import {
  GetAllLeaveReqProps,
  getMyInstituteResponse,
  initialState,
} from "./typings";
import {
  ApproveLeaveApi,
  DenyLeaveApi,
  GetAllLeaveApi,
  getMyInstitute,
} from "./api";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const LeaveMaster = createSlice({
  name: "leaveMaster",
  initialState,
  reducers: {
    updateSuccess: (state, action: PayloadAction<any>) => {
      state.isSuccess = action.payload;
    },
  },
  extraReducers: {
    [GetAllLeaveApi.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [GetAllLeaveApi.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<GetAllLeaveReqProps>>
    ) => {
      state.getAllLeaveReq = action.payload;
      state.isLoading = false;
    },
    [GetAllLeaveApi.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [getMyInstitute.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<getMyInstituteResponse>>
    ) => {
      state.getMyidInstitute = action.payload;
    },
    [DenyLeaveApi.fulfilled.toString()]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.message = action.payload;
    },
    [ApproveLeaveApi.pending.toString()]: (state) => {
      state.isSuccess = false;
    },
    [ApproveLeaveApi.fulfilled.toString()]: (state) => {
      state.isSuccess = true;
    },
    [ApproveLeaveApi.rejected.toString()]: (state) => {
      state.isSuccess = false;
    },
  },
});

export default LeaveMaster.reducer;
