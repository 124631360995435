import { Button, Card, Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../../const/theme";

interface CardProsp {
  backGround?: string;
  top?: string;
}

export const FlexWrapperSign = styled.div`
  padding: 4vw 2vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: auto;
  margin-top: 3%;
`;

export const CardWrapper = styled(Card)<CardProsp>`
  width: 25%;
  padding: 16px;
  margin-right: auto;
  margin-left: auto;
  margin-top: ${({ top }) => (top ? top : "9rem")};
  height: 20rem;
  text-align: center;
  display: flex;
  justify-content: center;
  border: none;
  background: ${({ backGround }) => (backGround ? backGround : "none")};
  transition: 0.5s;
  @media (max-width: 415px) {
    width: 80%;
    margin-bottom: 16px;
    transition: 0.5s;
  }
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: #e3f2fc;
    height: auto;
    transition: 0.5s;
  }
  @media (max-width: 650px) {
    width: 86%;
    height: 33%;
    transition: 0.5s;
  }
  @media (max-width: 415px) {
    width: 86%;
    height: 33%;
    transition: 0.5s;
  }
`;

export const CardText = styled.p`
  font-size: 1.4vw;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  color: ${colors.darkBlue};
  position: relative;
  top: 16px;
  width: 100%;
  @media (max-width: 550px) {
    font-size: 15px;
  }
`;
export const StyledModal = styled(Modal)`
  margin-top: 6%;
  @media (max-width: 575px) {
    margin-left: 14%;
    margin-top: 10%;
  }
  @media (max-width: 500px) {
    margin-left: 8%;
    margin-top: 10%;
  }
  @media (max-width: 450px) {
    margin-left: 4%;
    margin-top: 10%;
  }
  @media (max-width: 415px) {
    margin-left: 0%;
    margin-top: 10%;
  }
`;

export const CardHeader = styled.img`
  width: 50%;
  margin-top: 6%;
  margin-bottom: 4%;
`;

export const ForgetPassword = styled.p`
  font-size: 11px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #2097f5;
  margin-top: -1vw;
  text-decoration: none;
  cursor: pointer;
`;

export const ModalWrapper = styled.div`
  width: fit-content;
`;

export const SigninButton = styled(Button)`
  background: ${colors.darkBlue};
  color: white;
  border: 0.1 solid ${colors.darkBlue};
  border-radius: 4vw;
  box-shadow: transparent 0 0 0 3px, rgba(0, 0, 0, 0.1) 0 6px 20px;
  cursor: pointer;
  font-family: Inter, sans-serif;
  font-size: 1vw;
  font-weight: 700;
  margin-top: ${({ margintop }) => (margintop ? margintop : "2.7vw")};
  &:hover {
    background: ${colors.darkBlue};
    color: white;
  }
  @media (max-width: 550px) {
    font-size: 12px;
  }
`;

export const Image = styled.img`
  width: 4vw;
  object-fit: contain;
  @media (max-width: 550px) {
    width: 30%;
  }
`;

export const SignInButton = styled(Button)`
  background-image: linear-gradient(195deg, #2c62eb, #1b97f7);
  color: ${colors.white};
  border: none;
  font-size: 0.8rem;
  font-weight: 600;
  letter-spacing: 0.02857em;
  border-radius: 0.5rem;
  text-align: center;
  padding: 0.625rem 1.5rem;
  text-align: center;
  background-size: 300% 100%;
  transition: all 0.4s ease-in-out;
  &:focus,
  &:active {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    transition: 0.3s;
  }
  &:hover {
    background-position: 100% 0;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    background-image: linear-gradient(
      to right,
      #bf7dc4,
      #0047fa,
      #2c62eb,
      #1b97f7
    );
    box-shadow: 0 5px 15px rgba(242, 97, 103, 0.4);
  }
  margin: auto 4px;
`;

export const FormCheckForm = styled(Form.Check)`
  font-size: 11px;
  margin-top: -1vw;
`;

export const DIV = styled.div`
  width: fit-content;
  label {
    font-family: "Montserrat", sans-serif;
    font-weight: 600 !important;
    color: #2097f5 !important;
  }
  label:hover {
    color: ${colors.darkBlue} !important;
  }
`;

export const Circle = styled.div`
  display: flex;
  justify-content: center;
`;
export const ForgotLock = styled.div`
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background-color: #2097f5;
`;
export const ForgotHeading = styled.h1`
  color: #2097f5;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  padding-top: 80px;
`;
export const ForgotPara = styled.p`
  color: #0532a6;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
`;
export const Contact = styled.p`
  color: #2097f5;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
`;
