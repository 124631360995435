import { Card } from "react-bootstrap";
import styled from "styled-components";

export const ContactCardWraper = styled(Card)`
  display: flex;
  justify-content: center;
  width: 70%;
  margin: 1rem auto;
  margin-top: 4%;
  border: none;
`;

//-----------------------------------------

export const Container = styled.div`
  background: linear-gradient(to right, #002c99, #2097f5);
  height: auto;
  padding: 25vh 0px 20vh 0px;
  @media (max-width: 500px) {
    padding: 15vh 0px 10vh 0px;
  }
  
`;

export const PageContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden !important;
  font-family: "Montserrat";
  @media (max-width: 460px) {
    width: 100%;
    overflow: auto;
  }
`;

export const Header = styled.p`
  font-size: 4vw;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  color: whitesmoke;
  @media (max-width: 500px) {
    font-size: 20px;
  }
`;

export const SubHeader = styled.p`
  font-size: 2vw;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  color: whitesmoke;
  @media (max-width: 500px) {
    font-size: 15px;
    padding: 10px;
  }
`;

export const Coversation = styled.span`
  color: #002c99;
  font-size: 4vw;
  @media (max-width: 500px) {
    font-size: 20px;
  }
`;

export const TextWrapper = styled.div`
  label {
    color: #002c99 !important;
    font-family: "Montserrat" !important;
    font-weight: 400 !important;
    font-size: 15px;
  }
`;
