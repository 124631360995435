import { useState } from "react";
import { Accordion } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FlexWrapper } from "../../components";
import ROUTES from "../../const/routes";
import { AccorHead } from "./subcomponents";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
const CustomerQ = () => {
  const history = useHistory();
  const [active, setActive] = useState("");

  const { lang } = useSelector((state: RootState) => ({
    lang: state.Language.language,
  }));

  const {
    cusans1,
    cusans2,
    cusans3,
    cusans4_1,
    cusans4_2,
    cusans4_3,
    cusques1,
    cusques2,
    cusques3,
    cusques4,
  } = lang.signUp.faq;

  return (
    <FlexWrapper noMargin noPadding>
      <div
        style={{
          width: "95%",
        }}
      >
        <Accordion>
          <Accordion.Item
            eventKey="0"
            onClick={() => {
              setActive("0");
            }}
          >
            <Accordion.Header>
              <AccorHead
                style={{
                  color: active === "0" ? "#2097f5" : "#002C99",
                }}
              >
                {cusques1}
              </AccorHead>
            </Accordion.Header>
            <Accordion.Body>{cusans1}</Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="1"
            onClick={() => {
              setActive("1");
            }}
          >
            <Accordion.Header>
              <AccorHead
                style={{
                  color: active === "1" ? "#2097f5" : "#002C99",
                }}
              >
                {cusques2}
              </AccorHead>
            </Accordion.Header>
            <Accordion.Body>{cusans2}</Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="2"
            onClick={() => {
              setActive("2");
            }}
          >
            <Accordion.Header>
              <AccorHead
                style={{
                  color: active === "2" ? "#2097f5" : "#002C99",
                }}
              >
                {cusques3}
              </AccorHead>
            </Accordion.Header>
            <Accordion.Body>{cusans3}</Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="3"
            onClick={() => {
              setActive("3");
            }}
          >
            <Accordion.Header>
              <AccorHead
                style={{
                  color: active === "3" ? "#2097f5" : "#002C99",
                }}
              >
                {cusques4}
              </AccorHead>
            </Accordion.Header>
            <Accordion.Body>
              {cusans4_1}{" "}
              <span
                style={{ color: "#1F94F3", cursor: "pointer" }}
                onClick={() => {
                  history.push(ROUTES.CONTACT_FORM);
                }}
              >
                {cusans4_2}
              </span>{" "}
              {cusans4_3}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </FlexWrapper>
  );
};

export default CustomerQ;
