import { ReactElement } from "react";
import { Toast, CloseButton } from "react-bootstrap";
import { Small } from "../../typography";
import styled from "styled-components";
import { colors } from "../../const/theme";

interface ToastProps {
  top?: string;
  right?: string;
}

const ToastWrapper = styled(Toast)<ToastProps>`
  margin: auto;
  position: absolute;
  width: auto;
  right: ${({ right }) => (right ? `${right}` : "36%")};
  background-color: ${({ bg }) => (bg ? `${bg}` : "green")};
  top: ${({ top }) => (top ? `${top}` : "15px")};
  @media (max-width: 600px) {
    margin: auto;
    position: absolute;
    left: 17px;
  }
  @media (max-width: 415px) {
    margin: auto;
    position: absolute;
    left: 17px;
  }
`;

const Message = styled(Toast.Body)`
  display: flex;
  justify-content: space-between;
`;

interface TostMessageProps {
  show: boolean;
  onCloseHandler?: () => void;
  message: string;
  bg: string;
  top?: string;
  right?: string;
}

const ToastMessage = ({
  show,
  onCloseHandler,
  message,
  bg,
  top,
  right,
}: TostMessageProps): ReactElement => {
  return (
    <ToastWrapper
      show={show}
      bg={bg}
      autohide
      delay={3000}
      onClose={onCloseHandler}
      top={top}
      right={right}
    >
      <Message>
        <Small color={colors.white}>{message}</Small>
        {onCloseHandler && (
          <CloseButton
            style={{ padding: "7px 13px", verticalAlign: "middle" }}
            onClick={onCloseHandler}
            variant="white"
          />
        )}
      </Message>
    </ToastWrapper>
  );
};

export default ToastMessage;
