import { ReactElement, useEffect } from "react";
import { PageWrapper } from "../../subcomponent";
import { FlexWrapper } from "../../../../components";
import Aos from "aos";
import { FlipWrapper } from "./subcomponent";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

export const Development = (): ReactElement => {
  useEffect(() => {
    Aos.init({
      duration: 900,
      once: false,
    });
  }, []);
  const { lang } = useSelector((state: RootState) => ({
    lang: state.Language.language,
  }));
  const { c, g, i, m, n, n2, o, o1, o2, s,  } =
    lang.signUp.Onboarding_development;

  return (
    <PageWrapper>
      <FlexWrapper
        style={{ height: "100vh" }}
        justifyContent="center"
        backgroundImage="linear-gradient(to right, #002C99, #2097F5)"
      >
        <FlipWrapper>
          <span style={{ "--i": 1 } as React.CSSProperties}>{c}</span>
          <span style={{ "--i": 2 } as React.CSSProperties}>{o}</span>
          <span style={{ "--i": 3 } as React.CSSProperties}>{m}</span>
          <span style={{ "--i": 4 } as React.CSSProperties}>{i}</span>
          <span style={{ "--i": 5 } as React.CSSProperties}>{n}</span>
          <span style={{ "--i": 6 } as React.CSSProperties}>{g}</span>
          {"  "}
          <span style={{ marginLeft: "4%" }}></span>
          <span style={{ "--i": 7 } as React.CSSProperties}>{s}</span>
          <span style={{ "--i": 8 } as React.CSSProperties}>{o1}</span>
          <span style={{ "--i": 9 } as React.CSSProperties}>{o2}</span>
          <span style={{ "--i": 10 } as React.CSSProperties}>{n2}</span>
          <span style={{ "--i": 12 } as React.CSSProperties}>.</span>
          <span style={{ "--i": 13 } as React.CSSProperties}>.</span>
          <span style={{ "--i": 14 } as React.CSSProperties}>.</span>
          
        </FlipWrapper>
      </FlexWrapper>
    </PageWrapper>
  );
};
