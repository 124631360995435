import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { ReactElement, useEffect, useState, useRef } from "react";
import { Form, Modal, Overlay, Table, Tooltip } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  ActionButton,
  DropdownWrapper,
  FlexWrapper,
  Icon,
  Input,
  Loader,
  Modals,
  PageWrapper,
  SectionTitle,
  ToastMessage,
} from "../../../../../components";
import apiEndpoints from "../../../../../const/apiEndpoints";
import { TYPE } from "../../../../../const/getType";
import ROUTES from "../../../../../const/routes";
import { CommonDelete } from "../../../../../redux/Common/api";
import {
  updateCreateVacancyErr,
  updateSelectedTeacherId,
  updateSelectedVacancyDetails,
} from "../../../../../redux/Hq_Management/Vacancy/action";
import {
  CreateInternalVacancyApi,
  getTeacher,
  getUnCovered,
} from "../../../../../redux/Hq_Management/Vacancy/api";
import {
  Data,
  ExternalVacancy,
  InternalVacancy,
} from "../../../../../redux/Hq_Management/Vacancy/types";
import { RootState } from "../../../../../redux/store";
import { CardWraper, Tooltiped } from "../../unCovered/subcomponent";
import { teacherHeader } from "./const";
import { PrimaryButton } from "../../../../../components/Button";
import { Label } from "../../../../../typography";
import {
  CancleButton,
  DeleteButton,
} from "../../../../Institute/Management/ViewCustomer/subcomponents";

const HQUncobveredTable = (): ReactElement => {
  const {
    unCoveredData,
    userInfo,
    getTeacherDetails,
    setSelectedTeacherId,
    errMsg,
    lang,
    isLoading,
    reload,
  } = useSelector(
    (state: RootState) => ({
      unCoveredData: state.Vacancy.getUncoveredData,
      isLoading: state.Vacancy.isLoading,
      userInfo: state.login.userInfo,
      getTeacherDetails: state.Vacancy.getTeacherDetails,
      setSelectedTeacherId: state.Vacancy.setSelectedTeacherId,
      setAssignInternal: state.Vacancy.setAssignInternal,
      setAssignExternal: state.Vacancy.setAssignExternal,
      setVacancyStatus: state.Vacancy.setVacancyStatus,
      selectedUpdateTeacherExternal:
        state.Vacancy.selectedUpdateTeacherExternal,
      errMsg: state.Vacancy.createVacancyErr,
      lang: state.Language.language.Tables.uncovered,
      reload: state.common.reload,
    }),
    shallowEqual
  );

  const target = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const [showModal, setShowModal] = useState(false);
  const [values, setvalues] = useState({ internal: false, external: false });
  const [teacherModal, setteacherModal] = useState(false);
  const [isSingleExternalSelect, setisSingleExternalSelect] = useState<
    Array<ExternalVacancy>
  >([]);
  const [isSingleInternalSelect, setisSingleInternalSelect] = useState<
    Array<InternalVacancy>
  >([]);
  const [selected, setSelected] = useState<Array<InternalVacancy>>([]);

  ///dateValidation
  const [dateError, setdateError] = useState(false);
  const [Error, setError] = useState(false);
  const [Error2, setError2] = useState(false);
  const [timeError, setTimeError] = useState(false);
  const [breakeTime, setbreakeTime] = useState<any>("");
  const [tooltip, settooltip] = useState(false);
  const [pastTimeError, setPastTimeError] = useState(false);
  const arrayValues = selected.some((data) => data);

  const startTime = moment(
    selected.map((d) => d.start_time),
    "HH:mm:ss a"
  );
  const endTime = moment(
    selected.map((d) => d.end_time),
    "HH:mm:ss a"
  );
  const duration = moment.duration(endTime.diff(startTime));
  const getHours = duration.hours();
  const minutes = duration.minutes();
  let hh = getHours < 10 ? `0${getHours}` : getHours;
  let mm = minutes < 10 ? `0${minutes}` : minutes;

  const isBoolean = Error || Error2;

  useEffect(() => {
    if (userInfo?.type === TYPE.CUSTOMER) {
      dispatch(
        getUnCovered({
          ins_id: userInfo?.data.ins_id,
        })
      );
      dispatch(
        getTeacher({
          ins_id: userInfo?.data?.ins_id,
        })
      );
    } else {
      dispatch(
        getUnCovered({
          ins_id: userInfo?.data.id,
        })
      );
      dispatch(
        getTeacher({
          ins_id: userInfo?.data?.id,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (values.internal) {
      setteacherModal(true);
    }
  }, [values.internal]);

  useEffect(() => {
    if (values.internal === true) {
      setvalues({
        ...values,
        external: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.internal]);

  useEffect(() => {
    if (values.external === true) {
      setvalues({
        ...values,
        internal: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.external]);

  useEffect(() => {
    if (getHours >= 5) {
      setbreakeTime(
        moment(getHours, "hh:mm:ss").add(30, "minutes").format("hh:mm")
      );
    } else {
      setbreakeTime(`${hh}:${mm}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getHours]);

  const [date, setdate] = useState<any>("");

  useEffect(() => {
    if (isBoolean) {
      setSelected([]);
      setisSingleExternalSelect([]);
      setisSingleInternalSelect([]);
    }
  }, [isBoolean]);

  useEffect(() => {
    if (timeError || dateError) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeError || dateError]);

  useEffect(() => {
    if (reload) {
      if (userInfo?.type === TYPE.CUSTOMER) {
        dispatch(
          getUnCovered({
            ins_id: userInfo?.data.ins_id,
          })
        );
      } else {
        dispatch(
          getUnCovered({
            ins_id: userInfo?.data.id,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);
  const [deleteUncovered, setDeleteUncovered] = useState("");

  const modifiedUncoveredList: Array<Data> = [];

  unCoveredData?.map((name) =>
    name?.Data?.map((data) => {
      return modifiedUncoveredList?.push({
        teacher_name: name?.title,
        ...data,
      });
    })
  );

  const futureUncoveredList: Array<Data> = [...modifiedUncoveredList]
    ?.filter((data) => {
      return (
        moment(new Date()).format("DD-MM-YYYY") <=
        moment(data?.v_date).format("DD-MM-YYYY")
      );
    })
    .sort((a, b) => {
      return Number(new Date(a.v_date)) - Number(new Date(b.v_date));
    });

  const [isChecked, setIsChecked] = useState(false);

  return (
    <PageWrapper>
      <Modal
        show={deleteUncovered ? true : false}
        size={"sm"}
        centered={true}
        backdrop="static"
        keyboard={false}
      >
        <FlexWrapper justifyContent="center" width="100%">
          <FlexWrapper justifyContent="center">
            <Label>Are you sure you want to delete?</Label>
          </FlexWrapper>
          <FlexWrapper noPadding>
            <CancleButton
              onClick={() => {
                setDeleteUncovered("");
              }}
            >
              No
            </CancleButton>
            <DeleteButton
              onClick={() => {
                dispatch(
                  CommonDelete({
                    id: deleteUncovered,
                    endPoint: apiEndpoints.deleteUncoveredVacanccy,
                  })
                );
                setDeleteUncovered("");
              }}
            >
              Yes
            </DeleteButton>
          </FlexWrapper>
        </FlexWrapper>
      </Modal>
      <CardWraper>
        <FlexWrapper
          backgroundColor="#eff3fa"
          noMargin
          justifyContent="space-between"
          width="100%"
        >
          <SectionTitle title={lang.sectionTitle} noMargin />
          <FlexWrapper
            noMargin
            noPadding
            width="55%"
            justifyContent="space-between"
          >
            <PrimaryButton
              onClick={() => {
                setdate(moment(new Date()).format("YYYY-MM-DD"));
              }}
            >
              Today
            </PrimaryButton>
            <PrimaryButton
              onClick={() => {
                setdate(
                  moment(new Date().setDate(new Date().getDate() + 1)).format(
                    "YYYY-MM-DD"
                  )
                );
              }}
            >
              Tomorrow
            </PrimaryButton>
            <PrimaryButton
              onClick={() => {
                setdate("");
              }}
            >
              All uncovered
            </PrimaryButton>
            <Input
              value={date}
              inputType="date"
              onChange={(value: any) => {
                setdate(value);
              }}
              height="auto"
              width="auto"
            />
            <ActionButton
              disabled={!arrayValues}
              onClick={() => {
                setShowModal(true);
              }}
            >
              {lang.button}
            </ActionButton>{" "}
          </FlexWrapper>
        </FlexWrapper>
        <ToastMessage
          bg="primary"
          show={timeError || dateError || pastTimeError}
          onCloseHandler={() => {
            setTimeError(false);
            setdateError(false);
            setPastTimeError(false);
          }}
          message={
            dateError
              ? "Two different dates cannot be selected to create a vacancy. Please choose the same date."
              : pastTimeError
              ? "You cannot select a start time and date that has already passed to create vacancy."
              : "The times are overlapping. Please choose a different time entry."
          }
        />
        {isLoading ? (
          <Loader />
        ) : (
          <FlexWrapper justifyContent="center">
            <Table borderless hover>
              <thead
                style={{
                  backgroundColor: "#2097f5",
                  color: "white",
                  fontFamily: "Montserrat",
                }}
              >
                <tr style={{ textAlign: "center" }}>
                  {lang.tableHeader.map((data, index) => (
                    <th key={`Header Unique id -${index}`}>
                      {data === "Select" ? (
                        <Tooltiped
                          title="Choose one or more shifts to create a vacancy. For creating a vacancy without selecting shifts, use the 'Ad hoc vacancy' option under Absence Management."
                          placement="right"
                        >
                          <span style={{ color: "white" }}>
                            Select
                            <FontAwesomeIcon
                              icon={["fas", "info-circle"]}
                              size="sm"
                            />
                          </span>
                        </Tooltiped>
                      ) : data === "Kicka" ? (
                        <Tooltiped
                          title="Choose one or more shifts to create a vacancy. For creating a vacancy without selecting shifts, use the 'Ad hoc vacancy' option under Absence Management."
                          placement="right"
                        >
                          <span style={{ color: "white" }}>
                            Kicka
                            <FontAwesomeIcon
                              icon={["fas", "info-circle"]}
                              size="sm"
                            />
                          </span>
                        </Tooltiped>
                      ) : (
                        data
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <>
                  {futureUncoveredList
                    // eslint-disable-next-line array-callback-return
                    ?.filter((listValues: Data) => {
                      if (date === "") {
                        return listValues;
                      } else if (listValues?.v_date.includes(date)) {
                        return listValues;
                      }
                    })
                    .map((item: Data) => {
                      const combinedDateTimeString = `${item.v_date} ${item.start_time}`;
                      const currentTime = moment(new Date()).format(
                        "YYYY-MM-DD HH:mm:ss"
                      );
                      return (
                        <tr>
                          <td>{moment(item.v_date).format("YYYY/MM/DD")}</td>
                          <td>{item?.teacher_name} </td>
                          <td>{item?.year_group} </td>
                          <td>{item.subject}</td>
                          <td>{item.classroom}</td>
                          <td>{item.start_time}</td>
                          <td>{item.end_time}</td>
                          <td>{""}</td>{" "}
                          {currentTime > combinedDateTimeString ? (
                            <td>
                              <Form.Check
                                checked={isChecked}
                                readOnly
                                onClick={() => {
                                  setPastTimeError(true);
                                  window.scrollTo({
                                    top: 0,
                                    behavior: "smooth",
                                  });
                                }}
                              ></Form.Check>
                            </td>
                          ) : (
                            <td>
                              <Form.Check
                                key={item.id}
                                name={item.first_name}
                                id={item?.id}
                                onClick={(e: any) => {
                                  const { checked } = e.target;
                                  //vDateValidation
                                  var vacanchyDateValidation = selected.some(
                                    (data) => data.v_date !== item.v_date
                                  );

                                  if (checked) {
                                    if (vacanchyDateValidation) {
                                      setdateError(true);
                                      setError(true);
                                    } else {
                                      setdateError(false);
                                      setError(false);
                                    }
                                  }

                                  ///timeVal
                                  let fromTime = selected.some(
                                    (timeDate) =>
                                      timeDate.start_time === item.start_time
                                  );
                                  let toTime = selected.some(
                                    (timeDate) =>
                                      timeDate.end_time === item.end_time
                                  );
                                  function convertTimeToMinutes(time: any) {
                                    const [, hh, mm] =
                                      time.match(/(\d{2}):(\d{2})/);
                                    const minutes =
                                      parseInt(hh, 10) * 60 + parseInt(mm, 10);
                                    return minutes;
                                  }

                                  var isBetweenTime = selected.map((dd) => {
                                    const firstTime = convertTimeToMinutes(
                                      dd?.start_time
                                    );
                                    const secondTime = convertTimeToMinutes(
                                      dd?.end_time
                                    );

                                    const checkTime1 = convertTimeToMinutes(
                                      item?.start_time
                                    );
                                    const checkTime2 = convertTimeToMinutes(
                                      item?.end_time
                                    );

                                    return (
                                      (checkTime1 > firstTime &&
                                        checkTime1 < secondTime) ||
                                      (checkTime2 > firstTime &&
                                        checkTime2 < secondTime)
                                    );
                                    // true
                                  });

                                  if (checked) {
                                    if (fromTime) {
                                      setTimeError(true);
                                      setError2(true);
                                    } else if (toTime) {
                                      setTimeError(true);
                                      setError2(true);
                                    } else if (isBetweenTime[0]) {
                                      setTimeError(true);
                                      setError2(true);
                                    } else {
                                      setTimeError(false);
                                      setError2(false);
                                    }
                                  }
                                  ///dateSet
                                  setSelected([
                                    ...selected,
                                    {
                                      position: item.title,
                                      position_id: item.title_id,
                                      v_date: item.v_date,
                                      absence_id: item.absence_id,
                                      day: item.day,
                                      break_time: "",
                                      total_whrs: "",
                                      start_time: item.start_time,
                                      end_time: item.end_time,
                                      ins_id: item.ins_id,
                                      uncovered_id: item.uncovered_id,
                                      subject: item.subject,
                                    },
                                  ]);

                                  setisSingleExternalSelect([
                                    ...isSingleExternalSelect,
                                    {
                                      position_id: item.title_id,
                                      position: item.title,
                                      v_date: item.v_date,
                                      day: item.day,
                                      start_time: item.start_time,
                                      end_time: item.end_time,
                                      ins_id: item.ins_id,
                                      break_time: "",
                                      total_whrs: "",
                                      uncovered_id: item.uncovered_id,
                                      other_info: "",
                                      assigned_to_external: "000",
                                      absence_id: item.absence_id,
                                      publish_to_external: true,
                                      is_draft: false,
                                      ins_name: item.institute_name,
                                      ins_type: item.institute_type,
                                      location: item.area_name,
                                      postal_code: item.postal_code,
                                      assigned_from: item.cus_id,
                                      created_by: userInfo?.data.unique_id,
                                      subject: item.subject,
                                    },
                                  ]);
                                  setisSingleInternalSelect([
                                    ...isSingleInternalSelect,
                                    {
                                      position: item.title,
                                      position_id: item.title_id,
                                      v_date: item.v_date,
                                      absence_id: item.absence_id,
                                      day: item.day,
                                      start_time: item.start_time,
                                      end_time: item.end_time,
                                      ins_id: item.ins_id,
                                      uncovered_id: item.uncovered_id,
                                      publish_to_internal: true,
                                      is_draft: false,
                                      break_time: breakeTime,
                                      total_whrs:
                                        getHours > 10
                                          ? `${getHours}:00`
                                          : `0${getHours}:00`,
                                      other_info: "",
                                      assigned_from: item.cus_id,
                                      created_by: userInfo?.data.unique_id,
                                      subject: item.subject,
                                    },
                                  ]);
                                  if (!checked || Error) {
                                    setisSingleExternalSelect(
                                      isSingleExternalSelect.filter(
                                        (list) =>
                                          list.uncovered_id !==
                                          item.uncovered_id
                                      )
                                    );
                                    setisSingleInternalSelect(
                                      isSingleInternalSelect.filter(
                                        (list) =>
                                          list.uncovered_id !==
                                          item.uncovered_id
                                      )
                                    );
                                    setSelected(
                                      selected.filter(
                                        (list) =>
                                          list.uncovered_id !==
                                          item.uncovered_id
                                      )
                                    );
                                  }
                                }}
                                checked={
                                  isBoolean
                                    ? false
                                    : selected.some(
                                        (data) =>
                                          data.uncovered_id ===
                                          item.uncovered_id
                                      )
                                }
                              />
                            </td>
                          )}
                          <td>
                            <Icon
                              onClick={() => {
                                setDeleteUncovered(item?.uncovered_id);
                              }}
                            >
                              <FontAwesomeIcon icon={["far", "trash-alt"]} />
                            </Icon>
                          </td>
                        </tr>
                      );
                    })}
                </>
              </tbody>
            </Table>
          </FlexWrapper>
        )}
      </CardWraper>
      {showModal && (
        <Modals
          center={true}
          show={showModal}
          handleCancel={() => {
            setShowModal(false);
            setvalues({
              ...values,
              internal: false,
              external: false,
            });
          }}
          handleSubmit={() => {
            dispatch(updateSelectedVacancyDetails(isSingleExternalSelect));
            history.push(ROUTES.HQ_MANAGEMENT.HQ_UNCOVEREDSEPERATEVACANCY);
            dispatch(
              updateSelectedTeacherId({
                id: setSelectedTeacherId?.id,
              })
            );
            setShowModal(false);
          }}
          sizeModal="sm"
          title="Select"
        >
          <FlexWrapper>
            <DropdownWrapper width="100">
              <Form.Check
                name="Internal"
                label="Internal"
                disabled={selected.length > 1}
                checked={values.internal}
                onChange={(e: any) => {
                  setvalues({
                    ...values,
                    internal: e.target.checked,
                  });
                }}
              />
            </DropdownWrapper>
            <DropdownWrapper>
              <Form.Check
                name="External"
                label="External"
                checked={values.external}
                onChange={(e: any) => {
                  setvalues({
                    ...values,
                    external: e.target.checked,
                  });
                }}
              />
            </DropdownWrapper>
          </FlexWrapper>
        </Modals>
      )}
      <Modal
        show={teacherModal}
        onHide={() => {
          setteacherModal(false);
        }}
        handleSubmit={() => {}}
        size="lg"
      >
        <Modal.Header closeButton>Teacher List</Modal.Header>
        <Table striped borderless hover>
          <thead
            style={{
              backgroundColor: "#2097f5",
              color: "white",
              fontSize: "14px",
              fontFamily: "Montserrat",
            }}
          >
            <tr style={{ textAlign: "center" }}>
              {teacherHeader.map((data, index) => (
                <th key={`Header Unique id -${index}`}>{data}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {getTeacherDetails.map((data, index) => {
              const {
                first_name,
                last_name,
                email_id,
                organization_type,
                id,
                assigned_work_time,
              } = data;
              return (
                <tr
                  key={index}
                  style={{ textAlign: "center", fontSize: "14px" }}
                >
                  <td>{`${first_name}${last_name}`}</td>
                  <td>{email_id}</td>
                  <td>{organization_type}</td>
                  <td
                    ref={target}
                    onMouseEnter={() =>
                      assigned_work_time >= 3 ? settooltip(!tooltip) : null
                    }
                    onMouseLeave={() => settooltip(false)}
                  >
                    <ActionButton
                      disabled={assigned_work_time >= 3 ? true : false}
                      onClick={() => {
                        isSingleInternalSelect.forEach(
                          (elem) => (elem.assigned_to_internal = id)
                        );
                        setteacherModal(false);
                        setShowModal(false);
                        dispatch(
                          updateSelectedTeacherId({
                            id: id,
                          })
                        );
                        dispatch(
                          CreateInternalVacancyApi({
                            Vacancy: isSingleInternalSelect,
                            assigned_to: id,
                          })
                        );
                      }}
                    >
                      Assign
                    </ActionButton>
                    <Overlay
                      target={target.current}
                      show={tooltip}
                      placement="left"
                    >
                      {(props) => (
                        <Tooltip id="overlay-example" {...props}>
                          Completed 180 Minutes
                        </Tooltip>
                      )}
                    </Overlay>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Modal>
      <ToastMessage
        show={errMsg}
        bg="danger"
        top="90px"
        right="35rem"
        onCloseHandler={() => {
          dispatch(updateCreateVacancyErr(false));
        }}
        message="Create Vacancy Failed"
      ></ToastMessage>
    </PageWrapper>
  );
};

export default HQUncobveredTable;
