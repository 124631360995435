import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components"; 
import background from "../../../asserts/background.png";

export const AboutPageWrapper = styled.div`
  width: 100%;
  background: #152440;
  //background-image: linear-gradient(to left, #152440, #002c99);
  height: auto;
`;

export const AContainer = styled.div`
  margin: 8vw 0px -1vw 0px;
  @media (max-width: 550px) {
    margin-top: 15%;
  }
`;

export const TitleText = styled.div`
  font-size: 4vw;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  color: whitesmoke;
  text-align: center;
  padding-bottom: 2vw;
`;

export const Bullets = styled.div`
  display: inline-block;
  overflow: hidden;
  width: auto;
  height: auto;
  white-space: nowrap;
  animation: showup 5s;
  @keyframes showup {
    0% {
      opacity: 0;
    }

    80% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const Bullets2 = styled.div`
  display: inline-block;
  overflow: hidden;
  width: auto;
  opacity: 0;
  height: auto;
  white-space: nowrap;
  animation: showup2 5s forwards;
  animation-delay: 5s;
  @keyframes showup2 {
    0% {
      opacity: 0;
    }

    80% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const Bullets3 = styled.div`
  display: inline-block;
  overflow: hidden;
  width: auto;
  height: auto;
  opacity: 0;
  white-space: nowrap;
  animation: showup3 5s forwards;
  animation-delay: 10s;
  @keyframes showup3 {
    0% {
      opacity: 0;
    }

    80% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const Bullets4 = styled.div`
  display: inline-block;
  overflow: hidden;
  width: auto;
  height: auto;
  opacity: 0;
  white-space: nowrap;
  animation: showup4 5s forwards;
  animation-delay: 15s;
  @keyframes showup4 {
    0% {
      opacity: 0;
    }

    80% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const Dohr = styled.span`
  background: -webkit-linear-gradient(#002c99, #2097f5);
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const SupportText = styled.p`
  font-size: 18px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  color: #2097f5;
  text-align: center;
  margin-top: -2%;
`;

export const Icon = styled(FontAwesomeIcon)`
  margin-top: 10%;
  color: whitesmoke;
  animation: bounce 1600ms infinite;
  @keyframes bounce {
    50% {
      transform: translateY(-15px);
    }
  }
`;

interface AboutTextProps {
  marginLeft?: string;
}

export const AboutTitle = styled.p<AboutTextProps>`
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 0px;
  font-family: "Montserrat", sans-serif;
  color: ${({ color }) => (color ? color : "#2097f5")};
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : "")};
  @media (max-width: 650px) {
    font-size: 18px;
  }
  @media (max-width: 450px) {
    font-size: 16px;
  }
`;

export const AboutTextWrapper = styled.div`
  margin-top: 4%;
  width: 45%;
`;
export const AboutImage = styled.img`
  width: ${({ width }) => (width ? width : "28%")};
  height: auto;
  @media (max-width: 900px) {
  }
  @media (max-width: 650px) {
  }
  @media (max-width: 550px) {
  }
  @media (max-width: 450px) {
  }
  @media (max-width: 415px) {
  }
`;

export const SecondContainer = styled.div`
  display: flex;
  justify-content: space-around;
  background-color: #2097f5;
  padding-top: 2%;
  padding-bottom: 2%;
`;

export const AboutPara = styled.p`
  font-size: 1.5vw;
  text-align: left;
  color: whitesmoke;
  animation-name: moveInleft;
  animation-duration: 5s;
  @media (max-width: 550px) {
    font-size: 10px;
  }
  @keyframes moveInleft {
    0% {
      opacity: 0;
      transform: translateX(-150px);
    }

    80% {
      opacity: 1;
      transform: translateX(10px);
    }
    100% {
      opacity: 1;
      transform: translate(0);
    }
  }
`;

export const AboutPara2 = styled.p`
  font-size: 1.5vw;
  text-align: left;
  color: whitesmoke;
  opacity: 0;
  animation-name: moveInleft2;
  animation-duration: 5s;
  animation-delay: 5s;
  animation-fill-mode: forwards;
  @media (max-width: 550px) {
    font-size: 10px;
  }
  @keyframes moveInleft2 {
    0% {
      opacity: 0;
      transform: translateX(-150px);
    }

    80% {
      opacity: 1;
      transform: translateX(10px);
    }
    100% {
      opacity: 1;
      transform: translate(0);
    }
  }
`;

export const AboutPara3 = styled.p`
  font-size: 1.5vw;
  text-align: left;
  opacity: 0;
  color: whitesmoke;
  animation-name: moveInleft3;
  animation-duration: 5s;
  animation-delay: 10s;
  animation-fill-mode: forwards;
  @media (max-width: 550px) {
    font-size: 10px;
  }
  @keyframes moveInleft3 {
    0% {
      opacity: 0;
      transform: translateX(-150px);
    }

    80% {
      opacity: 1;
      transform: translateX(10px);
    }
    100% {
      opacity: 1;
      transform: translate(0);
    }
  }
`;

export const AboutPara4 = styled.p`
  font-size: 1.5vw;
  text-align: left;
  opacity: 0;
  color: whitesmoke;
  animation-name: moveInleft4;
  animation-duration: 5s;
  animation-delay: 15s;
  animation-fill-mode: forwards;
  @media (max-width: 550px) {
    font-size: 10px;
  }
  @keyframes moveInleft4 {
    0% {
      opacity: 0;
      transform: translateX(-150px);
    }

    80% {
      opacity: 1;
      transform: translateX(10px);
    }
    100% {
      opacity: 1;
      transform: translate(0);
    }
  }
`;

interface Props {
  margintop?: string;
}

export const EscapeWrapper2 = styled.div<Props>`
  margin: ${({ margintop }) =>
    margintop ? `${margintop} 0px 0px 1.5%` : "-0.7vw 0px 0px 1.5%"};
  width: 80%;
  overflow: hidden;
`;

export const NewStyledSection = styled.section`
  background-image: linear-gradient(
      rgba(28, 28, 28, 0.5),
      rgba(28, 28, 28, 0.5)
    ),
    url(${background});
  background-color: rgb(242, 243, 243);
  background-size: cover;
  background-repeat: no-repeat;
  height: auto;
  padding: 4%;
  padding-top: 0px;
  @media (max-width: 550px) {
    padding-top: 20px;
  }
`;

interface Props {
  size?: string;
  bold?: string;
}

export const NewSectionTitle = styled.p<Props>`
  font-size: ${({ size }) => (size ? size : "58px")};
  font-weight: ${({ bold }) => (bold ? bold : "800")};
  color: white;
  margin-bottom: 0px;
`;

export const NewSectionline = styled.hr`
  margin-top: -18vw;
  border-top: 5px solid white;
  color: white;
  margin-bottom: 0px;
  opacity: 2;
`;

export const NewSectionQuotes = styled.span`
  position: relative;
  top: -30px;
  font-size: 20vw;
  color: white;
  font-family: sans-serif;
`;
