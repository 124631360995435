import { ReactElement, useEffect, useState } from "react";
import {
  DropdownWrapper,
  FlexWrapper,
  Icon,
  Input,
  Loader,
  Modals,
  PageWrapper,
  SectionTitle,
} from "../../../../../components";
import { TableHeaderData } from "./const";
import { Table } from "react-bootstrap";
import { CardWraper, IsApprovedAction } from "./subcomponents";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import {
  ApprovemyAssignedVacancy,
  denyMyAssignedVacancy,
  GetMyAssignedCover,
} from "../../../../../redux/Teachers/CoverManagenment/api";
import moment from "moment";
import { updateSelecetedVacancyDetails } from "../../../../../redux/Teachers/CoverManagenment/action";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MyAssignedCover = (): ReactElement => {
  const dispatch = useDispatch();

  const {
    AssignedCover,
    id,
    coverManagement: { isLoading, isVacancyDenied, updateDenyDetails },
  } = useSelector((state: RootState) => ({
    AssignedCover: state.coverManagement.getMyAssignedCover,
    coverManagement: state.coverManagement,
    id: state.login.userInfo?.data.id,
  }));

  const [show, setShow] = useState(false);
  const [reason, setReason] = useState("");

  useEffect(() => {
    dispatch(GetMyAssignedCover({ cus_id: id, status: 0 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isVacancyDenied) {
      setShow(false);
      setReason("");
    }
  }, [isVacancyDenied]);

  return (
    <PageWrapper>
      <CardWraper>
        <FlexWrapper backgroundColor="#eff3fa" noMargin>
          <SectionTitle noMargin title="My assigned cover" />
        </FlexWrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <FlexWrapper justifyContent="center">
            <Table striped borderless hover>
              <thead
                style={{
                  backgroundColor: "#2097f5",
                  color: "white",
                  fontFamily: "Montserrat",
                }}
              >
                <tr style={{ textAlign: "center" }}>
                  {TableHeaderData.map((data, index) => (
                    <th key={`Header Unique id - ${index}`}>{data}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {AssignedCover.map((data, index) => {
                  const {
                    v_date,
                    from_time,
                    to_time,
                    id,
                    subject,
                    vid,
                    first_name,
                    last_name,
                    uncovered_id,
                    cus_id,
                    area_name,
                    year_group,
                  } = data;
                  return (
                    <tr key={index} style={{ textAlign: "center" }}>
                      <td>{index + 1}</td>
                      <td>{moment(v_date).format("DD-MM-YYYY")}</td>
                      <td>{from_time}</td>
                      <td>{to_time}</td>
                      <td>{subject}</td>
                      <td>{year_group}</td>
                      <td>{area_name}</td>
                      <td>{`${first_name}${last_name}`}</td>
                      <td>
                        <Icon>
                          <FontAwesomeIcon icon={["fas", "file-pdf"]} />
                        </Icon>
                      </td>
                      <td style={{ width: "150px" }}>
                        <IsApprovedAction
                          handleApprove={() => {
                            dispatch(
                              GetMyAssignedCover({ cus_id: id, status: 0 })
                            );
                            dispatch(
                              ApprovemyAssignedVacancy({
                                id: vid,
                                status: "2",
                              })
                            );
                          }}
                          handleDeny={() => {
                            dispatch(
                              updateSelecetedVacancyDetails({
                                id: vid,
                                uncoveredId: uncovered_id,
                                cus_id: cus_id,
                              })
                            );
                            setShow(true);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </FlexWrapper>
        )}
      </CardWraper>
      <Modals
        handleCancel={() => {
          setShow(false);
        }}
        center={true}
        handleSubmit={() => {
          dispatch(
            denyMyAssignedVacancy({
              id: updateDenyDetails?.id,
              uncovered_id: updateDenyDetails?.uncoveredId,
              cus_id: updateDenyDetails?.cus_id,
              reason: reason,
            })
          );
        }}
        show={show}
        sizeModal="lg"
        title="Provide reason"
      >
        {isLoading ? (
          <Loader />
        ) : (
          <FlexWrapper justifyContent="center">
            <DropdownWrapper width="90">
              <Input
                inputType="textarea"
                value={reason}
                onChange={(value: string) => {
                  setReason(value);
                }}
                placeholder="Provide a brief reason for declining"
              />
            </DropdownWrapper>
          </FlexWrapper>
        )}
      </Modals>
    </PageWrapper>
  );
};

export default MyAssignedCover;
