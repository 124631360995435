/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useEffect, useState } from "react";
import { FlexWrapper, Footers, Input } from "../../../components";
import { colors } from "../../../const/theme";
import { PageWrapper } from "../../Login/subcomponent";
import { NewsListswe, NewsListeng } from "./const";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Modal, Button } from "react-bootstrap";
import Typography from "@mui/material/Typography";
import { CardActionArea, CardActions } from "@mui/material";
import {
  ContainerWrapper,
  //Content,
  //TitleText,
  //Icon,
  NewsWrapper,
  Header,
  NewsCardWrapper,
  NewsHeader,
  CardButton,
  SignUpButton,
  SubscribeWrapper,
  SubscribeHeader,
  CloseButton,
  Small,
  CardWrappper,
} from "./subcomponent";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useDispatch } from "react-redux";
import { NewsEmail } from "../../../redux/Common/api";
import news_image from "../../../asserts/pic for article 2.png";

const News = (): ReactElement => {
  const { lang } = useSelector((state: RootState) => ({
    lang: state.Language.language,
  }));

  const { news } = lang.signUp;
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const [newsemail, setNewsemail] = useState(""); // State for email input
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [emailError, setEmailError] = useState("");
  const [showModalnews, setShowModalnews] = useState(false);

  const dispatch = useDispatch();

  const validateEmail = (email: any) => {
    if (!email) {
      // Email field is blank
      return "Email is required.";
    }

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!regex.test(email)) {
      // Invalid email format
      return "Please enter a valid email address.";
    }

    return ""; // No errors
  };

  const handleSubmit = () => {
    const emailError = validateEmail(newsemail);

    if (emailError) {
      // Display the email validation error message
      setEmailError(emailError);
    } else {
      // Clear any previous error message
      setEmailError("");

      // Dispatch the action only if the email is valid
      dispatch(NewsEmail({ email: newsemail }));

      // Callback function to show the modal message
      const showSuccessMessage = () => {
        setModalMessage(news.emailmessage);
        setShowModal(true);
      };

      // Show success message after dispatch
      showSuccessMessage();
      setNewsemail("");
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const [selectedAd, setSelectedAd] = useState({
    title: "",
    para: "",
  });
  const handleLearnMoreClick = (itemId: any) => {
    // Assuming news is an object that has a property newsinsight
    if (news.newsinsight === "News and Insights") {
      const newsItem = NewsListeng.find((item) => item.id === itemId);

      if (newsItem) {
        // If the news item is found, set the modal content
        setSelectedAd({
          title: newsItem.title,
          para: newsItem.para,
        });
        // Show the modal
        setShowModalnews(true);
      }
    } else {
      // Assuming there's a different news list for other categories
      const newsItemswe = NewsListswe.find((item) => item.id === itemId);

      if (newsItemswe) {
        // If the news item is found, set the modal content
        setSelectedAd({
          title: newsItemswe.title,
          para: newsItemswe.para,
        });
        // Show the modal
        setShowModalnews(true);
      }
    }
  };

  const [showFullText, setShowFullText] = useState(false);

  const fetchAdDetailsById = (adId: any) => {
    // Replace this with your actual data fetching logic
    // For example, you might have an array of ad data where you search for the ad by ID
    const adDetails = NewsListeng.find((ad) => ad.id === adId);

    // Return the ad details
    return adDetails;
  };

  const toggleModal = (adId: any) => {
    if (adId) {
      // Fetch ad details by ID here, and then set the selectedAd state
      const adDetails = fetchAdDetailsById(adId); // Replace with your actual fetching logic

      if (adDetails) {
        setSelectedAd(adDetails);
        setShowModalnews(!showModalnews);
      } else {
        setSelectedAd({ title: "", para: "" }); // No ad found, set selectedAd to null
        setShowModalnews(false);
      }
    }
  };
  return (
    <PageWrapper>
      <NewsWrapper>
        <ContainerWrapper>
          <FlexWrapper justifyContent="center" noMargin noPadding>
            <NewsHeader>{news.what}</NewsHeader>
          </FlexWrapper>
          {/* <FlexWrapper noMargin noPadding justifyContent="center">
            <Icon
              size="2x"
              icon={["fas", "arrow-down-long"]}
              onClick={() => {
                window.scrollBy(0, 500);
              }}
            />
          </FlexWrapper> */}
        </ContainerWrapper>

        <Header
          color={colors.darkBlue}
          font="2.5vw"
          center
          style={{ marginBottom: "5%", marginLeft: "1%" }}
        >
          {news.newsinsight}
        </Header>
        {/* <Header color={colors.darkBlue} font="1vw" style={{ marginLeft: "5%" }}>
          {news.filter}
        </Header>
        <FilterWrapper>
          <FlexWrapper noMargin noPadding>
            <FlexWrapper width="100%" noMargin noPadding>
              <InputWrapper>
                <Input2
                  value={""}
                  label={news.cat}
                  dropdownList={news.categorylist}
                  onChange={() => {}}
                />
              </InputWrapper>
              <InputWrapper>
                <Input2 onChange={() => {}} inputType="month" value={""} />
              </InputWrapper>
              <InputWrapper style={{ width: "40%" }}>
                <Input2 value={""} label={news.search} onChange={() => {}} />
              </InputWrapper>
            </FlexWrapper>
          </FlexWrapper>
        </FilterWrapper> */}

        <NewsCardWrapper>
          {news.newsinsight === "News and Insights"
            ? NewsListeng.map((item, i) => (
                <CardWrappper sx={{ marginTop: "2%" }} key={i}>
                  <CardActionArea
                    sx={{
                      display: "flex",
                      background: "#eff3f7",
                      justifyContent: "flex-end",
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="250px"
                      width={"100px"}
                      image={news_image}
                      alt="Sample img"
                    />
                    <CardContent sx={{ marginLeft: "5%" }}>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        sx={{
                          color: colors.darkBlue,
                          fontWeight: "500",
                          fontSize: "1.8vw",
                          fontFamily: "Montserrat",
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ fontSize: "1vw", fontFamily: "Montserrat" }}
                      >
                        {showFullText
                          ? item.para
                          : `${item.para.slice(0, 200)}...`}
                      </Typography>
                      <CardActions sx={{ marginLeft: "-12px" }}>
                        <CardButton size="small" color="primary">
                          <span
                            style={{
                              textTransform: "capitalize",
                              fontSize: "1vw",
                            }}
                          >
                            {news.share}
                          </span>
                        </CardButton>
                        <CardButton
                          size="small"
                          color="primary"
                          sx={{ fontSize: "1vw", padding: "4px 2% 4px 2%" }}
                          onClick={() => handleLearnMoreClick(item.id)}
                        >
                          <span style={{ textTransform: "capitalize" }}>
                            {news.learn}{" "}
                            <span style={{ textTransform: "lowercase" }}>
                              {news.more}
                            </span>
                          </span>
                          <span style={{ fontSize: "1vw" }}></span>
                        </CardButton>
                      </CardActions>
                    </CardContent>
                  </CardActionArea>
                </CardWrappper>
              ))
            : NewsListswe.map((item, i) => (
                <CardWrappper sx={{ marginTop: "4%" }} key={i}>
                  <CardActionArea
                    sx={{ display: "flex", background: "#eff3f7" }}
                  >
                    <CardMedia
                      component="img"
                      height="250px"
                      width={"100px"}
                      image={news_image}
                      alt="Sample img"
                    />
                    <CardContent sx={{ marginLeft: "5%" }}>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        sx={{
                          color: colors.darkBlue,
                          fontWeight: "500",
                          fontSize: "1.8vw",
                          fontFamily: "Montserrat",
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ fontSize: "1vw", fontFamily: "Montserrat" }}
                      >
                        {showFullText
                          ? item.para
                          : `${item.para.slice(0, 200)}...`}
                      </Typography>
                      <CardActions sx={{ marginLeft: "-12px" }}>
                        <CardButton size="small" color="primary">
                          <span
                            style={{
                              textTransform: "capitalize",
                              fontSize: "1vw",
                            }}
                          >
                            {news.share}
                          </span>
                        </CardButton>
                        <CardButton
                          size="small"
                          color="primary"
                          sx={{ fontSize: "1vw", padding: "4px 2% 4px 2%" }}
                          onClick={() => handleLearnMoreClick(item.id)}
                        >
                          <span style={{ textTransform: "capitalize" }}>
                            {news.learn}{" "}
                            <span style={{ textTransform: "lowercase" }}>
                              {news.more}
                            </span>
                          </span>
                          <span style={{ fontSize: "1vw" }}></span>
                        </CardButton>
                      </CardActions>
                    </CardContent>
                  </CardActionArea>
                </CardWrappper>
              ))}
          {/* <FlexWrapper justifyContent="center">
            <ReadMoreButton size="medium" color="primary" aria-label="add">
              <FontAwesomeIcon icon={["fas", "chevron-down"]} size="lg" />
            </ReadMoreButton>
          </FlexWrapper> */}
        </NewsCardWrapper>

        <SubscribeWrapper>
          <SubscribeHeader>{news.stay}</SubscribeHeader>
          <FlexWrapper justifyContent="center" noMargin noPadding>
            <div
              style={{
                width: "50%",
                marginRight: "1%",
                marginBottom: "5px",
                marginTop: "-2px",
              }}
            >
              <Input
                inputType="email"
                error={emailError}
                value={newsemail} // Set the value from the state
                placeholder={news.email}
                onChange={(value: string) => {
                  setNewsemail(value);
                }} // Handle input change
                size="lg"
              />
            </div>

            <SignUpButton onClick={handleSubmit}>{news.submit}</SignUpButton>
          </FlexWrapper>
        </SubscribeWrapper>

        <Footers />
      </NewsWrapper>
      <Modal show={showModal} onHide={handleCloseModal} style={{ top: "12%" }}>
        <Modal.Header closeButton>
          <Modal.Title>Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            {news.close}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showModalnews}
        size="xl"
        centered
        animation
        scrollable
        style={{ marginTop: "45px" }}
      >
        <Modal.Header>
          <Modal.Title style={{ color: colors.darkBlue }}>
            {selectedAd?.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "70vh", overflowY: "auto" }}>
          <Small>{selectedAd?.para}</Small>
        </Modal.Body>
        <Modal.Footer>
          <CloseButton onClick={toggleModal}>{news.close}</CloseButton>
        </Modal.Footer>
      </Modal>
    </PageWrapper>
  );
};

export default News;
