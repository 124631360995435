import strings from '../locale/en'

export const hasFormError = (formErrors: Record<string, string>): boolean => {
  return !!Object.keys(formErrors).find((errorkey) => formErrors[errorkey])
}
const { validationMessages } = strings


export const validateRequired = (value: any): any => {
  const { required } = validationMessages.field;

  if (!value || (Array.isArray(value) && value.length === 0)) {
    return required;
  } else {
    return '';
  }
};


export const validateEmail = (email: string): string => {
  const { required, invalid } = validationMessages.email
  if (!email) {
    return required
  } else {
    let lastAtPos = email.lastIndexOf('@')
    let lastDotPos = email.lastIndexOf('.')
    if (!(
      lastAtPos < lastDotPos &&
      lastAtPos > 0 &&
      email.indexOf('@@') === -1 &&
      lastDotPos > 2 &&
      email.length - lastDotPos > 2
    )) {
      return invalid
    } else {
      return ''
    }
  }
}

export const validatePhone = (phone: string): string => {
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const { required, numberOnly, invalid } = validationMessages.phone
  if (!phone) {
    return required
  } else if (!phone.match(/^[0-9\b]+$/)) {
    return numberOnly
  } else if (!phone.match(phoneRegExp) || phone.length !== 10) {
    return invalid
  } else {
    return ''
  }
}

export const validateLettersOnlyFirstname = (firstname: string): string => {
  const pattern = /^[A-Za-z]+$/;
  if (!pattern.test(firstname)) {
    return 'First name is required';
  }
  return '';
};

export const validateLettersOnlylastname = (lastname: string): string => {
  const pattern = /^[A-Za-z]+$/;
  if (!pattern.test(lastname)) {
    return 'last name is required';
  }
  return '';
};

export const validateBooleanSuccess = (value: any): any => {
  // You can define your success message here
  

  // Check if the value is either true or false
  if (value === true || value === false) {
    return '';
  } 
};