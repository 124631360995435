import { Nav } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "../../const/theme";

export const AccorHead = styled.p`
  margin-bottom: 0%;
`;

interface PageWrapperProps {
  margintop?: string;
}

export const PageWrapper = styled.div<PageWrapperProps>`
  box-sizing: border-box;
  display: flex;
  margin-top: ${({ margintop }) => (margintop ? margintop : "12%")};
  flex-direction: column;
  width: 100%;
  overflow: hidden !important;
  font-family: "Montserrat";
  @media (max-width: 460px) {
    width: 100%;
    overflow: auto;
  }
`;

export const TabWrapper = styled.div`
  padding: 2% 0px 4% 2%;
  .nav.nav-pills .nav-link.active {
    border-left: 4px ${colors.darkBlue} solid;
    background-color: transparent;
    color: ${colors.darkBlue};
  }
`;

export const NavLink = styled(Nav.Link)`
  color: ${colors.darkBlue};
  font-weight: 500;
  font-family: "Montserrat" !important;
  &:hover,
  &:active,
  &:focus {
    border-left: 4px ${colors.darkBlue} solid;
  }
`;
