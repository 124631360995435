export const TableHeaderData = [
  "SNo",
  "Date",
  "Start Time",
  "End Time",
  "Subject",
  "Year Group",
  "Received Feedback",
  "Covered by",
];
