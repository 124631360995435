import { ChangeEvent, ReactElement } from "react";
import { InputProps } from "./typings";
import { InputWrapper } from "./subcomponents";
import { BoldLabel, Label } from "../../typography";
import { FormControl } from "react-bootstrap";

const Input = ({
  label,
  isRequired,
  value,
  width,
  isDisabled,
  inputType,
  error,
  name,
  accept,
  onBlur,
  placeholder = "",
  onChange,
  onClick,
  height,
  minDate,
  size,
  boldLabel,
  id,
}: InputProps): ReactElement => {
  return (
    <InputWrapper width={width} isDisabled={isDisabled} height={height}>
      {boldLabel ? (
        <BoldLabel>
          {" "}
          {label}
          {isRequired && <strong style={{ color: "red" }}> *</strong>}
        </BoldLabel>
      ) : (
        <Label htmlFor="edu-input">
          {label}
          {isRequired && <strong style={{ color: "red" }}> *</strong>}
        </Label>
      )}
      {inputType === "textarea" ? (
        <FormControl
          as="textarea"
          rows={3}
          value={value}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChange && onChange(e.target.value)
          }
          onBlur={(e: any) => onBlur && onBlur(e.target.value)}
          placeholder={placeholder}
          onClick={onClick}
          autoComplete="off"
          style={{ resize: "both", color: "#002c99" }}
          isValid={!error && !!value}
          isInvalid={!!error}
        />
      ) : (
        <FormControl
          id="edu-input"
          type={inputType || "text"}
          name={name || ""}
          value={value}
          min={minDate}
          size={size}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChange && onChange(e.target.value)
          }
          onBlur={(e) => onBlur && onBlur(e.target.value)}
          placeholder={placeholder}
          isValid={!error && !!value}
          isInvalid={!!error}
          accept={accept}
          onClick={onClick}
          autoComplete="off"
        />
      )}

      {/* <FormControl.Feedback type="invalid">{error}</FormControl.Feedback> */}
    </InputWrapper>
  );
};

export default Input;
