import { Button } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "../../../const/theme";

export const ApplyButton = styled(Button)`
  background-color: white;
  color: ${colors.dohrBlue};
  font-size: 1.1vw;
  font-weight: 600;
  margin-top: 4%;
  border-radius: 4vw;
  padding: 5px 20px;
  border: none;
  &:hover {
    background-color: ${colors.dohrBlue};
    color: white;
  }
`;

export const FaqRedirec = styled.p`
  color: ${({ color }) => (color ? color : "#002C99")};
  font-size: 1.5vw;
  width: 100%;
  font-weight: 600;
  margin: 0px 0px 4vw 0px;
  text-align: center;
`;
