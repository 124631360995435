/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-pascal-case */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { ReactElement, useEffect, useRef, useState } from "react";
import { Modal, Overlay, Tooltip } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  DropdownWrapper,
  EditableDropdown,
  FlexWrapper,
  PageWrapper,
  SectionTitle,
  Input,
  ActionButton,
  ToastMessage,
} from "../../../../../components";
import { Title } from "../../../../../components/EditableDropdown/subcomponents";
import { DropdownListProps } from "../../../../../components/EditableDropdown/typings";
import { colors } from "../../../../../const/theme";
import {
  getConsultantsDropDown,
  getMyConsultantDropdown,
} from "../../../../../helpers/dropdown";
import {
  getChooseConsultant,
  getmyInsConsultant,
} from "../../../../../redux/Common/api";
import {
  updateCreateExternalVanc,
  updateCreateExternalVancError,
} from "../../../../../redux/Hq_Management/Vacancy/action";
import {
  GetRole,
  GetOrganization,
  CreateExternalVacancyApi,
} from "../../../../../redux/Hq_Management/Vacancy/api";
import { RootState } from "../../../../../redux/store";
import { ModalHeader } from "../../../../Dashboard/subcomponents";
import { CardWraper } from "../../Vacancies/PublishedVacancies/subcomponents";
import {
  CheckBoxText,
  CheckboxWrapper,
  FormCheck,
  IconButton,
  Info_Icon,
  Tooltiped,
} from "../subcomponent";
import {
  BreakTimeDropDown,
  BreakTimeDropDown2,
  checkBox,
  handoverDropDown,
  preperationDropDown,
} from "./const";
import { AdditionalProps } from "./types";
import { TYPE } from "../../../../../const/getType";
import { Label } from "../../../../../typography";
import {
  CancleButton,
  DeleteButton,
} from "../../../../Institute/Management/ViewCustomer/subcomponents";

const CreateSeperateVacancy = (): ReactElement => {
  const target = useRef(null);
  const {
    selectedUpdateTeacherExternal,
    getMyconsultant,
    hasExternalError,
    userData,
    myConsultants,
    reload,
  } = useSelector(
    (state: RootState) => ({
      selectedUpdateTeacherExternal:
        state.Vacancy.selectedUpdateTeacherExternal,
      setSelectedTeacherId: state.Vacancy.setSelectedTeacherId,
      hasExternalError: state.Vacancy.hasExternalError,
      getMyconsultant: state.common.chooseConsultant,
      myConsultants: state.common.getmyInstituteCons,
      userData: state.login.userInfo,

      reload: state.common.reload,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const [values, setValues] = useState({ start_time: "", end_time: "" });

  const consDropDown = getMyconsultant
    ? getConsultantsDropDown(getMyconsultant)
    : [];
  const getMyconsultantDropdown = myConsultants
    ? getMyConsultantDropdown(myConsultants)
    : [];
  const [createVacancy, setCreateVacancy] = useState({} as AdditionalProps);
  //checkBoxUseState
  const [isSingleSelect, setIsSingleSelect] = useState<Array<any>>([]);
  const [Preparation, setPreparation] = useState(false);
  const [preparationValue, setPreparationValue] = useState<any>("");
  const [Handovertime, setHandovertime] = useState(false);
  const [PublishCon, setPublishCon] = useState(false);
  const [PublishTec, setPublishTec] = useState(false);
  const [handovertimeValue, setHandovertimeValue] = useState<any>("");
  const [toast, setToast] = useState(false);
  const [breakeTime, setbreakeTime] = useState<any>("");
  const [breakTime, setbreakTime] = useState<any>();
  const [show, setShow] = useState(false);
  const [conshow, setConShow] = useState(false);
  const [multiVac, setMultiVac] = useState(false);
  const [uncomHour, setuncomHour] = useState(false);
  const [UnpaidBreakTime, setUnpaidBreakTime] = useState(false);
  const [routineInformation, setRoutineInformation] = useState<any>("");
  const startTime = moment(values.start_time, "HH:mm:ss a");
  const endTime = moment(values.end_time, "HH:mm:ss a");
  const duration = moment.duration(endTime.diff(startTime));
  const getHours = duration.hours();
  const minutes = duration.minutes();
  let hh = getHours < 10 ? `0${getHours}` : getHours;
  let mm = minutes < 10 ? `0${minutes}` : minutes;

  useEffect(() => {
    dispatch(GetRole());
    dispatch(GetOrganization());
    dispatch(getChooseConsultant());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStartTime = () => {
    setPreparation(true);
  };

  const handleEndTime = () => {
    setHandovertime(true);
  };

  useEffect(() => {
    if (preparationValue !== "") {
      let startTime = moment(`${values.start_time}:00`, "hh:mm:ss");
      var preparation = moment.duration(preparationValue);
      let subtract = startTime.subtract(preparation);
      let finalTime = moment(subtract).format("HH:mm:ss");
      setValues({ ...values, start_time: finalTime });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preparationValue]);

  useEffect(() => {
    if (handovertimeValue !== "") {
      let EndTime = moment(`${values.end_time}:00`, "hh:mm:ss");
      var handovertime = moment.duration(handovertimeValue);
      let add = EndTime.add(handovertime);
      let finalTime = moment(add).format("HH:mm:ss");

      setValues({ ...values, end_time: finalTime });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handovertimeValue]);

  useEffect(() => {
    let startTime = moment(values.start_time, "hh:mm");
    let endTime = moment(values.end_time, "hh:mm");
    const isAfterTime = moment(startTime).isAfter(endTime);
    if (isAfterTime) setToast(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.end_time]);

  useEffect(() => {
    if (getHours >= 2) {
      setbreakeTime(
        moment(getHours, "hh:mm:ss").add(breakTime, "minutes").format("hh:mm")
      );
    } else if (getHours >= 5) {
      setbreakeTime(
        moment(getHours, "hh:mm:ss").add(breakTime, "minutes").format("hh:mm")
      );
    } else {
      setbreakeTime(`${hh}:${mm}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breakTime]);

  const desConcat = `${isSingleSelect.join(" ")}${createVacancy?.description}`;

  useEffect(() => {
    if (createVacancy.publish_to === "Publish to my consultant(s)") {
      setPublishCon(true);
      setPublishTec(false);
    } else if (
      createVacancy.publish_to ===
      "Publish to teacher students only (185kr/hr + VAT)"
    ) {
      setPublishCon(false);
      setPublishTec(true);
    }
  }, [createVacancy.publish_to]);
  useEffect(() => {
    if (userData?.type === TYPE.INSTITUTE) {
      dispatch(
        getmyInsConsultant({
          ins_id: userData?.data?.id,
        })
      );
    } else {
      dispatch(
        getmyInsConsultant({
          ins_id: userData?.data?.ins_id,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  let finalUncvoveredVacancy: any = [];
  const [view, setView] = useState(false);
  return (
    <PageWrapper>
      <Modal
        show={view}
        size={"sm"}
        centered={true}
        backdrop="static"
        keyboard={false}
      >
        <FlexWrapper justifyContent="center" width="100%">
          <FlexWrapper justifyContent="center">
            <Label>
              {createVacancy.publish_to_id == "2"
                ? "In the event that our teacher student consultants are unable to accept the job within 30 minutes, do you agree that the vacancy will be automatically re-published to all consultants?"
                : createVacancy.publish_to_id == "3"
                ? "In the event that the consultant(s) from your ''saved list'' are unable to accept the job within 20 minutes, do you agree that the vacancy will be automatically re-published to all consultants?"
                : ""}{" "}
            </Label>
          </FlexWrapper>
          <FlexWrapper noPadding>
            <CancleButton
              onClick={() => {
                setCreateVacancy({
                  ...createVacancy,
                  publish_to_id: "",
                  publish_to: "",
                });
                setView(false);
              }}
            >
              No
            </CancleButton>
            <DeleteButton
              onClick={() => {
                setView(false);
              }}
            >
              Yes
            </DeleteButton>
          </FlexWrapper>
        </FlexWrapper>
      </Modal>
      <CardWraper>
        <FlexWrapper noMargin>
          <SectionTitle title="Create Vacancy" hasBackButton noMargin />
        </FlexWrapper>
        <ToastMessage
          show={toast}
          onCloseHandler={() => {
            setToast(false);
          }}
          top="30px"
          bg={"danger"}
          message={"Start time can never be later than end time"}
        ></ToastMessage>
        <ToastMessage
          show={hasExternalError}
          onCloseHandler={() => {
            dispatch(updateCreateExternalVancError(false));
          }}
          top="30px"
          bg={"danger"}
          message={"Vacancy Creation Failed"}
        ></ToastMessage>
        <FlexWrapper>
          <h5 style={{ fontWeight: 500, position: "relative", left: "33px" }}>
            Selected Vacancy Details
          </h5>
          {selectedUpdateTeacherExternal?.map((names, index) => {
            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <DropdownWrapper width="100">
                  <span>
                    {index + 1}. Vacancy-Date: <b>{names?.v_date}</b>
                  </span>
                </DropdownWrapper>{" "}
                <DropdownWrapper width="100">
                  <span>
                    Subject: <b>{names?.subject}</b>
                  </span>
                </DropdownWrapper>{" "}
                <DropdownWrapper width="100">
                  <span>
                    Start Time: <b>{names?.start_time}</b>
                  </span>
                </DropdownWrapper>{" "}
                <DropdownWrapper width="100">
                  <span>
                    End Time: <b>{names?.end_time}</b>
                  </span>
                </DropdownWrapper>{" "}
              </div>
            );
          })}
        </FlexWrapper>
        <FlexWrapper>
          {/* <DropdownWrapper width="25">
              <Input
                value={mapUpdatedValues.position}
                label="Position"
                inputType="text"
                isDisabled
                onChange={() => {}}
              />
            </DropdownWrapper> */}
          {/* <DropdownWrapper width="25">
              <Input
                value={mapUpdatedValues.v_date}
                label="Vacancy-Date"
                isDisabled
                inputType="text"
                onChange={() => {}}
              />
            </DropdownWrapper> */}
          <DropdownWrapper width="20">
            <Input
              value={values.start_time}
              label="Start-Time"
              inputType="time"
              onBlur={handleStartTime}
              onChange={(value: string) => {
                setValues({ ...values, start_time: value });
              }}
            />
          </DropdownWrapper>
          <DropdownWrapper width="20">
            <Input
              value={values.end_time}
              label="End-Time"
              inputType="time"
              onBlur={handleEndTime}
              onChange={(value: string) => {
                setValues({ ...values, end_time: value });
              }}
            />
          </DropdownWrapper>{" "}
          <FlexWrapper noPadding noMargin width="100%">
            {hh ? (
              <DropdownWrapper width="20">
                <span>
                  Total Hours :{" "}
                  <b>
                    {hh}:{mm} hour
                  </b>
                </span>
              </DropdownWrapper>
            ) : (
              ""
            )}
            {preparationValue && (
              <DropdownWrapper width="20">
                <span>
                  Preparation Time :{" "}
                  <b>
                    {
                      preperationDropDown?.filter(
                        (d) => d?.id === preparationValue
                      )[0]?.name
                    }{" "}
                  </b>
                </span>
              </DropdownWrapper>
            )}
            {handovertimeValue && (
              <DropdownWrapper width="20">
                <span>
                  Handover Time :{" "}
                  <b>
                    {
                      handoverDropDown?.filter(
                        (d) => d?.id === handovertimeValue
                      )[0]?.name
                    }{" "}
                  </b>
                </span>
              </DropdownWrapper>
            )}
            {breakTime && (
              <DropdownWrapper width="20">
                <span>
                  Break Time : <b>{breakTime} mins</b>
                </span>
              </DropdownWrapper>
            )}
          </FlexWrapper>
          <DropdownWrapper>
            <Input
              value={routineInformation}
              label="Routine Information"
              inputType="text"
              // isDisabled
              onChange={(data) => {
                setRoutineInformation(data);
              }}
            />
          </DropdownWrapper>
          {/* <DropdownWrapper width="25">
              <Input
                value={userData?.data?.title || userData?.data?.institute_name}
                label="Institute Name"
                inputType="text"
                isDisabled
                onChange={() => {}}
              />
            </DropdownWrapper>
            <DropdownWrapper width="25">
              <Input
                value={
                  userData?.data?.organization_type ||
                  userData?.data?.institute_type
                }
                label="Type of Institution"
                inputType="text"
                isDisabled
                onChange={() => {}}
              />
            </DropdownWrapper>
            <DropdownWrapper width="25">
              <Input
                value={`${userData?.data?.address},${userData?.data?.area_name},${userData?.data?.postal_code}`}
                label="Location"
                isDisabled
                inputType="text"
                onChange={() => {}}
              />
            </DropdownWrapper> */}
        </FlexWrapper>
        <FlexWrapper noPadding noMargin>
          <DropdownWrapper width="80">
            <Input
              value={desConcat}
              label="Provide a brief description and/or simply choose one or more suitable options below"
              inputType="textarea"
              placeholder="Description"
              height="100px"
              onChange={(value: string) => {
                setCreateVacancy({
                  ...createVacancy,
                  description: value,
                });
              }}
            />
          </DropdownWrapper>
        </FlexWrapper>
        <FlexWrapper noPadding>
          <Title style={{ marginLeft: "4%", marginBottom: "-1%" }}>
            Select one or more of the following options, if applicable:{" "}
            <Tooltiped
              title="We recommend selecting one or more of these alternatives,
which will automatically be inculded in the job description
if selected."
              placement="right"
            >
              <span>
                <FontAwesomeIcon icon={["fas", "info-circle"]} size="sm" />
              </span>
            </Tooltiped>
          </Title>
        </FlexWrapper>
        <FlexWrapper
          style={{ marginLeft: "2%", flexDirection: "row" }}
          noMargin
        >
          {checkBox.map((data, index) => (
            <CheckboxWrapper key={index}>
              <FormCheck
                key={data?.id}
                name={data?.name}
                id={data?.id}
                onClick={(e: any) => {
                  const { name, checked } = e.target;
                  setIsSingleSelect([...isSingleSelect, name]);
                  if (!checked) {
                    setIsSingleSelect(
                      isSingleSelect.filter((item) => item !== name)
                    );
                  }
                }}
                checked={isSingleSelect.includes(data?.name)}
              />
              <CheckBoxText>
                {data?.name}{" "}
                <span>
                  {data?.code ? (
                    <Tooltiped
                      title="This option will increase the chance that the position 
             will be filled quicker in the event that you need a 
             substitute approximately 1 hour before the start time. 
             We recommend that you note the actual arrival time and 
             approve the timesheet accordingly."
                      placement="right"
                    >
                      <span>
                        <FontAwesomeIcon
                          icon={["fas", "info-circle"]}
                          size="sm"
                        />
                      </span>
                    </Tooltiped>
                  ) : null}
                </span>
              </CheckBoxText>
            </CheckboxWrapper>
          ))}
        </FlexWrapper>
        <FlexWrapper noMargin justifyContent="center">
          <a
            href="#"
            onClick={() => {
              setMultiVac(!multiVac);
            }}
          >
            <span style={{ color: colors.dohrBlue }}>
              Can I publish a vacancy with multiple days of duration at once?
            </span>
          </a>
        </FlexWrapper>
        <FlexWrapper noMargin noPadding justifyContent="center">
          <a
            href="#"
            onClick={() => {
              setuncomHour(!uncomHour);
            }}
          >
            <span style={{ color: colors.dohrBlue }}>
              What are uncomfortable hours and a break?
            </span>
          </a>
        </FlexWrapper>
        <FlexWrapper noMargin>
          <DropdownWrapper width="45">
            <Title>
              Select recipient type to publish vacancy to
              <span
                style={{ marginLeft: "1%" }}
                ref={target}
                onClick={() => setConShow(!conshow)}
              >
                <FontAwesomeIcon icon={["fas", "info-circle"]} size="sm" />
              </span>
            </Title>
            <EditableDropdown
              placeholder="Select recipient type to publish vacancy to"
              dropdownList={consDropDown}
              handleSelect={(value: DropdownListProps) => {
                setCreateVacancy({
                  ...createVacancy,
                  publish_to_id: value.id,
                  publish_to: value?.name,
                });
                setView(value.id === 1 ? false : true);
              }}
            />
          </DropdownWrapper>
          {createVacancy?.publish_to_id === consDropDown[2]?.id && (
            <DropdownWrapper>
              <EditableDropdown
                title="Consultant List"
                placeholder="Select Consultant"
                dropdownList={getMyconsultantDropdown}
                handleSelect={(value: DropdownListProps) => {
                  setCreateVacancy({
                    ...createVacancy,
                    publish_to_id: value.id,
                    publish_to: value?.name,
                  });
                }}
              />
            </DropdownWrapper>
          )}
        </FlexWrapper>
        <FlexWrapper justifyContent="end">
          <ActionButton
            disabled={!!toast}
            onClick={() => {
              selectedUpdateTeacherExternal?.forEach((dds) => {
                finalUncvoveredVacancy?.push({
                  ...dds,
                  ...values,
                  to_time: values?.end_time,
                  from_time: values?.start_time,
                  total_whrs: `${hh}:${mm} hour`,
                  break_time: `${breakTime} mins`,
                  preparation_time: preperationDropDown?.filter(
                    (d) => d?.id === preparationValue
                  )[0]?.name,
                  handover_time: handoverDropDown?.filter(
                    (d) => d?.id === handovertimeValue
                  )[0]?.name,
                  discription: isSingleSelect,
                  publish_to: createVacancy?.publish_to,
                  publish_to_id: createVacancy?.publish_to_id,
                  choosecons20min: createVacancy?.choosecons20min,
                  publishedtoall30min: createVacancy?.publishedtoall30min,
                  isdraft: TrustedTypePolicy,
                  routine_information: routineInformation,
                });
              });

              dispatch(updateCreateExternalVanc(true));
              dispatch(
                CreateExternalVacancyApi({
                  externalVacancy: finalUncvoveredVacancy,
                })
              );
            }}
          >
            Save as Draft
          </ActionButton>
          <ActionButton
            disabled={!!toast}
            onClick={() => {
              selectedUpdateTeacherExternal?.forEach((dds) => {
                finalUncvoveredVacancy?.push({
                  ...dds,
                  ...values,
                  to_time: values?.end_time,
                  from_time: values?.start_time,
                  total_whrs: `${hh}:${mm} hour`,
                  break_time: `${breakTime} mins`,
                  preparation_time: preperationDropDown?.filter(
                    (d) => d?.id === preparationValue
                  )[0]?.name,
                  handover_time: handoverDropDown?.filter(
                    (d) => d?.id === handovertimeValue
                  )[0]?.name,
                  discription: isSingleSelect,
                  publish_to: createVacancy?.publish_to,
                  publish_to_id: createVacancy?.publish_to_id,
                  choosecons20min: createVacancy?.choosecons20min,
                  publishedtoall30min: createVacancy?.publishedtoall30min,
                  isdraft: false,
                  routine_information: routineInformation,
                });
              });

              dispatch(updateCreateExternalVanc(false));
              dispatch(
                CreateExternalVacancyApi({
                  externalVacancy: finalUncvoveredVacancy,
                })
              );
            }}
          >
            Publish
          </ActionButton>
        </FlexWrapper>
        <Modal
          show={UnpaidBreakTime}
          size="sm"
          onHide={() => {
            setUnpaidBreakTime(false);
          }}
        >
          <ModalHeader closeButton>
            Break time
            <Tooltiped
              title=" Break (rast)-According to §15 of the Working Hours Act (Arbetstidslagen), an employee may work no more than 5 hours without a break. As a rule, it is recommended to allocate a break of 30-60 minutes."
              placement="right"
            >
              <span>
                <FontAwesomeIcon icon={["fas", "info-circle"]} size="sm" />
              </span>
            </Tooltiped>
          </ModalHeader>

          <Modal.Body>
            <FlexWrapper justifyContent="center" noPadding>
              <DropdownWrapper width="80">
                <EditableDropdown
                  placeholder="Break time"
                  dropdownList={
                    getHours >= 5 ? BreakTimeDropDown : BreakTimeDropDown2
                  }
                  handleSelect={(value: DropdownListProps) => {
                    setbreakTime(value.id);
                    setUnpaidBreakTime(false);
                  }}
                />
              </DropdownWrapper>
            </FlexWrapper>
          </Modal.Body>
        </Modal>

        <Modal
          show={Preparation}
          size="sm"
          onHide={() => {
            setPreparation(false);
          }}
        >
          <ModalHeader closeButton>
            <FlexWrapper noMargin noPadding>
              Preparation time
              <IconButton ref={target} onClick={() => setShow(!show)}>
                <Info_Icon icon={["fas", "circle-info"]} size="sm" />
              </IconButton>
              <Overlay target={target.current} show={show} placement="right">
                {(props) => (
                  <Tooltip id="overlay-example" {...props}>
                    15 minutes preparation time is recommended.
                  </Tooltip>
                )}
              </Overlay>
            </FlexWrapper>
          </ModalHeader>

          <Modal.Body>
            <FlexWrapper justifyContent="center" noPadding>
              <DropdownWrapper width="80">
                <EditableDropdown
                  placeholder="Preparation time"
                  dropdownList={preperationDropDown}
                  handleSelect={(value: DropdownListProps) => {
                    setPreparationValue(value.id);
                    setPreparation(false);
                  }}
                />
              </DropdownWrapper>
            </FlexWrapper>
          </Modal.Body>
        </Modal>
        <Modal
          show={Handovertime}
          onHide={() => {
            setHandovertime(false);
          }}
          size="sm"
        >
          <ModalHeader closeButton>
            <FlexWrapper noMargin noPadding>
              Handover time
              <IconButton ref={target} onClick={() => setShow(!show)}>
                <Info_Icon icon={["fas", "circle-info"]} size="sm" />
              </IconButton>
              <Overlay target={target.current} show={show} placement="right">
                {(props) => (
                  <Tooltip id="overlay-example" {...props}>
                    15 minutes for hand-over is recommended.
                  </Tooltip>
                )}
              </Overlay>
            </FlexWrapper>
          </ModalHeader>
          <Modal.Body>
            <FlexWrapper justifyContent="center" noPadding>
              <DropdownWrapper width="80">
                <EditableDropdown
                  placeholder="Handover time"
                  dropdownList={handoverDropDown}
                  handleSelect={(value: DropdownListProps) => {
                    setHandovertimeValue(value.id);
                    setHandovertime(false);
                    setUnpaidBreakTime(true);
                  }}
                />
              </DropdownWrapper>
            </FlexWrapper>
          </Modal.Body>
        </Modal>
        <Modal
          show={multiVac}
          onHide={() => {
            setMultiVac(false);
          }}
          size="sm"
        >
          <ModalHeader closeButton></ModalHeader>
          <Modal.Body>
            <FlexWrapper justifyContent="center" noPadding>
              <Title style={{ textAlign: "center" }}>
                Please contact us if you need a substitute to fill a position
                for more than one day. We will be pleased to find a consultant
                who can accept a longer assignment.
              </Title>
            </FlexWrapper>
            <FlexWrapper justifyContent="center" noPadding noMargin>
              <ActionButton
                onClick={() => {
                  setMultiVac(false);
                }}
              >
                Close
              </ActionButton>
            </FlexWrapper>
          </Modal.Body>
        </Modal>
        <Modal
          show={uncomHour}
          onHide={() => {
            setuncomHour(false);
          }}
          size="sm"
        >
          <ModalHeader closeButton></ModalHeader>
          <Modal.Body>
            <FlexWrapper justifyContent="center" noPadding>
              <Title style={{ textAlign: "center" }}>
                Break (rast)-According to §15 of the Working Hours Act
                (Arbetstidslagen), an employee may work no more than 5 hours
                without a break. As a rule, it is recommended to allocate a
                break of 30-60 minutes. <br />
                <br />
                Inconvenient working hours are working hours that are not
                scheduled on weekdays between 07:00-18:00. In the event
                inconvenient working hours (OB) are applied, you will be
                invoiced accordingly.
              </Title>
            </FlexWrapper>
            <FlexWrapper justifyContent="center" noPadding noMargin>
              <ActionButton
                onClick={() => {
                  setuncomHour(false);
                }}
              >
                Close
              </ActionButton>
            </FlexWrapper>
          </Modal.Body>
        </Modal>
        <Modal
          show={conshow}
          onHide={() => {
            setConShow(false);
          }}
          size="sm"
        >
          <ModalHeader closeButton></ModalHeader>
          <Modal.Body>
            <FlexWrapper justifyContent="center" noPadding>
              {createVacancy.publish_to_id === "1" ? (
                <Title style={{ textAlign: "center" }}>
                  Publish to "all substitutes" means that the vacancy will be
                  seen by all of our substitutes, which increases the likelihood
                  that it will be promptly filled.
                </Title>
              ) : createVacancy.publish_to_id === "2" ? (
                <Title style={{ textAlign: "center" }}>
                  Publish to "teacher students" means that only substitutes who
                  are studying to become teachers will accept the vacancy.
                </Title>
              ) : createVacancy.publish_to_id === "3" ? (
                <Title style={{ textAlign: "center" }}>
                  When you publish to "my substitutes," only the substitutes you
                  have saved or selected will be able to accept it.
                </Title>
              ) : (
                <Title style={{ textAlign: "center" }}>
                  Publish to "all substitutes" means that the vacancy will be
                  seen by all of our substitutes, which increases the likelihood
                  that it will be promptly filled. <br /> <br /> Publish to
                  "teacher students" means that only substitutes who are
                  studying to become teachers will accept the vacancy. <br />{" "}
                  <br />
                  When you publish to "my substitutes," only the substitutes you
                  have saved or selected will be able to accept it. <br />{" "}
                  <br />
                  All consultants from all different groups have been screened
                  and approved by the DoHR team.
                </Title>
              )}
            </FlexWrapper>
            <FlexWrapper justifyContent="center" noPadding noMargin>
              <ActionButton
                onClick={() => {
                  setConShow(false);
                }}
              >
                Close
              </ActionButton>
            </FlexWrapper>
          </Modal.Body>
        </Modal>

        <Modal
          show={PublishCon}
          onHide={() => {
            setPublishCon(false);
          }}
          size="sm"
        >
          <Modal.Body>
            <FlexWrapper justifyContent="center" noPadding>
              <Title style={{ textAlign: "center" }}></Title>
            </FlexWrapper>
            <FlexWrapper justifyContent="center" noPadding>
              <ActionButton
                onClick={() => {
                  setCreateVacancy({
                    ...createVacancy,
                    choosecons20min: true,
                    publishedtoall30min: false,
                  });
                  setPublishCon(false);
                }}
              >
                Yes
              </ActionButton>
              <ActionButton
                onClick={() => {
                  setCreateVacancy({
                    ...createVacancy,
                    choosecons20min: false,
                    publishedtoall30min: false,
                  });
                  setPublishCon(false);
                }}
              >
                No
              </ActionButton>
            </FlexWrapper>
          </Modal.Body>
        </Modal>
        <Modal
          show={PublishTec}
          onHide={() => {
            setPublishTec(false);
          }}
          size="sm"
        >
          <Modal.Body>
            <FlexWrapper justifyContent="center" noPadding>
              <Title style={{ textAlign: "center" }}>
                In the event that our teacher student consultants are unable to
                accept the job within 30 minutes, do you agree that the vacancy
                will be automatically re-published to all consultants?
              </Title>
            </FlexWrapper>
            <FlexWrapper justifyContent="center" noPadding>
              <ActionButton
                onClick={() => {
                  setCreateVacancy({
                    ...createVacancy,
                    publishedtoall30min: true,
                    choosecons20min: false,
                  });
                  setPublishTec(false);
                }}
              >
                Yes
              </ActionButton>
              <ActionButton
                onClick={() => {
                  setCreateVacancy({
                    ...createVacancy,
                    publishedtoall30min: false,
                    choosecons20min: false,
                  });
                  setPublishTec(false);
                }}
              >
                No
              </ActionButton>
            </FlexWrapper>
          </Modal.Body>
        </Modal>
      </CardWraper>
    </PageWrapper>
  );
};

export default CreateSeperateVacancy;
