import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../services";
import apiEndpoints from "../../../const/apiEndpoints";
import { AddScheduleTypes, scheduleProps } from "./types";

export const AddScheduleApi = createAsyncThunk(
  "MySchedule/AddSchedule",
  async (requestPayload: AddScheduleTypes): Promise<any> => {
    const response = await api.post(apiEndpoints.AddSchedule, requestPayload);

    return response.data;
  }
);

export const GetScheduleApi = createAsyncThunk(
  "MySchedule/GetSchedule",
  async (requestPayload: { unique_id: any }): Promise<any> => {
    const response = await api.post(apiEndpoints.getSchedule, requestPayload);
    return response.data.message;
  }
);

export const GetSingleSchedule = createAsyncThunk(
  "MySchedule/GetSingleSchedule",
  async (requestPayload: { id: string }): Promise<scheduleProps> => {
    const response = await api.post(
      apiEndpoints.getSingleSchedule,
      requestPayload
    );
    return response.data.message;
  }
);

export const EditScheduleApi = createAsyncThunk(
  "MySchedule/EditSchedule",
  async (requestPayload: scheduleProps): Promise<any> => {
    const response = await api.post("Edit_My_Schedule", requestPayload);
    return response.data;
  }
);
