import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { colors } from "../../../const/theme";
import { Button, Fab } from "@mui/material";
import Card from "@mui/material/Card";

interface TypographyProps {
  color?: string;
  isHelpText?: boolean;
  hasPadding?: boolean;
}

export const NewsWrapper = styled.div`
  margin-top: 4rem;
`;

export const ContainerWrapper = styled.div`
  background: linear-gradient(to right, #002c99, #2097f5);
  height: auto;
  padding: 10vh 0px 10vh 0px;
`;

export const Icon = styled(FontAwesomeIcon)`
  margin-top: 3%;
  color: whitesmoke;
  position: absolute;
  animation: bounce 1600ms infinite;
  cursor: pointer;
  &:hover {
    animation: none;
  }
  @keyframes bounce {
    50% {
      transform: translateY(-15px);
    }
  }
`;

export const FilterWrapper = styled.div`
  height: auto;
  margin: 2% 0px 2% 1%;
`;

export const Title = styled.h5`
  margin-left: 12rem;
  font-size: 20px;
  margin-top: 75px;
`;

export const Content = styled.div`
  text-align: center;
  padding-top: 12%;
  padding-bottom: 0px;
`;

export const TitleText = styled.h2`
  color: #fff;
  font-size: 60px;
  position: absolute;
  width: 100%;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  color: whitesmoke;
  transform: translate(-50%, -50%);
  &:nth-child(1) {
    color: transparent;
    -webkit-text-stroke: 2px #fff;
  }
  &:nth-child(2) {
    color: ${colors.darkBlue};
    animation: animate 4s ease-in-out infinite;
  }
  @media (max-width: 900px) {
    font-size: 50px;
    padding-top: 28%;
  }
  @media (max-width: 600px) {
    font-size: 40px;
    padding-top: 48%;
  }
  @media (max-width: 450px) {
    font-size: 30px;
    padding-top: 78%;
  }
  @keyframes animate {
    0%,
    100% {
      clip-path: polygon(
        0% 45%,
        16% 44%,
        33% 50%,
        54% 60%,
        70% 61%,
        84% 59%,
        100% 52%,
        100% 100%,
        0% 100%
      );
    }

    50% {
      clip-path: polygon(
        0% 60%,
        15% 65%,
        34% 66%,
        51% 62%,
        67% 50%,
        84% 45%,
        100% 46%,
        100% 100%,
        0% 100%
      );
    }
  }
`;

interface HeaderTypes {
  font?: string;
  center?: boolean;
}

export const Header = styled.h2<HeaderTypes>`
  margin: 2% 0px 1% 4%;
  text-align: ${({ center }) => (center ? "center" : "left")};
  width: 100%;
  font-size: ${({ font }) => (font ? font : "28px")};
  font-weight: 700;
  color: ${({ color }) => (color ? color : "white")};
`;

export const NewsCardWrapper = styled.div`
  margin: 0px 0px 2% 4%;
`;

export const NewsHeader = styled.p`
  font-size: 4vw;
  font-weight: 700;
  color: white;
  text-align: center;
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 4s linear infinite;
  @keyframes textclip {
    to {
      background-position: -200% center;
    }
  }
`;

export const CardButton = styled(Button)`
  border-radius: 30px !important;
  background-color: #dbe5f1 !important;
`;

export const SignUpButton = styled(Button)`
  background-color: #002c99 !important;
  color: white !important;
  height: 50px;
  font-family: "Montserrat", sans-serif !important;
  font-size: 1.1vw !important;
  font-weight: 600 !important;
  padding: 0px 2% 0px 2% !important;
  text-transform: capitalize !important;
`;

export const SubscribeWrapper = styled.div`
  padding: 2% 0px 2% 0px;
  background-color: #152440;
`;

export const SubscribeHeader = styled.div`
  font-size: 1.5vw;
  color: white;
  text-align: center;
  font-weight: 500;
  margin-bottom: 2%;
`;

export const ReadMoreButton = styled(Fab)`
  animation: readmorebounce 1600ms infinite;
  cursor: pointer;
  &:hover {
    animation: none;
  }
  @keyframes readmorebounce {
    50% {
      transform: translateY(15px);
    }
  }
`;
export const Small = styled.small<TypographyProps>`
  font-size: 18px;
  opacity: ${({ isHelpText }) => (isHelpText ? "0.5" : "1")};
  padding: 2px ${({ hasPadding }) => (hasPadding ? "8px" : "0")};
  font-weight: ${({ isHelpText }) => (isHelpText ? 600 : 400)};
  color: ${({ color }) => (color ? color : colors.black)};
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: "Montserrat";
`;
export const CloseButton = styled(Button)`
  background-color: ${colors.gray};
  color: white;
  border: 2px solid white;
  font-weight: 600;
  font-size: 1.1vw;
  border-radius: 4vw;
`;

export const CardWrappper = styled(Card)`
  margin-left: 220px;
  max-width: 70%;
  @media (max-width: 550px) {
    max-width: 100%;
    margin-left: 0;
  }
`;
